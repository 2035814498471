import SelectV2 from 'components/SelectV2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n/i18n';

const ChangeLanguage = () => {
  const [language, setLanguage] = useState<IItems>();
  const [t] = useTranslation(['Settings']);

  useEffect(() => {
    if (language) i18n.changeLanguage(language.id);
  }, [language]);

  return (
    <div className="w-1/3">
      <SelectV2
        setSelectedItem={setLanguage}
        itemSelected={language}
        showDropDownArrow
        items={[
          {
            id: 'en',
            text: 'EN',
          },
          {
            id: 'es',
            text: 'ES',
          },
        ]}
        name={`${t('language')} (${i18n.language.toUpperCase()})`}
      />
    </div>
  );
};

export default ChangeLanguage;
