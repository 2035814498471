/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { REACT_APP_API } from 'utils/enviroment';

type IError = {
  code: string;
  details: string;
  message: string;
};

const client = (() => {
  return axios.create({
    baseURL: REACT_APP_API,
  });
})();

const request = async <T>(options: AxiosRequestConfig<any>): Promise<T> => {
  const onSuccess = async (response: AxiosResponse<T>) => {
    return response.data;
  };

  const onError = (error: AxiosError<IError>) => {
    if (error.response?.data.message) {
      toast.error(error.response?.data.message);
    }
    return Promise.reject(error.response);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
