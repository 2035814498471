export default {
  en: {
    ////Step1
    forgot: 'Forgot your password?',
    message: 'Please follow the steps, and we will help you recover the password',
    email: 'Email',
    recover: 'Send recovery email',
    havaAnAc: 'Already have an account ',
    logHere: 'Log in here',
    ////Step2
    sent: 'We send a recovery email to ',
    write: ' please enter the code to reset your password.',
    here: 'Type the code here',
    confirm: 'Confirm code',
    resend: 'Resend code',
    ////Step3
    change: 'Reset your password',
    new: 'New password',
    confirmPass: 'Confirm password',
    noMatch: 'Passwords do not match',
    confirm2: 'Confirm',
    ////Step4
    redirecting: 'Your password has been changed successfully, we are redirecting you to login',
    passwordRequirements: 'Password need to have minimum eight characters and a capital letter',
  },
  es: {
    ////Step1
    forgot: '¿Olvidaste tu contraseña?',
    message: 'Siga estos pasos y le ayudaremos a recuperar la contraseña',
    email: 'Correo',
    recover: 'Recuperar',
    havaAnAc: 'Ya tengo una cuenta ',
    logHere: 'Ingresa aquí',

    ////Step2
    sent: 'Te enviamos un código para recuperar tu contraseña a ',
    write: 'por favor digítalo',
    here: 'Digia el código aquí',
    confirm: 'Confirmar código',
    resend: 'Re enviar código',
    ////Step3
    change: 'Recuperar contraseña',
    new: 'Nueva contraseña',
    confirmPass: 'Confirmar contraseña',
    noMatch: 'Las contraseñas no coinciden',
    confirm2: 'Confirmar',
    ////Step4
    redirecting: 'Tu contraseña ha sido cambiada exitosamente, te estamos redirigiendo al login',
    passwordRequirements: 'La contraseña debe tener como mínimo ocho caracteres y una mayúscula',
  },
};
