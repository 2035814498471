import Button from 'components/Button';
import FloatingDialog from 'components/FloatingDialog';
import React, { useState } from 'react';

const createPromise = (): [Promise<boolean>, (value: boolean | PromiseLike<boolean>) => void] => {
  let resolve: (value: boolean | PromiseLike<boolean>) => void = () => {};
  const promise = new Promise<boolean>((res) => {
    resolve = res;
  });
  return [promise, resolve];
};

interface Resolver {
  (value: boolean | PromiseLike<boolean>): void;
}

const useConfirm = () => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState<Resolver | null>(null);
  const [label, setLabel] = useState('');

  const getConfirmation = async (text: string) => {
    setLabel(text);
    setOpen(true);
    const [promise, resolve] = await createPromise();
    setResolver(() => resolve);
    return promise;
  };

  const onClick = (status: boolean) => {
    setOpen(false);
    if (resolver) {
      resolver(status);
    }
  };

  const Confirmation = (): JSX.Element => (
    <FloatingDialog
      className="p-4 bg-white rounded-sm  max-w-[400px]"
      isOpen={open}
      setIsOpen={setOpen}
    >
      <>
        {label}

        <div className="flex justify-between mt-3">
          <Button name="Cancel" size="xs" color="outline" onClick={() => onClick(false)}>
            Cancel
          </Button>
          <Button name="OK" size="xs" color="primary" onClick={() => onClick(true)}>
            Yes
          </Button>
        </div>
      </>
    </FloatingDialog>
  );

  return { getConfirmation, Confirmation };
};

export default useConfirm;
