import { AxiosError } from 'axios';
import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { StepperContext } from 'pages/postShift/core/Infrastructure/Providers/Stepper.provider';
import { DraftUseCase } from 'pages/postShift/core/Modules/Checkout/Application/DraftUseCaes';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import useUser from 'utils/hooks/useUser';
const draftUseCase = new DraftUseCase();

const RecurrentAction = () => {
  const {
    business: { id },
    cognitoUser: { id: businessUserId },
  } = useUser();

  const contextSteps = useContext(StepperContext);
  const invokeSaveDraft = async (data: Partial<DataFormType>) => {
    try {
      const result = await draftUseCase.invokeSave(data as DataFormType, id, businessUserId);
      return result;
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data.message);
      } else {
        toast.error('Something went wrong');
      }
      contextSteps?.changeStep && contextSteps.changeStep('shiftInfo');
      return undefined;
    }
  };

  const invokeDeleteDraft = async (id: string) => {
    try {
      await draftUseCase.invokeDelete(id);
    } catch {
      toast.error('Something went wrong');
    }
  };

  return {
    invokeDeleteDraft,
    invokeSaveDraft,
  };
};

export default RecurrentAction;
