import { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @deprecated Use new InputV2
 */

const Input = ({
  label,
  register,
  name,
  validation,
  defaultValue,
  placeholder,
  type,
  errors,
  rows,
  message,
  ...props
}: IInput & InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [t] = useTranslation(['Others']);
  return (
    <div className="mt-4 text-primary ">
      <label className={`${!label ? 'sr-only' : 'pb-2 text-xs lg:text-base'}`}>{label}</label>
      {type === 'textarea' ? (
        <textarea
          id={name}
          type={type}
          autoComplete={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
          rows={rows}
          className="mt-1 font-light sm:text-lg appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
          placeholder={placeholder}
          {...props}
        />
      ) : (
        <input
          id={name}
          type={type}
          autoComplete={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
          className="mt-1 font-regular text-sm sm:text-lg appearance-none rounded-2xl border border-gray-300 bg-white relative block w-full px-3 py-1.5 placeholder-primary text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
          placeholder={placeholder}
          {...props}
        />
      )}

      <small className="text-red text-left mt-1">
        {errors && (message ? message : t('invalid'))}
      </small>
    </div>
  );
};

export default Input;
