import request from 'actions/api.wrapper';
import { RequestDynamicLink, ResponseDynamicLink } from './models';
import { API_ENDPOINTS } from 'actions/api.endpoints';

export default class IntegrationService {
  public static async createDynamicLink(data: RequestDynamicLink) {
    return request<ResponseDynamicLink>({
      data,
      url: API_ENDPOINTS.INTEGRATION.CREATE_DYNAMIC_LINK,
      method: 'POST',
    });
  }
}
