import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { EditShiftSet } from '../Dtos/EditShiftSet.dto';
import { DateTime } from 'luxon';

export const EditShiftMapper = (data: DataFormType, id: string): EditShiftSet => {
  return {
    abilities: data.shiftInfo.role.abilities.map((v) => v.id),
    breakTime: data.workers.breakTime,
    clockInInfo: data.shiftInfo.location.clockInInfo,
    contacts: data.shiftInfo.contacts,
    dressCode: data.attire.dressCode.id,
    endTime: DateTime.fromJSDate(data.shiftInfo.endTime).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
    startTime: DateTime.fromJSDate(data.shiftInfo.startTime).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
    experience: data.shiftInfo.role.experience.id,
    fullTimeOportunity: data.workers.fullTimeOportunity,
    groomingRestrictions: data.attire.groomingRestrictions.map((v) => v.id),
    id,
    jobInformation: data.shiftInfo.role.information,
    location: {
      address: data.shiftInfo.location.location.location.address,
      notificationsTopic: data.shiftInfo.location.location.location.notificationsTopic,
      topic: data.shiftInfo.location.location.location.topic,
    },
    locationCoords: {
      latitude: data.shiftInfo.location.location.locationCoords.latitude,
      longitude: data.shiftInfo.location.location.locationCoords.longitude,
    },
    locationInfo: data.shiftInfo.location.clockInInfo,
    onlyFavorites: data.workers.selectedAssign,
    openings: data.workers.openings,
    parkingInfo: data.shiftInfo.location.parking,
    positionId: data.shiftInfo.role.category.id,
    positionTitle: data.shiftInfo.role.name ?? data.shiftInfo.role.category.text,
    salaryAmount: data.shiftInfo.role.amount,
    salaryType: data.shiftInfo.role.payment.id,
    timeZone: parseInt(data.shiftInfo.timeZone.id),
    uniform: data.attire.uniform.id,
  };
};
