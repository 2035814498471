import { ICognitoUser } from 'actions/auth.api';
import { ICompany } from 'actions/business/model';
import { useSelector } from 'react-redux';
import { getBusiness, getCognitoUser } from 'store/business/selectors';

const useUser = () => {
  const business = useSelector(getBusiness);
  const cognitoUser = useSelector(getCognitoUser);

  return { business, cognitoUser } as { business: ICompany; cognitoUser: ICognitoUser };
};

export default useUser;
