import { useTranslation } from 'react-i18next';
import CheckoutInformationPresenter from './CheckoutInformation.Presenter';
import useUser from 'utils/hooks/useUser';
import ModalPayment from 'pages/postShift/screens/Checkout/ModalPayment';
import ModalV2 from 'components/ModalV2';
import InputV2 from 'components/InputV2';

type CheckoutInformationScreenProps = {
  setDateConfirmation: (v: boolean) => void;
};

const CheckoutInformationScreen = ({ setDateConfirmation }: CheckoutInformationScreenProps) => {
  const [t] = useTranslation(['Shifts']);
  const presenter = CheckoutInformationPresenter();
  const {
    business: { isMarkdown },
  } = useUser();

  return (
    <div className="md:fixed my-4 m-auto right-6 top-6 max-w-[210px] w-full">
      <div className=" border-neutral-400 border w-full rounded-lg bg-white py-5 px-4">
        {presenter.idDraft ? (
          <>
            <p className="text-base  font-semibold text-secondary-500">
              {t('create.confirm.estimate')}
            </p>
            <div className="flex justify-between text-[15px]">
              <p className="text-neutral-1400 font-normal">
                {presenter.form.dataForm.shiftInfo?.role.name ??
                  presenter.form.dataForm.shiftInfo?.role.category.text}
              </p>

              <p className="text-neutral-1400 font-normal">${presenter.total.total?.toFixed(2)}</p>
            </div>

            <p className="text-neutral-1400 font-normal text-[11px] mt-1.5">
              {presenter.total.openings} {t('create.confirm.opening')}
            </p>

            {presenter.total.hourly && (
              <div className="text-neutral-1400 font-normal text-[11px]">
                ${presenter.form.dataForm.shiftInfo?.role.amount} {t('create.confirm.hourly')}
              </div>
            )}

            {!isMarkdown && (
              <div className="flex justify-between text-[15px] mt-4">
                <p className="text-neutral-1400 font-normal">{t('create.confirm.fee')}</p>

                <p className="text-neutral-1400 font-normal text-[15px]">
                  ${presenter.total.fee.toFixed(2)}
                </p>
              </div>
            )}

            <div className="flex justify-between text-[15px] font-semibold mt-3">
              <p className="text-neutral-1400"> {t('create.confirm.total')}</p>

              <p className="text-neutral-1400 text-[15px]">
                ${(presenter.total.fee + (presenter?.total?.total ?? 0)).toFixed(2)}
              </p>
            </div>

            <button
              disabled={presenter.isLoading}
              onClick={presenter.onCheckout}
              className=" mt-9 text-center bg-primary text-white py-1.5 w-full text-[11px] font-semibold rounded"
            >
              {t('create.confirm.checkout')}
            </button>
          </>
        ) : (
          <>
            <p className="text-base  font-semibold text-secondary-500">
              {t('create.confirm.confirm')}
            </p>
            <button
              onClick={() => setDateConfirmation(true)}
              className=" mt-9 text-center bg-primary text-white py-1.5 w-full text-[11px] font-semibold rounded"
            >
              {t('create.save')}
            </button>
          </>
        )}
      </div>

      {presenter.idDraft && !presenter.form.templateCreated && (
        <div className="bg-white rounded-lg border-neutral-400 w-full py-5 px-4 border mt-4">
          <p className="text-base  font-semibold text-secondary-500">
            {t('create.confirm.template')}

            <InputV2
              placeholder={t('create.confirm.templatePlaceholder')}
              onChange={(e) => presenter.setTemplateName(e.target.value)}
              className="placeholder:text-neutral-1200 font-normal text-xs mt-3"
            />

            <button
              disabled={presenter.templateName === ''}
              onClick={presenter.onCreateTemplate}
              className=" mt-5 text-center bg-primary text-white py-0 w-full text-[11px] font-semibold rounded"
            >
              {t('create.save')}
            </button>
          </p>{' '}
        </div>
      )}

      <div className="w-[90%] m-auto mt-5">
        <button
          onClick={presenter.onOpenConfirmation}
          className="border w-full border-warnings-500 py-1.5 px-1 text-xs rounded text-warnings-500"
        >
          {t('create.confirm.discard')}
        </button>
      </div>

      <ModalV2
        isOpen={presenter.openConfirmation}
        setIsOpen={presenter.setOpenConfirmation}
        classNameContainer="w-full max-w-sm"
      >
        <p className=" text-primary text-lg text-center mb-5">{t('create.confirm.sure')}</p>
        <div className="flex justify-between">
          <button
            onClick={presenter.onCloseConfirmation}
            className=" rounded border border-primary-400 text-primary-400 py-1 px-6"
          >
            {t('create.confirm.cancel')}
          </button>
          <button
            onClick={presenter.onClickDelete}
            className=" rounded bg-primary-400 text-white py-1 px-6 border border-primary-400"
          >
            {t('create.confirm.continue')}
          </button>
        </div>
      </ModalV2>

      <ModalPayment isOpen={presenter.openPayment} setOpenModalPayment={presenter.setOpenPayment} />
    </div>
  );
};

export default CheckoutInformationScreen;
