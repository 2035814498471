import { ContactUseCases } from 'pages/postShift/core/Modules/Form/Application/ContactUseCases';
import { ContactFormSchemaType } from 'pages/postShift/core/Modules/Form/Domain/Dtos/ContactForm.dto';
import useUser from 'utils/hooks/useUser';
import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';
import toast from 'react-hot-toast';

const contactUseCase = new ContactUseCases();
const ContactAction = () => {
  const {
    business: { id },
  } = useUser();

  const invokeGetContacts = async () => await contactUseCase.invokeGet(id);

  const invokeSaveContact = async (d: ContactFormSchemaType) => {
    try {
      const result = await contactUseCase.invokeSave(d, id);
      return {
        text: getTextTranslated(result.data.name),
        id: result.data.id,
      };
    } catch {
      toast.error('Something went wrong creating contact');
    }
  };

  const invokeEditContact = async (data: ContactFormSchemaType, idContact?: string) => {
    try {
      await contactUseCase.invokeEdit(data, idContact ?? '', id);
    } catch {
      toast.error('Something went wrong editing contact');
    }
  };

  return {
    invokeGetContacts,
    invokeSaveContact,
    invokeEditContact,
  };
};

export default ContactAction;
