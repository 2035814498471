import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import { EditRoleSet, SaveRoleSet, ValidateFetchRole } from '../Dtos/Role.dto';
import { EventDataGet } from '../../../Catalog/Domain/Entities/Catalog.entity';

export class RoleRepository {
  public async get(id: string, catalog?: EventDataGet) {
    const result = await instance.get(
      getEndpoint(API_ENDPOINTS.EVENTS.GET_ROLE.replace('{id}', id))
    );

    return ValidateFetchRole(result.data, catalog);
  }

  public async save(data: SaveRoleSet) {
    return await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.SAVE_ROLE), data);
  }

  public async edit(data: EditRoleSet) {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.EDIT_ROLE), data);
  }
}
