import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';
import { LocationSchemaType, LocationFetchSchemaSingleType } from '../Dtos/Location';

export const fetchLocationToForm = (
  data?: LocationFetchSchemaSingleType
): LocationSchemaType | undefined => {
  if (data)
    return {
      info:
        data.locationInfo?.english && data.locationInfo.spanish
          ? getTextTranslated(data.locationInfo)
          : '',
      parking: data.parkingInfo,
      location: {
        location: {
          topic: data.location.topic,
          address: data.location.address,
          notificationsTopic: data.location.notificationsTopic,
        },
        locationCoords: {
          latitude: data.locationCoords.latitude,
          longitude: data.locationCoords.longitude,
        },
      },
      clockInInfo: getTextTranslated(data.clockInInfo),
      id: data.id,
    };
};
