import i18next from 'i18next';

export const PARKING_INFO = [
  {
    id: '94ba7630-f47b-427d-a0a4-be62e7531278',
    text: i18next.t<string>('Shifts:create.location.free'),
  },
  {
    id: '79258476-f609-459b-aadb-56d31fc37e9d',
    text: i18next.t<string>('Shifts:create.location.available'),
  },
  {
    id: 'cc02fc9e-d8e7-4b96-b583-1493fbf20f57',
    text: i18next.t<string>('Shifts:create.location.noParking'),
  },
];
