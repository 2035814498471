import Icon from 'assets/icons';

interface IItemCard {
  icon: string;
  attribute: string;
  value: string;
}

const ItemCard = ({ icon, attribute, value }: IItemCard) => {
  return (
    <div>
      <div className="flex items-center">
        <Icon icon={icon} size={21} />
        <p className="font-semibold ml-3 pb-0">{attribute}</p>
      </div>

      <div>
        <p className="my-2.5">{value}</p>
      </div>
    </div>
  );
};

export default ItemCard;
