export class GetInvoices {
  constructor(
    public list: {
      businessInvoices: {
        id: string;
        paymentOption: number;
        consecutive: number;
        startDate: string;
        endDate: string;
        invoiceDate: string;
        dueDate: string;
        totalAmount: number;
        status: number;
      }[];

      totalItems: number;
    }
  ) {}
}
