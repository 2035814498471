import { AxiosError } from 'axios';
import { TemplateUseCase } from 'pages/shift/core/Modules/Shifts/Application/TemplateUseCase';
import toast from 'react-hot-toast';
import useUser from 'utils/hooks/useUser';

const TemplateUseCases = new TemplateUseCase();

const ShiftsTemplateAction = () => {
  const {
    business: { id },
  } = useUser();

  const executeGetTemplates = async (page: number) => {
    try {
      return TemplateUseCases.invokeGetTemplates(id, page);
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return { executeGetTemplates };
};

export default ShiftsTemplateAction;
