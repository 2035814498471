import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import { EditLocationSet, ValidateFetchLocation } from '../Dtos/Location';
import { SaveLocationSet } from '../Dtos/Location';

export class LocationRepository {
  public async get(id: string) {
    const result = await instance.get(
      getEndpoint(API_ENDPOINTS.EVENTS.GET_LOCATION.replace('{id}', id))
    );
    return ValidateFetchLocation(result.data);
  }
  public async save(data: SaveLocationSet) {
    return await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.SAVE_LOCATION), data);
  }
  public async edit(data: EditLocationSet) {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.SAVE_LOCATION), data);
  }
}
