export class ApplicantShiftGet {
  constructor(
    public applicantShift: {
      shiftApplicants: {
        applicationId: string;
        userId: string;
        name: string;
        userImage: string;
        status: number;
        phoneNumber: string;
        estTotalCost: number;
        applicationDate: string;
        cancelledDate: string | null;
        clockIn: string | null;
        clockOut: string | null;
        breakStart: string | null;
        breakEnd: string | null;
        breakTime: number | null;
        totalCost: number | null;
        shiftFinished: boolean;
        totalShifts: number;
        isPreferred: boolean;
        isBlocked: boolean;
      }[];

      totalItems: number;
    }
  ) {}
}

export interface ApplicantShiftSet {
  filters: {
    shiftId: string;
  };
  page: number;
}

export enum ApplicantStatus {
  ACCEPTED = 1,
  CANCELED = 2,
  PAID = 3,
  NO_CLOCKOUT = 4,
  NO_SHOW = 5,
}

export interface ApplicantStatusSet {
  userId: string;
  businessId: string;
}

export interface ApplicantRejectSet {
  id: string;
  status: ApplicantStatus;
}
