import Icon from 'assets/icons';
import { useTranslation } from 'react-i18next';
import * as React from 'react';

const PasswordInput = ({
  label,
  register,
  name,
  validation,
  defaultValue,
  placeholder,
  errors,
  customStyle,
}: IInput): JSX.Element => {
  const [t] = useTranslation(['Others']);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const typeInput = React.useMemo(() => {
    return showPassword ? 'text' : 'password';
  }, [showPassword]);

  const errorMessage = React.useMemo(() => {
    if (errors) {
      if (errors.message !== '') {
        return errors.message;
      }
      return t('invalid');
    }
    return null;
  }, [errors]);

  return (
    <div>
      <label className="sr-only">{label}</label>
      <div className="relative">
        <input
          className={
            customStyle
              ? customStyle
              : `h-10 w-full mt-4 px-5 font-regular text-base appearance-none rounded-2xl relative bg-[#FFFFFF] placeholder-black text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500`
          }
          id={name}
          type={typeInput}
          placeholder={placeholder}
          {...register(name, validation)}
          autoComplete={name}
          defaultValue={defaultValue}
        />
        {showPassword ? (
          <div onClick={toggleShowPassword}>
            <Icon
              className="fa fa-eye-slash eye_1 absolute top-7 right-3 cursor-pointer"
              icon="eye"
              size={15}
              color="black"
              style={{ marginRight: 2 }}
            />
          </div>
        ) : (
          <div onClick={toggleShowPassword}>
            <Icon
              className="fa fa-eye-slash eye_1 absolute top-7 right-3 cursor-pointer"
              icon="eye-blocked"
              size={15}
              color="black"
              style={{ marginRight: 2 }}
            />
          </div>
        )}
      </div>

      <small className="text-red text-left mt-1">{errorMessage}</small>
    </div>
  );
};

export default PasswordInput;
