import SelectV2 from 'components/SelectV2';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SelectCountryProps = {
  selectedControlled?: IItems;
  setSelectedControlled?: (v: IItems) => void;
  className?: string;
};

export const countries = [
  {
    icon: 'us',
    name: 'United States of America',
    id: '1',
    code: 'us',
  },
  {
    icon: 'co',
    name: 'Colombia',
    id: '2',
    code: 'co',
  },
];

const SelectCountry = ({
  selectedControlled,
  setSelectedControlled,
  className,
}: SelectCountryProps) => {
  const [t] = useTranslation(['Others']);

  const [selectedUncontrolled, setSelectedUncontrolled] = useState<IItems>();

  const selected = selectedControlled ?? selectedUncontrolled;
  const setSelected = setSelectedControlled ?? setSelectedUncontrolled;

  const icon = useMemo(() => {
    return selected ? `https://flagcdn.com/16x12/${selected.id}.png` : undefined;
  }, [selected]);

  return (
    <SelectV2
      itemSelected={selected}
      name={t('selectCountry')}
      setSelectedItem={setSelected}
      items={countries.map((v) => ({ id: v.code, text: v.name }))}
      icon={icon ? <img className="mr-3" src={icon} /> : undefined}
      className={className}
    />
  );
};

export default SelectCountry;
