import { LocationSchemaType } from '../Domain/Dtos/Location';
import { locationFormToDto } from '../Domain/Mappers/locationFormToDto';
import { LocationRepository } from '../Domain/Repository/Location.Repository';

export class LocationUseCases {
  private locationRepository: LocationRepository;

  constructor() {
    this.locationRepository = new LocationRepository();
  }

  public async invokeGet(id: string) {
    return this.locationRepository.get(id);
  }

  public async invokeSave(d: LocationSchemaType, businessId: string) {
    const dto = locationFormToDto(d, businessId);
    return this.locationRepository.save(dto);
  }

  public async invokeEdit(d: LocationSchemaType, id: string, businessId: string) {
    const dto = locationFormToDto(d, businessId);
    return this.locationRepository.edit({ ...dto, businessId, id });
  }
}
