import { Auth } from 'aws-amplify';

const {
  REACT_APP_IDENTITY_CLIENT,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_WEB_CLIENT_ID,
  REACT_APP_OAUTH_DOMAIN,
  REACT_APP_OAUTH_SIGN,
  REACT_APP_OAUTH_SIGNOUT,
} = process.env;

export const IsAuthenticated = async (): Promise<boolean> => {
  try {
    const currentSession = await Auth.currentSession();
    currentSession.getIdToken().getJwtToken();
    return true;
  } catch {
    return false;
  }
};

export const AWSInitialConfiguration = (): void => {
  Auth.configure({
    identityPoolId: REACT_APP_IDENTITY_CLIENT,
    region: REACT_APP_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_WEB_CLIENT_ID,
    oauth: {
      domain: REACT_APP_OAUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: REACT_APP_OAUTH_SIGN,
      redirectSignOut: REACT_APP_OAUTH_SIGNOUT,
      responseType: 'code',
    },
  });
};
