import './index.css';
import 'utils/i18n/i18n';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import React from 'react';
import App from './App';
import store from 'store';

import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import historyObject from './routes/history';
import { Provider } from 'react-redux';
import { REACT_APP_GOOGLE_ANALYTICS } from 'utils/enviroment';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga4';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import { getUserId } from 'utils';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS || '');
hotjar.initialize(3198871, 6);

const instance = createInstance({
  urlBase: 'https://analytics.lachamba.app/',
  siteId: 1,
  userId: getUserId(), // optional, default value: `undefined`.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const element = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(element);

root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <HistoryRouter history={historyObject}>
            <App />
          </HistoryRouter>
        </QueryClientProvider>
      </Provider>
    </MatomoProvider>
  </React.StrictMode>
);
