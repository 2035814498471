import DateTimePicker from 'components/DateTimePicker';
import {
  DisputeOption,
  DisputeType,
  FormType,
} from 'pages/timesheet/core/Modules/Disputes/Domain/Entities/CreateDisputeFormSet';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DisputeAction from './Dispute.Action';
import DatePicker from 'react-datepicker';

const DisputePresenter = (
  executionId: string,
  refetch: () => void,
  setOpen: (e: boolean) => void
) => {
  const action = DisputeAction();
  const [t] = useTranslation(['Timesheet']);
  const [selectedDisputes, setSelectedDisputes] = useState<DisputeOption[]>([]);
  const form = useForm<FormType>();

  const DISPUTE_OPTIONS: DisputeOption[] = [
    {
      id: DisputeType.ClockIn,
      text: t('disputes.clockIn'),
    },
    {
      id: DisputeType.ClockOut,
      text: t('disputes.clockOut'),
    },
    {
      id: DisputeType.BreakStart,
      text: t('disputes.breakStart'),
    },
    {
      id: DisputeType.BreakEnd,
      text: t('disputes.breakEnd'),
    },
    {
      id: DisputeType.NoShow,
      text: t('disputes.noShow'),
    },
  ];

  const onClickDisputes = (d: DisputeOption) => {
    if (d.id === DisputeType.NoShow) {
      setSelectedDisputes([d]);
    } else {
      const alreadySelected = selectedDisputes.filter((e) => e.id === d.id);
      if (alreadySelected.length > 0) {
        setSelectedDisputes([...selectedDisputes.filter((e) => e.id !== d.id)]);
      } else {
        setSelectedDisputes([...selectedDisputes.filter((e) => e.id !== DisputeType.NoShow), d]);
      }
    }
  };

  const onSubmit: SubmitHandler<FormType> = (data) =>
    action.executeCreateDispute(data, selectedDisputes, executionId).then(() => {
      setOpen(false);
      refetch();
    });

  const dynamicForm = useMemo(() => {
    return selectedDisputes.map((e) => {
      switch (e.id) {
        case DisputeType.ClockIn:
          return (
            <div className="my-2">
              <p className="my-4">{t('disputes.correct')} clock in time</p>
              <Controller
                control={form.control}
                name="clockInDate"
                render={(e) => (
                  <>
                    <DatePicker
                      inline={true}
                      showIcon={true}
                      selected={e.field.value}
                      onChange={e.field.onChange}
                    />
                    <p className="text-red text-xs">{e.fieldState.error && 'Required'}</p>
                  </>
                )}
                rules={{
                  required: true,
                }}
              />
              <Controller
                control={form.control}
                name="clockInTime"
                rules={{
                  required: true,
                }}
                render={(e) => (
                  <>
                    <DateTimePicker onChangeField={e.field.onChange} />
                    <p className="text-red text-xs">{e.fieldState.error && 'Required'}</p>
                  </>
                )}
              />

              <textarea
                placeholder={t('disputes.addInformation')}
                className="border-[1px] border-neutral-200 rounded-xl py-1.5 px-3 w-full mt-2 placeholder-gray-500"
                rows={6}
                {...form.register('additionalClockIn')}
              />
            </div>
          );

        case DisputeType.ClockOut:
          return (
            <div>
              <p className="my-4">{t('disputes.correct')} clock out time</p>
              <Controller
                control={form.control}
                name="clockOutDate"
                render={(e) => (
                  <>
                    <DatePicker
                      inline={true}
                      showIcon={true}
                      selected={e.field.value}
                      onChange={e.field.onChange}
                    />
                    <p className="text-red text-xs">{e.fieldState.error && 'Required'}</p>
                  </>
                )}
                rules={{
                  required: true,
                }}
              />
              <Controller
                control={form.control}
                name="clockOutTime"
                render={(e) => (
                  <>
                    <DateTimePicker onChangeField={e.field.onChange} />
                    <p className="text-red text-xs">{e.fieldState.error && 'Required'}</p>
                  </>
                )}
                rules={{
                  required: true,
                }}
              />

              <textarea
                className="border-[1px] border-neutral-200 rounded-xl py-1.5 px-3 w-full mt-2 placeholder-gray-500"
                placeholder={t('disputes.addInformation')}
                rows={6}
                {...form.register('additionalClockOut')}
              />
            </div>
          );

        case DisputeType.BreakEnd:
          return (
            <div>
              <div>
                <p className="my-4">{t('disputes.correct')} break time start</p>
                <Controller
                  control={form.control}
                  name="breakTimeEnd"
                  render={(e) => (
                    <>
                      <DateTimePicker onChangeField={e.field.onChange} />
                      <p className="text-red text-xs">{e.fieldState.error && 'Required'}</p>
                    </>
                  )}
                  rules={{
                    required: true,
                  }}
                />
                <textarea
                  className="border-[1px] border-neutral-200 rounded-xl py-1.5 px-3 w-full mt-2 placeholder-gray-500"
                  placeholder={t('disputes.addInformation')}
                  rows={6}
                  {...form.register('additionalBreakTimeStart')}
                />
              </div>
            </div>
          );

        case DisputeType.BreakStart:
          return (
            <div>
              <div>
                <p className="my-4">{t('disputes.correct')} break time end</p>
                <Controller
                  control={form.control}
                  name="breakTimeStart"
                  rules={{
                    required: true,
                  }}
                  render={(e) => (
                    <>
                      <DateTimePicker onChangeField={e.field.onChange} />
                      <p className="text-red text-xs">{e.fieldState.error && 'Required'}</p>
                    </>
                  )}
                />
                <textarea
                  className="border-[1px] border-neutral-200 rounded-xl py-1.5 px-3 w-full mt-2 placeholder-gray-500"
                  placeholder={t('disputes.addInformation')}
                  rows={6}
                  {...form.register('additionalBreakTimeStart')}
                />
              </div>
            </div>
          );

        case DisputeType.NoShow:
          return (
            <textarea
              className="border-[1px] border-neutral-200 rounded-xl py-1.5 px-3 w-full mt-2 placeholder-gray-500"
              placeholder={t('disputes.addInformation')}
              rows={6}
              {...form.register('additionalInformationNoShow')}
            />
          );
      }
    });
  }, [selectedDisputes]);

  return {
    DISPUTE_OPTIONS,
    selectedDisputes,
    onClickDisputes,
    dynamicForm,
    onSubmit,
    form,
  };
};

export default DisputePresenter;
