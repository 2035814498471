import * as React from 'react';
import { Tab } from '@headlessui/react';
import RecentlyWorked from './components/RecentlyWorked';
import { useTranslation } from 'react-i18next';
import Browse from './components/Browse';
import Favorites from './components/Favorites';
import Blocked from './components/Blocked';
import SearchLocation from 'components/AutocompleteLocation';
import { ProfesionalsContext } from './context';
import { ILocationData } from 'actions/shifts.api';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import InputName from './components/InputName';
import Hire from './components/Hire';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const Professionals = (): JSX.Element => {
  const [t] = useTranslation('ApplicantsProfile');
  const [location, setlocation] = React.useState<ILocationData>();
  const [refresh, setRefresh] = React.useState<boolean | undefined>(undefined);
  const [name, setName] = React.useState('');
  const [idHire, setIdHire] = React.useState<{ name: string; id: string }>();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Professionals',
      href: window.location.href,
    });
  }, []);

  return (
    <ProfesionalsContext.Provider value={{ refresh, location, setRefresh, name, setName }}>
      <div className="flex w-full h-screen bg-gray-400 ">
        <div className="w-full flex justify-center py-12">
          <div className="w-11/12 2xl:w-4/5 flex ">
            <div className="w-full lg:w-1/2 px-4 xl:px-16">
              <h2 className="font-bold text-2xl">{t('professionals')}</h2>
              <div className="w-full px-2 pt-8 sm:px-0">
                <Tab.Group>
                  <Tab.List className="flex max-w-md space-x-1 rounded-xl bg-gray-700 p-1">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-1 text-sm  leading-5 text-blue-700 font-bold',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                          selected ? 'bg-white shadow' : 'text-black  hover:bg-white/[0.12]'
                        )
                      }
                    >
                      {t('browse')}
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-1 text-sm  leading-5 text-blue-700 font-bold',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                          selected ? 'bg-white shadow' : 'text-black  hover:bg-white/[0.12]'
                        )
                      }
                    >
                      {t('favorites')}
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-1 text-sm  leading-5 text-blue-700 font-bold',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                          selected ? 'bg-white shadow' : 'text-black  hover:bg-white/[0.12] '
                        )
                      }
                    >
                      {t('blocked')}
                    </Tab>
                  </Tab.List>

                  <div className="flex">
                    <SearchLocation
                      messageLocationAvailableShow={false}
                      className="bg-gray-400 mt-2"
                      classNameInput="rounded-lg font-medium text-sm sm:text-base appearance-none  relative block w-full px-4 py-2 border border-grayBack bg-white placeholder-primary text-gray-900 focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                      setValue={(data) => {
                        setlocation(data);
                      }}
                      placeholder={t<string>('cityName')}
                    />

                    <InputName />
                  </div>

                  <Tab.Panels className="mt-2">
                    <Tab.Panel
                      className={classNames(
                        'rounded-xl ',
                        'ring-gray-400 focus:outline-none focus:ring-2'
                      )}
                    >
                      <Browse setIdHire={setIdHire} />
                    </Tab.Panel>
                    <Tab.Panel
                      className={classNames(
                        'rounded-xl ',
                        'ring-gray-400 focus:outline-none focus:ring-2'
                      )}
                    >
                      <Favorites />
                    </Tab.Panel>
                    <Tab.Panel
                      className={classNames(
                        'rounded-xl ',
                        'ring-gray-400 focus:outline-none focus:ring-2'
                      )}
                    >
                      <Blocked />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
            <div className="w-1/2 hidden lg:flex">
              <RecentlyWorked />
            </div>
          </div>
        </div>
      </div>

      {idHire !== undefined && (
        <Hire userId={idHire.id} userName={idHire.name} setHire={setIdHire} />
      )}
    </ProfesionalsContext.Provider>
  );
};

export default Professionals;
