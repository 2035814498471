import * as React from 'react';
import { Menu, Transition } from '@headlessui/react';
import Icon from 'assets/icons';
import { useTranslation } from 'react-i18next';
import StarRating from 'components/StarsRating';
import ImageAvatar from 'components/ImageAvatar';
export interface ProfileCardProps {
  key: string;
  name: string;
  totalShifts?: number;
  userImage: string;
  userId: string;
  preferredUserId: string;
  isFavorite?: boolean;
  rating?: number;
  ratesNumber: number;
  isBlocked?: boolean;
  showProfile?: boolean;
  hiredDate?: string;
  offerDate?: string;
  declinedDate?: string;
  openProfile?: () => void;
  onRefresh: () => void;
  menuItems: JSX.Element;
}

const ProfileCard = ({
  name,
  totalShifts,
  userImage,
  showProfile = true,
  openProfile,
  menuItems,
  ratesNumber,
  rating,
  hiredDate,
  offerDate,
  declinedDate,
}: ProfileCardProps): JSX.Element => {
  const [t] = useTranslation('ApplicantsProfile');

  return (
    <div className="flex w-full flex-col bg-white p-5 rounded-2xl border border-[#D7D7D7] my-4">
      <div className="w-full flex">
        <div className="flex w-11/12">
          <div className="mr-5">
            <ImageAvatar url={userImage} size={48} />
          </div>
          <div>
            <div>
              <div className="flex">
                <h3 className="font-bold text-base">{name}</h3>
                {hiredDate && (
                  <h3 className="text-secondary-400 font-semibold ml-2">{t('profile.hired')}</h3>
                )}
                {declinedDate && (
                  <h3 className="text-warnings-500 font-semibold ml-2">{t('profile.declined')}</h3>
                )}
              </div>
              {!hiredDate && !offerDate && !declinedDate && (
                <p className="text-xs text-grayText">
                  {t('profile.shiftsDone')} {totalShifts}
                </p>
              )}
              {hiredDate && (
                <p className="text-xs text-grayText">
                  {t('profile.hiredSince')}: {hiredDate}
                </p>
              )}
              {offerDate && (
                <p className="text-xs text-grayText">
                  {t('profile.offerSent')}: {offerDate}
                </p>
              )}
              {declinedDate && (
                <p className="text-xs text-grayText">
                  {t('profile.declined')}: {declinedDate}
                </p>
              )}

              <div className=" flex items-center">
                <p className="text-xs text-grayText mr-2">
                  {t('profile.rating')} {rating}
                </p>
                <div className="text-secondary-500">
                  <StarRating defaultRating={rating} rates={ratesNumber} size={10} disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/12 flex justify-end">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <Icon icon={'dots'} size={15} className="cursor-pointer" />
              </Menu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">{menuItems}</div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {showProfile && (
        <div className="w-full mt-2 flex justify-center">
          <div className="flex cursor-pointer" onClick={openProfile}>
            <p className="text-sm text-secondary-500 ">{t('profile.viewFullProfile')}</p>
            <Icon
              icon="ChevronDown"
              size={8}
              className="self-center ml-1"
              color="text-secondary-500"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
