import { HireUseCase } from 'pages/hire/core/Modules/Hire/Application/HireUseCases';
import { HireListSet } from 'pages/hire/core/Modules/Hire/Domain/Entities/HireListSet';
import toast from 'react-hot-toast';

const hireApp = new HireUseCase();

const IndexScreenAction = () => {
  const executeGetList = (d: HireListSet) => {
    try {
      return hireApp.invokeGetList(d);
    } catch {
      toast.error('Something went wrong');
    }
  };

  return { executeGetList };
};

export default IndexScreenAction;
