import { InferType, array, bool, number, object, string } from 'yup';
import { ListShiftResultGet } from '../Entities/ListShift.entity';

export const ListSheetShiftSchema = object({
  timesheetItems: array()
    .required()
    .of(
      object({
        executionId: string().required(),
        userId: string().required(),
        shiftId: string().required(),
        userName: string().required().nullable(),
        shiftName: string().required(),
        clockIn: string().required().nullable(),
        clockOut: string().required().nullable(),
        breakStart: string().required().nullable(),
        breakEnd: string().required().nullable(),
        totalHours: number().required().nullable(),
        tips: number().notRequired(),
        rating: number().required(),
        timesheetStatus: number().required(),
        isPreferred: bool(),
        isBlocked: bool(),
      })
    ),
  totalItems: number().required(),
});

export type ListSheetShiftDto = InferType<typeof ListSheetShiftSchema>;

export const dtoToListSheetShiftGet = (dto: ListSheetShiftDto): ListShiftResultGet => {
  const validateDto = ListSheetShiftSchema.validateSync(dto);
  const listSheetShiftGet = new ListShiftResultGet({
    timesheetItems: validateDto.timesheetItems.map((e) => ({
      ...e,
      tips: e.tips ?? 0,
    })),
    totalItems: validateDto.totalItems,
  });

  return listSheetShiftGet;
};
