import { ShiftRepositoryType } from 'pages/shift/core/Infrastructure/Contracts/Shift.contract';
import { ShiftPaginationSet } from '../Entities/ShiftPaginationSet';
import instance from 'actions/http';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import { dtoToPaginatedShiftGet } from '../Dtos/PaginatedShifts.dto';
import { dtoToDescriptionShiftGet } from '../Dtos/DescriptionShift.dto';
import { ShiftPaginatedRequest, ShiftsCalendarSet } from '../Entities/ShiftsCalendarSet';
import { dtoToCalendarShiftGet } from '../Dtos/CalendarShifts.dto';
import { dtoToCalendarShifPaginatedGet } from '../Dtos/CalendarShiftPaginated.dto';

export class ShiftRepository implements ShiftRepositoryType {
  public async getPaginatedShifts(d: ShiftPaginationSet) {
    const request = await instance.post(getEndpoint(API_ENDPOINTS.SEARCH.GET_SHIFT_LIST), d);
    return dtoToPaginatedShiftGet(request.data);
  }

  public async getShiftDescription(shiftId: string) {
    const request = await instance.post(getEndpoint(API_ENDPOINTS.SEARCH.GET_SHIFT_DESCRIPTION), {
      shiftId,
    });

    return dtoToDescriptionShiftGet(request.data);
  }

  public async deleteShiftRepository(id: string) {
    await instance.delete(getEndpoint(API_ENDPOINTS.EVENTS.DELETE_SHIFT.replace('{id}', id)));
  }

  public async getCalendarShifts(payload: ShiftsCalendarSet) {
    const request = await instance.post(
      getEndpoint(API_ENDPOINTS.SEARCH.GET_POSTED_SHIFTS),
      payload
    );

    return dtoToCalendarShiftGet(request.data);
  }

  public async getShiftPaginated(data: ShiftPaginatedRequest) {
    const request = await instance.post(
      getEndpoint(API_ENDPOINTS.SEARCH.GET_SHIFT_PAGINATED),
      data
    );
    return dtoToCalendarShifPaginatedGet(request.data);
  }
}
