import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';

const NotFound = (): JSX.Element => {
  const navigate = useNavigate();
  const [t] = useTranslation(['Page404']);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Not found page',
      href: window.location.href,
    });
  }, []);

  return (
    <section className="h-screen flex items-center p-16 dark:bg-gray-300 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">{t('sorry')}</p>
          <p className="mt-4 mb-8 dark:text-gray-400">{t('but')}</p>
          <a
            onClick={() => navigate(RoutesConstant.unsecure.home)}
            rel="noopener noreferrer"
            href="#"
            className="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900"
          >
            {t('back')}
          </a>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
