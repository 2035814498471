import ModalV2 from 'components/ModalV2';
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import DisputePresenter from './Dispute.Presenter';
import Button from 'components/Button';

type DisputeContextProps = {
  //
};

const DisputeContext = createContext({} as Partial<DisputeContextProps>);

type DisputeScreenProps = {
  open: boolean;
  setOpen: (e: boolean) => void;
  executionId: string;
  refetch: () => void;
};

const DisputeScreen = ({ open, setOpen, executionId, refetch }: DisputeScreenProps) => {
  const [t] = useTranslation(['Timesheet']);

  const presenter = DisputePresenter(executionId, refetch, setOpen);

  return (
    <ModalV2
      isOpen={open}
      setIsOpen={setOpen}
      className="max-w-[470px] max-h-[522px] overflow-auto"
    >
      <DisputeContext.Provider value={{ presenter }}>
        <p className="text-primary font-semibold text-xl">{t('disputes.title')}</p>
        <p className="mt-2 text-lg">{t('disputes.description')}</p>

        <div className="my-1">
          {presenter.DISPUTE_OPTIONS.map((e, key) => {
            return (
              <button
                onClick={() => presenter.onClickDisputes(e)}
                key={key}
                className={`${
                  presenter.selectedDisputes.some((val) => e.id === val.id)
                    ? 'bg-secondary-500 text-white'
                    : ''
                }  px-3 py-2 shadow rounded-2xl mx-0.5 mt-2`}
              >
                {e.text}
              </button>
            );
          })}
        </div>
        <form onSubmit={presenter.form.handleSubmit(presenter.onSubmit)}>
          <div className="mt-4">{presenter.dynamicForm.map((e) => e)}</div>
          <div className="flex justify-end mt-2">
            <Button
              name="Cancel"
              color="ghost"
              size="sm"
              className="mr-4"
              type="button"
              onClick={() => setOpen(false)}
            />
            <Button name="Send" size="sm" type="submit" />
          </div>
        </form>
      </DisputeContext.Provider>
    </ModalV2>
  );
};

export default DisputeScreen;
