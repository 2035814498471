import { createSlice } from '@reduxjs/toolkit';
import { IApplicantResume, IShiftItems } from 'actions/shifts.api';
import { GetApplicantResumeThunk } from './api.thunks';

export interface IShiftsReducer {
  postedShifts: Array<{
    id: string;
    title: string;
    start: string;
    end: string;
    temp?: boolean;
    isActive: boolean;
  }>;
  shiftApplicants: IShiftItems | null;
  applicantResume: IApplicantResume | null;
}

const initialState: IShiftsReducer = {
  postedShifts: [],
  shiftApplicants: null,
  applicantResume: null,
};

const shiftSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetApplicantResumeThunk.pending, (state) => {
      state.applicantResume = null;
    });
    builder.addCase(GetApplicantResumeThunk.fulfilled, (state, action) => {
      state.applicantResume = action.payload;
    });
  },
});

export default shiftSlice.reducer;
