import { TIME_ZONE } from 'utils/constants/days';
import { ShiftPaginationSet } from '../Domain/Entities/ShiftPaginationSet';
import { ShiftRepository } from '../Domain/Repository/Shift.repository';
import { DateTime } from 'luxon';
import { ShiftPaginatedRequest, ShiftsCalendarSet } from '../Domain/Entities/ShiftsCalendarSet';
import { TFunction } from 'i18next';

export class ShiftUseCase {
  private shiftRepository = new ShiftRepository();

  public async invokeGetShifts(d: ShiftPaginationSet) {
    return this.shiftRepository.getPaginatedShifts(d);
  }

  public async invokeGetShiftDescription(shiftId: string) {
    return this.shiftRepository.getShiftDescription(shiftId);
  }

  public async invokeDeleteShift(shiftId: string) {
    return this.shiftRepository.deleteShiftRepository(shiftId);
  }

  public is24hDelete = (t: TFunction, timeZone?: string, startDate?: string) => {
    const timezoneData = TIME_ZONE(t).find((v) => v.id == timeZone) ?? TIME_ZONE(t)[0];
    const result = DateTime.fromISO(startDate ?? '').setZone(timezoneData.key);
    const now = DateTime.now().setZone(timezoneData.key);

    return Math.abs(result.diff(now, 'hour').hours) <= 24;
  };

  public invokeGetCalendarShifts = async (payload: ShiftsCalendarSet) => {
    return this.shiftRepository.getCalendarShifts(payload);
  };
  public getShiftPaginated = async (payload: ShiftPaginatedRequest) => {
    return this.shiftRepository.getShiftPaginated(payload);
  };
}
