import { InferType, array, boolean, number, object, string } from 'yup';
import { ShiftPaginationGet } from '../Entities/ShiftPagination.entity';
import toast from 'react-hot-toast';

export const PaginatedShiftSchema = object({
  shiftListItems: array()
    .required()
    .of(
      object({
        id: string().required(),
        positionTitle: string().required(),
        location: string().required(),
        startDate: string().required(),
        endDate: string().required(),
        openings: number().required(),
        timeZone: string().required(),
        totalApplicants: number().required(),
        isTotalCompensation: boolean().required(),
        salaryAmount: number().required(),
        totalCost: number().required(),
        isActive: boolean().required(),
      }).required()
    ),

  totalItems: number().required(),
});

export type PaginatedShiftDto = InferType<typeof PaginatedShiftSchema>;

export const dtoToPaginatedShiftGet = async (dto: PaginatedShiftDto) => {
  try {
    const validatedDto = await PaginatedShiftSchema.validate(dto);

    return new ShiftPaginationGet(validatedDto);
  } catch (e) {
    console.error(e);
    toast.error('Please contact support');
  }
};
