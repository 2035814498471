import { useQuery } from '@tanstack/react-query';
import IndexScreenAction from './Index.Action';
import { useEffect, useMemo, useState } from 'react';
import { HireListSet } from 'pages/hire/core/Modules/Hire/Domain/Entities/HireListSet';
import useUser from 'utils/hooks/useUser';
import { HireListGet } from 'pages/hire/core/Modules/Hire/Domain/Entities/HireList.entity';

const IndexPresenterHire = () => {
  const action = IndexScreenAction();
  const [page, setPage] = useState(1);
  const [selectedPro, setSelectedPro] = useState<HireListGet['list']['businessHires'][0]>();
  const [selectedHire, setSelectedHire] = useState<string>();
  const [selectedState, setSelectedState] = useState<IItems | undefined>();
  const {
    business: { id },
  } = useUser();

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };
  useEffect(() => {
    console.log(selectedState);
  }, [selectedState]);

  const filters = useMemo<HireListSet>(() => {
    if (selectedState) {
      return {
        businessId: id,
        filters: {
          status: parseInt(selectedState.id),
        },
        page,
      };
    } else
      return {
        businessId: id,
        filters: {},
        page,
      };
  }, [page, selectedState]);

  const query = useQuery(['get-hire-list', filters], () => action.executeGetList(filters));

  return {
    query,
    handlePageClick,
    setSelectedPro,
    selectedPro,
    setSelectedHire,
    selectedHire,
    selectedState,
    setSelectedState,
  };
};

export default IndexPresenterHire;
