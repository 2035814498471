import Card from 'components/Card';
import ItemCard from './ItemCard';
import useUser from 'utils/hooks/useUser';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';

const PersonalCard = () => {
  const {
    cognitoUser: { name: nameUser, email: emailUser },
    business: { phoneNumber },
  } = useUser();

  const [t] = useTranslation(['Profile']);
  return (
    <>
      <p className="py-3 font-semibold text-grayText">Personal:</p>
      <Card>
        <div className="flex">
          <Avatar width="w-[36px]" height="h-[36px]" name={nameUser} />
          <p className="text-base font-semibold ml-3.5 pt-1 text-primary">{nameUser} </p>
        </div>

        <hr className="my-2.5 w-full" />

        <ItemCard attribute={t('emailAddress')} icon="Envelope" value={emailUser} />

        <hr className="my-2.5 w-full" />

        <ItemCard attribute={t('phoneNumber')} icon="phone" value={phoneNumber} />
      </Card>
    </>
  );
};

export default PersonalCard;
