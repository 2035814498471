import { yupResolver } from '@hookform/resolvers/yup';
import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import {
  WorkerFormSchema,
  WorkerFormSchemaType,
} from 'pages/postShift/core/Modules/Form/Domain/Dtos/WorkerForm.dto';
import { useContext, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { set } from 'lodash';
import { StepperContext } from 'pages/postShift/core/Infrastructure/Providers/Stepper.provider';
import { entityToFormWorker } from 'pages/postShift/core/Modules/Checkout/Domain/Mappers/entityToForm.mapper';

const WorkerPresenter = () => {
  const [t] = useTranslation(['Shifts']);
  const contextForm = useContext(DataFormContext);
  const stepper = useContext(StepperContext);

  const form = useForm({
    resolver: yupResolver(WorkerFormSchema),
    defaultValues: {
      ...(entityToFormWorker(contextForm) as object),
    },
  });

  const unPaidBreaks = useMemo<IItems[]>(() => {
    const result: IItems[] = [];
    for (let i = 0; i <= 120; i++) {
      const multiple = i % 5;
      if (multiple === 0) {
        result.push({
          id: (i / 60).toFixed(2).toString(),
          text: `${i} ${t<string>('mins')}`,
        });
      }
    }
    return result;
  }, [t]);

  const onSubmit: SubmitHandler<WorkerFormSchemaType> = (data) => {
    const finalValue = set(contextForm.dataForm, 'workers', data);
    contextForm.setDataForm({ ...finalValue });

    stepper.changeStep('attire', true);
  };

  const onClickBack = () => {
    stepper.changeStep('shiftInfo', true);
  };

  return {
    unPaidBreaks,
    form,
    onClickBack,
    onSubmit,
  };
};

export default WorkerPresenter;
