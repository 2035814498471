import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import { EditContactnSet, SaveContactSet, ValidateFetchContact } from '../Dtos/ContactForm.dto';

export class ContactRepository {
  public async get(id: string) {
    const result = await instance.get(
      getEndpoint(API_ENDPOINTS.EVENTS.GET_CONTACT.replace('{id}', id))
    );
    return ValidateFetchContact(result.data);
  }
  public async save(data: SaveContactSet) {
    return await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.SAVE_CONTACT), data);
  }
  public async edit(data: EditContactnSet) {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.SAVE_CONTACT), data);
  }
}
