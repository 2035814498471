import IcoMoon from 'react-icomoon';

import iconSet from './selection.json';

const Icon = ({ icon, color, size, className, style }: IICon) => {
  return (
    <IcoMoon
      color={color}
      iconSet={iconSet}
      size={size}
      icon={icon}
      style={style}
      className={className}
    />
  );
};

export default Icon;
