import { useTranslation } from 'react-i18next';

const PendingItem = () => {
  const [t] = useTranslation(['Timesheet']);

  return (
    <div className="flex">
      <div className="flex items-center ml-5">
        <div className="w-[8px] h-[8px] mr-2  bg-secondary-500 rounded-full" />
        <p>{t('pending')}</p>
      </div>

      <div className="flex items-center ml-5">
        <div className="w-[8px] h-[8px] mr-2 bg-neutral-1000 rounded-full" />
        <p>{t('approve')}</p>
      </div>
    </div>
  );
};

export default PendingItem;
