import { PaymentOptionEnum } from 'actions/business/model';
import Icon from 'assets/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/hooks/useUser';
import Step2Payment from './Step2';

const SelectPlan = () => {
  const {
    business: { paymentOption },
  } = useUser();

  const [t] = useTranslation(['Profile']);
  const [selectedPayment, setSelectedPayment] = useState(PaymentOptionEnum.NotSelected);

  const [changeEmail, setChangeEmail] = useState(false);

  useEffect(() => {
    if (paymentOption === PaymentOptionEnum.NotSelected) {
      setSelectedPayment(paymentOption);
    } else if (paymentOption) {
      setChangeEmail(true);
    }
  }, [paymentOption]);

  const onClickItem = (e: PaymentOptionEnum) => {
    setSelectedPayment(e);
    setChangeEmail(true);
  };

  return (
    <>
      {changeEmail && <Step2Payment selectedPayment={selectedPayment} />}

      {!changeEmail && (
        <>
          <p className="mt-5 text-primary font-semibold">Select a payment method:</p>
          <div
            onClick={() => onClickItem(PaymentOptionEnum.AutoCharge)}
            className="bg-white rounded-xl cursor-pointer flex w-full justify-between px-10 items-center py-5 border-neutral-200 border-[1.5px] mt-7"
          >
            <Icon icon={'addCreditCard'} size={46} />
            <div className="w-full max-w-[600px] px-3">
              <span className="font-bold text-base text-primary">
                {' '}
                {t('paymentShift.method1')}{' '}
              </span>
              <p>{t('paymentShift.method1description')}</p>
            </div>
            <Icon icon={'arrow'} color="red" size={20} />
          </div>
          <div
            onClick={() => onClickItem(PaymentOptionEnum.InvoicePerWeek)}
            className="bg-white rounded-xl cursor-pointer flex w-full justify-between px-10 items-center py-5 border-neutral-200 border-[1.5px] mt-7"
          >
            <Icon icon={'calendar'} size={46} />
            <div className="w-full max-w-[600px] px-3 sm:px-0">
              <div className="">
                <span className="font-bold text-base text-primary">
                  {t('paymentShift.method4')}
                </span>
                <p>{t('paymentShift.method4description')}</p>
              </div>
            </div>
            <Icon icon={'arrow'} color="red" size={20} />
          </div>

          <div className="mb-5" />
        </>
      )}
    </>
  );
};

export default SelectPlan;
