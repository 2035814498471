export class ListShiftResultGet {
  constructor(
    public resultListShift: {
      timesheetItems: {
        executionId: string;
        userId: string;
        shiftId: string;
        userName: string | null;
        shiftName: string;
        clockIn: string | null;
        clockOut: string | null;
        breakStart: string | null;
        breakEnd: string | null;
        totalHours: number | null;
        tips?: number;
        rating: number;
        timesheetStatus: number;
        isPreferred?: boolean | undefined;
        isBlocked?: boolean | undefined;
      }[];

      totalItems: number;
    }
  ) {}
}

export enum TimeSheetStatus {
  pending = 0,
  approved = 1,
  disputed = 2,
}
