import { GetInvoicesSet } from '../Domain/Entities/GetInvoicesSet';
import { InvoiceRepository } from '../Domain/Repository/Invoice.repository';

export class InvoiceUseCase {
  private invoiceRepository = new InvoiceRepository();

  public async invokeGetInvoices(d: GetInvoicesSet) {
    return this.invoiceRepository.getBusinessInvoices(d);
  }

  public async invokeGetInvoiceDescription(id: string) {
    return this.invoiceRepository.getInvoiceDetail({
      invoiceId: id,
    });
  }
}
