import Input from 'components/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { passwordRegex, RoutesConstant, TERMS } from 'utils/constants';
import { useEffect, useMemo, useState } from 'react';
import {
  CloseSession,
  ILoginUser,
  IRegisterInvitedUser,
  IRegisterUser,
  LoginUser,
  RegisterInvtedUser,
} from 'actions/auth.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import PasswordInput from 'components/PasswordInput';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { REACT_APP_CDN } from 'utils/enviroment';
import { GetBusinessThunk, GetBusinessUserIdThunk } from 'store/business/api.thunks';
import { useAppDispatch } from 'store';

export interface IRegisterInvite extends IRegisterUser {
  position: string;
}

export interface IDataObject {
  id: string;
  name: string;
  role: number;
  email: string;
}

const RegisterScreen = (): JSX.Element => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [dataUrl, setDataUrl] = useState<string>();
  const navigate = useNavigate();
  const { trackPageView, trackEvent } = useMatomo();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IRegisterInvite>();

  const [t] = useTranslation(['Register']);

  const dataFromUrl = useMemo<IDataObject | null>(() => {
    if (dataUrl) {
      const result: IDataObject = JSON.parse(Buffer.from(dataUrl, 'base64').toString());
      return result;
    }
    return null;
  }, [dataUrl]);

  const loginUser = async (data: ILoginUser) => {
    setLoading(true);
    LoginUser(data)
      .then((result) => {
        if (result) {
          dispatch(GetBusinessUserIdThunk(result.getIdToken().payload.sub))
            .unwrap()
            .then((result) => {
              if (!result.isActive) {
                setLoading(false);
                toast.error(t('notFound'));
                CloseSession();
              } else if (result.businessId) {
                dispatch(GetBusinessThunk(result.businessId));
                navigate(RoutesConstant.secure.shift);
              } else {
                navigate(RoutesConstant.secure.onBoarding);
              }
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
      });
  };

  const onSubmit: SubmitHandler<IRegisterInvite> = (data) => {
    setLoading(true);
    if (dataFromUrl) {
      const requestData: IRegisterInvitedUser = {
        businessId: dataFromUrl.id,
        email: dataFromUrl.email,
        name: data.name,
        password: data.password,
        position: data.position,
        role: dataFromUrl.role,
      };
      RegisterInvtedUser(requestData)
        .then(() => {
          toast.success(`${t('success')}`);
          trackEvent({
            category: 'sign-up-invited',
            action: 'click-event',
          });
          loginUser({
            email: requestData.email,
            password: requestData.password,
          });
        })
        .catch((e) => {
          toast.error(e.message);
        })
        .finally(() => setLoading(false));
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const password = watch('password');

  useEffect(() => {
    const result = searchParams.get('data');
    if (result) {
      setDataUrl(result);
    } else {
      navigate(RoutesConstant.unsecure.home, { replace: true });
    }
  }, [searchParams]);

  useEffect(() => {
    trackPageView({
      documentTitle: 'Register invited',
      href: window.location.href,
    });
  }, []);

  return (
    <div>
      <div className="flex text-primary items-center h-screen bg-gray-400">
        <div className="w-full lg:w-1/2 px-5 xl:px-20">
          <h2 className="text-2xl sm:text-2xl font-bold">Set up</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="email"
              placeholder={t('email')}
              register={register}
              type="email"
              defaultValue={dataFromUrl?.email}
              value={dataFromUrl?.email}
              disabled
              errors={errors.email}
            />
            <Input
              name="name"
              placeholder={t('name')}
              register={register}
              type="text"
              errors={errors.name}
              validation={{
                required: true,
              }}
            />
            <PasswordInput
              customStyle="h-10 w-full mt-4 px-3 font-regular text-primary placeholder-primary text-sm sm:text-lg appearance-none rounded-2xl relative bg-[#FFFFFF] border border-gray-300 placeholder-black text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500"
              label={'password'}
              name="password"
              placeholder={t('password')}
              register={register}
              type="password"
              errors={errors.password}
              validation={{
                required: true,
                pattern: { value: passwordRegex, message: t<string>('passwordRequirements') },
              }}
            />
            <PasswordInput
              customStyle="h-10 w-full mt-4 px-3 font-regular text-primary placeholder-primary text-sm sm:text-lg appearance-none rounded-2xl relative bg-[#FFFFFF] border border-gray-300 placeholder-black text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500"
              label={'confirmPassword'}
              name="repeat_password"
              placeholder={t('confirmPass')}
              register={register}
              type="password"
              errors={errors.repeat_password}
              validation={{
                required: true,
                pattern: { value: passwordRegex, message: t<string>('passwordRequirements') },
                validate: (value: string) => value === password || `${t('noMatch')}`,
              }}
            />
            <Input
              name="position"
              placeholder={t('position')}
              register={register}
              type="text"
              errors={errors.position}
              validation={{
                required: true,
              }}
            />

            <small className="text-red text-left mt-1">{error && `${t('accept')}`}</small>
            <div className="flex justify-center my-5">
              <button
                disabled={loading}
                className="my-3 w-full rounded-full text-lg sm:text-2xl py-3 font-semibold bg-primary text-white"
              >
                {t('sign')}
              </button>
            </div>
            <div className="flex justify-center mt-3">
              <p>
                {t('bySign')}
                <span>
                  <a
                    href={TERMS}
                    target="_blank"
                    className="pl-1 underline text-secondary-500"
                    rel="noreferrer"
                  >
                    {t('terms')}
                  </a>
                </span>
              </p>
            </div>
          </form>
        </div>
        <div className="w-1/2 hidden lg:flex justify-center bg-secondary-200 h-full px-16 flex-col text-center">
          <img src={`${REACT_APP_CDN}/register/register-invited2.svg`} alt="kitchen" />
          <p className="mt-6 font-bold text-xl">{t('descriptionImage')}</p>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
