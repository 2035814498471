import {
  LocationSchema,
  LocationSchemaType,
} from 'pages/postShift/core/Modules/Form/Domain/Dtos/Location';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { set } from 'lodash';
import { entityToFormLocation } from 'pages/postShift/core/Modules/Checkout/Domain/Mappers/entityToForm.mapper';
import { useQuery } from '@tanstack/react-query';
import LocationAction from './CreateLocation.Action';
import { fetchLocationToForm } from 'pages/postShift/core/Modules/Form/Domain/Mappers/fetchLocationToForm';

const CreateLocationPresenter = () => {
  const contextForm = useContext(DataFormContext);
  const [openedModal, setOpenedModal] = useState(false);

  const action = LocationAction();
  const [editingId, setEditingId] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<IItems>();

  const [shouldNotShowSelect, setShouldNotShowSelect] = useState(
    contextForm.mode === 'edit' ? true : false
  );

  const form = useForm({
    resolver: yupResolver(LocationSchema),
    defaultValues: {
      ...entityToFormLocation(contextForm),
    },
  });

  const location = form.watch('location');
  const locations = useQuery(['get-locations'], () => action.invokeGetLocation());

  const locationMapData = useMemo(() => {
    if (
      location &&
      location.locationCoords &&
      location.locationCoords.latitude &&
      location.locationCoords.longitude
    ) {
      return {
        latitude: location.locationCoords.latitude,
        longitude: location.locationCoords.longitude,
      };
    } else {
      return {
        latitude: 39.742043,
        longitude: -104.991531,
      };
    }
  }, [location]);

  const onClickCreateLocation = () => {
    setOpenedModal(true);
  };

  const onClickLocation = (selected: IItems) => {
    setSelectedLocation(selected);
    const getSelectedLocation = locations.data?.find((c) => c.id === selected.id);

    contextForm.setDataForm(
      set(contextForm.dataForm, 'shiftInfo.location', fetchLocationToForm(getSelectedLocation))
    );
  };

  const onClickClose = () => {
    form.clearErrors();
    form.reset();
    setOpenedModal(false);
  };

  const onCancel = () => {
    setOpenedModal(false);
  };

  const onClickEditLocation = () => {
    setEditingId(selectedLocation?.id);
    setOpenedModal(true);
    form.reset(entityToFormLocation(contextForm) as object);
  };

  const onSubmit: SubmitHandler<LocationSchemaType> = (data) => {
    const finalValue = set(contextForm.dataForm, 'shiftInfo.location', data);
    contextForm.setDataForm({ ...finalValue });
    if (!editingId) {
      action.invokeSaveLocation(data).then(async (location) => {
        await locations.refetch();
        setSelectedLocation(location);
      });
    } else {
      action.invokeEditLocation(data, selectedLocation?.id).then(() => locations.refetch());
    }

    setEditingId(undefined);
    setOpenedModal(false);
  };

  const onClickClear = () => {
    form.reset();
    contextForm.setDataForm(set(contextForm.dataForm, 'shiftInfo.location', undefined));
    setShouldNotShowSelect(false);
  };

  useEffect(() => {
    if (contextForm.dataForm?.shiftInfo?.location?.id && locations.data) {
      const locationSelected = locations.data.find(
        (v) => v.id === contextForm.dataForm?.shiftInfo?.location?.id
      );

      if (locationSelected && locationSelected.id) {
        setSelectedLocation({
          id: locationSelected.id,
          text: locationSelected.location.address,
        });
      }
    }
  }, [locations.data]);

  return {
    openedModal,
    setOpenedModal,
    onCancel,
    locationMapData,
    location,
    form,
    locations,
    onClickEditLocation,
    onClickCreateLocation,
    onClickLocation,
    selectedLocation,
    onSubmit,
    contextForm,
    onClickClose,
    locationContext: contextForm.dataForm.shiftInfo?.location?.location ?? undefined,
    shouldNotShowSelect,
    onClickClear,
  };
};

export default CreateLocationPresenter;
