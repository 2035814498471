import { useQuery } from '@tanstack/react-query';
import ShiftsCalendarAction from './ShiftsCalendar.Action';
import { useMemo, useState } from 'react';
import { ShiftsCalendarSet } from 'pages/shift/core/Modules/Shifts/Domain/Entities/ShiftsCalendarSet';
import { DateTime } from 'luxon';
import {
  ColoredDateCellWrapper,
  TEvent,
  Toolbar,
} from 'pages/shift/shared/components/calendar-items';
import { Views, luxonLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';

const ShiftCalendarPresenter = () => {
  const action = ShiftsCalendarAction();
  const [startDate, setStartDate] = useState(DateTime.now().startOf('month'));
  const [endDate, setEndDate] = useState(DateTime.now().endOf('month'));

  const [openShowMore, setOpenShowMore] = useState<Date | undefined>();
  const navigate = useNavigate();

  const filterData = useMemo<ShiftsCalendarSet>(() => {
    return {
      filters: {
        startDate: startDate.toISO(),
        endDate: endDate.toISO(),
      },
    };
  }, [endDate, startDate]);

  const query = useQuery({
    queryFn: () => action.executeGetShiftCalendar(filterData),
    queryKey: ['get-calendar-shifts', filterData],
  });

  const myEvents = useMemo(() => {
    if (query.data) {
      return query.data.data.postedShifts.map((v) => {
        return {
          id: v.id,
          title: `${v.positionTitle} (${v.totalApplicants}/${v.openings})`,
          isActive: v.isActive,
          start: new Date(v.startDate),
          end: new Date(v.endDate),
          openings: v.openings,
          totalApplicants: v.totalApplicants,
          started: v.started,
        };
      });
    } else {
      return [];
    }
  }, [query]);

  const calendarConfig = useMemo(
    () => ({
      components: {
        toolbar: Toolbar,
        eventWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(),
      views: Object.keys(Views).map((k) => k),
      localizer: luxonLocalizer(DateTime),
    }),
    []
  );

  const onNavigateCalendar = (date: Date) => {
    const parsedDate = DateTime.fromJSDate(date);

    setStartDate(parsedDate.startOf('month'));

    setEndDate(parsedDate.endOf('month'));
  };

  const onClickShowMore = (events: object[], date: Date) => {
    setOpenShowMore(date);
  };

  const onClickEvent = (event: TEvent) => {
    navigate(event.id);
  };

  const onClickEventById = (id: string) => {
    navigate(id);
  };

  return {
    myEvents,
    calendarConfig,
    onNavigateCalendar,
    onClickShowMore,
    onClickEvent,
    openShowMore,
    setOpenShowMore,
    onClickEventById,
  };
};

export default ShiftCalendarPresenter;
