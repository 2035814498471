import { Tab } from '@headlessui/react';
import Members from './Members/Members';
import { classNames } from 'utils';
import Payment from './Payment/Payment';
import { useTranslation } from 'react-i18next';

const TabsProfileMemberPayment = () => {
  const [t] = useTranslation(['Profile']);

  return (
    <Tab.Group>
      <Tab.List className={'w-full bg-gray-700 rounded-lg p-2'}>
        <Tab
          className={({ selected }) =>
            classNames(
              'text-primary font-semibold',
              'w-1/2 rounded-lg py-1.5 text-sm font-medium leading-5 text-blue-700',
              selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
            )
          }
        >
          {t('teamMember')}
        </Tab>
        <Tab
          className={({ selected }) =>
            classNames(
              'text-primary font-semibold',
              'w-1/2 rounded-lg py-1.5 text-sm font-medium leading-5 text-blue-700',
              selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
            )
          }
        >
          {t('payments')}
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className={'overflow-y-auto max-h-[80vh]'}>
          <Members />
        </Tab.Panel>
        <Tab.Panel>
          <Payment />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
export default TabsProfileMemberPayment;
