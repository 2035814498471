import { TFunction } from 'react-i18next';
import CreateTemplateSet from '../../../../../shift/core/Modules/Shifts/Domain/Dtos/CreateTemplateSet.dto';
import { EventDataGet } from '../../Catalog/Domain/Entities/Catalog.entity';
import { dtoTemplateToForm } from '../Domain/Mappers/dtoToEntityForm.mapper';
import { TemplateRepository } from '../Domain/Repository/Template.repository';
import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { TemplateDetailGet } from '../Domain/Entities/TemplateDetail.entity';

export class TemplateUseCase {
  private templateRepository = new TemplateRepository();

  public async invokeCreateTemplate(data: CreateTemplateSet) {
    return this.templateRepository.createTemplate(data);
  }

  public async invokeGetTemplate(id: string, t: TFunction, catalog?: EventDataGet) {
    const result = await this.templateRepository.getTemplateDetail(id);

    const finalObject: { form: DataFormType; data: TemplateDetailGet } = {} as {
      form: DataFormType;
      data: TemplateDetailGet;
    };

    if (result && catalog) {
      const form = dtoTemplateToForm(result, catalog, t);
      finalObject.form = form;
    }

    if (result) {
      finalObject.data = result;
    }

    return finalObject ?? undefined;
  }
}
