export class EventDataGet {
  constructor(
    public eventData: {
      parkingInfos: {
        id: string;
        text: string;
      }[];
      shiftPositions: {
        id: string;
        text: string;
        image: string;
        default: boolean;
      }[];
      shiftSalaryTypes: {
        id: string;
        text: string;
      }[];
      uniforms: {
        id: string;
        text: string;
      }[];
      dressCodes: {
        id: string;
        text: string;
        image: string;
      }[];
      groomingRestrictions: {
        id: string;
        text: string;
        default: boolean;
      }[];
      abilities: {
        id: string;
        text: string;
        default: boolean;
      }[];
      shiftExperiences: {
        id: string;
        text: string;
      }[];
    }
  ) {}
}
