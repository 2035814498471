import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';
import businessReducer, { IUserReducer } from './business/index';
import generalSlice, { IGeneralReducer } from './generalSlice';
import shiftSlice, { IShiftsReducer } from './shifts';

const mainReducer = combineReducers({
  business: businessReducer,
  general: generalSlice,
  events: shiftSlice,
});

const store = configureStore({
  reducer: mainReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export interface GlobalState {
  general: IGeneralReducer;
  business: IUserReducer;
  events: IShiftsReducer;
}
export default store;
