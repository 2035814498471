import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CloseSession, ICognitoUser } from 'actions/auth.api';
import { IResponseCard } from 'actions/payments.api';
import {
  DeleteBusinessUserThunk,
  GetBusinessThunk,
  GetBusinessUserIdThunk,
  GetCardsByBusinessThunk,
  GetManageBusinessUsersThunk,
  GetOnboardDataThunk,
  OnBoardBusinessThunk,
  RegisterFederatedThunk,
  UpdateProfilePhotoThunk,
} from './api.thunks';
import { IOnboardData } from 'actions/catalog/catalog.api';
import { ICompany, IFullBusinessUser, PaymentOptionEnum } from 'actions/business/model';
export interface IUserReducer {
  data: ICompany | null;
  loggedIn: boolean | null;
  cards: IResponseCard[] | null;
  dataUser: ICognitoUser | null;
  loading: boolean;
  manageUsers: IFullBusinessUser[] | null;
  emailRecovery: string | null;
  onboardingData: IOnboardData | null;
  isOnboarding: boolean;
}

const initialState: IUserReducer = {
  data: null,
  loggedIn: null,
  cards: null,
  dataUser: null,
  loading: true,
  manageUsers: null,
  emailRecovery: null,
  onboardingData: null,
  isOnboarding: false,
};

const businessSlice = createSlice({
  name: 'business',
  initialState: initialState,
  reducers: {
    setLoading: (state: IUserReducer, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoggedIn: (state: IUserReducer, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setEmailRecovery: (state: IUserReducer, action: PayloadAction<string>) => {
      state.emailRecovery = action.payload;
    },
    clearSlice: (state: IUserReducer) => {
      state.loading = false;
      state.cards = null;
      state.loggedIn = false;
      state.data = null;
      state.dataUser = null;
      state.isOnboarding = false;
    },
    setPaymentOption: (state: IUserReducer, action: PayloadAction<PaymentOptionEnum>) => {
      if (state.data) {
        state.data.paymentOption = action.payload;
      }
    },

    reduceJob: (state: IUserReducer) => {
      if (state.data) {
        state.data.subscription.postRemaining--;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(OnBoardBusinessThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(OnBoardBusinessThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(GetBusinessThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loggedIn = true;
      if (state.dataUser && action.payload.id) {
        state.dataUser.businessId = action.payload.id;
      } else {
        state.isOnboarding = true;
      }
      state.loading = false;
    });

    builder.addCase(GetBusinessThunk.pending, (state) => {
      if (!state.data) {
        state.loading = true;
      }
    });

    builder.addCase(GetCardsByBusinessThunk.fulfilled, (state, action) => {
      state.cards = action.payload;
    });

    builder.addCase(GetBusinessUserIdThunk.pending, (state) => {
      if (!state.dataUser) {
        state.loading = true;
      }
    });

    builder.addCase(GetBusinessUserIdThunk.fulfilled, (state, action) => {
      if (action.payload.isActive) {
        state.dataUser = action.payload;
      } else {
        CloseSession();
      }

      if (!action.payload.businessId) {
        state.isOnboarding = true;
      }

      state.loading = false;
    });

    builder.addCase(RegisterFederatedThunk.fulfilled, (state, action) => {
      state.dataUser = action.payload;

      if (!state.data) {
        state.isOnboarding = true;
      }

      state.loading = false;
    });

    builder.addCase(GetManageBusinessUsersThunk.fulfilled, (state, action) => {
      state.manageUsers = action.payload;
    });

    builder.addCase(GetBusinessUserIdThunk.rejected, (state) => {
      state.loggedIn = false;
      state.loading = false;
      CloseSession();
    });

    builder.addCase(UpdateProfilePhotoThunk.fulfilled, (state, action) => {
      if (state.data) {
        state.data.logo = action.payload.logo;
      }
    });

    builder.addCase(DeleteBusinessUserThunk.fulfilled, (state, action) => {
      state.manageUsers?.forEach((value, key) => {
        if (value.id === action.payload) {
          state.manageUsers?.splice(key, 1);
        }
      });
    });
    builder.addCase(GetOnboardDataThunk.fulfilled, (state, action) => {
      state.onboardingData = action.payload;
    });
  },
});
export const {
  setLoggedIn,
  clearSlice,
  setEmailRecovery,
  setLoading,
  reduceJob,
  setPaymentOption,
} = businessSlice.actions;
export default businessSlice.reducer;
