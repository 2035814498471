import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { SaveDraftDataSet } from '../Entities/SaveDraftDataSet';
import { DateTime } from 'luxon';
import CreateTemplateSet from 'pages/shift/core/Modules/Shifts/Domain/Dtos/CreateTemplateSet.dto';

export const entityToSetDraftForm = (
  data: DataFormType,
  businessId: string,
  businessUserId: string
): SaveDraftDataSet => {
  return {
    businessId,
    businessUserId,
    shift: {
      abilities: data.shiftInfo.role.abilities.map((e) => e.id),
      breakTime: data.workers.breakTime,
      clockInInfo: data.shiftInfo.location.clockInInfo,
      contacts: data.shiftInfo.contacts,
      dressCode: data.attire.dressCode.id,
      endTime: DateTime.fromJSDate(data.shiftInfo.endTime).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
      startTime: DateTime.fromJSDate(data.shiftInfo.startTime).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
      experience: data.shiftInfo.role.experience.id,
      fullTimeOportunity: data.workers.fullTimeOportunity,
      groomingRestrictions: data.attire.groomingRestrictions.map((e) => e.id),
      jobInformation: data.shiftInfo.role.information || '',
      location: {
        address: data.shiftInfo.location.location.location.address,
        notificationsTopic: data.shiftInfo.location.location.location.notificationsTopic ?? '',
        topic: data.shiftInfo.location.location.location.topic ?? '',
      },
      locationCoords: {
        latitude: data.shiftInfo.location.location.locationCoords.latitude ?? 39.742043,
        longitude: data.shiftInfo.location.location.locationCoords.longitude ?? -104.991531,
      },
      locationInfo: data.shiftInfo.location.info,
      onlyFavorites: data.workers.selectedAssign,
      openings: data.workers.openings,
      parkingInfo: data.shiftInfo.location.parking,
      positionId: data.shiftInfo.role.category.id,
      positionTitle: data.shiftInfo.role.name,
      recurrent: data.shiftInfo.recurrent ?? false,
      salaryAmount: data.shiftInfo.role.amount,
      salaryType: data.shiftInfo.role.payment.id,
      timeZone: parseInt(data.shiftInfo.timeZone.id),
      uniform: data.attire.uniform.id,
      waitlist: data.workers.openings,

      ...(!data.shiftInfo.recurrent && data.shiftInfo.startDate
        ? {
            shiftDates: Array.isArray(data.shiftInfo.startDate)
              ? data.shiftInfo.startDate.map((v) =>
                  DateTime.fromJSDate(v).toFormat("yyyy-MM-dd'T'HH:mm:ss")
                )
              : [DateTime.fromJSDate(data.shiftInfo.startDate).toFormat("yyyy-MM-dd'T'HH:mm:ss")],
          }
        : {
            recurrentDays: data.shiftInfo.recurrentDays,
            recurrentStart: DateTime.fromJSDate(
              data.shiftInfo.recurrentStart ?? new Date()
            ).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            recurrentEnd: DateTime.fromJSDate(data.shiftInfo.recurrentEnd ?? new Date()).toFormat(
              "yyyy-MM-dd'T'HH:mm:ss"
            ),
          }),
    },
  };
};

export const entityToCreateTemplateForm = (
  data: DataFormType,
  businessId: string,
  businessUser: string,
  templateName: string
): CreateTemplateSet => {
  const startTime = DateTime.fromJSDate(data.shiftInfo.startTime);
  const endTime = DateTime.fromJSDate(data.shiftInfo.endTime);

  return {
    businessId,
    businessUserId: businessUser,
    templateName,
    shift: {
      abilities: data.shiftInfo.role.abilities.map((e) => e.id),
      breakTime: data.workers.breakTime,
      clockInInfo: data.shiftInfo.location.clockInInfo,
      contacts: data.shiftInfo.contacts,
      dressCode: data.attire.dressCode.id,
      editTemplate: false,
      experience: data.shiftInfo.role.experience.id,
      fullTimeOportunity: data.workers.fullTimeOportunity,
      groomingRestrictions: data.attire.groomingRestrictions.map((e) => e.id),
      jobInformation: data.shiftInfo.role.information || '',

      location: {
        address: data.shiftInfo.location.location.location.address,
        notificationsTopic: data.shiftInfo.location.location.location.notificationsTopic ?? '',
        topic: data.shiftInfo.location.location.location.topic ?? '',
      },
      locationCoords: {
        latitude: data.shiftInfo.location.location.locationCoords.latitude ?? 39.742043,
        longitude: data.shiftInfo.location.location.locationCoords.longitude ?? -104.991531,
      },
      locationInfo: data.shiftInfo.location.info,
      onlyFavorites: data.workers.selectedAssign,
      openings: data.workers.openings,
      parkingInfo: data.shiftInfo.location.parking,
      positionId: data.shiftInfo.role.category.id,
      positionTitle: data.shiftInfo.role.name,
      salaryAmount: data.shiftInfo.role.amount,
      salaryType: data.shiftInfo.role.payment.id,
      timeZone: data.shiftInfo.timeZone.id,
      uniform: data.attire.uniform.id,
      waitlist: data.workers.openings,
      isWaitlistFilled: false,
      saveTemplate: false,
      templateName,
      startDate: DateTime.now()
        .plus({ days: 1 })
        .set({
          hour: startTime.hour,
          minute: startTime.minute,
        })
        .toISO(),
      endDate: DateTime.now()
        .plus({ days: 1 })
        .set({
          hour: endTime.hour,
          minute: endTime.minute,
        })
        .toISO(),
      country: parseInt(data.shiftInfo.timeZone.id),
    },
  };
};
