import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { REACT_APP_CDN } from 'utils/enviroment';

const Step = (): JSX.Element => {
  const [t] = useTranslation(['Forgot']);
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    setTimeout(() => {
      navigate(RoutesConstant.unsecure.login);
    }, 2000);
  }, []);

  useEffect(() => {
    trackPageView({
      documentTitle: 'Forgot Password Step 4',
      href: window.location.href,
    });
  }, []);

  return (
    <div className="flex bg-[#E5E5E5] h-screen items-end text-primary">
      <div className="flex self-center">
        <div className="w-full md:w-[55%] flex justify-center self-center md:mr-10 px-10 sm:px-20">
          <h3 className="w-full text-3xl font-semibold ">{t('redirecting')}</h3>
        </div>
        <div className="hidden md:flex lg:w-1/3">
          <img src={`${REACT_APP_CDN}/img/malePC.png`} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Step;
