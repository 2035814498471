import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import BusinessService from 'actions/business';
import { useParams } from 'react-router-dom';
import NewShiftDocument from 'assets/pdf/invoices/newInvoiceshift';
import { PDFViewer } from '@react-pdf/renderer';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Invoice = () => {
  const { id } = useParams();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Invoice',
      href: window.location.href,
    });
  }, []);
  const { data, isLoading, isError } = useQuery(
    ['user', id],
    () => BusinessService.getInvoice(id),
    { enabled: !!id }
  );

  const component = useMemo(() => {
    return data ? NewShiftDocument(data) : null;
  }, [data]);

  if (isError) return <p>Try again</p>;
  if (isLoading || !data) return <p>Loading...</p>;

  return <PDFViewer style={{ width: '100%', height: '100vh' }}>{component}</PDFViewer>;
};

export default Invoice;
