import { DetailedHTMLProps, TdHTMLAttributes } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { cn } from 'utils';

type TableHeaderProps = {
  items: string[];
};

export const TableHeader = ({ items }: TableHeaderProps) => {
  return (
    <thead className="bg-neutral-200 py-3 px-2 w-full">
      <tr className="px-6 py-3">
        {items.map((item, key) => (
          <th
            className="px-6 py-3 text-xs min-w-32 font-normal text-center text-neutral-1200"
            key={key}
          >
            {item}
          </th>
        ))}
      </tr>
    </thead>
  );
};

type TableProps = {
  children: JSX.Element | JSX.Element[];
};

export const Table = ({ children }: TableProps) => {
  return <table className="w-full overflow-x-auto">{children}</table>;
};

type TableBodyProps = {
  children: JSX.Element;
};

export const TableBody = ({ children }: TableBodyProps) => {
  return <tbody>{children}</tbody>;
};

type TableTdProps = {
  children: JSX.Element | string | React.ReactNode;
} & DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>;

export const TableTd = ({ children, className, ...props }: TableTdProps) => {
  return (
    <td className={cn('py-5 text-xs font-normal px-3 text-center', className)} {...props}>
      {children}
    </td>
  );
};

type TableTrProps = {
  isActive?: boolean;
  children: JSX.Element | JSX.Element[];
};

export const TableTr = ({ children, isActive }: TableTrProps) => {
  return (
    <tr className={`border-b-[1px] text-gray-1200 px-6 py-3 ${isActive && 'bg-neutral-400/50'}`}>
      {children}
    </tr>
  );
};

export const TableLoading = () => {
  return <Skeleton count={10} width={'100%'} height={40} />;
};

export const TablePaginate = ({ pageCount = 10, ...props }: ReactPaginateProps) => {
  return (
    <div className="w-full m-auto">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        renderOnZeroPageCount={null}
        pageCount={pageCount}
        containerClassName={'flex justify-center items-center mt-7'}
        pageLinkClassName={'text-base text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        previousLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        nextLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        activeLinkClassName={'text-white bg-secondary-500'}
        {...props}
      />
    </div>
  );
};
