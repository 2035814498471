import FloatingDialog from 'components/FloatingDialog';
import InvoiceDetailPresenter from './InvoiceDetail.Presenter';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { formatWithTimezone } from 'utils/dates/formatWithTimezone';
import { DateTime } from 'luxon';

type InvoiceDetailScreenProps = {
  selectedId: string;
  label: string;
};

const InvoiceDetailScreen = ({ selectedId, label }: InvoiceDetailScreenProps) => {
  const presenter = InvoiceDetailPresenter(selectedId);
  const description = presenter.description.data;
  const [t] = useTranslation('Invoice');

  return (
    <div>
      <div
        onClick={() => {
          presenter.setOpenDialog(true);
        }}
      >
        {label}
      </div>
      <FloatingDialog
        isOpen={presenter.openDialog}
        setIsOpen={presenter.setOpenDialog}
        className="rounded-xl bg-white sm:p-5 p-4 w-full max-w-5xl max-h-[70vh] overflow-y-auto"
      >
        <div className="w-full">
          <div className="w-full inset-0 overflow-y-auto lg:no-scrollbar">
            {presenter.description.isLoading ? (
              <div>
                <Skeleton height={40} width={'50%'} />
                <Skeleton height={20} style={{ marginTop: 30, marginBottom: 10 }} />
                <Skeleton height={40} count={4} />
                <Skeleton height={40} style={{ marginTop: 100 }} />

                <div className="flex justify-center">
                  <Skeleton height={40} width={'40%'} style={{ margin: 'auto' }} />
                </div>
              </div>
            ) : (
              description?.details &&
              description?.details.length > 0 && (
                <div>
                  <div className="flex items-center justify-between mb-5">
                    <h1 className="text-2xl font-bold  text-primary">
                      {DateTime.fromISO(description.startDate).toFormat('DD')} -{' '}
                      {DateTime.fromISO(description.endDate).toFormat('DD')}
                    </h1>
                    <p
                      onClick={() => presenter.onClickExport(description.invoiceId)}
                      className="text-xs font-semibold text-secondary-500 underline cursor-pointer"
                    >
                      Export
                    </p>
                  </div>
                  <div>
                    <div className="hidden xl:flex py-4 text-sm rounded-lg px-4 text-primary">
                      <p className="w-1/6 font-bold">{t('name')}</p>
                      <p className="w-1/12 font-bold">{t('position')}</p>
                      <p className="w-1/6 text-center font-bold">{t('rate')}</p>
                      <p className="w-1/12 text-center font-bold">{t('clockIn')}</p>
                      <p className="w-1/12 text-center font-bold">{t('clockOut')}</p>
                      <p className="w-1/6 text-center font-bold">{t('unPaidHours')}</p>
                      <p className="w-1/6 text-center font-bold">{t('laborCost')}</p>
                    </div>
                    <div className="mb-6">
                      {description.details.map((detail) => (
                        <>
                          {detail.day && (
                            <p className="mb-2">{DateTime.fromISO(detail.day).toFormat('DD')}</p>
                          )}
                          {detail.applicants.map((applicant, index) => {
                            const applicantStatus = presenter.laborCostValues(applicant);
                            return applicantStatus === 'Cancelled' ? (
                              <div
                                key={index}
                                className="flex bg-gray-200 py-4 text-sm xl:rounded-lg px-4 mb-5"
                              >
                                <p className="w-2/6">{applicant.name}</p>
                                <p className="w-3/6 text-center bg-red text-white bg-warnings-500 text-sm rounded-lg ">
                                  Cancelled within 24 hours
                                </p>
                                <p className="w-1/6 text-center">${applicant.laborCost}</p>
                              </div>
                            ) : applicantStatus === 'No show' ? (
                              <div
                                key={index}
                                className="flex bg-gray-200 py-4 text-sm xl:rounded-lg px-4 mb-5"
                              >
                                <p className="w-2/6">{applicant.name}</p>
                                <p className="w-3/6 text-center bg-red text-white  text-sm rounded-lg ">
                                  No show
                                </p>
                              </div>
                            ) : (
                              <div
                                key={index}
                                className="flex bg-gray-200 py-4 text-sm xl:rounded-lg px-4 mb-5"
                              >
                                <p className="w-1/6">{applicant.name}</p>
                                <p className="w-1/12 truncate">{applicant.positionTitle}</p>
                                <p className="w-1/6 text-center">
                                  {applicant.isTotalCompensation
                                    ? applicant.salaryTotal
                                    : applicant.salaryHour}
                                </p>
                                <p className="w-1/12 text-center">
                                  {applicant.clockIn
                                    ? formatWithTimezone(t, 't', applicant.clockIn)
                                    : '----'}
                                </p>
                                <p className="w-1/12 text-center">
                                  {applicant.clockOut
                                    ? formatWithTimezone(t, 't', applicant.clockOut)
                                    : '----'}
                                </p>
                                <p className="w-1/6 text-center">
                                  {presenter.breakTimeMinutesToHours(applicant.breakTime)}
                                </p>
                                <p className="w-1/6 text-center">
                                  {presenter.laborCostValues(applicant)}
                                </p>
                              </div>
                            );
                          })}
                        </>
                      ))}
                    </div>
                    <hr className="mb-4 "></hr>
                    {!description.isMarkdown && (
                      <div className="flex justify-between bg-gray-200 py-4 text-sm rounded-lg px-4 mb-5">
                        <p className="w-2/6">Chamba serivce fee</p>
                        <p className="w-1/6">${description?.serviceFee}</p>
                      </div>
                    )}

                    <h1 className="flex justify-center text-2xl font-bold">
                      Total cost: ${description?.totalAmount}
                    </h1>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </FloatingDialog>
    </div>
  );
};

export default InvoiceDetailScreen;
