import { useQuery } from '@tanstack/react-query';
import CatalogService from 'actions/catalog/catalog.api';
import { SettingsContext } from './context';

interface IProviderSettings {
  children: JSX.Element;
}

const ProviderSettings = ({ children }: IProviderSettings) => {
  const { data: dataReasons } = useQuery(['DELETE-REASONS'], CatalogService.getDeleteReasons);
  return (
    <SettingsContext.Provider
      value={{
        dataReasons,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default ProviderSettings;
