import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { entityToSetDraftForm } from '../Domain/Mappers/Checkout.mapper';
import DraftRepository from '../Domain/Repository/Draft.repository';
import { EventDataGet } from '../../Catalog/Domain/Entities/Catalog.entity';

import { dtoDraftToEntityForm } from '../Domain/Mappers/dtoToEntityForm.mapper';
import { DraftDataGet } from '../Domain/Entities/DraftDataGet.entity';
import { dtoToEntityDraft } from '../Domain/Dtos/Draft.dto';
import { TFunction } from 'i18next';

export class DraftUseCase {
  private draftRepository = new DraftRepository();

  public async invokeSave(data: DataFormType, businessId: string, businessUserId: string) {
    const dataRequest = entityToSetDraftForm(data, businessId, businessUserId);
    const result = await this.draftRepository.save(dataRequest);
    const entity = await dtoToEntityDraft(result.data);

    if (entity) {
      return new DraftDataGet(entity);
    }

    return undefined;
  }

  public async invokeGet(id: string, t: TFunction, catalog?: EventDataGet) {
    const result = await this.draftRepository.get(id);

    const finalObject: { form: DataFormType; data: DraftDataGet } = {} as {
      form: DataFormType;
      data: DraftDataGet;
    };

    if (result && catalog) {
      const shift = result.resultDraftData.shifts[0];

      const entityForm = dtoDraftToEntityForm(shift, catalog, t);

      finalObject.form = entityForm;
    }

    if (result) {
      finalObject.data = result;
    }

    return finalObject ?? undefined;
  }

  public async invokeDelete(id: string) {
    await this.draftRepository.delete(id);
  }
}
