import Icon from 'assets/icons';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { ListSheetGet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/ListSheet.entity';

type MenuProps = {
  onClickOpenCalendar: () => void;
  setStartDate: (v: Date | null) => void;
  onClickOutsideCalendar: () => void;
  startDate: Date | null;
  openCalendar: boolean;
  onAddDays: () => void;
  onDecrementDays: () => void;
  sheetData?: ListSheetGet;
  setDate: (v: Date | null) => void;
};

const Menu = ({
  onClickOpenCalendar,
  startDate,
  setStartDate,
  onClickOutsideCalendar,
  openCalendar,
  sheetData,
  onAddDays,
  onDecrementDays,
  setDate,
}: MenuProps): JSX.Element => {
  const startDateParsed = DateTime.fromISO(sheetData?.timesheetWeekItems[0].dayStart ?? '');
  const endDateParsed = DateTime.fromISO(sheetData?.timesheetWeekItems[6].dayStart ?? '');
  const [t] = useTranslation(['Timesheet']);

  const onChangeDate = (date: Date | null) => {
    setStartDate(
      DateTime.fromJSDate(date ?? new Date())
        .startOf('week')
        .toJSDate()
    );
    setDate(date);
    onClickOutsideCalendar();
  };

  return (
    <div className=" border-2 border-neutral-200 items-center py-1.5 border-solid flex justify-between rounded-2xl px-2">
      <div className="flex items-center" onClick={onDecrementDays}>
        <Icon size={20} icon="ChevronDown" className="rotate-90 mr-2" />
      </div>
      <p>{t('week')}</p>
      {startDate && (
        <p className="ml-2">
          {startDateParsed.toFormat('LLL d') + ' - ' + endDateParsed.toFormat('dd')}{' '}
        </p>
      )}

      <div className="ml-3.5 flex cursor-pointer">
        <div className="flex items-center" onClick={onClickOpenCalendar}>
          <Icon size={20} icon="edit1" />
        </div>
        <div className="absolute z-30">
          {openCalendar && (
            <DatePicker
              inline={true}
              showIcon={true}
              selected={startDate}
              onChange={(date) => onChangeDate(date)}
              icon={<Icon icon="edit1" />}
            />
          )}
        </div>
      </div>
      <div onClick={onAddDays}>
        <Icon size={20} icon="ChevronDown" className="-rotate-90 ml-2" />
      </div>
    </div>
  );
};

export default Menu;
