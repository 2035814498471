import { GeJobseekers, IGetGeneralJobseekers, IJobseekersResponse } from 'actions/pros/pros.api';
import React, { useContext, useEffect } from 'react';
import { ShowToastError } from 'utils';
import useUser from 'utils/hooks/useUser';
import { ProfesionalsContext } from './context';
import { GetProsType } from 'actions/pros/models';
import { IBlockedUser, IPreferredUser } from 'actions/shifts.api';
import { useAppDispatch } from 'store';
import {
  CreateBlockedUserThunk,
  CreatePreferredUserThunk,
  DeleteBlockedUserThunk,
  DeletePreferredUserThunk,
} from 'store/shifts/api.thunks';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const useGetPros = (type: GetProsType) => {
  const [items, setItems] = React.useState<IJobseekersResponse['jobseekerCards']>([]);
  const [page, setPage] = React.useState<number>(1);
  const { location, refresh, name } = React.useContext(ProfesionalsContext);
  const [generalList, setGeneralList] = React.useState<IJobseekersResponse>({
    jobseekerCards: [],
    totalItems: 0,
  });
  const { business } = useUser();

  const onEndReached = () => {
    setPage(page + 1);
  };

  const getGeneralJobseekers = React.useCallback(
    async (
      pageList: number,
      items: IJobseekersResponse['jobseekerCards'],
      shouldReset?: boolean
    ) => {
      try {
        if (business !== null && business.id) {
          let dto: IGetGeneralJobseekers;
          if (location) {
            dto = {
              businessId: business.id,
              filters: {
                ...(name && type !== 'recent' ? { combined: name } : undefined),
                location: {
                  latitude: location.locationCoords.latitude,
                  longitude: location.locationCoords.longitude,
                },
              },
              page: pageList,
            };
          } else {
            dto = {
              businessId: business.id,
              filters: {
                ...(name && type !== 'recent' ? { combined: name } : undefined),
                location: undefined,
              },
              page: pageList,
            };
          }
          const response = await GeJobseekers(dto, type);
          setGeneralList(response);

          if (shouldReset) {
            setItems(response.jobseekerCards);
          } else {
            setItems(
              pageList === 1 ? response.jobseekerCards : [...items, ...response.jobseekerCards]
            );
          }
        }
      } catch (e) {
        ShowToastError(e);
      }
    },
    [business, type, page, location, name]
  );

  const refreshList = () => {
    setPage(1);
    getGeneralJobseekers(1, [], true);
  };

  useEffect(() => {
    refreshList();
  }, [location, name]);

  useEffect(() => {
    if (typeof refresh !== 'undefined') refreshList();
  }, [refresh]);

  React.useEffect(() => {
    getGeneralJobseekers(page, items);
  }, [getGeneralJobseekers]);

  return { generalList, onEndReached, items, refreshList };
};

export const useMutationPros = () => {
  const {
    business: { id },
  } = useUser();
  const [t] = useTranslation('ApplicantsProfile');
  const dispatch = useAppDispatch();
  const { setRefresh, refresh } = useContext(ProfesionalsContext);

  const blockUser = async (userId: string) => {
    const dto: IBlockedUser = {
      userId: userId,
      businessId: id,
    };
    await dispatch(CreateBlockedUserThunk(dto)).unwrap();
    setRefresh(!refresh);
    toast.success(t('actions.successBlockUser'));
  };

  const addPreferredUser = async (userId: string) => {
    const dto: IPreferredUser = {
      userId: userId,
      businessId: id,
    };
    await dispatch(CreatePreferredUserThunk(dto)).unwrap();
    setRefresh(!refresh);
    toast.success(t('actions.successPreferred'));
  };

  const removePreferredUser = async (preferredUserId: string) => {
    await dispatch(DeletePreferredUserThunk(preferredUserId)).unwrap();
    setRefresh(!refresh);
    toast.success(t('actions.successRemoveFavorite'));
  };

  const removeBlockUser = async (userId: string) => {
    if (id) {
      await dispatch(DeleteBlockedUserThunk(userId)).unwrap();
      setRefresh(!refresh);
      toast.success(t('actions.successUnblockUser'));
    }
  };

  return { blockUser, addPreferredUser, removePreferredUser, removeBlockUser };
};
