import { DraftDataGet } from '../Entities/DraftDataGet.entity';
import { EventDataGet } from '../../../Catalog/Domain/Entities/Catalog.entity';
import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';
import { PAYMENT_TYPES } from 'pages/postShift/shared/constants/payments';
import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import { TIME_ZONE } from 'utils/constants/days';
import { TemplateDetailGet } from '../Entities/TemplateDetail.entity';

export const dtoDraftToEntityForm = (
  data: DraftDataGet['resultDraftData']['shifts']['0'],
  catalog: EventDataGet,
  t: TFunction
): DataFormType => {
  const shift = data;
  const timeZone = TIME_ZONE(t).find((t) => t.id == data.timeZone) ?? TIME_ZONE(t)[0];

  const testStart = DateTime.fromJSDate(shift.startDate).setZone(timeZone.key);
  const testEnd = DateTime.fromJSDate(shift.endDate).setZone(timeZone.key);

  const start = new Date(testStart.toFormat('yyyy-MM-dd HH:mm:ss'));
  const end = new Date(testEnd.toFormat('yyyy-MM-dd HH:mm:ss'));

  const payment = PAYMENT_TYPES.find((v) => v.id === shift.salaryType) ?? PAYMENT_TYPES[0];
  const paymentTranslated = { id: payment.id, text: t(payment.text, 'Shifts') };

  return {
    attire: {
      dressCode:
        catalog?.eventData.dressCodes.find((v) => v.id === shift.dressCode) ??
        catalog.eventData.dressCodes[0],

      groomingRestrictions: shift.groomingRestrictions.map(
        (v) =>
          catalog.eventData.groomingRestrictions.find((restrictions) => restrictions.id === v) ??
          catalog.eventData.groomingRestrictions[0]
      ),

      uniform:
        catalog.eventData.uniforms.find((v) => v.id === shift.uniform) ??
        catalog.eventData.uniforms[0],
    },

    shiftInfo: {
      location: {
        parking: shift.parkingInfo,
        info: shift.jobInformation ? getTextTranslated(shift.jobInformation) : undefined,
        clockInInfo: getTextTranslated(shift.clockInInfo),
        location: {
          locationCoords: {
            latitude: shift.locationCoords.latitude,
            longitude: shift.locationCoords.longitude,
          },
          location: {
            address: shift.location.address,
            notificationsTopic: shift.location.notificationsTopic,
            topic: shift.location.topic,
          },
        },
      },

      role: {
        abilities: shift.abilities.map(
          (v) =>
            catalog.eventData.abilities.find((ab) => ab.id === v) ?? catalog.eventData.abilities[0]
        ),

        amount: shift.salaryAmount,
        category:
          catalog.eventData.shiftPositions.find((v) => v.id === shift.positionId) ??
          catalog.eventData.shiftPositions[0],

        experience:
          catalog.eventData.shiftExperiences.find((v) => v.id === shift.experience) ??
          catalog.eventData.shiftExperiences[0],

        information: shift.jobInformation ? getTextTranslated(shift.jobInformation) : undefined,

        payment: paymentTranslated,

        name: shift.positionTitle ? getTextTranslated(shift.positionTitle) : '',
      },

      startDate: start,
      endDate: end,
      startTime: start,
      contacts: shift.contacts,
      endTime: end,
      timeZone: TIME_ZONE(t).find((v) => v.id == shift.timeZone) ?? TIME_ZONE(t)[0],
    },

    workers: {
      breakTime: shift.breakTime,
      fullTimeOportunity: shift.fullTimeOportunity,
      openings: shift.openings,
      selectedAssign: shift.onlyFavorites,
    },
  };
};

export const dtoTemplateToForm = (
  data: TemplateDetailGet,
  catalog: EventDataGet,
  t: TFunction
): DataFormType => {
  const shift = data.template;
  const timeZone = TIME_ZONE(t).find((t) => t.id == shift.timeZone.toString()) ?? TIME_ZONE(t)[0];

  const testStart = DateTime.fromISO(shift.startDate).setZone(timeZone.key);
  const testEnd = DateTime.fromISO(shift.endDate).setZone(timeZone.key);

  const start = new Date(testStart.toFormat('yyyy-MM-dd HH:mm:ss'));
  const end = new Date(testEnd.toFormat('yyyy-MM-dd HH:mm:ss'));

  const payment = PAYMENT_TYPES.find((v) => v.id === shift.salaryType) ?? PAYMENT_TYPES[0];
  const paymentTranslated = { id: payment.id, text: t(payment.text, 'Shifts') };

  return {
    attire: {
      dressCode:
        catalog?.eventData.dressCodes.find((v) => v.id === shift.dressCode) ??
        catalog.eventData.dressCodes[0],

      groomingRestrictions: shift.groomingRestrictions.map(
        (v) =>
          catalog.eventData.groomingRestrictions.find((restrictions) => restrictions.id === v) ??
          catalog.eventData.groomingRestrictions[0]
      ),

      uniform:
        catalog.eventData.uniforms.find((v) => v.id === shift.uniform) ??
        catalog.eventData.uniforms[0],
    },

    shiftInfo: {
      location: {
        parking: shift.parkingInfo,
        info: shift.jobInformation ? getTextTranslated(shift.jobInformation) : undefined,
        clockInInfo: getTextTranslated(shift.clockInInfo),
        location: {
          locationCoords: {
            latitude: shift.locationCoords.latitude,
            longitude: shift.locationCoords.longitude,
          },
          location: {
            address: shift.location.address,
            notificationsTopic: shift.location.notificationsTopic,
            topic: shift.location.topic,
          },
        },
      },

      role: {
        abilities: shift.abilities.map(
          (v) =>
            catalog.eventData.abilities.find((ab) => ab.id === v) ?? catalog.eventData.abilities[0]
        ),

        amount: shift.salaryAmount,
        category:
          catalog.eventData.shiftPositions.find((v) => v.id === shift.positionId) ??
          catalog.eventData.shiftPositions[0],

        experience:
          catalog.eventData.shiftExperiences.find((v) => v.id === shift.experience) ??
          catalog.eventData.shiftExperiences[0],

        information: shift.jobInformation ? getTextTranslated(shift.jobInformation) : undefined,

        payment: paymentTranslated,

        name: shift.positionTitle ? getTextTranslated(shift.positionTitle) : '',
      },

      startDate: start,
      endDate: end,
      startTime: start,
      contacts: shift.contacts,
      endTime: end,
      timeZone: TIME_ZONE(t).find((v) => v.id == shift.timeZone.toString()) ?? TIME_ZONE(t)[0],
    },

    workers: {
      breakTime: shift.breakTime,
      fullTimeOportunity: shift.fullTimeOportunity,
      openings: shift.openings,
      selectedAssign: shift.onlyFavorites,
    },
  };
};
