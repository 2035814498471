import toast from 'react-hot-toast';
import { InferType, array, number, object, string } from 'yup';

export const UploadedResultSchema = object({
  totalRows: number().required(),
  successfulRows: number().required(),
  errorRows: number().required(),
  successfulShifts: number().required(),
  estimatedCost: number().required(),
  errors: array(
    object({
      errorMessages: array(string().required()).required(),
      row: number(),
    }).required()
  ).required(),
  eventIds: array(string().required()),
});

export type UploadedResultType = InferType<typeof UploadedResultSchema>;

export const dtoToUploadedResultGet = async (data: object) => {
  try {
    const validatedDto = await UploadedResultSchema.validate(data);

    return validatedDto;
  } catch (e) {
    console.error(e);
    toast.error('Please contact support');
  }
};
