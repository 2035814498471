import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetBusiness,
  GetBusinessCognito,
  ICognitoUser,
  IFederatedRequest,
  IInviteUserRequest,
  InviteBusinessUser,
  RegisterCognito,
} from 'actions/auth.api';
import {
  DeleteBusinessUser,
  EditBusinessLanguage,
  GetUsersManagement,
  OnBoardBusiness,
  UpdateBusinessRole,
  UpdateProfilePhoto,
} from 'actions/business';
import {
  AddCard,
  CreateSubscription,
  DeleteCard,
  DeleteSubscription,
  GetCardsByBusiness,
  IAddCardRequest,
  IChangeDefaultCard,
  ICreateSubscription,
  IResponseCard,
  IResultCreateSubscription,
  UpdateDefaultCard,
} from 'actions/payments.api';
import { ShowToastError } from 'utils';
import { USER_NOT_FOUND } from 'utils/constants';
import { GetOnboardData, IOnboardData } from 'actions/catalog/catalog.api';
import {
  ICompany,
  IFullBusinessUser,
  IOnBoardingApiRequest,
  UpdateLang,
} from 'actions/business/model';

export const GetCardsByBusinessThunk = createAsyncThunk<IResponseCard[], string, IReject>(
  'user/list-cards',
  async (data, { rejectWithValue }) => {
    try {
      const result = await GetCardsByBusiness(data);
      return result;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const UpdateDefaultCardThunk = createAsyncThunk<void, IChangeDefaultCard, IReject>(
  'user/default-card',
  async (data, { rejectWithValue }) => {
    try {
      await UpdateDefaultCard(data);
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const GetBusinessThunk = createAsyncThunk<ICompany, string, IReject>(
  'business/get-thunk',
  async (data, { rejectWithValue }) => {
    try {
      const business = await GetBusiness(data);
      return business;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const GetBusinessUserIdThunk = createAsyncThunk<ICognitoUser, string, IReject>(
  'business/get-user-thunk',
  async (data, { rejectWithValue }) => {
    try {
      const business = await GetBusinessCognito(data);
      return business;
    } catch (e) {
      const error = e as AxiosError<IErrorResponse>;
      if (!error.response || error.response.data.code != USER_NOT_FOUND) {
        ShowToastError(e);
      }
      return rejectWithValue(e);
    }
  }
);

export const DeleteCardThunk = createAsyncThunk<void, string, IReject>(
  'user/delete-card',
  async (data, { rejectWithValue }) => {
    try {
      await DeleteCard(data);
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const AddCardThunk = createAsyncThunk<void, IAddCardRequest, IReject>(
  'user/add-card',
  async (data, { rejectWithValue }) => {
    try {
      await AddCard(data);
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const CreateSubscriptionThunk = createAsyncThunk<
  IResultCreateSubscription,
  ICreateSubscription,
  IReject
>('user/create-subscription', async (data, { rejectWithValue }) => {
  try {
    const result = await CreateSubscription(data);
    return result;
  } catch (e) {
    ShowToastError(e);
    return rejectWithValue(e);
  }
});

export const DeleteSubscriptionThunk = createAsyncThunk<void, string, IReject>(
  'user/delete-subscription',
  async (data, { rejectWithValue }) => {
    try {
      await DeleteSubscription(data);
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const GetManageBusinessUsersThunk = createAsyncThunk<IFullBusinessUser[], string, IReject>(
  'user/getall-user',
  async (data, { rejectWithValue }) => {
    try {
      const result = await GetUsersManagement(data);
      return result;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const RegisterFederatedThunk = createAsyncThunk<ICognitoUser, IFederatedRequest, IReject>(
  'user/add-federated',
  async (data, { rejectWithValue }) => {
    try {
      const result = await RegisterCognito(data);
      return result;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const DeleteBusinessUserThunk = createAsyncThunk<string, string, IReject>(
  'user/remove-user',
  async (data, { rejectWithValue }) => {
    try {
      await DeleteBusinessUser(data);
      return data;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const InviteBusinessUserThunk = createAsyncThunk<void, IInviteUserRequest, IReject>(
  'user/invite-business-user',
  async (data, { rejectWithValue }) => {
    try {
      await InviteBusinessUser(data);
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const UpdateRoleBusinessUserThunk = createAsyncThunk<
  void,
  { id: string; role: number },
  IReject
>('user/update-role-business-user', async (data, { rejectWithValue }) => {
  try {
    await UpdateBusinessRole(data.id, data.role);
  } catch (e) {
    ShowToastError(e);
    return rejectWithValue(e);
  }
});

export const UpdateProfilePhotoThunk = createAsyncThunk<ICompany, FormData, IReject>(
  'user/update-photo-business',
  async (data, { rejectWithValue }) => {
    try {
      const result = await UpdateProfilePhoto(data);
      return result;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const EditBusinessLanguageThunk = createAsyncThunk<boolean, UpdateLang, IReject>(
  'business/update-lang',
  async (opt, { rejectWithValue }) => {
    try {
      return await EditBusinessLanguage(opt);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const GetOnboardDataThunk = createAsyncThunk<IOnboardData, undefined, IReject>(
  'business/get-onboard-data',
  async (_, { rejectWithValue }) => {
    try {
      return await GetOnboardData();
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const OnBoardBusinessThunk = createAsyncThunk<ICompany, IOnBoardingApiRequest, IReject>(
  'business/onboard-business',
  async (opt, { rejectWithValue }) => {
    try {
      return await OnBoardBusiness(opt);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
