import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AttireFormSchema,
  AttireFormSchemaType,
} from 'pages/postShift/core/Modules/Form/Domain/Dtos/Attire.dto';
import { useContext } from 'react';
import { set } from 'lodash';
import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { StepperContext } from 'pages/postShift/core/Infrastructure/Providers/Stepper.provider';
import { CatalogContext } from 'pages/postShift/core/Infrastructure/Providers/Catalog.provider';
import { entityToFormAttire } from 'pages/postShift/core/Modules/Checkout/Domain/Mappers/entityToForm.mapper';

const AttirePresenter = () => {
  const contextForm = useContext(DataFormContext);
  const stepper = useContext(StepperContext);
  const catalog = useContext(CatalogContext);

  const form = useForm({
    resolver: yupResolver(AttireFormSchema),
    defaultValues: {
      ...(entityToFormAttire(contextForm) as object),
    },
  });

  const onSubmit: SubmitHandler<AttireFormSchemaType> = (data) => {
    const finalValue = set(contextForm.dataForm, 'attire', {
      uniform: data.uniform,
      groomingRestrictions: data.groomingRestrictions,
      dressCode: data.dressCode,
    });
    contextForm.setDataForm({ ...finalValue });
    stepper.changeStep('confirm', true);
  };

  const onClickBack = () => {
    stepper.changeStep('worker', true);
  };

  return {
    onSubmit,
    form,
    onClickBack,
    catalog,
  };
};

export default AttirePresenter;
