import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface IRecenter {
  lat: number;
  lng: number;
}

const Recenter = ({ lat, lng }: IRecenter) => {
  const map = useMap();

  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);

  return <></>;
};

export default Recenter;
