/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { LangEnum } from 'utils/constants';

const instance = axios.create();

instance.interceptors.request.use(
  async (config) => {
    const token = await GetToken();
    const langResult = localStorage.getItem('i18nextLng') === 'es' ? LangEnum.ES : LangEnum.EN;
    if (config && config.headers) {
      config.headers['Authorization'] = token;
      config.headers['lang'] = langResult;
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const GetToken = async (): Promise<string> => {
  let token = '';
  try {
    const currentSession = await Auth.currentSession();
    token = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
    return token;
  } catch (e) {
    return '';
  }
};

export default instance;
