import { HireListSet } from '../Domain/Entities/HireListSet';
import HireRepository from '../Domain/Repository/Hire.repository';

export class HireUseCase {
  private hireRepository = new HireRepository();

  public async invokeGetList(d: HireListSet) {
    return this.hireRepository.getHireList(d);
  }

  public async invokeGetDescription(hireId: string) {
    return this.hireRepository.getHireDescription(hireId);
  }
}
