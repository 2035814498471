import { createContext, useState } from 'react';
import { DraftDataGet } from '../../Modules/Checkout/Domain/Entities/DraftDataGet.entity';

export type TemplateHubContextProps = {
  draft: DraftDataGet | undefined;
  setDraft: (e: DraftDataGet | undefined) => void;
};

export const TemplateHubContext = createContext({} as TemplateHubContextProps | undefined);
TemplateHubContext.displayName = 'HubContext';

type TemplateHubProviderProps = {
  children: JSX.Element;
};

export const TemplateHubProvider = ({ children }: TemplateHubProviderProps) => {
  const [draft, setDraft] = useState<DraftDataGet>();
  return (
    <TemplateHubContext.Provider value={{ draft, setDraft }}>
      {children}
    </TemplateHubContext.Provider>
  );
};
