export default {
  en: {
    title: 'Hire',
    offer: 'See offer',
    profile: 'Profile',
    username: 'Username offer',
    position: 'Position title',
    starts: 'Starts on',
    first: 'First day instruction (Optional)',
    payment: 'Payment',
    benefits: 'Benefits',
    location: 'Location',
    enterLocation: 'Enter a location',
  },
  es: {
    title: 'Contratación',
    offer: 'Ver oferta',
    profile: 'Perfil',
    username: 'Oferta del usuario',
    position: 'Título de la posición',
    starts: 'Inicia en',
    first: 'Instrucciones del primer día (Opcional)',
    payment: 'Pago',
    benefits: 'Beneficios',
    location: 'Ubicación',
    enterLocation: 'Ingrese un lugar',
  },
};
