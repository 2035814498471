import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { ApplicantsStatus, USER_ID } from './constants';
import { TFunction } from 'react-i18next';
import { REACT_APP_CDN } from './enviroment';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { LAST_EMAIL } from './persistor';
import { PaymentOptionEnum } from 'actions/business/model';
import { ValidationError } from 'yup';

export const ShowToastError = (e: unknown): IErrorResponse | undefined => {
  const error = e as AxiosError<IErrorResponse>;
  if (error.response) {
    const message = error.response.data.message;
    toast.error(message);
    return error.response.data;
  }
};

export const ReturnImageCard = (value: string) => {
  switch (value) {
    case 'visa':
      return `${REACT_APP_CDN}/img/cards/visa.png`;
    case 'mastercard':
      return `${REACT_APP_CDN}/img/cards/mastercard.png`;
    case 'cartes_bancaires':
      return `${REACT_APP_CDN}/img/cards/cartes_bancaires.png`;
    case 'discover':
      return `${REACT_APP_CDN}/img/cards/discover.png`;
    case 'jcb':
      return `${REACT_APP_CDN}/img/cards/jcb.png`;
    case 'unionpay':
      return `${REACT_APP_CDN}/img/cards/unionpay.png`;
    case 'diners':
      return `${REACT_APP_CDN}/img/cards/diners_club.png`;
    case 'amex':
      return `${REACT_APP_CDN}/img/cards/amex.png`;

    default:
      break;
  }
};

export const removeItemOnce = (arr: string[], value: string) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export enum AuthMethod {
  Email = 0,
  Google = 1,
  Facebook = 2,
  Apple = 3,
}

export const translateApplicantStatus = (
  status: number,
  translator: TFunction<'ApplicantsManager'[], undefined>
): string => {
  switch (status) {
    case ApplicantsStatus.received:
      return translator('received');
    case ApplicantsStatus.reviewed:
      return translator('reviewed');
    case ApplicantsStatus.interested:
      return translator('interested');
    case ApplicantsStatus.hired:
      return translator('hired');
    case ApplicantsStatus.rejected:
      return translator('rejected');
    case ApplicantsStatus.interview:
      return translator('interview');

    default:
      return '';
  }
};

export const downloadFromUrl = (url: string, filename: string) => {
  fetch(url).then((t) => {
    return t.blob().then((b) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(b);
      a.setAttribute('download', filename);
      a.click();
    });
  });
};

export const getUserId = () => {
  const localStorageUserId = localStorage.getItem(USER_ID);
  if (localStorageUserId) {
    return localStorageUserId;
  } else {
    return undefined;
  }
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const emailOptions = (businessEmail: string) => {
  const emails = localStorage.getItem(LAST_EMAIL);
  let options = [];
  if (emails) {
    options = JSON.parse(emails);
  }

  if (businessEmail && !options.find((e: { value: string }) => e.value === businessEmail)) {
    options.push({ label: businessEmail, value: businessEmail });
  }
  return options;
};

export const getStepPaymentName = (selectedPayment: PaymentOptionEnum) => {
  switch (selectedPayment) {
    case PaymentOptionEnum.AutoCharge:
      return 'Auto charge';

    case PaymentOptionEnum.InvoicePerDay:
      return 'Daily invoice';

    case PaymentOptionEnum.InvoicePerWeek:
      return 'Weekly invoice';

    case PaymentOptionEnum.InvoicePerShift:
      return 'Per shift';

    default:
      return '';
  }
};

export const getErrors = (e: unknown) => {
  if (e instanceof ValidationError) {
    console.log(e.errors);
  }
};
