import Icon from 'assets/icons';
import { useTranslation } from 'react-i18next';
import ConfirmPresenter from './Confirm.Presenter';
import RecurrentScreen from './Recurrent/Recurrent.Screen';
import { DateTime } from 'luxon';
import { PARKING_INFO } from 'pages/postShift/shared/constants/parking';
import Button from 'components/Button';
import CheckoutInformationScreen from './CheckoutInformation/CheckoutInformation.Screen';

interface IStepConfirm {
  stepName: string;
}

// ESLINT DISABLED FOR STEPPER NAME FROM REACT-STEP-WIZARD
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConfirmScreen = ({ stepName }: IStepConfirm) => {
  const [t] = useTranslation(['Shifts']);
  const presenter = ConfirmPresenter();
  const parking = PARKING_INFO.filter(
    (parking) => parking.id === presenter.dataForm.shiftInfo?.location?.parking
  );

  return (
    <>
      {presenter.mode !== 'edit' && (
        <CheckoutInformationScreen setDateConfirmation={presenter.setDateConfirmationOpen} />
      )}

      {!presenter.dateConfirmationOpen ? (
        <div>
          <h2 className="font-semibold text-base mt-3">{t('create.confirm.title')}</h2>

          <div className="bg-neutral-200 py-4 px-6 mt-3 rounded">
            <div>
              <div className="flex justify-between mb-1">
                <div className=" text-lg font-semibold text-secondary-500">
                  {t('create.contacts.role')}
                </div>
                <div
                  onClick={presenter.onClickEditInfo}
                  className="text-[9px] cursor-pointer text-secondary-500 font-semibold flex items-center"
                >
                  <div className="mr-2">
                    <Icon icon="edit1" size={16} />
                  </div>
                  <p>{t('create.confirmation.edit')}</p>
                </div>
              </div>
              {presenter.dataForm.shiftInfo?.role && (
                <div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.shiftName')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter?.dataForm?.shiftInfo?.role?.name}
                    </p>
                  </div>
                  {presenter?.dataForm?.shiftInfo?.startTime &&
                    presenter?.dataForm?.shiftInfo?.endTime && (
                      <div className="pb-2">
                        <p className="text-base font-semibold text-neutral-1800">
                          {t('create.confirmation.shiftTime')}
                        </p>

                        <p className="text-base  text-neutral-1400">
                          {t('create.confirmation.from')}:
                          {DateTime.fromJSDate(presenter?.dataForm?.shiftInfo?.startTime).toFormat(
                            'hh:mm a'
                          )}{' '}
                          -{' '}
                          {DateTime.fromJSDate(presenter?.dataForm?.shiftInfo?.endTime).toFormat(
                            'hh:mm a'
                          )}{' '}
                          ({presenter?.dataForm?.shiftInfo?.timeZone.text})
                        </p>
                      </div>
                    )}
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.description')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter.dataForm.shiftInfo?.role.information}
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.payment')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter.dataForm.shiftInfo?.role?.amount} /{' '}
                      {t(presenter.dataForm.shiftInfo?.role?.payment?.text)}
                    </p>
                  </div>

                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.worker.fullTime')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter.dataForm.workers?.fullTimeOportunity
                        ? t('create.worker.yes')
                        : 'No'}
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.abilities')}
                    </p>

                    {presenter?.dataForm?.shiftInfo?.role?.abilities &&
                      presenter.dataForm.shiftInfo?.role.abilities.map((abilitie) => (
                        <p key={abilitie.id} className="text-base  text-neutral-1400">
                          {abilitie.text}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>
            <hr className="bg-neutral-400  border-neutral-400 w-11/12" />
            <div>
              <div className="flex justify-between mb-1 mt-2">
                <div className=" text-lg font-semibold text-secondary-500">
                  {t('table.location')}
                </div>
                <div
                  onClick={presenter.onClickEditInfo}
                  className="text-[9px] cursor-pointer text-secondary-500 font-semibold flex items-center"
                >
                  <div className="mr-2">
                    <Icon icon="edit1" size={16} />
                  </div>
                  <p>{t('create.confirmation.edit')}</p>
                </div>
              </div>
              {presenter.dataForm.shiftInfo?.location && (
                <div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('description.address')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter.dataForm.shiftInfo?.location.location.location.address}
                    </p>
                  </div>

                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.parking')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {parking.length > 0 && parking[0].text}
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.instructions')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter.dataForm.shiftInfo?.location.info}
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.location.instructions')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter.dataForm.shiftInfo?.location.clockInInfo}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <hr className="bg-neutral-400  border-neutral-400 w-11/12" />
            <div>
              <div className="flex justify-between mb-1 mt-2">
                <div className=" text-lg font-semibold text-secondary-500">
                  {t('create.shiftInfo.contact')}
                </div>
                <div
                  onClick={presenter.onClickEditInfo}
                  className="text-[9px] cursor-pointer text-secondary-500 font-semibold flex items-center"
                >
                  <div className="mr-2">
                    <Icon icon="edit1" size={16} />
                  </div>
                  <p>{t('create.confirmation.edit')}</p>
                </div>
              </div>
              <div className="flex flex-wrap mt-3">
                {presenter?.dataForm?.shiftInfo?.contacts &&
                  presenter.dataForm.shiftInfo?.contacts.map((contact) => (
                    <div
                      key={contact.email}
                      className=" bg-secondary-300 bg-opacity-50 rounded py-2 px-4 w-full sm:w-1/2 lg:w-2/5 mr-3"
                    >
                      <p className="text-secondary-500 text-sm font-semibold">{contact.name}</p>
                      <p className=" text-primary text-sm">{contact.role} </p>
                      <p className=" text-primary text-sm">{contact.phoneNumber} </p>
                      <p className=" text-primary text-sm">{contact.email} </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="bg-neutral-200 py-4 px-6 mt-3 rounded">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-secondary-500">
                {t('create.worker.workers')}
              </div>
              <div
                className="text-[9px]  text-secondary-500 font-semibold flex items-center cursor-pointer"
                onClick={presenter.onClickEditWorker}
              >
                <div className="mr-2">
                  <Icon icon="edit1" size={16} />
                </div>
                <p>{t('create.confirmation.edit')}</p>
              </div>
            </div>
            <div>
              {presenter.dataForm.workers && (
                <div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.pros')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter.dataForm.workers?.openings}{' '}
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.unpaid')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {(presenter.dataForm.workers?.breakTime * 60).toFixed(0)} mins
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.selectFrom')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {!presenter.dataForm.workers?.selectedAssign
                        ? t('create.worker.autoAssign')
                        : t('create.worker.favorites')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="bg-neutral-200 py-4 px-6 mt-3 rounded">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-secondary-500">
                {t('create.confirmation.attire')}
              </div>
              <div
                className="text-[9px]  text-secondary-500 font-semibold flex items-center cursor-pointer"
                onClick={presenter.onClickEditAttire}
              >
                <div className="mr-2">
                  <Icon icon="edit1" size={16} />
                </div>
                <p>{t('create.confirmation.edit')}</p>
              </div>
            </div>
            <div>
              {presenter.dataForm.attire && (
                <div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.attire.provided')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter?.dataForm?.attire?.uniform?.text}{' '}
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.dressCode')}
                    </p>

                    <p className="text-base  text-neutral-1400">
                      {presenter?.dataForm?.attire?.dressCode?.text}
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="text-base font-semibold text-neutral-1800">
                      {t('create.confirmation.restrictions')}
                    </p>

                    {presenter?.dataForm?.attire?.groomingRestrictions.map((restriction) => (
                      <p key={restriction.id} className="text-base  text-neutral-1400">
                        {restriction.text}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between mt-6">
            <p className="cursor-pointer" onClick={presenter.onStepperBack}>
              {t('create.back')}
            </p>
            <Button
              className=" rounded-md py-2 px-4"
              type="submit"
              onClick={presenter.onClickNext}
              color="primary"
              name={t('create.save')}
              size="xs"
            />
          </div>
        </div>
      ) : (
        <>
          <RecurrentScreen />
        </>
      )}
    </>
  );
};

export default ConfirmScreen;
