export default {
  en: {
    success: 'Success',
    error: 'Error',
    setting: 'Settings',
    notifications: 'Notifications',
    sms: 'Enable SMS from Chamba',
    email: 'Email',
    collaborators: 'Collaborators',
    name: 'Name',
    position: 'Position',
    since: 'Member since',
    permitions: 'Permissions',
    change: 'Change password',
    old: 'Old password',
    new: 'New password',
    confirm: 'Confirm new password',
    noMatch: 'Passwords do not match',
    delete: 'Delete account',
    want: 'Do you want to delete your account?',
    remember: 'Remember that this action is permanent and cannot be undone',
    dateleC: 'Delete',
    addCollaborator: 'Invite a collaborator',
    send: 'Send invitation',
    emailCollab: 'Add a new colaborator by email here',
    type: 'Role',
    title: 'Account Settings',
    changeTax: 'To change your requested Pro type, please contact us at team@lachamba.app',
    password: 'Password',
    choose: 'Choose your preferred way for receiving notifications',
    allow: 'Allow Chamba send email to you for updates and communication',
    typePro: 'Type of Pro requested for this Account',
    changing: 'Changing password once a while makes your account more secure',
    account: 'Account',
    logout: 'Log out',
    deleteAction:
      'The delete account action will result in losing access to all data you have with Chamba permanently',
    why: 'Please tell us why you want to delete the account',
    feedback:
      'Your feedback will help us improving our services. We appreciate all suggestions (Optional)',
    errorSelect: 'You must select an option',
    memberDelete:
      'Once you delete this collaborator, he will no longer have access to any information of this account. Do you confirm?',
    cancel: 'Cancel',
    language: 'Language',
  },
  es: {
    success: 'Éxito',
    error: 'Error',
    setting: 'Configuración',
    notifications: 'Notificaciones',
    sms: 'Permitir SMS de Chamba',
    email: 'Permitir notificaciones por email',
    collaborators: 'Colaboradores',
    name: 'Nombre',
    position: 'Posición',
    since: 'Miembro desde',
    permitions: 'Permisos',
    change: 'Cambiar contraseña',
    old: 'Contraseña antigua',
    new: 'Nueva contraseña',
    confirm: 'Confirmar nueva contraseña',
    noMatch: 'Las contraseñas no coinciden',
    delete: 'Eliminar cuenta',
    want: '¿Quieres eliminar tu cuenta?',
    remember: 'Recuerda que esta acción es permanente y no podrá deshacerse',
    deleteC: 'Borrar',
    addCollaborator: 'Añadir nuevo colaborador',
    send: 'Enviar invitación',
    emailCollab: 'Añade un nuevo colaborador por su email',
    type: 'Rol',
    title: 'Ajustes',
    changeTax:
      'Para cambiar el tipo de Pro solicitado, póngase en contacto con nosotros en team@lachamba.app',
    password: 'Contraseña',
    choose: 'Elige la forma que prefieras para recibir las notificaciones',
    allow:
      'Permitir que Chamba te envíe correos electrónicos para actualizaciones y comunicaciones',
    typePro: 'Tipo de solicitudes para esta cuenta',
    changing: 'Cambiar la contraseña de vez en cuando hace que tu cuenta sea más segura',
    account: 'Cuenta',
    logout: 'Cerrar sesión',
    deleteAction:
      'La acción de eliminar la cuenta conllevará la pérdida de acceso a todos los datos que tenga con Chamba de forma permanente.',
    why: 'Por favor dinos por qué quieres eliminar tu cuenta',
    feedback:
      'Tu retroalimentación nos ayudará a mejorar nuestros servicios. Apreciamos las sugerencias (opcional)',
    errorSelect: 'Debes seleccionar una opción',
    memberDelete:
      'Una vez que elimines a este colaborador, ya no tendrá acceso a ninguna información de esta cuenta. ¿Lo confirma?',
    cancel: 'Cancelar',
    language: 'Idioma',
  },
};
