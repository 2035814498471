import ModalV2 from 'components/ModalV2';
import CreateContactPresenter from './CreateContact.Presenter';
import InputV2 from 'components/InputV2';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Icon from 'assets/icons';
import SelectV2 from 'components/SelectV2';

const CreateContactScreen = () => {
  const presenter = CreateContactPresenter();
  const [t] = useTranslation(['Shifts']);
  return (
    <>
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold my-3">{t('create.contacts.contact')}</p>
        {presenter.shouldNotShowSelect && (
          <p className="text-xs font-medium cursor-pointer" onClick={presenter.onClickClear}>
            Clear
          </p>
        )}
      </div>

      {!presenter.shouldNotShowSelect && (
        <SelectV2
          items={
            presenter?.contacts?.data?.map((c) => ({
              id: c.id || c.email,
              text: c.name,
            })) ?? []
          }
          className="w-full rounded-lg text-base border-neutral-400 px-3 py-1 border mb-3 mt-2"
          itemSelected={presenter.selectedContact}
          name={t('create.shiftInfo.selectContact')}
          showDropDownArrow
          setSelectedItem={presenter.onClickContact}
        />
      )}

      {!presenter.shouldNotShowSelect && (
        <p
          onClick={() => presenter.onClickTrigger()}
          className=" text-secondary-500 underline text-sm mt-3 mb-3"
        >
          {t('create.shiftInfo.createContact')}
        </p>
      )}

      {presenter?.contextForm.dataForm.shiftInfo?.contacts &&
        presenter?.contextForm.dataForm.shiftInfo?.contacts.length > 0 && (
          <div className="bg-neutral-200 border border-neutral-400 rounded p-4">
            <div className="flex justify-between">
              <p className="text-neutral-1600 text-base font-semibold ">
                {t('create.shiftInfo.contact')}
              </p>
              <div className="mr-2  cursor-pointer" onClick={() => presenter.onClickEditContact()}>
                <Icon icon="edit1" size={16} />
              </div>
            </div>

            <p className="text-neutral-1600 text-base ">
              {presenter.contextForm?.dataForm.shiftInfo.contacts[0].name}
            </p>
            <p className="text-neutral-1600 text-base font-semibold mt-2">
              {t('create.contacts.role')}
            </p>
            <p className="text-neutral-1600 text-base ">
              {presenter.contextForm.dataForm.shiftInfo.contacts[0].role}
            </p>
            <p className="text-neutral-1600 text-base font-semibold mt-2">
              {t('create.contacts.email')}
            </p>
            <p className="text-neutral-1600 text-base ">
              {presenter.contextForm.dataForm.shiftInfo.contacts[0].email}
            </p>
            <p className="text-neutral-1600 text-base font-semibold mt-2">
              {t('create.contacts.phone')}
            </p>
            <p className="text-neutral-1600 text-base ">
              {presenter.contextForm.dataForm.shiftInfo.contacts[0].phoneNumber}
            </p>
          </div>
        )}

      <ModalV2
        isOpen={presenter.openedModal}
        setIsOpen={presenter.setOpenedModal}
        classNameContainer="w-full max-w-3xl"
      >
        <p className="font-bold text-primary text-xl mb-3">{t('create.contacts.description')}</p>
        <form onSubmit={presenter.form.handleSubmit(presenter.onSubmit)}>
          <InputV2
            {...presenter.form.register('name')}
            className="mt-3"
            placeholder={t('create.contacts.name')}
          />

          <InputV2
            {...presenter.form.register('role')}
            className="mt-3"
            placeholder={t('create.contacts.role')}
          />

          <InputV2
            {...presenter.form.register('email')}
            className="mt-3"
            placeholder={t('create.contacts.email')}
          />

          <InputV2
            {...presenter.form.register('phoneNumber')}
            className="mt-3 "
            placeholder={t('create.contacts.phone')}
          />

          <div className="flex justify-between flex-shrink-0 mt-7">
            <Button
              onClick={presenter.onClickBack}
              size="xs"
              type="button"
              name={t('create.contacts.cancel')}
              color="outline"
            />
            <Button type="submit" size="xs" name={t('create.contacts.save')} color="primary" />
          </div>
        </form>
      </ModalV2>
    </>
  );
};

export default CreateContactScreen;
