import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { QueryObserverResult, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import ShiftProsAction from './ShiftDescriptionPros.Action';
import {
  ApplicantShiftGet,
  ApplicantShiftSet,
} from 'pages/shift/core/Modules/Users/Domain/Entities/ApplicantShift.entity';
import useConfirm from 'utils/confirmation';
import { ShiftDescriptionContext } from '../ShiftDescription.Screen';
import { useTranslation } from 'react-i18next';

export interface ShiftProsPresenterType {
  query: UseQueryResult<ApplicantShiftGet | undefined, unknown>;
  setPage: Dispatch<SetStateAction<number>>;
  handlePageClick: (event: { selected: number }) => void;
  onAddPreferredUser: (
    userId: string
  ) => Promise<QueryObserverResult<ApplicantShiftGet | undefined, unknown>>;
  onAddBlockedUser: (
    userId: string,
    appId: string
  ) => Promise<QueryObserverResult<ApplicantShiftGet | undefined, unknown>>;
  selectedPro?: ApplicantShiftGet['applicantShift']['shiftApplicants'][0];
  setSelectedPro: Dispatch<
    SetStateAction<ApplicantShiftGet['applicantShift']['shiftApplicants'][0] | undefined>
  >;
  onRejectApplicant: (applicantId: string) => void;
  Confirmation: () => JSX.Element;
}

const ShiftProsPresenter = (): ShiftProsPresenterType => {
  const action = ShiftProsAction();
  const [page, setPage] = useState(0);
  const [selectedPro, setSelectedPro] = useState<
    ApplicantShiftGet['applicantShift']['shiftApplicants'][0] | undefined
  >(undefined);
  const { id } = useParams();

  const { getConfirmation, Confirmation } = useConfirm();
  const contextShift = useContext(ShiftDescriptionContext);
  const [t] = useTranslation(['Shifts']);

  const filter = useMemo<ApplicantShiftSet>(() => {
    return {
      filters: {
        shiftId: id ?? '',
      },
      page: page + 1,
    };
  }, [page, id]);

  const query = useQuery({
    queryKey: ['get-applicants-shift', filter],
    queryFn: () => action.executeGetShiftApplicants(filter),
  });

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected);
  };

  const onAddPreferredUser = (userId: string) =>
    action.executeAddToFavorite(userId).then(() => query.refetch());

  const onRejectApplicant = async (applicantId: string) => {
    if (id) {
      const description = contextShift.queryDetailShift.data?.description;
      if (action.executeIs24HRefuse(t, description?.timeZone, description?.startDate)) {
        const result = await getConfirmation(
          'You are rejecting this Pro within 24 hours of the shift start time. you may get charged for 4 hours of the shift rate. Do you confirm?'
        );

        if (result) {
          action.executeRejectApplicant(applicantId).then(() => query.refetch());
        }
      } else {
        action.executeRejectApplicant(applicantId).then(() => query.refetch());
      }
    }
  };

  const onAddBlockedUser = async (userId: string) => {
    return action.executeAddToBlocked(userId).then(() => query.refetch());
  };

  return {
    query,
    setPage,
    handlePageClick,
    onAddPreferredUser,
    onAddBlockedUser,
    selectedPro,
    setSelectedPro,
    Confirmation,
    onRejectApplicant,
  };
};

export default ShiftProsPresenter;
