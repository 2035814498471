import { InferType, array, bool, date, number, object, string } from 'yup';

export const DraftSchema = object({
  id: string().required(),
  postedDate: date().required(),
  isActive: bool().required(),
  businessId: string().required(),
  businessUserId: string().required(),
  paymentTotal: number().required(),
  shifts: array(
    object({
      id: string().required(),
      eventId: string().required(),
      location: object({
        topic: string().required(),
        address: string().required(),
        notificationsTopic: string().required(),
      }).required(),

      locationCoords: object({
        latitude: number().required(),
        longitude: number().required(),
      }).required(),

      parkingInfo: string().required(),
      locationInfo: object({
        english: string().required(),
        spanish: string().required(),
      }).nullable(),

      clockInInfo: object({
        english: string().required(),
        spanish: string().required(),
      }).required(),

      positionTitle: object({
        english: string().required(),
        spanish: string().required(),
      }),
      positionId: string().required(),
      startDate: date().required(),
      endDate: date().required(),
      breakTime: number().required(),
      openings: number().required(),
      timezone: string(),
      salaryType: string().required(),
      salaryAmount: number().required(),
      fullTimeOportunity: bool().required(),
      uniform: string().required(),
      dressCode: string().required(),
      groomingRestrictions: array(string().required()).required(),
      jobInformation: object({
        english: string().required(),
        spanish: string().required(),
      })
        .required()
        .nullable(),
      abilities: array(string().required()).required(),
      experience: string().required(),
      contacts: array(
        object({
          name: string().required(),
          role: string().required(),
          phoneNumber: string().required(),
          email: string().required(),
        }).required()
      ).required(),
      isFilled: bool().required(),
      isFinished: bool().required(),
      isAccounted: bool().required(),
      isActive: bool().required(),
      views: number().required(),
      stripeId: string().nullable(),
      postedDate: date().required(),
      cancelledDate: date().required().nullable(),
      cancelledByBusiness: bool().required(),
      cancelledByAdmin: bool().required(),
      isW2: bool().required(),
      onlyFavorites: bool().required(),
      external: number().required(),
    }).required()
  ).required(),

  hold: bool(),
  draft: bool(),
}).required();

export type DraftSchemaType = InferType<typeof DraftSchema>;

export const dtoToEntityDraft = async (d: object) => {
  try {
    const result = await DraftSchema.validate(d);
    return result;
  } catch (e) {
    console.error(e);
  }
};
