/* eslint-disable @typescript-eslint/no-explicit-any */
import Icon from 'assets/icons';
import { useState } from 'react';

type StarType = {
  selected: any;
  onSelect: any;
  onHover: () => void;
  onLeave: () => void;
  size: number;
  disabled?: boolean;
};

const Star = ({ selected, onSelect, onHover, onLeave, size, disabled }: StarType) => (
  <label
    onMouseEnter={disabled ? undefined : onHover}
    onMouseLeave={disabled ? undefined : onLeave}
    onClick={disabled ? undefined : onSelect}
    className="cursor-pointer text-2xl transition duration-300 mx-0.5 justify-center flex items-center"
  >
    <Icon size={size} icon={!selected ? 'star' : 'backfillStar'} />
    <input type="radio" name="rating" value={selected} className="hidden" />
  </label>
);

type StarRatingType = {
  defaultRating?: number;
  rates?: number;
  onChangeRating?: (v: number) => void;
  size: number;
  disabled?: boolean;
};

const StarRating = ({ defaultRating, onChangeRating, size, disabled, rates }: StarRatingType) => {
  const [rating, setRating] = useState(defaultRating ?? 0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleSelect = (selectedRating: number) => {
    setRating(selectedRating);
    if (onChangeRating) {
      onChangeRating(selectedRating);
    }
  };

  const handleHover = (hoveredRating: number) => {
    setHoveredRating(hoveredRating);
  };

  const handleLeave = () => {
    setHoveredRating(0);
  };

  const stars = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <div>
      <div className="flex">
        {stars.map((star) => (
          <Star
            disabled={disabled}
            size={size}
            key={star}
            selected={hoveredRating > 0 ? star <= hoveredRating : star <= rating}
            onSelect={() => handleSelect(star)}
            onHover={() => handleHover(star)}
            onLeave={handleLeave}
          />
        ))}
        <span
          className={`ml-1 ${
            disabled ? 'text-neutral-600 fill-neutral-600' : 'text-secondary-500 fill-secondary-500'
          }`}
          style={{ fontSize: 10 }}
        >
          ({rates ?? defaultRating})
        </span>
      </div>
    </div>
  );
};

export default StarRating;
