import { ReturnImageCard } from 'utils';
import { REACT_APP_CDN } from 'utils/enviroment';

export interface CreditCardProps {
  number: string;
  name: string;
  bank: string;
  date: string;
}

const CreditCard = ({ number, name, bank, date }: CreditCardProps): JSX.Element => (
  <div className="text-primary mb-8 xl:w-full xl:flex xl:justify-center">
    <div className="flex  items-center flex-wrap justify-center lg:justify-start lg:flex-nowrap">
      <div className={`bg-[#4339F2] text-white w-72 py-2 px-4 rounded-lg`}>
        <div className="flex justify-between">
          <p className="text-base uppercase">{bank}</p>
          <img className="mt-2" src={`${REACT_APP_CDN}/img/payments/Wifi.svg`} alt="" />
        </div>
        <p className="text-xs mt-8 font-light text-left">{name}</p>
        <p className="text-lg text-left">
          {number.length > 4 ? number : `**** **** **** ${number}`}{' '}
        </p>
        <div className="flex justify-between mt-5">
          <p className="text-sm mt-2 font-light">{date}</p>
          <img className=" mb-1 mt-2 w-10" src={ReturnImageCard(bank)} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default CreditCard;
