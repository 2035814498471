import instance from 'actions/http';
import { SaveDraftDataSet } from '../Entities/SaveDraftDataSet';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import { DraftDataGet } from '../Entities/DraftDataGet.entity';
import { dtoToEntityDraft } from '../Dtos/Draft.dto';

class DraftRepository {
  public async save(data: SaveDraftDataSet) {
    return await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.SAVE_DRAFT_DATA), data);
  }

  public async get(id: string) {
    const result = await instance.get(
      getEndpoint(API_ENDPOINTS.EVENTS.GET_DRAFT_DATA.replace('{id}', id))
    );

    const dto = await dtoToEntityDraft(result.data);
    if (dto) {
      return new DraftDataGet(dto);
    }
  }

  public async delete(id: string) {
    await instance.delete(getEndpoint(API_ENDPOINTS.EVENTS.DELETE_DRAFT_DATA.replace('{id}', id)));
  }
}

export default DraftRepository;
