import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { ValidateRole } from '../../Form/Domain/Dtos/Role.dto';
import { ValidateLocation } from '../../Form/Domain/Dtos/Location';
import { ValidateShiftInfo } from '../../Form/Domain/Dtos/ShiftInfo.dto';
import { ValidateWorker } from '../../Form/Domain/Dtos/WorkerForm.dto';
import { ValidateAttire } from '../../Form/Domain/Dtos/Attire.dto';

const ValidationsFormUseCase = () => {
  const validateShiftInfo = async (d: Partial<DataFormType>) => {
    const roleFormOK = await ValidateRole(d?.shiftInfo?.role ?? {});
    const contactsFormOK = d?.shiftInfo?.contacts?.length ?? 0;
    const locationFormOK = await ValidateLocation(d?.shiftInfo?.location ?? {});
    const rootFormOK = await ValidateShiftInfo(d?.shiftInfo ?? {});

    return roleFormOK && contactsFormOK > 0 && locationFormOK && rootFormOK;
  };

  const validateWorker = async (d: Partial<DataFormType>) => {
    return ValidateWorker(d.workers as object);
  };

  const validateAttire = async (d: Partial<DataFormType>) => {
    return ValidateAttire(d.attire as object);
  };

  return {
    validateShiftInfo,
    validateWorker,
    validateAttire,
  };
};

export { ValidationsFormUseCase };
