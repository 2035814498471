import { InferType, array, number, object, string, bool } from 'yup';
import { ListSheetGet } from '../Entities/ListSheet.entity';
import { ListSheetSet } from '../Entities/ListSheetSet';

export const ListSheetSchema = object({
  timesheetWeekItems: array()
    .of(
      object({
        dayStart: string().required(),
        dayEnd: string().required(),
        timesheetStatus: number().required().nullable(),
        allowChanges: bool().default(false),
      })
    )
    .required(),
});

export type ListSheetDto = InferType<typeof ListSheetSchema>;

export const dtoToListSheetGet = (dto: ListSheetDto): ListSheetGet => {
  const validatedDto = ListSheetSchema.validateSync(dto);
  const listSheetGet = new ListSheetGet(validatedDto.timesheetWeekItems);

  return listSheetGet;
};

export const listSheetToDto = (data: ListSheetSet): ListSheetSet => {
  return {
    businessId: data.businessId,
    weekDate: data.weekDate,
  };
};
