import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { ShiftUseCase } from 'pages/shift/core/Modules/Shifts/Application/ShiftUseCase';
import { ShiftPaginationSet } from 'pages/shift/core/Modules/Shifts/Domain/Entities/ShiftPaginationSet';
import toast from 'react-hot-toast';
import useUser from 'utils/hooks/useUser';

const ShiftUseCases = new ShiftUseCase();

const ShiftsTableAction = () => {
  const {
    business: { id },
  } = useUser();

  const executeGetShiftsPaginated = async (d: Omit<ShiftPaginationSet, 'businessId'>) => {
    try {
      const result = await ShiftUseCases.invokeGetShifts({
        ...d,
        filters: {
          ...d.filters,
          businessId: id,
        },
      });
      return result;
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeDeleteShift = async (id: string) => {
    try {
      await ShiftUseCases.invokeDeleteShift(id);
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeIs24 = (t: TFunction, timezone?: string, startDate?: string) => {
    return ShiftUseCases.is24hDelete(t, timezone, startDate);
  };

  return {
    executeGetShiftsPaginated,
    executeDeleteShift,
    executeIs24,
  };
};

export default ShiftsTableAction;
