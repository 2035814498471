import Icon from 'assets/icons';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StepPaymentContext } from '..';
import { PaymentOptionEnum } from 'actions/business/model';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

interface IStep1 {
  setPaymentMode: (v: PaymentOptionEnum) => void;
}

const Step1 = ({ setPaymentMode }: IStep1) => {
  const [t] = useTranslation(['Profile', 'Shifts']);
  const instance = useContext(StepPaymentContext);
  const { trackEvent } = useMatomo();

  const onNext = (value: PaymentOptionEnum) => {
    setPaymentMode(value);
    if (instance && instance.nextStep && instance.goToStep) {
      if (value === PaymentOptionEnum.AutoCharge) {
        instance.nextStep();
      } else {
        instance.goToStep(3);
      }
    }
  };

  useEffect(() => {
    trackEvent({
      action: 'open-modal-payment',
      category: 'Shift',
      value: 1,
      name: 'step',
    });
  }, []);

  return (
    <>
      <div className="flex">
        <h1 className="mb-7 font-bold text-4xl text-primary">
          {t('paymentModal.title', { ns: 'Shifts' })}
        </h1>
      </div>
      <div className="bg-secondary-200 pl-10 py-5 pr-5 flex rounded-xl">
        <div className="pr-3">
          <Icon icon={'about'} size={20} />
        </div>
        <span className="text-xl">
          {t('paymentShift.breadcumb')}{' '}
          <a className="text-secondary-500" href="mailto:team@lachamba.app">
            team@lachamba.app
          </a>{' '}
          {t('paymentShift.change')}
        </span>
      </div>
      <div
        onClick={() => onNext(PaymentOptionEnum.AutoCharge)}
        className="rounded-xl cursor-pointer flex w-full justify-between px-10 items-center py-5 border-neutral-200 border-[1.5px] mt-7"
      >
        <Icon icon={'addCreditCard'} size={46} />
        <div className="w-full max-w-[600px] px-3">
          <span className="font-bold"> {t('paymentShift.method1')} </span>
          <p>{t('paymentShift.method1description', { ns: 'Profile' })}</p>
        </div>
        <Icon icon={'arrow'} color="red" size={20} />
      </div>
      <div
        onClick={() => onNext(PaymentOptionEnum.InvoicePerWeek)}
        className="rounded-xl cursor-pointer flex w-full justify-between px-10 items-center py-5 border-neutral-200 border-[1.5px] mt-7"
      >
        <Icon icon={'calendar'} size={46} />
        <div className="w-full max-w-[600px] px-3 sm:px-0">
          <div className="">
            <span className="font-bold">{t('paymentShift.method4')}</span>
            <p>{t('paymentShift.method4description')}</p>
          </div>
        </div>
        <Icon icon={'arrow'} color="red" size={20} />
      </div>
    </>
  );
};

export default Step1;
