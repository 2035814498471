import Icon from 'assets/icons';
import { IndexPresenterMassive } from './Index.Presenter';
import { Link } from 'react-router-dom';
import UploadDocumentScreen from '../UploadDocument/UploadDocument.Screen';
import UploadDocumentProvider, {
  StepUploadDocument,
  UploadDocumentContext,
} from 'pages/massiveShift/core/infraestructure/Providers/UploadDocument.provider';
import ResultUploadScreen from '../ResultUpload/ResultUpload.Screen';

const IndexScreen = () => {
  const { t } = IndexPresenterMassive();

  return (
    <UploadDocumentProvider>
      <UploadDocumentContext.Consumer>
        {({ step }) => {
          return (
            <div className="bg-white w-full min-h-screen">
              <header className="pl-8 py-5 flex justify-between px-2">
                <span className="text-xl font-bold text-primary">{t('title')}</span>

                <Link
                  className="bg-secondary-500 py-1.5 px-3 text-white rounded-2xl"
                  target="_blank"
                  to="/ShiftBulkLoad.xlsx"
                >
                  {t('download')}
                  <Icon icon="sheet" size={14} className="-mt-1 ml-2.5" />
                </Link>
              </header>

              <hr />

              <div className=" border-neutral-200 border rounded my-4 mx-8">
                {step === StepUploadDocument.UPLOAD && <UploadDocumentScreen />}

                {step === StepUploadDocument.REPORT && <ResultUploadScreen />}
              </div>
            </div>
          );
        }}
      </UploadDocumentContext.Consumer>
    </UploadDocumentProvider>
  );
};

export default IndexScreen;
