import { useQuery } from '@tanstack/react-query';
import IndexActionDescription from './Index.Action';

const IndexPresenterDescription = (hireId?: string) => {
  const action = IndexActionDescription();

  const { data } = useQuery(['get-description-hire', hireId], () =>
    action.executeGetDescription(hireId ?? '')
  );

  return { data };
};

export default IndexPresenterDescription;
