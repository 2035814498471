import { RoleSchemaType, SaveRoleSet } from "../Dtos/Role.dto";

export const roleFormToDto = (data: RoleSchemaType, businessId: string): SaveRoleSet => {
    return {
        abilities: data.abilities.map(v => v.id),
        businessId,
        experience: data.experience.id,
        jobInformation: data.information,
        positionId:data.category.id,
        positionTitle:  data.name ?? data.category.text,
        salaryAmount: data.amount,
        salaryType: data.payment.id
    }
}