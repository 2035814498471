import { PaymentOptionEnum } from 'actions/business/model';
import { useEffect, useState } from 'react';
import useUser from 'utils/hooks/useUser';
import Step1Payment from './Step1';
import SelectPlan from './SelectPlan';
import Step2Payment from './Step2';

enum IPaymentStep {
  CHOOSE = 1,
  SELECTED_CARD = 2,
  SELECTED_PLAN = 3,
}

const Payment = () => {
  const {
    business: { paymentOption },
  } = useUser();

  const [step, setStep] = useState<IPaymentStep>(IPaymentStep.CHOOSE);
  const [selectedPayment, setSelectedPayment] = useState(PaymentOptionEnum.NotSelected);

  useEffect(() => {
    if (paymentOption === PaymentOptionEnum.AutoCharge) {
      setStep(IPaymentStep.SELECTED_CARD);
      setSelectedPayment(paymentOption);
    } else if (paymentOption) {
      setStep(IPaymentStep.SELECTED_PLAN);
      setSelectedPayment(paymentOption);
    }
  }, [paymentOption]);

  if (step === IPaymentStep.SELECTED_CARD) {
    return <Step1Payment selectedPayment={selectedPayment} />;
  }

  if (step === IPaymentStep.SELECTED_PLAN) {
    return <Step2Payment selectedPayment={selectedPayment} />;
  }

  return <SelectPlan />;
};

export default Payment;
