import { UploadDocumentContext } from 'pages/massiveShift/core/infraestructure/Providers/UploadDocument.provider';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ResultUploadPresenter = () => {
  const ctxDocument = useContext(UploadDocumentContext);
  const navigate = useNavigate();
  const [openPayment, setOpenPayment] = useState(false);

  const onDiscard = () => {
    navigate(0);
  };

  const onClickPublish = () => {
    setOpenPayment(true);
  };

  return {
    data: ctxDocument.result,
    openPayment,
    setOpenPayment,
    onDiscard,
    onClickPublish,
  };
};

export default ResultUploadPresenter;
