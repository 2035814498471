export default {
  en: {
    title: 'Create shift using Excel',
    download: 'Download Excel template',
    drag: 'Drag and drop the fullfiled file here.',
    browse: 'Browse file',
    rowsRead: 'Row read',
    rows: 'Rows',
    summary: 'Summary',
    successShift: 'Successfully uploaded shifts',
    rowsPass: 'Row pass',
    estimationCost: 'Total estimation cost',
    errorReport: 'Error report',
    rowNumber: 'Row number',
    typeError: 'Type error',
  },
  es: {
    title: 'Cree un turno usando excel',
    download: 'Descargue la plantilla de excel',
    drag: 'Arrastre y suelte el documento aquí',
    browse: 'Buscar archivo',
    rowsRead: 'Filas leidas',
    rows: 'Filas',
    summary: 'Resumen',
    successShift: 'Turnos subidos correctamente',
    rowPass: 'Filas verificadas',
    estimationCost: 'Costo total',
    errorReport: 'Reporte de errores',
    rowNumber: 'Número de fila',
    typeError: 'Tipo de error',
  },
};
