import { Disclosure } from '@headlessui/react';
import Icon from 'assets/icons';
import Button from 'components/Button';
import { DateTime } from 'luxon';
import React, { ReactElement, cloneElement, useMemo } from 'react';
import { DayPropGetter, EventPropGetter, SlotPropGetter, ToolbarProps } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import useUser from 'utils/hooks/useUser';

export interface TEvent {
  allDay?: boolean | undefined;
  title?: React.ReactNode | undefined;
  start?: Date | undefined;
  end?: Date | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resource?: any;
  isActive?: boolean;
  id: string;
  temp?: boolean;
  openings?: number;
  totalApplicants: number;
  started?: boolean;
}

export interface EventWrapperProps<TEvent extends object = Event> {
  // https://github.com/intljusticemission/react-big-calendar/blob/27a2656b40ac8729634d24376dff8ea781a66d50/src/TimeGridEvent.js#L28
  style?: (React.CSSProperties & { xOffset: number }) | undefined;
  className: string;
  event: TEvent;
  isRtl: boolean;
  getters: {
    eventProp?: EventPropGetter<TEvent> | undefined;
    slotProp?: SlotPropGetter | undefined;
    dayProp?: DayPropGetter | undefined;
  };
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  onDoubleClick: (e: React.MouseEvent<HTMLElement>) => void;
  accessors: {
    title?: ((event: TEvent) => string) | undefined;
    tooltip?: ((event: TEvent) => string) | undefined;
    end?: ((event: TEvent) => Date) | undefined;
    start?: ((event: TEvent) => Date) | undefined;
  };
  selected: boolean;
  label: string;
  continuesEarlier: boolean;
  continuesLater: boolean;
  children: JSX.Element;
}

export const ColoredDateCellWrapper = ({
  event,
  children,
}: EventWrapperProps<TEvent>): JSX.Element => {
  let mainColor = 'bg-secondary-200';
  if (
    event.end &&
    DateTime.fromJSDate(event.end).startOf('hour') <=
      DateTime.fromJSDate(new Date()).startOf('hour')
  )
    mainColor = 'bg-neutral-400';

  if (!event.isActive) mainColor = 'bg-neutral-400';

  if (event.openings == event.totalApplicants && !event.started)
    mainColor = 'bg-success-background';

  return <div className={`rounded text-base ${mainColor}`}>{children}</div>;
};

export const CustomDateCellWrapper = ({ children }: { children: ReactElement }): JSX.Element => {
  const Element = cloneElement(React.Children.only(children), {
    className: {},
  });

  return <div className="w-full h-full rbc-hover-event">{Element}</div>;
};

export const Toolbar = ({ date, onNavigate, view }: ToolbarProps) => {
  const router = useNavigate();

  const {
    business: { id },
  } = useUser();

  const dateToShow = useMemo(() => {
    switch (view) {
      case 'day':
        return DateTime.fromJSDate(date).toFormat('LLLL d,  y');

      case 'week':
      case 'month':
        return DateTime.fromJSDate(date).toFormat('LLLL y');

      default:
        return DateTime.fromJSDate(date).toFormat('d LLLL y');
    }
  }, [view, date]);

  const onClickCode = () => {
    router(RoutesConstant.unsecure.event + '/' + id);
  };

  const onClickCreateShift = () => {
    router(RoutesConstant.secure.postShift);
  };

  const onClickCreateManualShift = () => {
    router(RoutesConstant.secure.createShiftExcel);
  };

  const DisclosureItem = (
    <Disclosure>
      <div className="flex flex-col">
        <Disclosure.Button className="bg-secondary-500 text-white rounded-3xl py-2 px-6">
          <Icon icon="add" size={15} className="-mt-0.5 mr-2" />
          Create shift <Icon icon="arrowRight" size={10} className="text-white -mt-0.5 rotate-90" />
        </Disclosure.Button>
        <Disclosure.Panel className="absolute mt-12 -ml-4 bg-neutral-200 rounded-md  font-semibold text-neutral-1200 shadow-lg z-50">
          <p className="my-2 mx-3 cursor-pointer" onClick={onClickCreateShift}>
            Create shift manually
          </p>
          <hr className="bg-neutral-1200 h-0.5" />
          <p onClick={onClickCreateManualShift} className="my-2 mx-3 cursor-pointer">
            Create shift using excel
          </p>
        </Disclosure.Panel>
      </div>
    </Disclosure>
  );

  return (
    <div className="flex justify-center md:justify-between flex-wrap md:flex-nowrap	w-full">
      <div className="sm:hidden flex justify-around my-3">
        <Button
          name="Download code"
          color="outlineSecondary"
          size="sm"
          className="mr-2 py-2  mb-3 xl:mb-0 max-h-[57px]"
          onClick={onClickCode}
          prefixIcon={<Icon icon="qr" size={15} className="-mt-0.5" />}
        />
        {DisclosureItem}
      </div>
      <div className="flex justify-between items-center w-full">
        <div onClick={() => onNavigate('PREV')}>
          <Icon className="rotate-90 cursor-pointer" icon="ChevronDown" size={20} />
        </div>
        <div>
          <p className="px-8">{dateToShow}</p>
        </div>
        <div>
          <div className="flex-wrap absolute right-14 -mt-2 hidden sm:flex">
            <Button
              name="Download code"
              color="outlineSecondary"
              size="sm"
              className="mr-2 py-2  mb-3 xl:mb-0 max-h-[57px]"
              onClick={onClickCode}
              prefixIcon={<Icon icon="qr" size={15} className="-mt-0.5" />}
            />
          </div>
          <div onClick={() => onNavigate('NEXT')}>
            <Icon className="-rotate-90 cursor-pointer" icon="ChevronDown" size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};
