import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentOptionEnum } from 'actions/business/model';
import { IAddCardRequest } from 'actions/payments.api';
import Icon from 'assets/icons';
import Tooltip from 'components/TooltipDeprecated';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { AddCardThunk, GetCardsByBusinessThunk } from 'store/business/api.thunks';
import { getCards } from 'store/business/selectors';
import { ReturnImageCard, getStepPaymentName } from 'utils';
import useUser from 'utils/hooks/useUser';

interface IStep1Payment {
  selectedPayment: PaymentOptionEnum;
}

const Step1Payment = ({ selectedPayment }: IStep1Payment) => {
  const [t] = useTranslation(['Shifts']);
  const [addingCard, setAddingCard] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const data = useSelector(getCards);

  const {
    business: { id },
  } = useUser();
  const dispatch = useAppDispatch();

  const [readyCard, setReadyCard] = useState({
    number: false,
    expirity: false,
    ccv: false,
  });

  const updateCards = useCallback(() => {
    if (id) {
      dispatch(GetCardsByBusinessThunk(id));
    }
  }, [id]);

  const onAddCard = () => {
    setAddingCard(!addingCard);
  };

  useEffect(() => {
    updateCards();
  }, [updateCards, id]);

  const onSaveCard = async () => {
    if (!elements || !stripe) {
      toast.error(`${t('again')}`);
    } else {
      const elementNumber = elements.getElement('cardNumber');
      if (stripe && elementNumber) {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elementNumber,
        });

        if (result.paymentMethod && id) {
          const requestData: IAddCardRequest = {
            businessId: id,
            paymentMethodId: result.paymentMethod?.id,
          };
          await dispatch(AddCardThunk(requestData)).unwrap();
          toast.success('Card stored');
          updateCards();
          setAddingCard(false);
        } else {
          toast.error(`${t('again')}`);
        }
      }
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="bg-withe pb-9 w-full">
          <div className="flex mb-7">
            <div className="flex items-center content-center">
              <span className="flex content-center items-center mt-5">
                {getStepPaymentName(selectedPayment)}
                <Tooltip>
                  <p className="max-w-[1400px] text-sm text-white">
                    {t('paymentShift.changePayment')}
                  </p>
                </Tooltip>
              </span>
            </div>
          </div>
          <div
            onClick={onAddCard}
            className="py-6 border my-5 rounded-xl text-center cursor-pointer bg-white"
          >
            <span>
              <Icon size={23.9} icon="add" /> <span className="pl-2">Add a new card</span>
            </span>
          </div>
          {!addingCard && (
            <>
              {data?.map((e, key) => {
                return (
                  <div
                    className="flex border mt-2 rounded-xl border-neutralGray py-6 px-4"
                    key={key}
                  >
                    <img className="max-w-[55px] h-[42.9px]" src={ReturnImageCard(e.brand)} />
                    <div className="ml-6">
                      <p className="uppercase">{e.brand}</p>
                      <p className="capitalize">
                        {e.funding} **** {e.last4}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {addingCard && (
            <div>
              <div>
                <CardNumberElement
                  onReady={() => setReadyCard({ ...readyCard, number: true })}
                  options={{
                    placeholder: t('paymentModal.card'),
                    showIcon: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        padding: '10px 20px',
                      },
                    },
                  }}
                  className="mt-4 px-3 shadow-md text-base appearance-none rounded-lg relative block w-full py-2 border border-grayBack placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                />
              </div>

              <div className="flex">
                <CardExpiryElement
                  onReady={() => setReadyCard({ ...readyCard, expirity: true })}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        padding: '10px 20px',
                      },
                    },
                  }}
                  className="w-1/2 p-5 shadow-md mt-4 mr-1 font-light appearance-none rounded-lg relative block px-3 py-2 border border-grayBack placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                />
                <CardCvcElement
                  onReady={() => setReadyCard({ ...readyCard, ccv: true })}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        padding: '10px 20px',
                      },
                    },
                  }}
                  className="w-1/2 p-5 shadow-md mt-4 text-base appearance-none rounded-lg relative block px-3 py-2 border border-grayBack placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                />
              </div>
            </div>
          )}
          {addingCard && (
            <>
              <br />
              <button
                onClick={onSaveCard}
                className="bg-primary text-white w-full rounded-full py-3"
              >
                Save card
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Step1Payment;
