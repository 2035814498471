import { useTranslation } from 'react-i18next';
import RecurrentPresenter from './Recurrent.Presenter';
import { DateTime } from 'luxon';
import { Languages } from 'utils/constants';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const RecurrentScreen = () => {
  const presenter = RecurrentPresenter();

  const [t, i18n] = useTranslation(['Shifts']);

  return (
    <div className="bg-neutral-200 py-4 px-6 mt-3 w-full">
      {presenter.loading ? (
        <div className="w-full">
          <div className="flex justify-center w-full pb-6">
            <div className=" min-w-[32%] h-24  mr-2">
              <SkeletonTheme width={'100%'} baseColor="#ABCDF4" highlightColor="#8BBAEF">
                <Skeleton height={'10vh'} style={{ margin: 'auto', marginRight: '2px' }} />
              </SkeletonTheme>
            </div>

            <div className=" min-w-[32%] h-24 mr-2">
              <SkeletonTheme width={'100%'} baseColor="#ABCDF4" highlightColor="#8BBAEF">
                <Skeleton height={'10vh'} style={{ margin: 'auto', marginRight: '2px' }} />
              </SkeletonTheme>
            </div>

            <div className=" min-w-[32%] h-24">
              <SkeletonTheme width={'100%'} baseColor="#ABCDF4" highlightColor="#8BBAEF">
                <Skeleton height={'10vh'} style={{ margin: 'auto', marginRight: '2px' }} />
              </SkeletonTheme>
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="text-secondary-500 font-semibold text-base pb-4">{presenter.titleShifts}</p>
          <div className="rounded flex flex-wrap justify-between">
            {presenter.draft?.resultDraftData.shifts.map((shift, key) => {
              const date = DateTime.fromJSDate(shift.startDate).setLocale(
                i18n.language === Languages.EN ? 'en-US' : 'es'
              );
              return (
                <div
                  key={key}
                  className=" bg-secondary-200 rounded bg-opacity-40 py-2 px-4 mr-0.5 xs:w-[49%] w-full md:w-[32%] mb-3"
                >
                  <div className="flex justify-between">
                    <p className=" text-primary text-sm">{date.toFormat('cccc')} </p>
                    {(presenter.draft?.resultDraftData.shifts.length ?? 0) > 1 && (
                      <p
                        onClick={() => presenter.onRemoveShift(shift.id)}
                        className="text-sm cursor-pointer"
                      >
                        X
                      </p>
                    )}
                  </div>
                  <p className="text-secondary-500 text-lg p-0 leading-tight">
                    {date.toFormat('LLLL')} {date.toFormat('dd')}
                  </p>
                  <div className="flex w-full justify-between">
                    <p className=" text-neutral-1400 text-base">{t('table.opening')}: </p>
                    <div className="flex w-[90%] justify-end items-center">
                      <button
                        onClick={() => presenter.onChangeOpenings(shift.id, false)}
                        className="bg-neutral-400 leading-none text-neutral-1400 w-5 h-5 "
                      >
                        -
                      </button>
                      <p className="bg-white w-1/2 leading-none h-5 text-center text-xs items-center flex justify-center text-neutral-1400">
                        {shift.openings}
                      </p>
                      <button
                        onClick={() => presenter.onChangeOpenings(shift.id, true)}
                        className="bg-neutral-400 leading-none text h-5 text-neutral-1400 w-5"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default RecurrentScreen;
