import Icon from 'assets/icons';
import ModalV2 from 'components/ModalV2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Help = () => {
  const [t] = useTranslation(['Timesheet']);
  const [isModalActive, setIsModalActive] = useState(false);

  const onClickHelp = () => {
    setIsModalActive(true);
  };

  return (
    <>
      <div className="text-center ml-3 cursor-pointer" onClick={onClickHelp}>
        <Icon icon="helpbook" size={20} />
        <p className="text-[10px]">{t('help')}</p>
      </div>

      <ModalV2 className="max-w-xl rounded-xl" isOpen={isModalActive} setIsOpen={setIsModalActive}>
        <h2 className="text-xl text-left font-semibold text-primary mt-5">How it works</h2>
        <p className="mt-4">{t('helpDescription')}</p>

        <p className="mt-4">
          {t('helpOperation')} <span className="text-blue-500">{t('operation')}.</span>
          <span className="ml-1">{t('anyShift')}</span>{' '}
          <span className="text-blue-500">{t('time')}</span> <span>{t('dispute')}</span>
        </p>

        <p className="mt-4 mb-5">{t('submitDispute')}</p>
      </ModalV2>
    </>
  );
};

export default Help;
