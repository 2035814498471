import StarRating from 'components/StarsRating';
import { DateTime } from 'luxon';
import { CreateIndividualRateSet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/CreateIndividualRateSet';
import {
  ListShiftResultGet,
  TimeSheetStatus,
} from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/ListShift.entity';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import EditTips from './EditTip';
import { Dispatch, SetStateAction } from 'react';
import DotMenu from './DotMenu';
import {
  BlockUserSet,
  CreatePreferredUserSet,
} from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/User.entity';
import Icon from 'assets/icons';

type TableTimeSheetProps = {
  setPage: (e: number) => void;
  data?: ListShiftResultGet;
  selectedDate: {
    startDate: string;
    endDate: string;
    allowChanges: boolean;
  };
  createIndividualRate: (d: Omit<CreateIndividualRateSet, 'businessId'>) => void;
  setTipValue: Dispatch<SetStateAction<string | undefined>>;
  tipValue?: string;
  updateTip: (tip: string) => Promise<void>;
  selectedExecutions: string[];
  onClickSelectAllExecutions: (selected: boolean) => void;
  onClickExecution: (selected: boolean, executionId: string) => void;
  onApprove: (v: string) => void;
  onPreferredUser: (d: CreatePreferredUserSet) => Promise<void>;
  onBlockUser: (d: BlockUserSet) => Promise<void>;
  refetch: () => void;
};

const TableTimeSheet = ({
  setPage,
  data,
  selectedDate,
  createIndividualRate,
  setTipValue,
  tipValue,
  updateTip,
  onClickSelectAllExecutions,
  selectedExecutions,
  onClickExecution,
  onApprove,
  onPreferredUser,
  onBlockUser,
  refetch,
}: TableTimeSheetProps) => {
  const [t] = useTranslation(['Timesheet']);

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected);
  };

  const handleRateClick = (rate: number, userId: string) => {
    createIndividualRate({
      ratedDate: selectedDate.startDate.toString(),
      rating: rate,
      userId,
    });
  };

  const handleEditTips = (id: string) => updateTip(id);

  return (
    <>
      <table className="mt-5 w-full">
        <tr className="text-secondary-500 w-full">
          <th className="p-2">
            <input
              type="checkbox"
              value="second_checkbox"
              onChange={(e) => onClickSelectAllExecutions(e.target.checked)}
            />
          </th>
          <th className="text-left">{t('table.shiftName')}</th>
          <th className="text-left">{t('table.name')}</th>
          <th className="text-left">{t('table.clockIn')}</th>
          <th className="text-left">{t('table.clockOut')}</th>
          <th className="text-left">{t('table.startBreak')}</th>
          <th className="text-left">{t('table.endBreak')}</th>
          <th className="text-left">{t('table.total')}</th>
          <th className="text-left">{t('table.tips')}</th>
          <th className="text-left">{t('table.rating')}</th>
          <th className="text-left">{t('table.status')}</th>
        </tr>

        <tbody>
          {data?.resultListShift.timesheetItems.map((e, key) => (
            <tr
              className={`border-b-[1px] border-black ${
                key % 2 === 0 ? 'bg-neutral-200' : 'bg-white'
              }`}
              key={key}
            >
              <td className="p-2 text-center">
                <input
                  type="checkbox"
                  value="second_checkbox"
                  onChange={(event) => onClickExecution(event.target.checked, e.executionId)}
                  checked={selectedExecutions.findIndex((ex) => ex === e.executionId) !== -1}
                />
              </td>
              <td>{e.shiftName}</td>
              <td>{e.userName}</td>
              <td>
                {e.clockIn
                  ? DateTime.fromISO(e.clockIn).toLocaleString(DateTime.TIME_SIMPLE)
                  : '----'}
              </td>
              <td>
                {e.clockOut
                  ? DateTime.fromISO(e.clockOut).toLocaleString(DateTime.TIME_SIMPLE)
                  : '----'}
              </td>
              <td>
                {e.breakStart
                  ? DateTime.fromISO(e.breakStart).toLocaleString(DateTime.TIME_SIMPLE)
                  : '----'}
              </td>
              <td>
                {e.breakEnd
                  ? DateTime.fromISO(e.breakEnd).toLocaleString(DateTime.TIME_SIMPLE)
                  : '----'}
              </td>
              <td>{e.totalHours?.toFixed(2)}h</td>
              <td>
                <EditTips
                  shiftId={e.shiftId}
                  setTipValue={setTipValue}
                  tipValue={tipValue}
                  handleEditTips={() => handleEditTips(e.executionId)}
                >
                  <>
                    {e.tips ? (
                      <span>${`${e.tips ?? 0}`}</span>
                    ) : (
                      <span>
                        <Icon size={15} icon={'edit'} />
                      </span>
                    )}{' '}
                  </>
                </EditTips>
              </td>
              <td>
                <StarRating
                  size={12}
                  defaultRating={e.rating}
                  onChangeRating={(r) => handleRateClick(r, e.userId)}
                  disabled={e.rating !== 0 || !selectedDate.allowChanges}
                />
              </td>
              <td>
                {e.timesheetStatus === TimeSheetStatus.approved && (
                  <p className="text-grayText text-xs">
                    {t(`sheetTip.${TimeSheetStatus[e.timesheetStatus]}`)}
                  </p>
                )}

                {e.timesheetStatus === TimeSheetStatus.pending && (
                  <p
                    onClick={selectedDate.allowChanges ? () => onApprove(e.executionId) : undefined}
                    className={`text-blue-500 ${
                      selectedDate.allowChanges ? 'text-blue-500' : 'text-grayText'
                    } cursor-pointer text-xs`}
                  >
                    {t(`sheetTip.approve`)}
                  </p>
                )}

                {e.timesheetStatus === TimeSheetStatus.disputed && (
                  <p className="text-red text-xs">
                    {t(`sheetTip.${TimeSheetStatus[e.timesheetStatus]}`)}
                  </p>
                )}
              </td>
              <td>
                <DotMenu
                  isActive={selectedDate.allowChanges}
                  id={e.userId}
                  name=""
                  onBlockUser={onBlockUser}
                  onPreferredUser={onPreferredUser}
                  executionId={e.executionId}
                  refetch={refetch}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={15}
        pageCount={Math.ceil((data?.resultListShift.totalItems ?? 0) / 15)}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={'flex justify-end items-center my-5'}
        pageLinkClassName={'text-base text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        previousLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        nextLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        activeLinkClassName={'text-white bg-secondary-500'}
      />
    </>
  );
};

export default TableTimeSheet;
