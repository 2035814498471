import toast from 'react-hot-toast';
import { getErrors } from 'utils';
import { InferType, array, bool, number, object, string } from 'yup';

export const InvoiceDescriptionSchema = object({
  invoiceId: string().required(),
  consecutive: number().required(),
  paymentOption: number().required(),
  invoiceDate: string().required(),
  businessName: string().required(),
  startDate: string().required(),
  endDate: string().required(),
  positionTitle: string().required().nullable(),
  totalPros: number().required(),
  totalHours: number().required(),
  serviceFee: number().required(),
  tips: number().required(),
  totalAmount: number().required(),
  isMarkdown: bool().required(),
  details: array(
    object({
      day: string().required().nullable(),
      applicants: array(
        object({
          name: string().required(),
          positionTitle: string().required().nullable(),
          isTotalCompensation: bool().required(),
          salaryTotal: number().required(),
          salaryHour: number().required(),
          clockIn: string().required().nullable(),
          clockOut: string().required().nullable(),
          breakTime: number().required(),
          laborCost: number().required(),
        }).required()
      ).required(),
    })
  ).required(),
}).required();

export type InvoiceDescriptionDto = InferType<typeof InvoiceDescriptionSchema>;

export const dtoToInvoiceDescription = async (d: object) => {
  try {
    const validatedDto = await InvoiceDescriptionSchema.validate(d);
    return validatedDto;
  } catch (e) {
    getErrors(e);
    toast.error('Please contact support');
  }
};
