import { IInviteUserRequest } from 'actions/auth.api';
import Input from 'components/Input';
import SelectV2 from 'components/SelectV2';
import { ProfileContext } from 'pages/profile/provider';
import { useContext, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { InviteBusinessUserThunk } from 'store/business/api.thunks';
import { getBusiness, getCognitoUser } from 'store/business/selectors';
import { emailRegex, Role } from 'utils/constants';

interface IInvite {
  email: string;
  permision: IItems;
}

const Invite = (): JSX.Element => {
  const [t] = useTranslation(['Settings']);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const businessUser = useSelector(getCognitoUser);
  const { setAddBusinessUser } = useContext(ProfileContext);
  const business = useSelector(getBusiness);

  const roles: IItems[] = Role.map((value, key) => {
    return { id: key.toString(), text: value.toString() };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IInvite>();

  const onSubmit: SubmitHandler<IInvite> = async (data) => {
    try {
      setLoading(false);
      if (business && businessUser) {
        const requestData: IInviteUserRequest = {
          businessId: businessUser.businessId,
          email: data.email,
          role: parseInt(data.permision.id),
          name: business.name,
          userBusiness: businessUser.name,
        };
        await dispatch(InviteBusinessUserThunk(requestData)).unwrap();
        toast.success(t('success'));
        reset();
      }
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setAddBusinessUser(false);
  };

  return (
    <>
      <div className="w-full">
        <div className="text-primary px-5 ">
          <div className=" py-6">
            <h3 className="text-2xl font-bold">{t('addCollaborator')}</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                name={'email'}
                placeholder={'Email'}
                register={register}
                type="text"
                errors={errors.email}
                validation={{
                  required: true,
                  pattern: emailRegex,
                }}
              />

              <div className="w-full">
                <div className="w-full">
                  <Controller
                    control={control}
                    defaultValue={{ id: '-1', text: `${t('type')}` }}
                    name="permision"
                    rules={{
                      required: true,
                      validate: (value: IItems) => value.id !== '-1' || `${t('choose')}`,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div className="mt-3 font-regular text-sm sm:text-lg appearance-none rounded-2xl border border-gray-300 bg-white relative block w-full px-3 py-1 placeholder-primary text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10">
                        <SelectV2
                          items={roles}
                          itemSelected={value}
                          name="Roles"
                          setSelectedItem={(type) => {
                            onChange(type);
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
                <button
                  disabled={loading}
                  className="w-full py-2 bg-primary text-white text-sm sm:text-lg px-3 rounded-full mt-10"
                >
                  {t('send')}
                </button>
                <button
                  onClick={onCancel}
                  disabled={loading}
                  className="w-full py-2 border-primary border text-primary text-sm sm:text-lg px-3 rounded-full mt-4"
                >
                  {t('Cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invite;
