import { useTranslation } from 'react-i18next';
import { ShiftDescriptionContext } from '../ShiftDescription.Screen';
import { DateTime } from 'luxon';
import { TIME_ZONE } from 'utils/constants/days';
import Skeleton from 'react-loading-skeleton';
import { cn } from 'utils';

const ShiftDescriptionInfo = () => {
  const [t] = useTranslation(['Shifts']);
  return (
    <ShiftDescriptionContext.Consumer>
      {({ queryDetailShift: { data } }) => {
        const timezone =
          TIME_ZONE(t).find((timezone) => timezone.id === data?.description.timeZone) ??
          TIME_ZONE(t)[0];

        return (
          <div className="mx-9 flex flex-wrap">
            <div className="w-full md:w-[60%]">
              <p className="font-semibold text-xl">{t('description.date')}</p>

              <div className="flex flex-wrap md:flex-nowrap mt-3">
                <div className="bg-neutral-200 border w-full xs:w-[48%] mb-2 md:mb-0 border-neutral-400 mr-2 pr-12 pl-2 py-2 rounded">
                  <p className="text-secondary-500 text-xs">{t('description.dateLocal')}:</p>
                  <p className={cn(data?.description.cancelledWithin24Hours ? 'line-through' : '')}>
                    {DateTime.fromISO(data?.description.startDate ?? '')
                      .setZone(timezone.key)
                      .toFormat('DD')}
                  </p>
                </div>

                <div className="bg-neutral-200 border w-full xs:w-[48%] mb-2 md:mb-0 border-neutral-400 mr-2 pr-12 pl-2 py-2 rounded">
                  <p className="text-secondary-500 text-xs">{t('description.startTime')}:</p>
                  <p className={cn(data?.description.cancelledWithin24Hours ? 'line-through' : '')}>
                    {!data?.description.startDate ? (
                      <Skeleton />
                    ) : (
                      DateTime.fromISO(data.description.startDate)
                        .setZone(timezone.key)
                        .toFormat('hh:mm a')
                    )}
                  </p>
                </div>

                <div className="bg-neutral-200 border w-full xs:w-[48%] mb-2 md:mb-0 border-neutral-400 mr-2 pr-12 pl-2 py-2 rounded">
                  <p className="text-secondary-500 text-xs">{t('description.endTime')}:</p>
                  <p className={cn(data?.description.cancelledWithin24Hours ? 'line-through' : '')}>
                    {!data?.description.endDate ? (
                      <Skeleton />
                    ) : (
                      DateTime.fromISO(data.description.endDate)
                        .setZone(timezone.key)
                        .toFormat('hh:mm a')
                    )}
                  </p>
                </div>

                <div className="bg-neutral-200 border w-full xs:w-[48%] mb-2 md:mb-0 border-neutral-400 mr-2 pr-12 pl-2 py-2 rounded">
                  <p className="text-secondary-500 text-xs">{t('description.openings')}:</p>
                  <p className={cn(data?.description.cancelledWithin24Hours ? 'line-through' : '')}>
                    {data?.description.openings ?? <Skeleton />}
                  </p>
                </div>
              </div>

              <div>
                <p className="font-semibold text-xl mt-8">{t('description.address')}</p>
                <p className="text-base font-normal text-secondary-500">
                  {data?.description.location ?? <Skeleton className="max-w-[200px]" />}
                </p>
              </div>

              <div>
                <p className="font-semibold text-xl mt-8">{t('description.locationInfo')}</p>
                <p className="text-base font-normal text-secondary-500">
                  {data?.description.locationInfo}
                </p>

                <ul className="mt-3 list-disc	ml-4">
                  {data?.description.parkingInfo && (
                    <li>
                      <span className="text-base font-normal">
                        {t('description.parkingInfo')}: {data?.description.parkingInfo}
                      </span>
                    </li>
                  )}

                  {data?.description.locationInfo && (
                    <li>
                      <span className="text-base font-normal">
                        {data?.description.locationInfo}
                      </span>
                    </li>
                  )}
                </ul>
              </div>

              {data?.description.clockInInfo && (
                <div>
                  <p className="font-semibold text-xl mt-8">{t('description.clockInfo')}</p>

                  <ul className="mt-3 list-disc	ml-4">
                    <li>{data?.description.clockInInfo}</li>
                  </ul>
                </div>
              )}

              <div>
                <p className="font-semibold text-xl mt-8">{t('description.contact')}</p>
                {!data?.description.contacts && (
                  <div>
                    <Skeleton className="max-w-[200px] h-16" />
                  </div>
                )}
                <div className="flex mt-3 flex-wrap">
                  {data?.description.contacts.map((e, key) => (
                    <div key={key}>
                      <div className="bg-secondary-300/50 px-3 py-2 mr-2 rounded">
                        <p className="text-secondary-500 font-medium">{e.name}</p>
                        <p className="text-primary">{e.role}</p>
                        <p className="text-primary">{e.phoneNumber}</p>
                        <p className="text-primary">{e.email}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="bg-secondary-200/50 p-6 md:w-[40%] w-full rounded-xl font-normal md:mt-0 mt-4">
              <p className="font-semibold text-xl">{t('description.skills')}</p>

              <ul className="list-disc ml-4 mt-3">
                <li>
                  <span className="text-secondary-500">{t('description.abilities')}</span>
                  <span className="ml-1">{data?.description.abilities.map((e) => e + ', ')}</span>
                </li>

                <li>
                  <span className="text-secondary-500">{t('description.experience')}</span>
                  <span className="ml-1">{data?.description.experience}</span>
                </li>
              </ul>

              {data?.description.jobInformation && (
                <>
                  <p className="font-semibold text-xl mt-4">{t('description.roleInfo')}</p>

                  <ul className="list-disc ml-4 mt-3">
                    <li>{data?.description.jobInformation}</li>
                  </ul>
                </>
              )}

              <p className="font-semibold text-xl mt-4">{t('description.dressCode')}</p>

              <div>
                <ul className="mt-3 list-disc	ml-4">
                  <li>
                    <span className="text-secondary-500">{t('description.uniform')}</span>:{' '}
                    {data?.description.dressCode}
                  </li>
                  <li>
                    <span className="text-secondary-500">{t('description.attire')}</span>:{' '}
                    {data?.description.uniform}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      }}
    </ShiftDescriptionContext.Consumer>
  );
};
export default ShiftDescriptionInfo;
