import { useQuery } from '@tanstack/react-query';
import { IProfileContext } from 'actions/business/model';
import { GetOnboardData } from 'actions/catalog/catalog.api';
import React, { createContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { GetManageBusinessUsersThunk } from 'store/business/api.thunks';
import useUser from 'utils/hooks/useUser';

export const ProfileContext = createContext({} as IProfileContext);

interface IProfileProvider {
  children: React.ReactNode;
}

const ProfileProvider = ({ children }: IProfileProvider) => {
  const [addBusinessUser, setAddBusinessUser] = useState(false);
  const dispatch = useAppDispatch();
  const {
    business: { id },
  } = useUser();

  const { data: onboardData } = useQuery({
    queryFn: GetOnboardData,
  });

  useEffect(() => {
    if (id) {
      dispatch(GetManageBusinessUsersThunk(id));
    }
  }, [id]);

  return (
    <ProfileContext.Provider
      value={{
        data: onboardData,
        addBusinessUser,
        setAddBusinessUser,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
