import { useMutation } from '@tanstack/react-query';
import { CloseSession } from 'actions/auth.api';
import BusinessService from 'actions/business';
import Input from 'components/InputPost';
import Select from 'components/SelectPost';
import { SettingsContext } from 'pages/settings/context';
import { useContext } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/hooks/useUser';

interface IDelete {
  leavingReason?: string;
  opinion: IItems;
}

const Invite = (): JSX.Element => {
  const [t] = useTranslation(['Settings']);
  const { dataReasons } = useContext(SettingsContext);
  const { mutateAsync } = useMutation(BusinessService.deleteBusiness);
  const {
    business: { id },
  } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IDelete>({
    defaultValues: {
      opinion: {
        id: '0',
        text: 'Select',
      },
    },
  });

  const onSubmit: SubmitHandler<IDelete> = async (data) => {
    if (data.opinion.id !== '0') {
      mutateAsync({
        opinion: data.leavingReason,
        leavingReason: data.opinion.id,
        businessId: id,
      }).then(() => {
        CloseSession();
      });
    }
  };

  return (
    <>
      <div className=" w-[400px] max-h-[500px]">
        <div className="text-primary px-5 ">
          <div className=" py-2">
            <h3 className="text-2xl text-red font-bold">{t('delete')}</h3>
            <p className="text-sm font-thin text-red mt-3">{t('deleteAction')}</p>
            <p className="mt-4 text-sm">{t('why')}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="opinion"
                rules={{
                  required: true,
                  validate: (value: IItems) => value.id !== '0' || `${t('errorSelect')}`,
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      customStyle="mt-2 font-medium text-sm sm:text-base appearance-none rounded-3xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                      items={dataReasons ?? []}
                      itemSelected={value}
                      setSelectedItem={(type) => {
                        onChange(type);
                      }}
                      errors={errors.opinion}
                    ></Select>
                  </>
                )}
              />

              <p className="mt-4 text-sm">{t('feedback')}</p>
              <Input
                rows={5}
                customStyle="mt-2 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                name="leavingReason"
                placeholder={''}
                register={register}
                type="textarea"
                errors={errors.leavingReason}
                validation={{
                  required: false,
                }}
              />
              <div className="flex justify-center mt-4">
                <button type="submit" className="bg-red text-white w-full rounded-3xl py-2 text-sm">
                  {t('delete')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invite;
