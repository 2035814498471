import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { forwardRef, useRef } from 'react';
import Icon from 'assets/icons';
import { es, enUS } from 'date-fns/locale';
import i18n from 'i18next';

import 'react-datepicker/dist/react-datepicker.css';
import { cn } from 'utils';
export interface CustomDatePickerProps extends ReactDatePickerProps {
  textInput: string;
  errors?: FieldError;
  message?: string;
  bgWhite?: boolean;
  customStyle?: string;
  hideArrow?: boolean;
}

const DatePickerRef = forwardRef<HTMLButtonElement, { hideArrow?: boolean }>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref) => (
    <div className="w-full flex justify-between">
      <button
        className="w-full flex justify-between"
        ref={ref}
        onClick={props.onClick}
        type={'button'}
      >
        {props.value ? props.value : <span className="w-full text-left">{props.id}</span>}
        {!props.hideArrow && (
          <Icon icon="arrow-down" size={20} color="black" style={{ marginLeft: 8, marginTop: 2 }} />
        )}
      </button>
    </div>
  )
);
DatePickerRef.displayName = 'DatePickerRef';

const CustomDatePicker = (props: CustomDatePickerProps): JSX.Element => {
  const [t] = useTranslation(['Others']);

  const refCustomInput = useRef();

  return (
    <div>
      <div
        className={cn(
          `mt-4 flex text-left ${
            props ? ' bg-white border-2 border-grayBack ' : 'bg-grayBack border-0 '
          } font-medium placeholder-primary text-neutralBlue text-sm sm:text-base items-center appearance-none rounded-md relative w-full px-3 py-2 focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10`,
          props.customStyle
        )}
      >
        <DatePicker
          locale={i18n.language == 'es' ? es : enUS}
          id={props.textInput}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={refCustomInput as any}
          {...props}
          customInput={<DatePickerRef hideArrow={props.hideArrow} />}
        />
      </div>
      <small className="text-warnings-400 text-left mt-1">
        {props.errors && (props.message ? props.message : t('invalid'))}
      </small>
    </div>
  );
};

export default CustomDatePicker;
