import { ApproveExecutionsSet } from '../../Domain/Entities/ApproveExecutionsSet';
import { CreateIndividualRateSet } from '../../Domain/Entities/CreateIndividualRateSet';
import { UpdateTipSet } from '../../Domain/Entities/UpdateTipSet';
import { TimeSheetRepository } from '../../Domain/Repository/Timesheet.repository';

export class UpdateShiftUseCase {
  private timeSheetRepository = new TimeSheetRepository();

  public async invokeCreateRate(data: CreateIndividualRateSet) {
    return this.timeSheetRepository.createIndividualRate(data);
  }

  public async invokeUpdateTip(data: UpdateTipSet) {
    return this.timeSheetRepository.updateTip(data);
  }

  public async invokeCreateApproved(data: ApproveExecutionsSet) {
    return this.timeSheetRepository.approveExecutions(data);
  }
}
