import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { TIME_ZONE } from 'utils/constants/days';

export const formatWithTimezone = (
  t: TFunction,
  format: string,
  date?: string,
  timezoneId?: string
) => {
  const timezone = TIME_ZONE(t).find((timezone) => timezone.id === timezoneId) ?? undefined;

  const formattedDate = DateTime.fromISO(date ?? '')
    .setZone(timezone?.key)
    .toFormat(format);

  return formattedDate;
};
