import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileCard from '../ProfileCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProfileInformation from '../ProfileInformation';
import { useGetPros, useMutationPros } from 'pages/professionals/helper';
import useWindowDimensions from 'utils/hooks/window';
import { Menu } from '@headlessui/react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Blocked = (): JSX.Element => {
  const [t] = useTranslation('ApplicantsProfile');
  const [openProfileModal, setOpenProfileModal] = React.useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null);
  const { height } = useWindowDimensions();

  const openProfileModalHandler = (userId: string) => {
    setOpenProfileModal(true);
    setSelectedUserId(userId);
  };

  const { generalList, onEndReached, items, refreshList } = useGetPros('blocked');
  const { removeBlockUser } = useMutationPros();

  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Professionals',
      href: '/blocked',
    });
  }, []);

  return (
    <div>
      <hr className="mt-5 w-full border-[1px] rounded-full  border-grayText" />
      <div className="mt-2">
        {items.length > 0 ? (
          <InfiniteScroll
            dataLength={items.length}
            next={onEndReached}
            hasMore={items.length < generalList.totalItems}
            className={'pb-4'}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollable"
            height={height * 0.7}
          >
            {items.map((jobseeker) => (
              <ProfileCard
                key={jobseeker.userId}
                name={jobseeker.name}
                totalShifts={jobseeker.totalShifts}
                userImage={jobseeker.userImage}
                userId={jobseeker.userId}
                rating={jobseeker.rating}
                ratesNumber={jobseeker.ratesNumber}
                preferredUserId={jobseeker.preferredUserId}
                openProfile={() => openProfileModalHandler(jobseeker.userId)}
                onRefresh={refreshList}
                menuItems={
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-primary text-white' : 'text-primary'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => removeBlockUser(jobseeker.blockedUserId)}
                        >
                          {t('actions.unblock')}
                        </button>
                      )}
                    </Menu.Item>
                  </>
                }
              />
            ))}
          </InfiniteScroll>
        ) : (
          <div className="flex justify-center items-center h-96">
            <h3 className="text-2xl font-bold text-gray-500">{t('emptyGeneralList')}</h3>
          </div>
        )}
      </div>
      <ProfileInformation
        isOpen={openProfileModal}
        closeModal={() => {
          setOpenProfileModal(false);
          setSelectedUserId(null);
        }}
        userId={selectedUserId}
      />
    </div>
  );
};

export default Blocked;
