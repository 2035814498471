export default {
  en: {
    welcome: 'Welcome back',
    title: 'Welcome to Chamba',
    help: 'Get access to our On-Call Pros 24/7/365.',
    email: 'Email',
    password: 'Password',
    forgot: 'Forgot my password ',
    click: 'Click here',
    login: 'Log in',
    textRegister: `Don't have an account yet?`,
    register: 'Sign up here',
    or: 'Or continue with:',
    notFound: 'Account not found',
    chambaFills: 'Chamba fills the requested shifts within 2 hours',
    continueWithG: 'Continue with Google',
    continueWithA: 'Continue with Apple',
  },
  es: {
    welcome: 'Bienvenido de vuelta',
    title: 'Bienvenido a Chamba',
    help: 'Get access to our On-Call Pros 24/7/365.',
    email: 'Correo electrónico',
    password: 'Contraseña',
    forgot: 'Olvidé mi contraseña ',
    click: 'Click aquí',
    login: 'Iniciar sesión',
    textRegister: '¿Aun no tienes cuenta? ',
    register: 'Registrate aquí',
    or: 'O ingresa con:',
    notFound: 'Cuenta no encontrada',
    chambaFills: 'Chamba cubre los turnos solicitados en 2 horas',
    continueWithG: 'Ingresa con Google',
    continueWithA: 'Ingresa con Apple',
  },
};
