import { ContactFormSchemaType } from '../../Modules/Form/Domain/Dtos/ContactForm.dto';
import { createContext, useEffect, useState } from 'react';
import { RoleSchemaType } from '../../Modules/Form/Domain/Dtos/Role.dto';
import { LocationSchemaType } from '../../Modules/Form/Domain/Dtos/Location';
import { AttireFormSchemaType } from '../../Modules/Form/Domain/Dtos/Attire.dto';

type ModeForm = 'draft' | 'initial' | 'edit' | 'template';
export interface DataFormType {
  shiftInfo: {
    contacts: ContactFormSchemaType[];
    role: RoleSchemaType;
    location: LocationSchemaType;
    startDate?: Date | Date[];
    endDate?: Date | Date[];
    recurrentStart?: Date;
    recurrentEnd?: Date;
    recurrentDays?: number[];
    startTime: Date;
    recurrent?: boolean;
    endTime: Date;
    timeZone: {
      id: string;
      text: string;
    };
  };
  workers: {
    openings: number;
    breakTime: number;
    selectedAssign: boolean;
    fullTimeOportunity: boolean;
  };
  attire: AttireFormSchemaType;
}

export interface DataFormContextType {
  dataForm: Partial<DataFormType>;
  setDataForm: (d: Partial<DataFormType>) => void;
  mode: ModeForm;
  setMode: (d: ModeForm) => void;
  templateCreated?: boolean;
  setTemplateCreated?: (d: boolean) => void;
}

export const DataFormContext = createContext({} as DataFormContextType);
DataFormContext.displayName = 'DataFormContext';

const DataFormProvider = ({ children }: { children: JSX.Element }) => {
  const [data, setData] = useState<Partial<DataFormType>>({
    shiftInfo: {
      recurrent: false,
    },
  } as DataFormType);
  const [mode, setMode] = useState<ModeForm>('initial');
  const [templateCreated, setTemplateCreated] = useState(false);

  useEffect(() => {
    return () => {
      setData({
        shiftInfo: {
          recurrent: false,
        },
      } as DataFormType);
    };
  }, []);

  return (
    <DataFormContext.Provider
      value={{
        dataForm: data,
        setDataForm: setData,
        mode: mode,
        setMode: setMode,
        setTemplateCreated,
        templateCreated,
      }}
    >
      {children}
    </DataFormContext.Provider>
  );
};

export default DataFormProvider;
