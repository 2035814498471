import ModalV2 from 'components/ModalV2';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { ITEMS_PER_PAGE, Languages } from 'utils/constants';
import useUser from 'utils/hooks/useUser';
import useWindowDimensions from 'utils/hooks/window';
import ShiftCalendarPresenter from './ShiftsCalendar.Presenter';
import ShiftsCalendarAction from './ShiftsCalendar.Action';
import { ShiftCalendarPaginatedGet } from 'pages/shift/core/Modules/Shifts/Domain/Entities/ShiftCalendarPaginated.entity';

interface ModalMoreEventsType {
  dateSelected: Date | undefined;
  setDateSelected: (v: Date | undefined) => void;
  onSelectEvent: (id: string) => void;
}

const ModalMoreEvents = ({ dateSelected, setDateSelected }: ModalMoreEventsType) => {
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const action = ShiftsCalendarAction();

  const presenter = ShiftCalendarPresenter();
  const {
    business: { id },
  } = useUser();

  const [page, setPage] = useState(0);
  const [shiftsResult, setShiftsResult] = useState<ShiftCalendarPaginatedGet>({
    list: {
      data: [],
      nextPage: false,
      metadata: null,
    },
  });
  const [items, setItems] = useState<ShiftCalendarPaginatedGet['list']['data']>([]);

  const fetchShifts = useCallback(() => {
    if (dateSelected) {
      action
        .executeGetShiftPaginated({
          businessId: id,
          endDate: DateTime.fromJSDate(dateSelected)
            .set({ hour: 23, minute: 59, second: 59 })
            .toISO(),
          startDate: DateTime.fromJSDate(dateSelected)
            .set({ hour: 0, minute: 0, second: 0 })
            .toISO(),
          page,
          size: ITEMS_PER_PAGE,
        })
        .then((e) => {
          setLoading(false);
          if (e) {
            setShiftsResult(e);
            setItems([...items, ...e.list.data]);
          }
        });
    }
  }, [page, id, dateSelected]);

  const onEndReached = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchShifts();
  }, [fetchShifts]);

  useEffect(() => {
    return () => {
      setItems([]);
      setPage(0);
    };
  }, []);

  const setIsOpen = () => {
    setDateSelected(undefined);
  };

  return (
    <ModalV2 isOpen={dateSelected !== undefined} setIsOpen={setIsOpen}>
      <div id="scrollable">
        {loading && (
          <>
            <Skeleton height={20} width={300} />
          </>
        )}
        <InfiniteScroll
          dataLength={items.length}
          next={onEndReached}
          hasMore={
            shiftsResult.list.metadata ? items.length < shiftsResult.list.metadata.total : false
          }
          className={'pb-4'}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollable"
          height={height * 0.4}
        >
          {items &&
            items.map((e, key) => (
              <div
                className="text-primary py-3 mt-2 px-6 flex rounded-md border justify-between"
                key={key}
                onClick={() => {
                  presenter.onClickEvent;
                }}
              >
                <div className="mr-4 w-1/3">
                  {e.positionTitle !== null
                    ? i18next.language === Languages.EN
                      ? e.positionTitle.english
                      : e.positionTitle.spanish
                    : 'Shift'}
                </div>
                <div>
                  {DateTime.fromISO(e.startDate).toLocaleString(DateTime.TIME_SIMPLE)}
                  {' - ' + DateTime.fromISO(e.endDate).toLocaleString(DateTime.TIME_SIMPLE)}
                </div>
                <div className="ml-7">
                  <button onClick={() => presenter.onClickEventById(e.id)}>View details</button>
                </div>
              </div>
            ))}
        </InfiniteScroll>
      </div>
    </ModalV2>
  );
};

export default ModalMoreEvents;
