import { Table, TableBody, TablePaginate } from 'components/Table';
import IndexPresenterHire from './Index.Presenter';
import ProfileCard from 'pages/hire/shared/ProfileCard';
import { ITEMS_PER_PAGE } from 'utils/constants';
import SelectV2 from 'components/SelectV2';
import { useTranslation } from 'react-i18next';

const IndexScreenHire = () => {
  const presenter = IndexPresenterHire();
  const [t] = useTranslation(['ApplicantsProfile']);
  const [hireTranslation] = useTranslation(['Hire']);
  const state = [
    { id: '0', text: t('pending') },
    { id: '1', text: t('hired') },
    { id: '2', text: t('rejected') },
  ];

  return (
    <div className="flex flex-col w-full h-screen bg-gray-400 py-12 px-8">
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-2xl">{hireTranslation('title')}</h2>
        <div className="mt-3 max-w-48">
          <SelectV2
            items={state}
            className="  m-0 rounded-xl text-base bg-white border-neutral-400 px-3 py-1 border mb-3 mt-2"
            itemSelected={presenter.selectedState}
            name={t('selectStatus')}
            showDropDownArrow
            setSelectedItem={presenter.setSelectedState}
          />
        </div>
      </div>

      <Table>
        <TableBody>
          <>
            <div className="flex flex-wrap justify-between min-h-[800px]">
              {presenter.query.data?.list.businessHires.map((hire) => (
                <ProfileCard
                  className="sm:w-[48%] max-h-[134px]"
                  status={hire.status}
                  key={hire.hireId}
                  menuItems={<></>}
                  name={hire.userName}
                  onRefresh={presenter.query.refetch}
                  ratesNumber={hire.ratesNumber}
                  rating={hire.rating}
                  userId={hire.userId}
                  userImage={hire.userImage}
                  preferredUserId=""
                  statusDate={hire.statusDate}
                  hireId={hire.hireId}
                />
              ))}
            </div>
          </>
        </TableBody>
      </Table>

      <TablePaginate
        pageCount={Math.ceil((presenter.query.data?.list.totalItems ?? 0) / ITEMS_PER_PAGE)}
        pageRangeDisplayed={ITEMS_PER_PAGE}
        onPageChange={presenter.handlePageClick}
      />
    </div>
  );
};

export default IndexScreenHire;
