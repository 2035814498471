import toast from 'react-hot-toast';
import { InferType, ValidationError, array, boolean, number, object, string } from 'yup';
import { ShiftCalendarPaginatedGet } from '../Entities/ShiftCalendarPaginated.entity';

export const CalendarShiftsPaginatedSchema = object({
  metadata: object({
    total: number().required(),
    page: number().required(),
  }),
  nextPage: boolean().required(),
  data: array(
    object({
      id: string().required(),
      eventId: string(),
      location: object({
        topic: string().required(),
        address: string().required(),
        notificationsTopic: string().required(),
      }).required(),
      locationCoords: object({
        latitude: number().required(),
        longitude: number().required(),
      }).required(),
      parkingInfo: string().required(),
      locationInfo: object({
        english: string().required(),
        spanish: string().required(),
      })
        .optional()
        .nullable(),
      clockInInfo: object({
        english: string().required(),
        spanish: string().required(),
      }).required(),
      positionTitle: object({
        english: string().required(),
        spanish: string().required(),
      }).required(),
      positionId: string().required(),
      startDate: string().required(),
      endDate: string().required(),
      breakTime: number().required(),
      openings: number().required(),
      salaryType: string().required(),
      salaryAmount: number().required(),
      fullTimeOportunity: boolean().required(),
      uniform: string().required(),
      dressCode: string().required(),
      groomingRestrictions: array(string().required()).required(),
      jobInformation: object({
        english: string().required(),
        spanish: string().required(),
      }).required(),
      abilities: array(string().required()).required(),
      experience: string().required(),
      contacts: array(
        object({
          name: string().required(),
          role: string().required(),
          phoneNumber: string().required(),
          email: string().required(),
        }).required()
      ).required(),

      isFilled: boolean().required(),
      isFinished: boolean().required(),
      isAccounted: boolean().required(),
      isActive: boolean().required(),
      views: number().required(),
      stripeId: string().nullable(),
      postedDate: string().required(),
      cancelledDate: string().nullable(),
      isW2: boolean().required(),
      onlyFavorites: boolean().required(),
    })
  ).required(),
});

export type CalendarShiftPaginatedDto = InferType<typeof CalendarShiftsPaginatedSchema>;

export const dtoToCalendarShifPaginatedGet = async (dto: object) => {
  try {
    const validateDto = await CalendarShiftsPaginatedSchema.validate(dto);
    return new ShiftCalendarPaginatedGet(validateDto);
  } catch (e) {
    if (e instanceof ValidationError) {
      console.log(e.message); // Property 'message' does not exist on type '{}'.

      toast.error('Please contact support');
    }
  }
};
