import { DateTime } from 'luxon';

const getHours = (start: Date, end: Date) => {
  const startDate = DateTime.fromJSDate(start);
  const endDate = DateTime.fromJSDate(end);

  return startDate.diff(endDate, ['hours', 'minutes', 'days']);
};

export { getHours };
