import { DragEvent, useContext, useRef, useState } from 'react';
import UploadDocumentAction from './UploadDocument.Action';
import {
  StepUploadDocument,
  UploadDocumentContext,
} from 'pages/massiveShift/core/infraestructure/Providers/UploadDocument.provider';

export const UploadDocumentPresenter = () => {
  const [file, setFile] = useState<File>();
  const refInput = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const ctxUpload = useContext(UploadDocumentContext);

  const action = UploadDocumentAction();

  const handleFileChange = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const newFiles = Array.from(files);
      setFile(newFiles[0]);
      setLoading(true);

      const formData = new FormData();
      formData.append('File', newFiles[0]);

      const result = await action.executeUploadFile(formData);

      if (result) {
        ctxUpload.setResult(result);
        ctxUpload.setStep(StepUploadDocument.REPORT);
      }
    }
  };
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      handleFileChange(droppedFiles);
    }
  };

  const onClickSubmit = () => {
    refInput?.current?.click();
  };

  return { handleDrop, handleFileChange, file, refInput, onClickSubmit, loading };
};
