import useUser from 'utils/hooks/useUser';
import { FileUserCase } from '../../core/modules/Files/Application/FileUserCase';
import { ShowToastError } from 'utils';

const fileUserCases = new FileUserCase();

const UploadDocumentAction = () => {
  const {
    business: { id },
    cognitoUser: { id: idUser },
  } = useUser();

  const executeUploadFile = async (data: FormData) => {
    try {
      data.append('BusinessId', id);
      data.append('BusinessUserId', idUser);

      const result = await fileUserCases.invokeUploadFile(data);
      return result;
    } catch (e) {
      ShowToastError(e);
      return false;
    }
  };

  return { executeUploadFile };
};

export default UploadDocumentAction;
