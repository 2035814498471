import { AxiosError } from 'axios';
import { DisputeUseCase } from 'pages/timesheet/core/Modules/Disputes/Applications/UseCases/DisputeUseCase';
import {
  FormType,
  DisputeOption,
} from 'pages/timesheet/core/Modules/Disputes/Domain/Entities/CreateDisputeFormSet';
import toast from 'react-hot-toast';
import useUser from 'utils/hooks/useUser';

const DisputeUseCases = new DisputeUseCase();

const DisputeAction = () => {
  const {
    business: { id },
    cognitoUser: { id: userId },
  } = useUser();

  const executeCreateDispute = async (
    data: FormType,
    selectedDisputes: DisputeOption[],
    executionId: string
  ) => {
    try {
      await DisputeUseCases.invokeCreateDispute(data, selectedDisputes, id, userId, executionId);
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return { executeCreateDispute };
};

export default DisputeAction;
