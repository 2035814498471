import { InvoiceUseCase } from 'pages/invoice/core/Modules/Invoices/Application/InvoiceUseCase';
import { ShowToastError } from 'utils';

const invoiceUseCases = new InvoiceUseCase();

const InvoiceDetailAction = () => {
  const executeGetDetail = (id: string) => {
    try {
      return invoiceUseCases.invokeGetInvoiceDescription(id);
    } catch (e) {
      ShowToastError(e);
    }
  };

  return {
    executeGetDetail,
  };
};

export default InvoiceDetailAction;
