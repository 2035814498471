import { EventDataGet } from 'pages/postShift/core/Modules/Catalog/Domain/Entities/Catalog.entity';
import { RoleUseCases } from 'pages/postShift/core/Modules/Form/Application/RoleUseCases';
import { RoleSchemaType } from 'pages/postShift/core/Modules/Form/Domain/Dtos/Role.dto';
import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';
import toast from 'react-hot-toast';
import useUser from 'utils/hooks/useUser';

const roleUseCase = new RoleUseCases();

const CreateRoleAction = () => {
  const {
    business: { id },
  } = useUser();

  const invokeGetRoles = async (catalog?: EventDataGet) => await roleUseCase.invokeGet(id, catalog);

  const invokeSaveRole = async (d: RoleSchemaType) => {
    try {
      const result = await roleUseCase.invokeSave(d, id);
      return {
        text: getTextTranslated(result.data.positionTitle),
        id: result.data.id,
      };
    } catch {
      toast.error('Something went wrong creating role');
    }
  };

  const invokeEditRole = async (data: RoleSchemaType, idRole?: string) => {
    try {
      await roleUseCase.invokeEdit(data, idRole ?? '', id);
    } catch {
      toast.error('Something went wrong editing role');
    }
  };

  return {
    invokeGetRoles,
    invokeSaveRole,
    invokeEditRole,
  };
};

export default CreateRoleAction;
