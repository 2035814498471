import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from 'store';
import { IShiftsReducer } from '.';

const getState = (state: GlobalState): IShiftsReducer => state.events;

export const getApplicantResume = createSelector(
  getState,
  (state: IShiftsReducer) => state.applicantResume
);
