import Icon from 'assets/icons';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { UpdateProfilePhotoThunk } from 'store/business/api.thunks';
import useUser from 'utils/hooks/useUser';

interface IProfileImage {
  editMode?: boolean;
}

const ProfileImage = ({ editMode }: IProfileImage) => {
  const {
    business: { logo, name, id },
  } = useUser();
  const [t] = useTranslation(['Profile']);

  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File>();
  const inputFile = useRef<HTMLInputElement>(null);

  const onChangeInputFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const onClickEditPhoto = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  useEffect(() => {
    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('id', id);
      dispatch(UpdateProfilePhotoThunk(form))
        .unwrap()
        .then(() => {
          toast.success(t('success'));
          setFile(undefined);
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  }, [file]);

  return (
    <div className="flex">
      <img
        src={logo}
        style={{ width: !editMode ? 36 : 52, height: !editMode ? 36 : 52 }}
        className={`rounded-full`}
      />

      {editMode && (
        <div
          onClick={onClickEditPhoto}
          className="bg-secondary-500 cursor-pointer rounded-full relative flex items-center justify-center"
          style={{ width: 18, height: 18, top: 34, left: -14 }}
        >
          <input
            type="file"
            id="file"
            onChange={onChangeInputFile}
            ref={inputFile}
            style={{ display: 'none' }}
          />

          <Icon icon="edit" size={10.12} color="white" />
        </div>
      )}
      {!editMode && <p className="text-base font-semibold ml-3.5 pt-1 text-primary">{name}</p>}
    </div>
  );
};

export default ProfileImage;
