export default {
  en: {
    success: 'Successful process',
    payment: 'Payment',
    ///Add Card
    again: 'Please try again',
    save: 'Save',
    add: 'Add a new card',
    your: 'Your cards',
    card: 'Card',
    using: 'Using this card',
    use: 'Use this card',
    delete: 'Delete card',
    placeholder: 'Card number',
    bank: 'Bank',
    title: 'Add card',
    titleCards: 'Payment Method',

    noCard:
      "It looks like you don't have any payment method registered yet, do you want to add a new credit card?",
  },
  es: {
    success: 'Proceso exitoso',
    payment: 'Pagos',
    ///Add Card
    again: 'Por favor intente de nuevo',
    save: 'Guardar',
    add: 'Añadir una nueva tarjeta',
    your: 'Tus tarjetas',
    card: 'Tarjeta',
    using: 'Usando esta tarjeta',
    use: 'Usar esta tarjeta',
    delete: 'Eliminar tarjeta',
    placeholder: 'Número de la tarjeta',
    bank: 'Banco',
    title: 'Añadir tarjeta',
    titleCards: 'Método de Pago',

    noCard:
      'Parece que no tienes ningún método de pago registrado aún, ¿quieres añadir una nueva tarjeta de crédito?',
  },
};
