import CreatableSelect from 'components/CreatableSelect';
import Tooltip from 'components/TooltipDeprecated';
import { KeyboardEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/hooks/useUser';
import { Option } from 'actions/shift/model';
import { PaymentOptionEnum } from 'actions/business/model';
import Button from 'components/Button';
import { useMutation } from '@tanstack/react-query';
import BusinessService from 'actions/business';
import { LAST_EMAIL } from 'utils/persistor';
import toast from 'react-hot-toast';
import { setPaymentOption } from 'store/business';
import { useAppDispatch } from 'store';
import { emailOptions, getStepPaymentName } from 'utils';

interface IStep2Payment {
  selectedPayment: PaymentOptionEnum;
}

const Step2Payment = ({ selectedPayment }: IStep2Payment) => {
  const [t] = useTranslation(['Shifts']);
  const [value, setValue] = useState<Option[]>([]);
  const dispatch = useAppDispatch();
  const { mutateAsync: mutatePaymentOption } = useMutation(BusinessService.updatePaymentOption);

  const {
    business: { email, id, invoiceEmails },
  } = useUser();

  useEffect(() => {
    setValue(
      invoiceEmails
        ? invoiceEmails?.map((email) => {
            return { label: email, value: email };
          })
        : emailOptions(email)
    );
  }, [email]);

  const [inputValue, setInputValue] = useState('');

  const createOption = (label: string, prev: Option[]): Option[] => [
    ...prev,
    {
      label,
      value: label,
    },
  ];

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue(createOption(inputValue, value));
        setInputValue('');
        event.preventDefault();
    }
  };

  const onUpdateEmail = () => {
    mutatePaymentOption({
      id,
      invoiceEmails: value.map((e) => e.value),
      paymentOption: selectedPayment,
    }).then(() => {
      dispatch(setPaymentOption(selectedPayment));
      toast.success('Success');
      localStorage.setItem(LAST_EMAIL, JSON.stringify(value));
    });
  };

  return (
    <>
      <div className="flex mb-7 mt-2">
        <div className="flex items-center content-center">
          <span className="mt-4 flex content-center items-center text-base font-semibold text-primary">
            {getStepPaymentName(selectedPayment)}
            <Tooltip>
              <p className="max-w-[1400px] text-sm text-white">{t('paymentShift.changePayment')}</p>
            </Tooltip>
          </span>
        </div>
      </div>
      <p className="mb-4 text-primary">{t('emails')}</p>
      <CreatableSelect
        isMulti={true}
        isClearable={true}
        inputValue={inputValue}
        onChange={(newValue) => setValue(newValue as Option[])}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        value={value}
        defaultValue={{ label: email, value: email }}
        options={emailOptions(email)}
      />
      <div className="text-center mt-10">
        <Button onClick={onUpdateEmail} name={t('updateEmail')} />
      </div>
    </>
  );
};

export default Step2Payment;
