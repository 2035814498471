import {
  ShiftInfoFormSchema,
  ShiftInfoFormSchemaType,
} from 'pages/postShift/core/Modules/Form/Domain/Dtos/ShiftInfo.dto';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo } from 'react';
import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { getHours } from 'pages/postShift/shared/utils/date-calc';
import { DateTime } from 'luxon';
import { StepperContext } from 'pages/postShift/core/Infrastructure/Providers/Stepper.provider';
import { entityToFormShiftSection } from 'pages/postShift/core/Modules/Checkout/Domain/Mappers/entityToForm.mapper';

const ShiftInfoPresenter = () => {
  const dataFormContext = useContext(DataFormContext);
  const stepper = useContext(StepperContext);

  const [t] = useTranslation(['Shifts']);
  const form = useForm({
    resolver: yupResolver(ShiftInfoFormSchema),
    mode: 'onChange',
    defaultValues: {
      ...entityToFormShiftSection(dataFormContext),
    },
  });

  const times = form.watch('endTime');
  const isRecurrent = form.watch('recurrent');

  const selectDay = (day: number, value?: number[]) => {
    if (value) {
      if (value.includes(day)) {
        return [...value.filter((saved) => saved != day)];
      } else {
        return [day, ...value];
      }
    }

    return [day];
  };

  const onSubmit: SubmitHandler<ShiftInfoFormSchemaType> = (data) => {
    if (!dataFormContext.dataForm.shiftInfo?.contacts) {
      return toast(t('create.shiftInfo.contactsLeft'), {
        icon: '⚠️',
      });
    }

    if (!dataFormContext.dataForm.shiftInfo?.location) {
      return toast(t('create.shiftInfo.locationLeft'), {
        icon: '⚠️',
      });
    }

    if (!dataFormContext.dataForm.shiftInfo?.role) {
      return toast(t('create.shiftInfo.roleLeft'), {
        icon: '⚠️',
      });
    }

    const newForm = {
      ...dataFormContext.dataForm,
      shiftInfo: {
        ...dataFormContext.dataForm.shiftInfo,
        ...(data.recurrent && data.recurrentStart && data.recurrentEnd
          ? {
              recurrentStart: data.recurrentStart,
              recurrentEnd: data.recurrentEnd,
              recurrent: true,
              recurrentDays: data.recurrentDays,
            }
          : {
              startDate: data.startDate,

              recurrent: false,
            }),
        startDate: data.startDate,
        startTime: data.startTime,
        endTime: data.endTime,
        timeZone: data.timeZone,
      },
    };

    dataFormContext.setDataForm({ ...newForm });

    stepper.changeStep('worker', true);
  };

  const hasTimeError = useMemo(() => {
    const start = form.getValues('startTime');
    const end = times;

    if (start && end) {
      let newEndTime = DateTime.fromJSDate(end);
      if (start.getTime() > end.getTime()) {
        newEndTime = newEndTime.plus({ days: 1 });
      }
      const hours = getHours(start, newEndTime.toJSDate());

      if (Math.abs(hours.shiftTo('hours').as('hours')) > 14) {
        return true;
      }
    }

    return false;
  }, [times, form]);

  const onAddNewDate = () => {
    const start = form.getValues('startDate');

    if (Array.isArray(start)) {
      form.setValue('startDate', [...start, new Date()]);
    } else {
      if (start) {
        form.setValue('startDate', [start, new Date()]);
      } else {
        form.setValue('startDate', [new Date()]);
      }
    }
  };

  useEffect(() => {
    if (dataFormContext.mode === 'template' && !stepper.firstEntry) {
      stepper.setFirstEntry(true);
      stepper.changeStep('confirm', true);
    }
  }, [dataFormContext.mode, stepper, stepper.firstEntry]);

  return {
    form,
    selectDay,
    onSubmit,
    hasTimeError,
    isRecurrent,
    mode: dataFormContext.mode,
    onAddNewDate,
  };
};

export default ShiftInfoPresenter;
