import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyCard from './components/Cards/Company';
import ProfileProvider from './provider';
import PersonalCard from './components/Cards/Personal';
import PaymentAndMemberCard from './components/Cards/PaymentAndMember';
import { Helmet } from 'react-helmet';
import useWindowDimensions from 'utils/hooks/window';
import Button from 'components/Button';

const Profile = (): JSX.Element => {
  const [t] = useTranslation(['Profile']);
  const { trackPageView } = useMatomo();
  const { width } = useWindowDimensions();
  const [memberPayment, setMemberPayment] = useState(width <= 1180);

  useEffect(() => {
    trackPageView({
      documentTitle: 'Profile',
      href: window.location.href,
    });
  }, []);

  const onClickMembers = () => {
    setMemberPayment(!memberPayment);
  };

  return (
    <ProfileProvider>
      <Helmet>
        <title>{t('title')} | Chamba Business</title>
      </Helmet>

      <div className="bg-gray-400 w-full min-h-screen pt-5">
        <div className="flex tablet:mx-12 rounded-xl flex-wrap tablet:flex-nowrap">
          {!memberPayment && (
            <div className="tablet:w-1/2 tablet:mr-5 mx-4 w-full">
              <CompanyCard />

              <br />

              <PersonalCard />
            </div>
          )}

          {(memberPayment || width >= 1180) && (
            <div className="tablet:w-1/2 tablet:ml-5 w-full">
              <PaymentAndMemberCard />
            </div>
          )}
        </div>
        <div className="text-center mt-5 tablet:hidden block">
          <Button
            onClick={onClickMembers}
            color="primary"
            name={memberPayment ? 'Back' : 'Member / Payment'}
          />
        </div>
        {/* <div className="lg:ml-[15%] lg:w-[85%] text-primary xl:px-56 lg:px-36 sm:px-14 px-3 py-14">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="flex w-full flex-wrap justify-center ">
              <img className="w-40" src={businessUser?.logo} alt="" />
              <h2 className="w-full text-center text-3xl mt-3 font-bold">{businessUser?.name}</h2>
            </div>
            <div className="w-full md:w-min flex justify-center mt-3 md:mt-0">
              <div>
                <button
                  onClick={() => navigate(RoutesConstant.secure.editProfile)}
                  className="bg-primary py-1 text-white pl-7 pr-8 rounded-2xl flex items-center"
                >
                  <Icon
                    className=""
                    icon="Pencil"
                    size={16}
                    color="text-black"
                    style={{ marginRight: 3 }}
                  />
                  {t('edit')}
                </button>
              </div>
            </div>
          </div>
          <div className="2xl:px-56 xl:px-40 lg:px-16 md:px-7 px-0 text-center md:text-left">
            <p className="mt-5 text-xl">{businessUser?.description}</p>
            <h4 className="text-base mt-5"> {t('email')}:</h4>
            <p className="text-xl">{businessUser?.email}</p>
            <h4 className="text-base mt-5"> {t('contact')}:</h4>
            <p className="text-xl">{businessUser?.phoneNumber}</p>
            <h4 className="text-base mt-5"> {t('type')}:</h4>
            <p className="text-xl">
              {types?.find((element) => element.id === businessUser?.type)?.text}
            </p>
            <h4 className="text-base mt-5"> {t('plan')}:</h4>
          </div>
        </div> */}
      </div>
    </ProfileProvider>
  );
};

export default Profile;
