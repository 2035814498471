import {
  ContactFormSchema,
  ContactFormSchemaType,
} from 'pages/postShift/core/Modules/Form/Domain/Dtos/ContactForm.dto';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { set } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import CreateContactAction from './CreateContact.Action';
import { fetchContactToForm } from 'pages/postShift/core/Modules/Form/Domain/Mappers/fetchContactToForm';
import { entityToFormContact } from 'pages/postShift/core/Modules/Checkout/Domain/Mappers/entityToForm.mapper';

const CreateContactPresenter = () => {
  const contextForm = useContext(DataFormContext);
  const [openedModal, setOpenedModal] = useState(false);
  const action = CreateContactAction();
  const [editingId, setEditingId] = useState<string>();
  const [selectedContact, setSelectedContact] = useState<IItems>();

  const [shouldNotShowSelect, setShouldNotShowSelect] = useState(
    contextForm.mode === 'edit' ? true : false
  );

  const form = useForm({
    resolver: yupResolver(ContactFormSchema),
  });

  const contacts = useQuery(['get-contacts'], () => action.invokeGetContacts());

  const onSubmit: SubmitHandler<ContactFormSchemaType> = (data) => {
    const finalValue = set(contextForm.dataForm, 'shiftInfo.contacts', [{ ...data }]);
    contextForm.setDataForm({ ...finalValue });

    if (!editingId) {
      action.invokeSaveContact(data).then(async (contact) => {
        await contacts.refetch();
        setSelectedContact(contact);
      });
    } else {
      action.invokeEditContact(data, selectedContact?.id).then(() => contacts.refetch());
    }
    setEditingId(undefined);
    setOpenedModal(false);
  };

  const onClickTrigger = () => {
    setOpenedModal(true);
  };

  const onClickBack = () => {
    setOpenedModal(false);
  };

  const onClickEditContact = () => {
    setEditingId(selectedContact?.id);
    setOpenedModal(true);
    form.reset(entityToFormContact(contextForm) as object);
  };

  const onClickContact = (selected: IItems) => {
    setSelectedContact(selected);
    const getSelectedContact = contacts.data?.find((c) => c.id === selected.id);

    contextForm.setDataForm(
      set(contextForm.dataForm, 'shiftInfo.contacts', [
        { ...fetchContactToForm(getSelectedContact) },
      ])
    );
  };

  const onClickClear = () => {
    form.reset();
    setShouldNotShowSelect(false);
    set(contextForm.dataForm, 'shiftInfo.contacts', []);
  };

  useEffect(() => {
    if ((contextForm.dataForm.shiftInfo?.contacts?.length ?? 0) > 0 && contacts.data) {
      const contactSelected = contacts.data.find(
        (v) => v.id === contextForm.dataForm.shiftInfo?.contacts[0]?.id
      );

      if (contactSelected && contactSelected.id) {
        setSelectedContact({
          id: contactSelected.id,
          text: contactSelected.name,
        });
      }
    }
  }, [contacts.data]);

  return {
    openedModal,
    setOpenedModal,
    form,
    onSubmit,
    contextForm,
    onClickClear,
    onClickEditContact,
    onClickTrigger,
    contacts,
    onClickBack,
    selectedContact,
    setSelectedContact,
    onClickContact,
    shouldNotShowSelect,
  };
};

export default CreateContactPresenter;
