import { date, InferType, number, object, string } from 'yup';

const Form = object({
  location: object({
    location: object({
      topic: string(),
      address: string().required(),
      notificationsTopic: string(),
    }).required(),
    locationCoords: object({
      latitude: number().required(),
      longitude: number().required(),
    }).required(),
  }),
  paymentType: object({
    id: string().required(),
    text: string().required(),
  }).required(),
  paymentAmount: number().required(),
  startDate: date().required(),
  firstDayInstructions: string(),
  positionTitle: string().required(),
  benefits: string().required(),
}).required();

export type HireFormType = InferType<typeof Form>;

export default Form;
