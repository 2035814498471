import { InferType, array, boolean, object, string } from 'yup';

export const AttireFormSchema = object({
  uniform: object({
    id: string().required(),
    text: string().required(),
  }).required(),
  dressCode: object({
    id: string().required(),
    text: string().required(),
    image: string().required(),
  }).required(),
  groomingRestrictions: array(
    object({
      id: string().required(),
      text: string().required(),
      default: boolean().required(),
    })
  ).required(),
});

export interface IStepShifInfo {
  stepName: string;
}
export interface IStepForm {
  startTime: Date;
  endTime: Date;
  startDate: Date;
  endDate: Date;
}

export type AttireFormSchemaType = InferType<typeof AttireFormSchema>;

export const ValidateAttire = async (d: object): Promise<boolean> => {
  try {
    await AttireFormSchema.validate(d);
    return true;
  } catch {
    return false;
  }
};
