import { DisputeRepositoryType } from 'pages/timesheet/core/Infrastructure/Contracts/Disputes.contract';
import { CreateDisputeRequestSet } from '../Entities/CreateDisputeFormSet';
import instance from 'actions/http';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';

export class DisputeRepository implements DisputeRepositoryType {
  public createDispute = async (d: CreateDisputeRequestSet): Promise<boolean> => {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.CREATE_DISPUTE), d);
    return true;
  };
}
