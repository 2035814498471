import instance from 'actions/http';
import { GetInvoicesSet } from '../Entities/GetInvoicesSet';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import { dtoToInvoiceList } from '../Dtos/InvoiceList.dto';
import { GetInvoiceDetailSet } from '../Entities/GetInvoiceDetailSet';
import { dtoToInvoiceDescription } from '../Dtos/InvoiceDescription.dto';

export class InvoiceRepository {
  public async getBusinessInvoices(d: GetInvoicesSet) {
    const request = await instance.post(getEndpoint(API_ENDPOINTS.SEARCH.GET_BUSINESS_INVOICES), d);
    return dtoToInvoiceList(request.data);
  }

  public async getInvoiceDetail(data: GetInvoiceDetailSet) {
    const request = await instance.post(getEndpoint(API_ENDPOINTS.SEARCH.GET_INVOICE_DETAIL), data);
    return dtoToInvoiceDescription(request.data);
  }
}
