import { DataFormContextType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';

export const entityToFormShiftSection = (data: DataFormContextType) => {
  const dataForm = data.dataForm.shiftInfo;

  return {
    startDate: dataForm?.startDate,
    endDate: dataForm?.endDate,
    startTime: dataForm?.startTime ? new Date(dataForm.startTime) : undefined,
    endTime: dataForm?.endTime ? new Date(dataForm.endTime) : undefined,
    timeZone: dataForm?.timeZone,
    recurrent: typeof dataForm?.recurrent === 'boolean' ? dataForm?.recurrent : undefined,
  };
};

export const entityToFormLocation = (data: DataFormContextType) => {
  const dataForm = data.dataForm.shiftInfo?.location;
  return {
    info: dataForm?.info ?? '',
    clockInInfo: dataForm?.clockInInfo ?? '',
    parking: dataForm?.parking ?? '',
    location: {
      location: dataForm?.location?.location,
      locationCoords: dataForm?.location.locationCoords,
    },
  };
};
export const entityToFormContact = (data: DataFormContextType) => {
  const dataForm = data.dataForm.shiftInfo?.contacts[0];
  return {
    name: dataForm?.name,
    role: dataForm?.role,
    email: dataForm?.email,
    phoneNumber: dataForm?.phoneNumber,
  };
};

export const entityToFormRole = (data: DataFormContextType) => {
  const dataForm = data.dataForm.shiftInfo?.role;

  return {
    category: dataForm?.category,
    name: dataForm?.name,
    information: dataForm?.information,
    amount: dataForm?.amount,
    experience: dataForm?.experience,
    abilities: dataForm?.abilities,
    payment: dataForm?.payment,
  };
};

export const entityToFormAttire = (data: DataFormContextType) => {
  const dataForm = data.dataForm.attire;

  return {
    uniform: dataForm?.uniform,
    dressCode: dataForm?.dressCode,
    groomingRestrictions: dataForm?.groomingRestrictions,
  };
};

export const entityToFormWorker = (data: DataFormContextType) => {
  const dataForm = data.dataForm.workers;
  return {
    openings: dataForm?.openings,
    breakTime: dataForm?.breakTime,
    selectedAssign: dataForm?.selectedAssign,
    fullTimeOportunity: dataForm?.fullTimeOportunity,
  };
};
