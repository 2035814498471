import i18next from 'i18next';

export const fullCompensation = '3219c114-2aca-47d8-907e-a6cdecfdcc3f';

export const PAYMENT_TYPES = [
  {
    id: '56620a39-5ccb-4f47-874e-efb4851f4cbd',
    text: i18next.t<string>('create.role.hourly'),
  },
  {
    id: '3219c114-2aca-47d8-907e-a6cdecfdcc3f',
    text: i18next.t<string>('create.role.compensation'),
  },
];
