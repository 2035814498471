import { LocationUseCases } from 'pages/postShift/core/Modules/Form/Application/LocationUseCases';
import { LocationSchemaType } from 'pages/postShift/core/Modules/Form/Domain/Dtos/Location';
import toast from 'react-hot-toast';
import useUser from 'utils/hooks/useUser';

const locationUseCase = new LocationUseCases();
const LocationAction = () => {
  const {
    business: { id },
  } = useUser();

  const invokeGetLocation = async () => await locationUseCase.invokeGet(id);

  const invokeSaveLocation = async (d: LocationSchemaType) => {
    try {
      const result = await locationUseCase.invokeSave(d, id);
      return {
        text: result.data.id,
        id: result.data.id,
      };
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong creating location');
    }
  };
  const invokeEditLocation = async (data: LocationSchemaType, idLocation?: string) => {
    try {
      await locationUseCase.invokeEdit(data, idLocation ?? '', id);
    } catch {
      toast.error('Something went wrong editing location');
    }
  };

  return {
    invokeGetLocation,
    invokeSaveLocation,
    invokeEditLocation,
  };
};

export default LocationAction;
