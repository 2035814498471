import Button from 'components/Button';
import ResultUploadPresenter from './ResultUpload.Presenter';
import ModalPayment from '../ModalPayment';
import { useTranslation } from 'react-i18next';

const ResultUploadScreen = () => {
  const presenter = ResultUploadPresenter();
  const [t] = useTranslation(['Massive']);

  return (
    <div className="p-4">
      <p className="font-semibold text-xl text-primary">{t('summary')} </p>

      <div className="flex mt-2">
        <div className="text-primary w-full max-w-[180px] bg-secondary-200 rounded-md py-2 text-center mr-3">
          <p className="text-xs font-normal">{t('rowsRead')}</p>
          <p className="text-base font-semibold">
            {presenter.data?.totalRows} {t('rows')}
          </p>
        </div>

        <div className="text-primary w-full max-w-[180px] bg-secondary-200 rounded-md py-2 text-center mr-3">
          <p className="text-xs font-normal">{t('rowPass')}</p>
          <p className="text-base font-semibold">
            {presenter.data?.successfulRows} {t('rows')}
          </p>
        </div>

        <div className="text-primary w-full max-w-[180px] bg-secondary-200 rounded-md py-2 text-center mr-3">
          <p className="text-xs font-normal">{t('successShift')}</p>
          <p className="text-base font-semibold">{presenter.data?.successfulShifts} Shifts</p>
        </div>

        <div className="text-primary w-full max-w-[180px] bg-secondary-200 rounded-md py-2 text-center mr-3">
          <p className="text-xs font-normal">{t('estimationCost')}</p>
          <p className="text-base font-semibold">${presenter.data?.estimatedCost} </p>
        </div>
      </div>

      <div className="mt-6 flex justify-between">
        <p className="font-semibold text-xl leading-none">{t('errorReport')}</p>
        <div className="flex">
          <Button
            name="Discard all"
            className="mr-2"
            color="danger"
            size="xs"
            onClick={presenter.onDiscard}
          />
          <Button
            name="Publish shifts"
            size="xs"
            color="primary"
            disabled={(presenter.data?.successfulShifts ?? 0) <= 0}
            onClick={presenter.onClickPublish}
          />
        </div>
      </div>

      <div className="p-3 bg-neutral-200 flex mt-3 text-xs text-neutral-1200">
        <div className="w-2/6">{t('rowNumber')}</div>
        <div className="w-4/6">{t('typeError')}</div>
      </div>

      <div className="mt-3">
        {presenter.data?.errors.map((v, key) => (
          <div key={key} className="flex text-xs border-b py-2">
            <div className="w-2/6 pl-2 text-warnings-500">Row # {v.row}</div>
            <div className="w-4/6 pl-2 text-neutral-1200">
              {v.errorMessages.map((v, key) => {
                return <div key={key}>{v}</div>;
              })}
            </div>
          </div>
        ))}
      </div>

      <ModalPayment
        eventIds={presenter.data?.eventIds}
        isOpen={presenter.openPayment}
        setOpenModalPayment={presenter.setOpenPayment}
      />
    </div>
  );
};

export default ResultUploadScreen;
