import Button from 'components/Button';
import InputV2 from 'components/InputV2';
import SelectV2 from 'components/SelectV2';
import WorkerPresenter from './Worker.Presenter';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

interface IStepWorker {
  stepName: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WorkerScreen = ({ stepName }: IStepWorker) => {
  const presenter = WorkerPresenter();
  const [t] = useTranslation(['Shifts']);

  return (
    <form onSubmit={presenter.form.handleSubmit(presenter.onSubmit)}>
      <p className="text-lg font-semibold mt-5 pb-3">{t('create.worker.howMany')}</p>
      <InputV2
        type="number"
        {...presenter.form.register('openings')}
        placeholder={t('create.worker.workers')}
        name="openings"
      />
      {presenter.form.formState.errors.openings && (
        <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
      )}
      <p className="text-lg font-semibold mt-3 pb-3">{t('create.worker.breaks')}</p>
      <Controller
        name="breakTime"
        control={presenter.form.control}
        render={({ field: { onChange, value } }) => (
          <SelectV2
            itemSelected={{
              text: `${value === 0 ? '0' : value} ${t<string>('mins')}`,
              id: value === 0 ? '0.00' : value?.toString(),
            }}
            items={presenter.unPaidBreaks}
            className="w-full rounded text-base border-neutral-400 px-3 py-1 border mb-3 mt-2"
            name={t('create.worker.selectBreak')}
            showDropDownArrow
            setSelectedItem={(e) => onChange(e.id)}
          />
        )}
      />

      {presenter.form.formState.errors.breakTime && (
        <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
      )}

      <p className="text-lg font-semibold mt-3 pb-3">{t('create.worker.inviteWorker')}</p>
      <span className="text-neutral-1600 text-base">
        {t('create.worker.inviteWorkerDescription')}
      </span>
      <Controller
        control={presenter.form.control}
        name="selectedAssign"
        render={({ field: { onChange, value } }) => (
          <div className="flex  mt-3">
            <div
              onClick={() => onChange(false)}
              className={`w-1/2 border-neutral-400 border px-2.5 cursor-pointer py-2 mr-2 rounded-md flex items-start ${value === false && 'outline outline-secondary-400'}  `}
            >
              <input
                type="radio"
                className="mr-3"
                name="selectedAssign"
                checked={value === false}
                onChange={() => onChange(false)}
              />

              <div>
                <p className="text-neutral-1600">{t('create.worker.autoAssign')}</p>
                <span className="text-neutral-1000 font-normal text-[10px] block">
                  {t('create.worker.autoAssignDesc')}
                </span>
              </div>
            </div>

            <div
              onClick={() => onChange(true)}
              className={`w-1/2 py-2 px-2.5 border-neutral-400 cursor-pointer border flex items-start rounded-md ml-2  ${value === true && 'outline outline-secondary-400'} `}
            >
              <input
                type="radio"
                className="mr-3"
                checked={value === true}
                name="selectedAssign"
                onChange={() => onChange(true)}
              />
              {presenter.form.formState.errors.selectedAssign && (
                <p className="text-xs text-red">{t('required')}</p>
              )}
              <div className="">
                <p className="text-neutral-1600">{t('create.worker.favorites')}</p>
                <span className="text-neutral-1000 font-normal text-[10px] block">
                  {t('create.worker.autoAssignFav')}
                </span>
              </div>
            </div>
          </div>
        )}
      />

      {presenter.form.formState.errors.selectedAssign && (
        <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
      )}

      <p className="text-lg font-semibold mt-3 pb-3">{t('create.worker.fullTime')}</p>
      <Controller
        control={presenter.form.control}
        name="fullTimeOportunity"
        render={({ field: { onChange, value } }) => (
          <>
            <input checked={value === true} type="radio" onChange={() => onChange(true)} />
            <label className="ml-1">{t('create.worker.yes')}</label>
            <input
              checked={value === false}
              type="radio"
              className="ml-4"
              onChange={() => onChange(false)}
            />
            <label className="ml-1">No</label>
          </>
        )}
      />

      {presenter.form.formState.errors.fullTimeOportunity && (
        <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
      )}
      <div className="flex justify-between mt-6">
        <p className="cursor-pointer" onClick={presenter.onClickBack}>
          {t('create.back')}
        </p>
        <Button
          className=" rounded-md py-2 px-4"
          color="primary"
          name={t('create.save')}
          size="xs"
        />
      </div>
    </form>
  );
};

export default WorkerScreen;
