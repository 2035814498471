import { HTMLAttributes, forwardRef, TextareaHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  prefixIcon?: JSX.Element;
  rows?: number;
  placeholder?: string;
  error?: FieldError;
}

const TextArea = forwardRef<
  HTMLTextAreaElement,
  HTMLAttributes<HTMLTextAreaElement> & TextAreaProps
>(({ prefixIcon, rows, placeholder, error, className, ...props }, propRef) => {
  return (
    <>
      <div className="flex relative">
        {prefixIcon && <div className="absolute pl-3 pt-1.5">{prefixIcon}</div>}

        <textarea
          ref={propRef}
          {...props}
          rows={rows}
          placeholder={placeholder}
          className={twMerge(
            'border-[1px] focus:outline-secondary-400 border-neutral-400 text-neutral-1600 placeholder:text-neutral-1600 rounded py-1.5 w-full',
            className,
            prefixIcon ? 'pl-7' : 'px-3 '
          )}
        />
      </div>
      {error && <small className="text-left mt-1 text-warnings-400">Required field</small>}
    </>
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
