import { ListSheetSet } from '../Entities/ListSheetSet';
import { dtoToListSheetGet, listSheetToDto } from '../Dtos/ListSheet.dto';
import instance from 'actions/http';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import { TimeSheetRepositoryType } from 'pages/timesheet/core/Infrastructure/Contracts/Timesheet.contract';
import { ListSheetGet } from '../Entities/ListSheet.entity';
import { ListShiftResultGet } from '../Entities/ListShift.entity';
import { ListSheetShiftDto, dtoToListSheetShiftGet } from '../Dtos/ListSheetShift.dto';
import { ListShiftSet } from '../Entities/ListShiftSet';
import { CreateIndividualRateSet } from '../Entities/CreateIndividualRateSet';
import { UpdateTipSet } from '../Entities/UpdateTipSet';
import { ApproveExecutionsSet } from '../Entities/ApproveExecutionsSet';

export class TimeSheetRepository implements TimeSheetRepositoryType {
  public async getTimesheetList(data: ListSheetSet) {
    const request = listSheetToDto(data);
    const response = await instance.post<ListSheetGet>(
      getEndpoint(API_ENDPOINTS.SEARCH.GET_TIMESHEET_WEEK),
      request
    );
    return dtoToListSheetGet(response.data);
  }

  public async getListSheetList(data: ListShiftSet): Promise<ListShiftResultGet> {
    const request = await instance.post<ListSheetShiftDto>(
      getEndpoint(API_ENDPOINTS.SEARCH.GET_TIMESHEET),
      data
    );

    return dtoToListSheetShiftGet(request.data);
  }

  public async createIndividualRate(data: CreateIndividualRateSet): Promise<boolean> {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.CREATE_INDIVIDUAL_RATE), data);
    return true;
  }

  public async updateTip(data: UpdateTipSet): Promise<boolean> {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.EDIT_SHIFT_EXECUTION_TIP), data);
    return true;
  }

  public async approveExecutions(data: ApproveExecutionsSet): Promise<boolean> {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.APPROVE_TIMESHEET), data);
    return true;
  }
}
