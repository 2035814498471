import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { ShiftUseCase } from 'pages/shift/core/Modules/Shifts/Application/ShiftUseCase';
import toast from 'react-hot-toast';

const ShiftUseCases = new ShiftUseCase();

const ShiftDescriptionAction = () => {
  const executeGetShiftDescription = async (id: string) => {
    try {
      const result = await ShiftUseCases.invokeGetShiftDescription(id);
      return result;
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeDeleteShift = async (id: string) => {
    try {
      const result = await ShiftUseCases.invokeDeleteShift(id);
      toast.success('Success');
      return result;
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeIs24Cancel = (
    t: TFunction,
    timeZone?: string | undefined,
    startDate?: string | undefined
  ) => {
    return ShiftUseCases.is24hDelete(t, timeZone, startDate);
  };

  return {
    executeGetShiftDescription,
    executeDeleteShift,
    executeIs24Cancel,
  };
};

export default ShiftDescriptionAction;
