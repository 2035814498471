interface BusinessHire {
  hireId: string;
  userId: string;
  userName: string;
  userImage: string;
  status: number;
  postedDate: string;
  statusDate: string | null;
  rating: number;
  ratesNumber: number;
}

interface BusinessHiresResponse {
  businessHires: BusinessHire[];
  totalItems: number;
}

export class HireListGet {
  constructor(public list: BusinessHiresResponse) {}
}
