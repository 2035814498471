interface Location {
  topic: string;
  address: string;
  notificationsTopic: string;
}

interface LocationCoords {
  latitude: number;
  longitude: number;
}

interface ClockInInfo {
  english: string;
  spanish: string;
}

interface PositionTitle {
  english: string;
  spanish: string;
}

interface JobInformation {
  english: string;
  spanish: string;
}

interface Contact {
  name: string;
  role: string;
  phoneNumber: string;
  email: string;
}

interface TemplateDetail {
  id: string;
  businessId: string;
  templateName: string;
  location: Location;
  locationCoords: LocationCoords;
  parkingInfo: string;
  locationInfo: {
    english: string;
    spanish: string;
  } | null;
  clockInInfo: ClockInInfo;
  positionTitle: PositionTitle;
  positionId: string;
  startDate: string; // ISO 8601 date string
  endDate: string; // ISO 8601 date string
  breakTime: number;
  openings: number;
  salaryType: string;
  salaryAmount: number;
  fullTimeOportunity: boolean;
  uniform: string;
  dressCode: string;
  groomingRestrictions: string[];
  jobInformation: JobInformation;
  abilities: string[];
  experience: string;
  contacts: Contact[];
  onlyFavorites: boolean;
  creationDate: string; // ISO 8601 date string
  timeZone: ChambaTimeZoneEnum;
}

export class TemplateDetailGet {
  constructor(public template: TemplateDetail) {}
}
