import instance from 'actions/http';
import { ApplicantShiftDto } from '../Dtos/ApplicantShift.dto';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import {
  ApplicantRejectSet,
  ApplicantShiftGet,
  ApplicantShiftSet,
  ApplicantStatusSet,
} from '../Entities/ApplicantShift.entity';
import dtoToApplicantShift from '../Mappers/DtoToApplicantShift.mapper';
import { UserRepositoryType } from 'pages/shift/core/Infrastructure/Contracts/User.contract';

export class UserRepository implements UserRepositoryType {
  public getShiftApplicants = async (
    d: ApplicantShiftSet
  ): Promise<ApplicantShiftGet | undefined> => {
    const request = await instance.post<ApplicantShiftDto>(
      getEndpoint(API_ENDPOINTS.SEARCH.GET_SHIFT_APPLICANTS),
      d
    );

    return dtoToApplicantShift(request.data);
  };

  public addApplicantToFavorites = async (d: ApplicantStatusSet) => {
    await instance.post(getEndpoint(API_ENDPOINTS.USERS.CREATE_PREFERRED_USER), d);
  };

  public addApplicantToBlock = async (d: ApplicantStatusSet) => {
    await instance.post(getEndpoint(API_ENDPOINTS.USERS.CREATE_BLOCKED_USER), d);
  };

  public rejectApplicant = async (d: ApplicantRejectSet) => {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.POST_EVENT_CHANGE_STATUS), d);
  };
}
