import { ContactFormSchemaType, SaveContactSet } from '../Dtos/ContactForm.dto';

export const contactFormToDto = (data: ContactFormSchemaType, businessId: string): SaveContactSet => {
    return {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        role: data.role,
        businessId,
    }
}