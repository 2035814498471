import Icon from 'assets/icons';
import Invite from 'pages/profile/components/invite';
import Collaborator from 'pages/profile/components/Cards/CollaboratorCard';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { getManagedUsers } from 'store/business/selectors';
import { Role } from 'utils/constants';
import { ProfileContext } from 'pages/profile/provider';
import { useTranslation } from 'react-i18next';

const Members = () => {
  const users = useSelector(getManagedUsers);
  const { addBusinessUser, setAddBusinessUser } = useContext(ProfileContext);

  const onClickAddCard = () => {
    setAddBusinessUser(true);
  };

  const [t] = useTranslation(['Profile']);
  return (
    <div className="">
      {addBusinessUser ? (
        <>
          <Invite />
        </>
      ) : (
        <>
          {!users ? (
            <Skeleton style={{ width: '100%', height: 20, marginTop: 10 }} count={5} />
          ) : (
            <>
              <div
                onClick={onClickAddCard}
                className="py-6 border my-5 rounded-xl text-center cursor-pointer bg-white"
              >
                <span>
                  <Icon size={23.9} icon="add" /> <span className="pl-2">{t('newMember')}</span>
                </span>
              </div>
              {users.map((value, key) => (
                <Collaborator
                  key={key}
                  id={value.id}
                  email={value.email}
                  name={value.name}
                  position={value.position || ''}
                  since={new Date(value.creationDate).toLocaleDateString()}
                  permitions={Role[value.role]}
                  roleNumber={value.role}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Members;
