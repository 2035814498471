import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import PrintQr from './Event/PrintQr';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import IntegrationService from 'actions/integration';

const EventQr = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation('Others');
  const { trackPageView } = useMatomo();

  const [qr, setQr] = useState('');
  const [qrImage, setQrImage] = useState<string>('');
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const qrValidate = () => {
    if (id) {
      setQr(id);
    }
  };

  useEffect(() => {
    trackPageView({
      documentTitle: 'QR Page',
      href: window.location.href,
    });
  }, []);

  useEffect(() => {
    qrValidate();
  }, [qrValidate]);

  useEffect(() => {
    if (qr) {
      IntegrationService.createDynamicLink({
        dynamicLinkInfo: {
          domainUriPrefix: 'https://sharing.lachamba.app',
          link: `https://sharing.lachamba.app/clock-in/${qr}`,
          androidInfo: {
            androidPackageName: 'com.chambaapp',
          },
          iosInfo: {
            iosBundleId: 'org.chamba.co',
          },
        },
      }).then((r) => {
        QRCode.toString(
          r.shortLink,
          {
            width: 300,
            margin: 2,
          },
          (_, url) => {
            const svgString = encodeURIComponent(url);
            const dataUri = `url("data:image/svg+xml,${svgString}")`;
            setQrImage(dataUri);
          }
        );
      });
    }
  }, [qr]);

  return (
    <Fragment>
      <div className="flex w-full mt-20 sm:mb-52 text-primary px-10 sm:px-20 items-center">
        <div className="w-full">
          <div className="flex justify-center self-center">
            <div>
              <h1 className=" text-2xl font-bold mb-5 text-center">{t('scanQr')}</h1>
              <p className="mb-7 text-center text-xl max-w-md">
                {t('scanDescription1')}{' '}
                <b className=" text-secondary-500">{t('scanDescription1')}</b>{' '}
              </p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center',
                }}
              >
                {qrImage && (
                  <div
                    style={{
                      background: qrImage,
                      width: 300,
                      height: 300,
                    }}
                  />
                )}
              </div>
              <div className="mt-10 justify-center flex">
                <button
                  type="submit"
                  className=" w-72 rounded-md py-1 text-base font-semibold bg-primary text-white"
                  onClick={handlePrint}
                >
                  {t<string>('print')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <PrintQr image={qrImage} />
        </div>
      </div>
    </Fragment>
  );
};

export default EventQr;
