import { ProfesionalsContext } from 'pages/professionals/context';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

const InputName = () => {
  const { setName } = useContext(ProfesionalsContext);
  const [t] = useTranslation('ApplicantsProfile');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeoutRef = useRef<any>();
  const [query, setQuery] = useState('');

  const onChangeBar = (text: string) => {
    setName(text);
  };

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;

      const barFunction = debounce(onChangeBar, 100);
      barFunction(query);
    }, 500);
  }, [query]);

  return (
    <input
      value={query}
      placeholder={t('name')}
      onChange={(e) => setQuery(e.target.value)}
      className="rounded-lg mt-4 ml-4 font-medium text-sm sm:text-base appearance-none relative block w-full px-4 py-2 border border-grayBack bg-white placeholder-primary text-gray-900 focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
    />
  );
};
export default InputName;
