import { UseFloatingOptions } from '@floating-ui/react';
import FloatingDialog from 'components/FloatingDialog';
import { twMerge } from 'tailwind-merge';

type ModalMoreEventsType = {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  children: JSX.Element | JSX.Element[];
  customClassname?: string;
  className?: string;
  classNameContainer?: string;
} & UseFloatingOptions;

const ModalV2 = ({
  isOpen,
  setIsOpen,
  children,
  customClassname,
  className,
  classNameContainer,
  ...props
}: ModalMoreEventsType) => {
  return (
    <FloatingDialog setIsOpen={setIsOpen} isOpen={isOpen} className={classNameContainer} {...props}>
      <div
        className={
          customClassname
            ? customClassname
            : twMerge('rounded-lg shadow-md bg-white m-auto p-6 ', className)
        }
      >
        {children}
      </div>
    </FloatingDialog>
  );
};

export default ModalV2;
