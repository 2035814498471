import { ShiftUseCase } from 'pages/shift/core/Modules/Shifts/Application/ShiftUseCase';
import {
  ShiftPaginatedRequest,
  ShiftsCalendarSet,
} from 'pages/shift/core/Modules/Shifts/Domain/Entities/ShiftsCalendarSet';
import useUser from 'utils/hooks/useUser';

const ShiftUseCases = new ShiftUseCase();

const ShiftsCalendarAction = () => {
  const {
    business: { id },
  } = useUser();

  const executeGetShiftCalendar = (data: Omit<ShiftsCalendarSet, 'businessId'>) => {
    return ShiftUseCases.invokeGetCalendarShifts({
      ...data,
      filters: {
        ...data.filters,
        businessId: id,
      },
    });
  };
  const executeGetShiftPaginated = (data: ShiftPaginatedRequest) => {
    return ShiftUseCases.getShiftPaginated({
      ...data,
    });
  };

  return { executeGetShiftCalendar, executeGetShiftPaginated };
};

export default ShiftsCalendarAction;
