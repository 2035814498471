import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import Recenter from 'components/Map/recenter';
import 'leaflet/dist/leaflet.css';
import { useState } from 'react';
import Refresh from './refresh';

interface IMapComponent {
  latitude: number;
  longitude: number;
  width?: number;
  whenReady?: boolean;
  height?: number;
}

const MapComponent = ({
  latitude,
  longitude,
  width,
  whenReady,
  height,
}: IMapComponent): JSX.Element => {
  const [ready, setReady] = useState(false);

  const onReady = () => setReady(true);

  return (
    <div
      style={{
        width: width ? width : '100%',
        height: height ?? 300,
        zIndex: -1,
      }}
    >
      <MapContainer
        whenReady={whenReady ? onReady : undefined}
        center={{
          lat: latitude,
          lng: longitude,
        }}
        zoom={20}
        scrollWheelZoom={false}
      >
        <Recenter lat={latitude} lng={longitude} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {ready && <Refresh />}
        <Marker
          key={latitude}
          position={{
            lat: latitude,
            lng: longitude,
          }}
        ></Marker>
      </MapContainer>
    </div>
  );
};

export default MapComponent;
