import * as React from 'react';
import ProfileCard from '../ProfileCard';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProfileInformation from '../ProfileInformation';
import useWindowDimensions from 'utils/hooks/window';
import { Menu } from '@headlessui/react';
import { useGetPros, useMutationPros } from 'pages/professionals/helper';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Icon from 'assets/icons';

const RecentlyWorked = (): JSX.Element => {
  const { height } = useWindowDimensions();
  const [t] = useTranslation('ApplicantsProfile');
  const [openProfileModal, setOpenProfileModal] = React.useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null);

  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Professionals',
      href: '/recenlty',
    });
  }, []);

  const { onEndReached, refreshList, items, generalList } = useGetPros('recent');

  const openProfileModalHandler = (userId: string) => {
    setOpenProfileModal(true);
    setSelectedUserId(userId);
  };

  const { addPreferredUser, blockUser } = useMutationPros();

  return (
    <div className="bg-white rounded-xl w-full p-5 xl:p-10">
      <div className="w-full">
        <h3 className="font-bold text-xl text-primary text-center">
          {t('recentlyWorked.recentlyWorkedWith')}
        </h3>
        <div className="bg-lightBlue flex px-5 py-3 rounded-2xl mt-4 mb-4">
          <div>
            <Icon icon={'about'} size={25} className="mr-3 mt-1" />
          </div>
          <p className="text-primary">
            {t('clickOn')} “ <Icon icon={'dots'} size={15} /> “ {t('addPreferred')}
          </p>
        </div>
        <div className="h-auto overflow-y-scroll scrollbar-hide" id="scrollableDiv">
          <InfiniteScroll
            dataLength={items.length}
            next={onEndReached}
            hasMore={items.length < generalList.totalItems}
            loader={<></>}
            height={height * 0.68}
            scrollableTarget="scrollableDiv"
            className=""
          >
            {items.length > 0 ? (
              <React.Fragment>
                {items.map((jobseeker) => (
                  <ProfileCard
                    key={jobseeker.userId}
                    name={jobseeker.name}
                    totalShifts={jobseeker.totalShifts}
                    userImage={jobseeker.userImage}
                    userId={jobseeker.userId}
                    preferredUserId={jobseeker.preferredUserId}
                    showProfile={true}
                    rating={jobseeker.rating}
                    ratesNumber={jobseeker.ratesNumber}
                    openProfile={() => openProfileModalHandler(jobseeker.userId)}
                    onRefresh={refreshList}
                    menuItems={
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? 'bg-primary text-white' : 'text-primary'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              onClick={() => addPreferredUser(jobseeker.userId)}
                            >
                              {t('actions.addFavorite')}
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? 'bg-primary text-white' : 'text-primary'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              onClick={() => blockUser(jobseeker.userId)}
                            >
                              {t('actions.block')}
                            </button>
                          )}
                        </Menu.Item>
                      </>
                    }
                  />
                ))}
              </React.Fragment>
            ) : (
              <div className="flex justify-center items-center h-full mt-5">
                <h3 className="text-2xl font-bold text-gray-500">{t('emptyPros')}</h3>
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
      <ProfileInformation
        isOpen={openProfileModal}
        closeModal={() => {
          setOpenProfileModal(false);
          setSelectedUserId(null);
        }}
        userId={selectedUserId}
      />
    </div>
  );
};

export default RecentlyWorked;
