import { useTranslation } from 'react-i18next';
import { ReturnImageCard } from 'utils';
import { REACT_APP_CDN } from 'utils/enviroment';

interface ICard {
  number: string;
  name: string;
  bank: string;
  date: string;
  cvv?: number;
  using?: boolean;
  funding: string;
  id: string;
  loading: boolean;
  onSetFavoriteCard: (value: string) => void;
  onDeleteCard: (value: string) => void;
}

const Cards = ({
  id,
  number,
  name,
  bank,
  date,
  using,
  funding,
  loading,
  onSetFavoriteCard,
  onDeleteCard,
}: ICard): JSX.Element => {
  const onClickFavoriteCard = () => {
    onSetFavoriteCard(id);
  };

  const onClickDeleteCard = () => {
    onDeleteCard(id);
  };
  const [t] = useTranslation(['Payment']);

  return (
    <div className="text-primary mb-8 xl:w-full xl:flex xl:justify-center">
      <div className="flex  items-center flex-wrap justify-center lg:justify-start lg:flex-nowrap">
        <div className={`bg-[#4339F2] text-white w-72 py-2 px-4 rounded-lg`}>
          <div className="flex justify-between">
            <p className="text-base uppercase">{bank}</p>
            <img className="mt-2" src={`${REACT_APP_CDN}/img/payments/Wifi.svg`} alt="" />
          </div>
          <p className="text-xs mt-8 font-light text-left">{name}</p>
          <p className="text-lg text-left">**** **** **** {number}</p>
          <div className="flex justify-between mt-5">
            <p className="text-sm mt-2 font-light">{date}</p>
            <img className=" mb-1 mt-2 w-10" src={ReturnImageCard(bank)} alt="" />
          </div>
        </div>
        <div className="lg:w-max w-full lg:ml-8 mt-4 lg:mt-0">
          <p className="text-xl lg:text-3xl font-semibold lg:mr-40 capitalize">
            {funding} {t('card')} # **** **** **** {number}
          </p>
          <div className="text-lg font-semibold mt-3 flex justify-center lg:justify-start">
            {using ? (
              <button
                disabled={loading}
                className="py-1 w-1/4 lg:w-1/3 min-w-[130px] rounded-md mr-3"
              >
                {t('using')}
              </button>
            ) : (
              <button
                disabled={loading}
                onClick={onClickFavoriteCard}
                className="bg-primary py-1 text-white min-w-[130px] w-1/4 lg:w-1/3 rounded-md mr-3"
              >
                {t('use')}
              </button>
            )}

            <button
              onClick={onClickDeleteCard}
              disabled={loading}
              className="bg-red py-1 text-white min-w-[130px] w-1/4 lg:w-1/3 rounded-md"
            >
              {t('delete')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
