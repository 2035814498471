import {
  Table,
  TableBody,
  TableHeader,
  TableLoading,
  TablePaginate,
  TableTd,
  TableTr,
} from 'components/Table';
import IndexInvoicePresenter from './Index.Presenter';
import { formatWithTimezone } from 'utils/dates/formatWithTimezone';
import ModalBankInfo from 'pages/invoice/shared/ModalBankInfo';
import { StatusInvoice } from 'pages/invoice/core/Modules/Invoices/Domain/Dtos/InvoiceList.dto';
import { cn } from 'utils';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import InvoiceDetailScreen from '../InvoiceDetail/InvoiceDetail.Screen';
import useUser from 'utils/hooks/useUser';

const IndexInvoiceScreen = () => {
  const presenter = IndexInvoicePresenter();
  const [t] = useTranslation(['Invoice']);
  const {
    business: { invoicePayment },
  } = useUser();

  return (
    <div className="w-full bg-white  p-4 sm:p-11">
      <div className="flex justify-end text-primary font-normal">
        {/* <div className="py-2 px-10 bg-secondary-200 border-secondary-500 rounded-md border flex items-center">
          <div>
            <p>My balance</p>
            <p>$10000</p>
          </div>
          <div className="ml-2">
            <Icon icon="add" size={20} />
          </div>
        </div> */}
        <ModalBankInfo />
      </div>
      <h1 className="text-2xl font-bold mb-4 mt-5 ">
        {t('title')} {invoicePayment} {t('days')}
      </h1>

      <Table>
        <TableHeader
          items={[
            t('invoiceNumber'),
            t('time'),
            t('issue'),
            t('dueDate'),
            t('bill'),
            t('status'),
            '',
          ]}
        />

        <TableBody>
          <>
            {presenter.getInvoices.data?.list.businessInvoices.map((invoice, key) => {
              let color = 'border-neutral-800 bg-neutral-200 text-neutral-800';
              let text = t('pending');

              if (invoice.status === StatusInvoice.PAID) {
                color = 'border-secondary-500 border bg-secondary-200 text-secondary-500';
                text = t('paid');
              }

              if (invoice.status === StatusInvoice.PAST_DUE) {
                color = 'border-warnings-600 bg-warnings-200 text-warnings-600';
                text = t('due');
              }

              return (
                <TableTr key={key}>
                  <TableTd> {invoice.consecutive}</TableTd>
                  <TableTd className="text-secondary-500 underline cursor-pointer">
                    <InvoiceDetailScreen
                      selectedId={invoice.id}
                      label={`${formatWithTimezone(t, 'DD', invoice.startDate)} - ${formatWithTimezone(t, 'DD', invoice.endDate)}`}
                    />
                  </TableTd>
                  <TableTd>{formatWithTimezone(t, 'DD', invoice.invoiceDate)}</TableTd>
                  <TableTd>{formatWithTimezone(t, 'DD', invoice.dueDate)}</TableTd>
                  <TableTd>${invoice.totalAmount.toFixed(2)}</TableTd>
                  <TableTd>
                    <div className={cn('border rounded-full py-1', color)}>{text}</div>
                  </TableTd>
                  <TableTd onClick={() => presenter.onClickExport(invoice.id)}>
                    <Button size="xs" name={t('export')} />
                  </TableTd>
                </TableTr>
              );
            })}
          </>
        </TableBody>
      </Table>

      <>{presenter.getInvoices.isLoading && <TableLoading />}</>

      <TablePaginate
        pageCount={Math.ceil((presenter?.getInvoices?.data?.list.totalItems ?? 0) / 10)}
        pageRangeDisplayed={10}
        onPageChange={presenter.handlePageClick}
      />

      {!presenter.getInvoices.data?.list.businessInvoices.length &&
        !presenter.getInvoices.isLoading && (
          <p className="w-full text-center mt-3">{t('noElement')}</p>
        )}
    </div>
  );
};

export default IndexInvoiceScreen;
