import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import { Buffer } from 'buffer';
import { IDataObject } from '.';
import { REACT_APP_CDN } from 'utils/enviroment';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const HomeScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const [t] = useTranslation(['Register']);
  const [searchParams] = useSearchParams();
  const [dataUrl, setDataUrl] = useState<string>();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Welcome register',
      href: window.location.href,
    });
  }, []);

  const dataObject = useMemo(() => {
    if (dataUrl) {
      const result: IDataObject = JSON.parse(Buffer.from(dataUrl, 'base64').toString());
      return result;
    }
  }, [dataUrl]);

  const onClickRegister = () => {
    navigate(RoutesConstant.unsecure.invitedRegister + `?data=${dataUrl}`);
  };

  useEffect(() => {
    const result = searchParams.get('data');
    if (result) {
      setDataUrl(result);
    } else {
      navigate(RoutesConstant.unsecure.home, { replace: true });
    }
  }, [searchParams]);

  return (
    <div className="w-full flex justify-center items-center text-primary h-screen bg-gray-400">
      <div className="flex w-full flex-wrap justify-center lg:flex-nowrap lg:justify-around items-center">
        <div className="w-full lg:w-6/12 mt-1 px-24">
          <h2 className=" text-3xl sm:text-5xl font-bold mb-5">{t('welcome')}</h2>
          <div className="flex flex-wrap">
            <p className="text-xl sm:text-2xl lg:text-xl w-full mb-5">
              {t('invited')} {'"'} {dataObject?.name}
              {'"'} {t('invited2')}
            </p>
          </div>
          <button
            onClick={onClickRegister}
            className="text-lg rounded-full lg:block hidden sm:text-xl py-2 bg-primary mr-6 text-white font-medium w-full mt-3"
          >
            {t('continue')}
          </button>
        </div>
        <div className="w-9/12 lg:w-6/12 text-center  justify-center h-screen bg-secondary-200 px-11 hidden sm:flex flex-col">
          <img src={`${REACT_APP_CDN}/register/register-invited.svg`} alt="chef" />
          <p className="mt-6 font-bold text-xl">{t('descriptionImage')}</p>
        </div>
        <div className="w-full lg:hidden flex mt-8 justify-center">
          <button
            onClick={onClickRegister}
            className="text-lg sm:text-2xl py-1 bg-primary mr-6 text-white rounded-lg font-medium w-[45%]"
          >
            {t('continue')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
