import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getLogged } from 'store/business/selectors';
import { RoutesConstant } from 'utils/constants';

interface IRequireAuth {
  children: JSX.Element;
}

const NotRequireAuth = ({ children }: IRequireAuth) => {
  const logged = useSelector(getLogged);
  if (logged === true) {
    return <Navigate to={RoutesConstant.secure.shift} />;
  } else {
    return children;
  }
};

export default NotRequireAuth;
