import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import Step1 from 'pages/forgot/components/Steps/step1';
import Step2 from 'pages/forgot/components/Steps/step2';
import Step3 from 'pages/forgot/components/Steps/step3';
import Step4 from 'pages/forgot/components/Steps/step4';
import { useEffect, useState } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Forgot = (): JSX.Element => {
  const [instance, setInstance] = useState<Partial<StepWizardChildProps>>();
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState('0');

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Forgot password',
      href: window.location.href,
    });
  }, []);

  const NextStep = () => {
    if (instance && instance.nextStep) {
      instance.nextStep();
    }
  };

  const PreviousStep = () => {
    if (instance && instance.previousStep) {
      instance.previousStep();
    }
  };

  return (
    <StepWizard instance={setInstance} isLazyMount>
      <Step1 nextStep={NextStep} setEmail={setEmail} previousStep={PreviousStep} />
      <Step2 nextStep={NextStep} email={email} setCode={setCode} previousStep={PreviousStep} />
      <Step3 nextStep={NextStep} code={code} email={email} previousStep={PreviousStep} />
      <Step4 />
    </StepWizard>
  );
};
export default Forgot;
