import { useEffect, useMemo, useState } from 'react';
import TimeSheetAction from './IndexPage.Action';
import { DateTime } from 'luxon';
import { ListSheetGet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/ListSheet.entity';
import { ListShiftSet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/ListShiftSet';
import { useQuery } from '@tanstack/react-query';
import { CreateIndividualRateSet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/CreateIndividualRateSet';
import {
  BlockUserSet,
  CreatePreferredUserSet,
} from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/User.entity';

export const TimeSheetPresenter = () => {
  const [searchText, setSearchText] = useState('');
  const timesheetAction = TimeSheetAction();
  const [sortNameAsc, setSortNameAsc] = useState<IItems>();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [date, setDate] = useState<Date | null>(new Date());
  const [sheetData, setSheetData] = useState<ListSheetGet>();
  const [tipValue, setTipValue] = useState<string>();
  const [selectedExecutions, setSelectedExecutions] = useState<string[]>([]);
  const [page, setPage] = useState(0);

  const [selectedDate, setSelectedDate] = useState({
    startDate: DateTime.now().toISO(),
    endDate: DateTime.now().toISO(),
    allowChanges: false,
  });
  const onClickOpenCalendar = () => setOpenCalendar(true);
  const onClickOutsideCalendar = () => setOpenCalendar(false);

  const onAddDays = () => {
    const startDateParsed = DateTime.fromJSDate(startDate ?? new Date());

    const dayWeek: number = startDateParsed.weekday;

    const daysForNextMonday: number = (8 - dayWeek) % 7;
    const nextMonday: DateTime = startDateParsed.plus({ days: daysForNextMonday });
    const nextSunday: DateTime = nextMonday.plus({ days: 7 });

    setStartDate(nextSunday.toJSDate());
  };

  const onDecrementDays = () => {
    const startDateParsed = DateTime.fromJSDate(startDate ?? new Date());

    const weekDay: number = startDateParsed.weekday;
    const daysFromMonday: number = (weekDay - 1 + 8) % 7;
    const mondayActualWeek: DateTime = startDateParsed.minus({ days: daysFromMonday });
    const daysUntilSundayPrevious: number = (weekDay - 1 + 8) % 7;

    const sundayPreviousWeek: DateTime = mondayActualWeek
      .minus({ days: 1 })
      .minus({ days: daysUntilSundayPrevious });
    setStartDate(sundayPreviousWeek.toJSDate());
  };

  const filterTable = useMemo<Omit<ListShiftSet, 'businessId'>>(() => {
    return {
      dayEnd: selectedDate.endDate,
      dayStart: selectedDate.startDate,
      filters: {
        sortNameAsc: sortNameAsc?.id === '1',
        ...(searchText ? { name: searchText } : undefined),
      },
      page: page + 1,
    };
  }, [selectedDate, page, sortNameAsc, searchText, searchText]);

  const { data: timeShiftSheetList, refetch } = useQuery(['get-timesheet', filterTable], () =>
    timesheetAction.executeGetShiftList(filterTable)
  );

  useEffect(() => {
    if (startDate) {
      handleTimeSheetList(DateTime.fromJSDate(startDate))?.then((e) => {
        setSheetData(e);
        if (e.timesheetWeekItems.length > 0) {
          const index = e.timesheetWeekItems.findIndex((obj) =>
            sameDay(
              DateTime.fromJSDate(date ?? new Date()).startOf('day'),
              DateTime.fromISO(obj.dayStart).startOf('day')
            )
          );
          setSelectedDate({
            startDate: e.timesheetWeekItems[index].dayStart,
            endDate: e.timesheetWeekItems[index].dayEnd,
            allowChanges: e.timesheetWeekItems[index].allowChanges,
          });
        }
      });
    }
  }, [startDate, date]);

  const sameDay = (a: DateTime, b: DateTime): boolean => {
    return a.hasSame(b, 'day') && a.hasSame(b, 'month') && a.hasSame(b, 'year');
  };

  const handleTimeSheetList = (weekDate: DateTime) => {
    try {
      setIsLoading(true);
      return timesheetAction.executeGetListSheet(weekDate);
    } finally {
      setIsLoading(false);
    }
  };

  const createIndividualRate = (data: Omit<CreateIndividualRateSet, 'businessId'>) => {
    timesheetAction.executeCreateIndividualRate(data).then(() => {
      refetch();
    });
  };

  const updateTip = (id: string) =>
    timesheetAction
      .executeUpdateTip({
        id,
        tips: tipValue ? parseFloat(tipValue) : 0,
      })
      .then(() => {
        refetch();
        setTipValue('');
      });

  const onClickSelectAllExecutions = (selected: boolean) => {
    if (selected) {
      const selectedIds = timeShiftSheetList?.resultListShift.timesheetItems.map(
        (e) => e.executionId
      );

      if (selectedIds) {
        setSelectedExecutions(selectedIds);
      }
    } else {
      setSelectedExecutions([]);
    }
  };

  const onClickExecution = (selected: boolean, executionId: string) => {
    if (selected) {
      setSelectedExecutions([...selectedExecutions, executionId]);
    } else {
      setSelectedExecutions([...selectedExecutions.filter((e) => e !== executionId)]);
    }
  };

  const onApproveSelected = () =>
    timesheetAction.executeApprovedExecutions(selectedExecutions).then(() => refetch());

  const onApprove = (id: string) =>
    timesheetAction.executeApprovedExecutions([id]).then(() => refetch());

  const onCreatePreferredUser = (d: CreatePreferredUserSet) =>
    timesheetAction.executeCreatePreferredUser(d);

  const onBlockUser = (d: BlockUserSet) => timesheetAction.executeBlockUser(d);

  return {
    onClickOutsideCalendar,
    handleTimeSheetList,
    isLoading,
    onClickOpenCalendar,
    openCalendar,
    setStartDate,
    startDate,
    sheetData,
    timeShiftSheetList,
    setSelectedDate,
    setPage,
    selectedDate,
    onAddDays,
    onDecrementDays,
    setSortNameAsc,
    sortNameAsc,
    searchText,
    setSearchText,
    timesheetAction,
    createIndividualRate,
    setTipValue,
    tipValue,
    updateTip,
    onClickSelectAllExecutions,
    selectedExecutions,
    onClickExecution,
    onApproveSelected,
    onApprove,
    onCreatePreferredUser,
    onBlockUser,
    refetch,
    setDate,
    date,
  };
};
