import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ResetPassword } from 'actions/auth.api';
import toast from 'react-hot-toast';
import { REACT_APP_CDN } from 'utils/enviroment';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import Input from 'components/InputPost';

interface IForgot {
  code: string;
}

const Step = ({
  nextStep,
  setCode,
  email,
}: IStep & { email: string; setCode: (v: string) => void }): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgot>();
  const [t] = useTranslation(['Forgot']);
  const { trackPageView } = useMatomo();

  const onSubmit: SubmitHandler<IForgot> = (data) => {
    const removeCodeSpaces = data.code.replace(/\s/g, '');
    setCode(removeCodeSpaces);
    nextStep();
  };

  const onReSend = async () => {
    try {
      await ResetPassword(email);
      toast.success('Revisar correo');
    } catch (e) {
      const error = e as IError;
      toast.error(error.message);
    }
  };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Forgot Password Step 2',
      href: window.location.href,
    });
  }, []);

  return (
    <div className="flex h-[100vh] bg-[#E5E5E5] w-full">
      <div className="w-full lg:w-1/2 p-10 sm:p-20 xl:p-40 flex self-center">
        <div className="w-full">
          <p className="w-full text-lg md:text-xl mt-3 mb-5 text-primary">
            {t('sent')} <a className="text-secondary-500">{email}</a>
            {`, ${t('write')}`}
          </p>
          <div className="w-full flex justify-center md:hidden mb-3">
            <img className="w-80" src={`${REACT_APP_CDN}/Coder.svg`} alt="" />
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full">
              <Input
                customStyle="mt-4 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                label={'code'}
                name="code"
                placeholder={'Code'}
                register={register}
                type="text"
                errors={errors.code}
                validation={{
                  required: true,
                }}
              />
            </div>

            <div className="flex flex-wrap px-12 justify-between md:justify-between my-5 md:my-10 w-full ">
              <button
                className="w-[40%] rounded-full py-2 font-semibold bg-primary text-white"
                type="submit"
              >
                {t('confirm')}
              </button>
              <button
                className="w-[40%] md:my-0 rounded-full py-2 font-semibold border border-primary"
                onClick={onReSend}
                type={'button'}
              >
                {t('resend')}
              </button>
            </div>
            <div className="flex items-center justify-center ">
              <p className="text-sm font-semibold mr-1">{t('havaAnAc')} </p>
              <Link
                to={RoutesConstant.unsecure.login}
                className="text-[#117AF4] py-1 rounded-md text-center text-sm font-semibold underline underline-offset-2"
              >
                {t('logHere')}
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 bg-lightBlue/30 hidden lg:flex justify-center">
        <div className="self-center ">
          <img src={`${REACT_APP_CDN}/Coder.svg`} alt={`${REACT_APP_CDN}/Coder.svg`} />
        </div>
      </div>
    </div>
  );
};

export default Step;
