import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { DraftUseCase } from 'pages/postShift/core/Modules/Checkout/Application/DraftUseCaes';
import { SummaryUseCases } from 'pages/postShift/core/Modules/Checkout/Application/SummaryUseCase';
import { TemplateUseCase } from 'pages/postShift/core/Modules/Checkout/Application/TemplateUseCases';
import { DraftDataGet } from 'pages/postShift/core/Modules/Checkout/Domain/Entities/DraftDataGet.entity';
import { entityToCreateTemplateForm } from 'pages/postShift/core/Modules/Checkout/Domain/Mappers/Checkout.mapper';
import toast from 'react-hot-toast';
import useUser from 'utils/hooks/useUser';

const draftUseCase = new DraftUseCase();
const summaryUseCase = new SummaryUseCases();
const templateUseCase = new TemplateUseCase();

const CheckoutInformationAction = () => {
  const {
    business: { isMarkdown, serviceFee, id },
    cognitoUser: { id: userId },
  } = useUser();

  const invokeDeleteDraft = async (id: string) => {
    try {
      await draftUseCase.invokeDelete(id);
    } catch {
      toast.error('Something went wrong');
    }
  };

  const invokeCalcSummary = (draft?: DraftDataGet) => {
    return summaryUseCase.invokeCalculateTotal(draft, isMarkdown, serviceFee);
  };

  const invokeCreateTemplate = async (data: DataFormType, templateName: string) => {
    try {
      await templateUseCase.invokeCreateTemplate(
        entityToCreateTemplateForm(data, id, userId, templateName)
      );
    } catch {
      toast.error('Something went wrong');
    }
  };

  return {
    invokeDeleteDraft,
    invokeCalcSummary,
    invokeCreateTemplate,
  };
};

export default CheckoutInformationAction;
