import * as React from 'react';
import { useTranslation } from 'react-i18next';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import { useSelector } from 'react-redux';
import { getBusiness } from 'store/business/selectors';
import BusinessService from 'actions/business';
import { useMutation } from '@tanstack/react-query';
import useUser from 'utils/hooks/useUser';
import { useAppDispatch } from 'store';
import toast from 'react-hot-toast';
import { setPaymentOption } from 'store/business';
import { PaymentOptionEnum } from 'actions/business/model';
import { IChangePayment, IChargeCard, Option } from 'actions/shift/model';
import { LAST_EMAIL } from 'utils/persistor';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import LoadingV2 from 'components/LoadingV2';
import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import FloatingDialog from 'components/FloatingDialog';
import { PostDraftDto } from 'pages/postShift/core/Modules/Checkout/Domain/Dtos/PostDraft.dto';
import { TemplateHubContext } from 'pages/postShift/core/Infrastructure/Providers/TemplateHub.provider';
import { SummaryUseCases } from 'pages/postShift/core/Modules/Checkout/Application/SummaryUseCase';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import ModalPaymentStep3 from './steps/step3';

export interface ModalPayment {
  isOpen: boolean;
  setOpenModalPayment: (e: boolean) => void;
}

export const StepPaymentContext = React.createContext<
  Partial<StepWizardChildProps> & IChangePayment & IChargeCard
>({} as Partial<StepWizardChildProps> & IChangePayment & IChargeCard);

/**
 * @deprecated Must be updated with new pattern
 */
const ModalPayment = ({ isOpen, setOpenModalPayment }: ModalPayment): JSX.Element => {
  const {
    business: { id, paymentOption, isMarkdown, serviceFee },
    cognitoUser: { id: cognitoId },
  } = useUser();

  const summary = new SummaryUseCases();

  const [paymentId, setPaymentId] = React.useState('');
  const dispatch = useAppDispatch();
  const contextForm = React.useContext(DataFormContext);
  const hubContext = React.useContext(TemplateHubContext);

  const [instance, setInstance] = React.useState<Partial<StepWizardChildProps>>({});
  const [step, setStep] = React.useState(1);
  const [selectedPayment, setSelectedPayment] = React.useState<PaymentOptionEnum>(
    PaymentOptionEnum.NotSelected
  );

  const [email, setEmail] = React.useState<Option[]>([]);
  const business = useSelector(getBusiness);

  const [t] = useTranslation(['Shifts']);

  const { mutateAsync, isLoading } = useMutation(BusinessService.postEvent);
  const { mutateAsync: mutatePaymentOption } = useMutation(BusinessService.updatePaymentOption);

  const { trackEvent } = useMatomo();
  const navigate = useNavigate();

  React.useEffect(() => {
    trackEvent({
      action: 'open-modal-payment',
      category: 'Shift',
    });
  }, []);

  React.useEffect(() => {
    if (!isOpen) {
      setStep(1);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (instance && instance.goToStep) {
      if (paymentOption === PaymentOptionEnum.AutoCharge) {
        instance.goToStep(2);
        setSelectedPayment(PaymentOptionEnum.AutoCharge);
      } else if (paymentOption) {
        instance.goToStep(3);
        setSelectedPayment(paymentOption);
      }
    }
  }, [business, instance, paymentOption]);

  const onBack = () => {
    setStep(1);
    setOpenModalPayment(false);
  };

  const onBook = React.useCallback(
    async (paymentIdParam?: string) => {
      if (paymentOption === PaymentOptionEnum.NotSelected) {
        if (email.length === 0) return toast.error('Select an email');
        dispatch(setPaymentOption(selectedPayment));
        if (selectedPayment === PaymentOptionEnum.AutoCharge) {
          await mutatePaymentOption({
            id,
            paymentOption: selectedPayment,
            invoiceEmails: email.map((e) => e.value),
          });
        } else {
          await mutatePaymentOption({
            id,
            invoiceEmails: email.map((e) => e.value),
            paymentOption: selectedPayment,
          });
        }
      }

      if (hubContext?.draft) {
        const postEvent: PostDraftDto = {
          id: hubContext.draft.resultDraftData.id,
          paymentTotal:
            summary.invokeCalculateTotal(hubContext.draft, isMarkdown, serviceFee).total ?? 0,
          shifts: hubContext?.draft?.resultDraftData.shifts.map((v) => ({
            id: v.id,
            openings: v.openings,
          })),
          ...(paymentIdParam && { paymentMethod: paymentIdParam }),
          ...(paymentId && { paymentMethod: paymentId }),
        };

        localStorage.setItem(LAST_EMAIL, JSON.stringify(email));
        mutateAsync(postEvent).then(() => {
          toast.success(t('create.success'));
          contextForm.setDataForm({});
          setOpenModalPayment(false);
          navigate(RoutesConstant.secure.shift);
        });
      }
    },
    [id, cognitoId, email, selectedPayment, paymentId, paymentOption, contextForm.dataForm]
  );

  const isReady = React.useMemo(() => {
    if (email.length === 0) {
      return true;
    }

    if (step === 3 && selectedPayment !== PaymentOptionEnum.AutoCharge && email.length > 0) {
      return false;
    }

    if (step === 2 && selectedPayment === PaymentOptionEnum.AutoCharge) {
      return false;
    }

    return true;
  }, [step, selectedPayment, email]);

  return (
    <StepPaymentContext.Provider
      value={{
        ...instance,
        email,
        setEmail,
        selectedPayment,
        setSelectedPayment,
        paymentId,
        setPaymentId,
      }}
    >
      <FloatingDialog
        isOpen={isOpen}
        setIsOpen={setOpenModalPayment}
        className={`bg-white p-5 rounded-md max-w-4xl max-h-[98vh] overflow-y-auto overflow-x-hidden`}
      >
        <div className="w-full px-2 sm:px-11 py-9 sm:pl-7 bg-white shadow-xl mb-9 rounded-2xl">
          <StepWizard
            instance={setInstance}
            transitions={{}}
            onStepChange={(e) => setStep(e.activeStep)}
          >
            <Step1 setPaymentMode={setSelectedPayment} />
            <Step2 />
            <ModalPaymentStep3 />
          </StepWizard>
        </div>
        <React.Fragment>
          <div className="w-full px-2 sm:px-0 md:pl-7 bg-white shadow-xl mb-9 rounded-2xl">
            <div>
              <div className="py-9 px-4 md:px-14 font-base md:text-xl text-primary">
                <p>{t('paymentModal.info')}</p>
              </div>
            </div>
          </div>
          <div className="w-full px-4 sm:px-0 pl-7 bg-white shadow-xl mb-9 rounded-2xl">
            <div className=" ">
              <div className="pt-8 px-4 md:px-11 text-primary">
                <h1 className="mb-7 font-bold text-xl md:text-3xl">
                  {t('paymentModal.cancellation')}
                </h1>
                <li>{t('paymentModal.policyOne')}</li>
                <li>{t('paymentModal.policyTwo')}</li>
                <p className="pb-10">
                  {t('paymentModal.agreeTerms')}
                  <a className="text-secondary-500">{t('paymentModal.terms')} </a>{' '}
                  {t('paymentModal.and')}
                  <a className="text-secondary-500" href="">
                    {t('paymentModal.cancellation')}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button
              onClick={() => onBook()}
              disabled={isReady || isLoading}
              className="bg-primary text-white w-full rounded-full py-3 flex items-center justify-center"
            >
              {isLoading && <LoadingV2 />}
              <p className="ml-3">{t('chambaPros')}</p>
            </button>
          </div>
          {step > 1 && (
            <div className="w-full flex justify-center">
              <button
                onClick={() => onBack()}
                className="bg-white text-black w-full rounded-full py-3 mt-5"
              >
                {t('back')}
              </button>
            </div>
          )}
          {step === 1 && (
            <div className="w-full flex justify-center">
              <button
                onClick={() => setOpenModalPayment(false)}
                className="bg-white text-black w-full rounded-full py-3 mt-5"
              >
                {t('close')}
              </button>
            </div>
          )}
        </React.Fragment>
      </FloatingDialog>
    </StepPaymentContext.Provider>
  );
};

export default ModalPayment;
