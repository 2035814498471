import { InferType, array, bool, number, object, string } from 'yup';

export const ApplicantShiftSchema = object({
  shiftApplicants: array()
    .required()
    .of(
      object({
        applicationId: string().required(),
        userId: string().required(),
        name: string().required(),
        userImage: string().required(),
        status: number().required(),
        phoneNumber: string().required(),
        applicationDate: string().required(),
        cancelledDate: string().required().nullable(),
        clockIn: string().required().nullable(),
        clockOut: string().required().nullable(),
        breakStart: string().required().nullable(),
        breakEnd: string().required().nullable(),
        breakTime: number().required().nullable(),
        totalCost: number().required().nullable(),
        shiftFinished: bool().required(),
        totalShifts: number().required(),
        isPreferred: bool().required(),
        isBlocked: bool().required(),
        estTotalCost: number().required(),
      })
    ),

  totalItems: number().required(),
}).required();

export type ApplicantShiftDto = InferType<typeof ApplicantShiftSchema>;
