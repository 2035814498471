import { ContactFormSchemaType } from '../Dtos/ContactForm.dto';

export const fetchContactToForm = (
  data?: ContactFormSchemaType
): ContactFormSchemaType | undefined => {
  if (data)
    return {
      name: data.name,
      role: data.role,
      email: data.email,
      phoneNumber: data.phoneNumber,
      id: data.id,
    };
};
