import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  UseFloatingOptions,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { useId } from 'react';
import { twMerge } from 'tailwind-merge';

type FloatingArrowType = {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
} & UseFloatingOptions;

const FloatingDialog = ({
  isOpen,
  setIsOpen,
  children,
  className,
  ...props
}: FloatingArrowType) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    ...props,
  });

  const click = useClick(context, {
    enabled: true,
  });
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });
  const role = useRole(context);

  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  const labelId = useId();
  const descriptionId = useId();

  const { styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
  });

  return (
    <>
      {isOpen && (
        <FloatingPortal>
          <FloatingOverlay
            className="flex justify-center items-center"
            lockScroll
            style={{ background: 'rgba(0, 0, 0, 0.8)', zIndex: 102 }}
          >
            <FloatingFocusManager context={context}>
              <div
                ref={refs.setFloating}
                aria-labelledby={labelId}
                aria-describedby={descriptionId}
                style={{ ...styles }}
                {...getFloatingProps()}
                className={twMerge(
                  'ring-transparent bg-white p-4 rounded border-transparent outline-none overflow-y-auto',
                  className
                )}
              >
                {children}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
};

export default FloatingDialog;
