import { Tab } from '@headlessui/react';
import { classNames } from 'utils';
import ShiftsTableScreen from './ShiftsTable/ShiftsTable.Screen';
import ShiftCalendarScreen from './ShiftsCalendar/ShiftsCalendar.Screen';
import { useTranslation } from 'react-i18next';
import ShiftsTemplateScreen from './ShiftsTemplate/ShiftsTemplate.Screen';
import CatalogProvider from 'pages/shift/core/Infrastructure/Providers/Catalog.provider';

const IndexScreen = () => {
  const [t] = useTranslation(['Shifts']);
  return (
    <div className="w-full bg-white pt-5">
      <CatalogProvider>
        <div>
          <Tab.Group>
            <h1 className="font-bold text-primary text-xl sm:text-2xl pl-12 absolute">
              {t('shift')}
            </h1>

            <div className="flex w-full items-center justify-center">
              <div className="max-w-[250px] w-full bg-neutral-200 p-0.5 rounded-2xl mt-10 sm:mt-0">
                <Tab.List className="flex space-x-1 rounded-2xl bg-blue-900/20 p-1">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-1 text-sm font-medium leading-5',
                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-blue-700 shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                      )
                    }
                  >
                    {t('list')}
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'w-full py-1 text-sm font-medium leading-5 rounded-2xl',
                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-blue-700 shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                      )
                    }
                  >
                    {t('calendar')}
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-1 text-sm font-medium leading-5',
                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-blue-700 shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                      )
                    }
                  >
                    {t('templates')}
                  </Tab>
                </Tab.List>
              </div>
            </div>

            <hr className="h-[1px] bg-neutral-200 mt-5" />
            <Tab.Panels className="w-full">
              <Tab.Panel
                className={classNames(
                  'rounded-xl bg-white p-3',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}
              >
                <ShiftsTableScreen />
              </Tab.Panel>
              <Tab.Panel
                className={classNames(
                  'rounded-xl bg-white p-3',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}
              >
                <ShiftCalendarScreen />
              </Tab.Panel>
              <Tab.Panel
                className={classNames(
                  'rounded-xl bg-white p-3',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}
              >
                <ShiftsTemplateScreen />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </CatalogProvider>
    </div>
  );
};

export default IndexScreen;
