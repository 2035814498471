import { CatalogRepository } from '../Domain/Repository/Catalog.repository';

export class CatalogUseCase {
  private catalogRepo: CatalogRepository;

  constructor() {
    this.catalogRepo = new CatalogRepository();
  }

  public async invokeGetEventData() {
    return await this.catalogRepo.getEventData();
  }
}
