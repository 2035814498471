export default {
  en: {
    sorry: "Sorry, we couldn't find this page.",
    but: 'But dont worry, you can find plenty of other things on our homepage.',
    back: 'Back to homepage',
  },
  es: {
    sorry: 'Lo siento, no pudimos encontrar esta página.',
    but: 'Pero no te preocupes, puedes encontrar muchas otras cosas en nuestra página de inicio',
    back: 'Volver al inicio',
  },
};
