import * as yup from 'yup';
import { HireListGet } from '../Entities/HireList.entity';
import toast from 'react-hot-toast';

export enum HireStatusEnum {
  Pending = 0,
  Accepted = 1,
  Declined = 2,
}

export const hireStatusValues = Object.values(HireStatusEnum).filter(
  (value) => typeof value === 'number'
) as HireStatusEnum[];

const businessHireSchema = yup.object().shape({
  hireId: yup.string().required(),
  userId: yup.string().required(),
  userName: yup.string().required(),
  userImage: yup.string().url().required(),
  status: yup.mixed<HireStatusEnum>().oneOf(hireStatusValues).required(),
  postedDate: yup.string().required(),
  statusDate: yup.string().required().nullable(),
  rating: yup.number().required(),
  ratesNumber: yup.number().integer().required(),
});

export const hireListSchema = yup.object().shape({
  businessHires: yup.array().of(businessHireSchema).required(),
  totalItems: yup.number().integer().required(),
});

export type HireListDto = yup.InferType<typeof hireListSchema>;

export const dtoToHireListDto = async (dto: object) => {
  try {
    const validatedDto = await hireListSchema.validate(dto);
    return new HireListGet(validatedDto);
  } catch (e) {
    if (e instanceof yup.ValidationError) {
      console.log(e.errors);
    }
    toast.error('Please contact support');
  }
};
