import { IResponseCard } from 'actions/payments.api';
import Card from 'components/PaymentCards';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';

interface IPayment {
  data: IResponseCard[];
  onSetFavoriteCard: (value: string) => void;
  onDeleteCard: (value: string) => void;
  loading: boolean;
}

const Payment = ({ data, onSetFavoriteCard, loading, onDeleteCard }: IPayment): JSX.Element => {
  const [t] = useTranslation(['Payment']);
  return (
    <div className="text-center text-primary flex justify-center flex-wrap">
      <h3 className="lg:text-4xl text-2xl font-bold w-full my-10">{t('your')}</h3>
      {data.map((card, i) => (
        <Card
          key={i}
          id={card.id}
          number={card.last4}
          name={'DEFAULT USER'}
          bank={card.brand}
          date={card.cvc}
          using={card.default}
          funding={card.funding}
          loading={loading}
          onSetFavoriteCard={onSetFavoriteCard}
          onDeleteCard={onDeleteCard}
        />
      ))}

      <div className="w-full flex justify-center">
        <Link
          to={RoutesConstant.secure.addCard}
          className="lg:text-3xl text-xl mb-12 font-semibold py-1 px-6 border mt-1 lg:mt-5 border-primary rounded-lg"
        >
          {t('add')}
        </Link>
      </div>
    </div>
  );
};

export default Payment;
