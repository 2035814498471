import Switch from 'components/Switch';
import Input from 'components/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ChangePassword, IChangePassword } from 'actions/auth.api';
import { useAppDispatch } from 'store';
import { GetManageBusinessUsersThunk } from 'store/business/api.thunks';
import { useSelector } from 'react-redux';
import { RoleConstant, RoutesConstant } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import DeleteModal from 'pages/settings/components/DeleteModal';
import Invite from 'pages/profile/components/invite';
import Modal from 'components/Modal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ChangeNotification } from 'actions/business';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { TaxTypeEnum } from 'actions/business/model';
import Icon from 'assets/icons';
import Tooltip from 'components/Tooltip';
import { getBusiness, getCognitoUser } from 'store/business/selectors';
import ProviderSettings from './provider';
import { CloseSession } from 'actions/auth.api';
import ChangeLanguage from './components/ChangeLanguage';

const Settings = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const business = useSelector(getCognitoUser);
  const businessData = useSelector(getBusiness);
  const [enabled, setEnabled] = useState(business?.notifications.email ?? false);
  const { trackPageView } = useMatomo();

  const [t] = useTranslation(['Settings']);

  const [openModal, setOpenModal] = useState(
    searchParams.get('manage') && business?.role === RoleConstant.ADMIN ? true : false
  );
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const modalComponent = (
    <>
      <Invite />
    </>
  );
  const Logout = async () => {
    await CloseSession();
    navigate(RoutesConstant.unsecure.login);
  };

  const modalDelete = (
    <>
      <DeleteModal />
    </>
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<IChangePassword>();

  const onSubmit: SubmitHandler<IChangePassword> = (data) => {
    Auth.currentAuthenticatedUser().then((user) => {
      setLoading(true);
      ChangePassword(user, data)
        .then(() => {
          toast.success(`${t('success')}`);
          reset();
        })
        .catch(() => {
          toast.error(`${t('error')}`);
        })
        .finally(() => setLoading(false));
    });
  };

  useEffect(() => {
    if (business) {
      dispatch(GetManageBusinessUsersThunk(business.businessId));
    }
  }, [business]);

  useEffect(() => {
    if (business?.businessId && enabled !== business?.notifications.email) {
      ChangeNotification(business.id, enabled)
        .then(() => {
          toast.success(`${t('success')}`);
        })
        .catch((e) => {
          toast.error(e.error);
        });
    }
  }, [enabled]);

  useEffect(() => {
    trackPageView({
      documentTitle: 'Settings',
      href: window.location.href,
    });
  }, []);

  return (
    <ProviderSettings>
      <div className="flex w-full top-0 left-0 bg-gray-400 min-h-screen">
        <div className="w-full">
          <Helmet>
            <title>{t('title')} | Chamba Business</title>
          </Helmet>

          <div className="lg:ml-[5%] lg:w-[85%] text-primary px-5">
            <div className="md:px-16 py-12">
              <h3 className="text-2xl lg:text-3xl font-bold">{t('setting')}</h3>
              <hr className="hidden lg:block border-neutral-600 mt-5"></hr>
              <div className="flex flex-wrap mt-5">
                <div className="w-full lg:w-1/2 ">
                  <div className="max-w-sm">
                    <p className="lg:text-xl  font-semibold mb-1 lg:mb-3">{t('notifications')}:</p>
                    <p className="text-sm lg:text-lg mb-5 lg:mb-0">{t('choose')}</p>
                  </div>
                </div>
                <div className="flex">
                  <div className=" mr-7 pt-1">
                    <Switch enabled={enabled} setEnabled={setEnabled} />
                  </div>
                  <div className=" max-w-xs">
                    <p className="lg:text-xl  font-semibold mb-1 lg:mb-3">{t('email')}</p>

                    <p className="lg:text-lg text-sm">{t('allow')}</p>
                  </div>
                </div>
              </div>
              <br />
              <hr className="hidden lg:block border-neutral-600 mt-5"></hr>
              <div className="flex my-4 items-center">
                <div className="w-1/2">
                  <h5 className="text-sm font-semibold lg:text-lg text-primary">Language</h5>
                </div>
                <div className="w-1/2">
                  <ChangeLanguage />
                </div>
              </div>
              <hr className="hidden lg:block border-neutral-600 mt-5"></hr>
              {businessData?.taxType && (
                <>
                  <div className="pt-1 lg:pt-9 pb-4 flex flex-wrap lg:flex-nowrap items-center">
                    <div className="w-full lg:w-1/2">
                      <h5 className="text-sm font-semibold lg:text-lg text-primary">
                        {t('typePro')}
                      </h5>
                    </div>
                    <div className="bg-neutral-400 text-sm mt-3 lg:mt-0 flex items-center px-4 text-anotherGrayOmg rounded-xl w-full lg:w-1/2 max-w-md">
                      {businessData.taxType == TaxTypeEnum.F_1099
                        ? '1099 independent contractors'
                        : 'Chamba employees W2'}

                      <Tooltip content={t('changeTax')}>
                        <Icon icon="support" size={40} className="ml-2" />
                      </Tooltip>
                    </div>
                  </div>
                  <hr className="hidden lg:block border-neutral-600 my-5"></hr>
                </>
              )}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className=" flex flex-wrap lg:flex-nowrap mb-8"
              >
                <div className="w-full lg:w-1/2">
                  <div className="max-w-sm">
                    <p className="lg:text-xl mt-7 font-semibold lg:mb-3 mb-1">{t('change')}:</p>
                    <p className="lg:text-lg text-sm">{t('changing')}</p>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <div className=" max-w-md">
                    <Input
                      label={t('old')}
                      name={'oldPass'}
                      placeholder={t('password')}
                      register={register}
                      type="password"
                      errors={errors.oldPass}
                      validation={{
                        required: true,
                      }}
                    />

                    <Input
                      label={t('new')}
                      name="newPass"
                      placeholder={t('password')}
                      register={register}
                      type="password"
                      errors={errors.newPass}
                      validation={{
                        required: true,
                      }}
                    />
                    <Input
                      label={t('confirm')}
                      name="confirmPass"
                      placeholder={t('password')}
                      register={register}
                      type="password"
                      errors={errors.confirmPass}
                      validation={{
                        required: true,
                        validate: (value: string) =>
                          value === getValues('confirmPass') || `${t('noMatch')}`,
                      }}
                    />
                  </div>

                  <div className="w-full flex justify-center lg:justify-start">
                    <button
                      disabled={loading}
                      className=" bg-primary min-w-[150px] w-full lg:w-1/3 text-white text-xs lg:text-sm py-2 rounded-3xl mt-6 lg:mt-9"
                    >
                      {t('change')}
                    </button>
                  </div>
                </div>
              </form>
              <hr className="hidden lg:block border-neutral-600 mt-5"></hr>
              <div className=" lg:pt-9 pb-4 flex flex-wrap lg:flex-nowrap items-center">
                <div className="w-full lg:w-1/2">
                  <h5 className=" font-semibold lg:text-lg text-primary mb-4 lg:mb-0">
                    {t('account')}
                  </h5>
                </div>
                <div className="w-full lg:w-1/2  ">
                  <button
                    onClick={Logout}
                    className="w-full lg:w-1/3 mr-3 min-w-[150px] bg-primary text-white text-xs lg:text-sm py-2 rounded-3xl mb-4 lg:mb-2"
                  >
                    <Icon size={15} className="text-white mr-2" icon="logout" /> {t('logout')}
                  </button>

                  <button
                    onClick={() => setOpenModalDelete(true)}
                    className="w-full lg:w-1/3 min-w-[150px] bg-warnings-500 text-white text-xs lg:text-sm py-2  rounded-3xl"
                  >
                    <Icon size={17} className="text-white mr-2" icon="delete" />
                    {t('delete')}
                  </button>
                </div>
              </div>
              <Modal
                modalOpen={openModal}
                customComponent={modalComponent}
                actionClose={() => {
                  if (searchParams.get('manage')) {
                    navigate(RoutesConstant.secure.shift);
                  }
                  setOpenModal(false);
                }}
              ></Modal>
              <Modal
                modalOpen={openModalDelete}
                customComponent={modalDelete}
                actionClose={() => {
                  setOpenModalDelete(false);
                }}
              ></Modal>
            </div>
          </div>
        </div>
      </div>
    </ProviderSettings>
  );
};

export default Settings;
