import i18next from 'i18next';
import { EventDataGet } from 'pages/postShift/core/Modules/Catalog/Domain/Entities/Catalog.entity';
import { Languages } from 'utils/constants';

export const getTextTranslated = (text: { spanish: string; english: string }) => {
  const lang = i18next.language || window.localStorage.i18nextLng;

  if (lang === Languages.ES) {
    return text.spanish;
  }

  return text.english;
};

export const getCategoryText = (id: string, catalog?: EventDataGet) => {
  if (catalog) {
    return (
      catalog.eventData.shiftPositions.find((v) => v.id === id)?.text ??
      catalog.eventData.shiftPositions[0].text
    );
  }

  return id;
};
