import LeftNavigation from 'components/LeftNavigation';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'store';
import { AddCardThunk } from 'store/business/api.thunks';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant, SIDEBAR_NAV } from 'utils/constants';
import { useSelector } from 'react-redux';
import { getBusiness } from 'store/business/selectors';
import { useTranslation } from 'react-i18next';
import { ReturnImageCard } from 'utils';
import { Helmet } from 'react-helmet';
import { IAddCardRequest } from 'actions/payments.api';
import { REACT_APP_CDN } from 'utils/enviroment';

const payment = (): JSX.Element => {
  const [vendor, setVendor] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [readyCard, setReadyCard] = useState({ number: false, expirity: false, ccv: false });
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const business = useSelector(getBusiness);
  const [t] = useTranslation(['Payment']);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!elements || !stripe) {
      toast.error(`${t('again')}`);
    } else {
      const elementNumber = elements.getElement('cardNumber');
      if (stripe && elementNumber) {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elementNumber,
        });

        if (result.paymentMethod && business?.id) {
          const requestData: IAddCardRequest = {
            businessId: business.id,
            paymentMethodId: result.paymentMethod?.id,
          };
          setLoading(true);
          dispatch(AddCardThunk(requestData))
            .then(() => {
              toast.success(`${t('success')}`);
              navigate(RoutesConstant.secure.payment, { replace: true });
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          toast.error(`${t('again')}`);
        }
      }
    }
  };

  return (
    <>
      <LeftNavigation selected={SIDEBAR_NAV.PAYMENT} />

      <Helmet>
        <title>{t('title')} | Chamba Business</title>
      </Helmet>

      <div className="lg:ml-[15%] lg:w-[85%] text-primary flex flex-wrap justify-center p-2">
        <h2 className="text-3xl font-bold pt-10 mb-5 w-full text-center">{t('payment')}</h2>
        <div className="w-full flex justify-center mb-8 mt-10">
          <div
            className={`bg-[#9adb9c] hidden sm:block absolute -ml-28 mt-4 text-white w-96 py-2 px-4 rounded-lg`}
          >
            <div className="flex justify-between flex-row min-h-[32px]">
              <p className="mt-2"> {t('bank')}</p>
              <div>
                <img className="mt-4" src={`${REACT_APP_CDN}/img/payments/Wifi.svg`} alt="" />
              </div>
            </div>
            <div className="min-h-[16px]">
              <p className="text-xs mt-8 font-light text-left">{business?.name}</p>
            </div>
            <div className="min-h-[28px]">
              <p className="text-lg text-left">45667 **** **** 4501</p>
            </div>

            <div className="flex justify-between mt-5">
              <p className="text-sm mt-2 font-light">MM/YY</p>
              <div className="flex mt-1">
                <img className="w-10 h-6" src={ReturnImageCard(vendor)} />
              </div>
            </div>
          </div>
          <div
            className={`bg-[#ff9d94] hidden sm:block absolute ml-24 mt-4 text-white w-96 py-2 px-4 rounded-lg`}
          >
            <div className="flex justify-between flex-row min-h-[32px]">
              <p className="mt-2"> {t('bank')}</p>
              <div>
                <img className="mt-4" src={`${REACT_APP_CDN}/img/payments/Wifi.svg`} alt="" />
              </div>
            </div>
            <div className="min-h-[16px]">
              <p className="text-xs mt-8 font-light text-left">{business?.name}</p>
            </div>
            <div className="min-h-[28px]">
              <p className="text-lg text-left">45667 **** **** 4501</p>
            </div>

            <div className="flex justify-between mt-5">
              <p className="text-sm mt-2 font-light">MM/YY</p>
              <div className="flex mt-1">
                <p className="font-light">mastercard</p>
              </div>
            </div>
          </div>

          <div className={`bg-[#4339F2] z-10 text-white w-96 py-2 px-4 rounded-lg`}>
            <div className="flex justify-between flex-row min-h-[64px]">
              <p className="mt-2 text-xl"> {t('bank')}</p>
              <div>
                <img className="mt-4" src={`${REACT_APP_CDN}/img/payments/Wifi.svg`} alt="" />
              </div>
            </div>
            <div className="min-h-[16px]">
              <p className="text-xs mt-8 font-light text-left">{business?.name}</p>
            </div>
            <div className="min-h-[28px]">
              <p className="text-lg text-left">45667 **** **** 4501</p>
            </div>

            <div className="flex justify-between mt-5">
              <p className="text-sm mt-2 font-light">MM/YY</p>
              <div className="flex mt-1">
                <img className="w-10 h-6" src={ReturnImageCard(vendor)} />
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={onSubmit} className="w-full max-w-[800px]">
          <div className="text-center">
            <div>
              <CardNumberElement
                onReady={() => setReadyCard({ ...readyCard, number: true })}
                onChange={(e) => setVendor(e.brand)}
                options={{
                  placeholder: t('placeholder'),
                  showIcon: true,
                  style: {
                    base: { fontSize: '19.66px', lineHeight: '30px', padding: '10px 20px' },
                  },
                }}
                className="mt-4 px-3 shadow-md text-lg sm:text-lg appearance-none rounded-lg relative block w-full py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              />
            </div>

            <div className="flex">
              <CardExpiryElement
                onReady={() => setReadyCard({ ...readyCard, expirity: true })}
                options={{
                  style: {
                    base: { fontSize: '19.66px', lineHeight: '30px', padding: '10px 20px' },
                  },
                }}
                className="w-1/2 p-5 shadow-md mt-4 mr-1 font-light sm:text-lg appearance-none rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              />
              <CardCvcElement
                onReady={() => setReadyCard({ ...readyCard, ccv: true })}
                options={{
                  style: {
                    base: { fontSize: '19.66px', lineHeight: '30px', padding: '10px 20px' },
                  },
                }}
                className="w-1/2 p-5 shadow-md mt-4  text-sm sm:text-lg appearance-none rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              />
            </div>

            <button
              disabled={loading}
              type="submit"
              className="bg-primary text-2xl py-1 my-5 mt-6 text-white px-12 font-semibold rounded-lg m-auto"
            >
              {t('save')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default payment;
