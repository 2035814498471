import { API_ENDPOINTS, getEndpoint } from './api.endpoints';
import api from './http';

export interface IResultTranslateCoords {
  id: string;
  title: string;
  address: string;
  city: string;
  county: string;
  street: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  topic: string;
}

export interface ILocationItem {
  title: string;
  id: string;
}

export interface IResultPlace {
  id: string;
  title: string;
  address: string;
  city: string;
  county: string;
  street: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  topic: string;
}

export interface ICoords {
  latitude: number;
  longitude: number;
}

export const GetPlaceByCoords = async (data: ICoords): Promise<IResultTranslateCoords> => {
  const result = await api.get<IResultTranslateCoords>(
    getEndpoint(API_ENDPOINTS.INTEGRATION.GET_PLACE_BY_COORDS) +
      `?latitude=${data.latitude}&longitude=${data.longitude}`
  );

  return result.data;
};

export const GetAutoCompleteData = async (text: string): Promise<ILocationItem[]> => {
  const result = await api.get<ILocationItem[]>(
    getEndpoint(API_ENDPOINTS.INTEGRATION.GET_PLACE_AUTOSUGGEST, { text })
  );

  return result.data;
};

export const GetPlaceById = async (id: string): Promise<IResultPlace> => {
  const result = await api.get<IResultPlace>(
    getEndpoint(API_ENDPOINTS.INTEGRATION.GET_PLACE_BY_ID, { id })
  );

  return result.data;
};
