import { HireStatusEnum } from '../Dtos/HireList.dto';

interface LocationCoords {
  latitude: number;
  longitude: number;
}

export interface BusinessHire {
  hireId: string;
  positionTitle: string;
  startDate: string;
  firstDayInstructions: string;
  paymentType: string;
  paymentAmount: number;
  benefits: string;
  location: string;
  locationCoords: LocationCoords;
  status: HireStatusEnum;
}

export class HireDescriptionGet {
  constructor(public description: BusinessHire) {}
}
