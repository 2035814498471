import { createContext, useState } from 'react';
import { UploadedResultType } from '../../modules/Files/Domain/Dtos/UploadedResult.dto';

export enum StepUploadDocument {
  UPLOAD = 0,
  REPORT = 1,
}

export interface UploadDocumentType {
  step: StepUploadDocument;
  setStep: (step: StepUploadDocument) => void;
  result?: UploadedResultType;
  setResult: (d: UploadedResultType) => void;
}

export const UploadDocumentContext = createContext({} as UploadDocumentType);

type UploadDocumentProviderProps = {
  children: JSX.Element;
};

const UploadDocumentProvider = ({ children }: UploadDocumentProviderProps) => {
  const [step, setStep] = useState<StepUploadDocument>(StepUploadDocument.UPLOAD);
  const [result, setResult] = useState<UploadedResultType>();

  return (
    <UploadDocumentContext.Provider value={{ step, setStep, result, setResult }}>
      {children}
    </UploadDocumentContext.Provider>
  );
};

export default UploadDocumentProvider;
