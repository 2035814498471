import instance from 'actions/http';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import { dtoToEventDataGet } from '../Dtos/Catalog.dto';
import { EventDataRepositoryType } from 'pages/postShift/core/Infrastructure/Contracts/EventData.contract';

export class CatalogRepository implements EventDataRepositoryType {
  public async getEventData() {
    const request = await instance.get(getEndpoint(API_ENDPOINTS.CATALOG.GET_EVENT_DATA));
    const result = await dtoToEventDataGet(request.data);
    return result;
  }
}
