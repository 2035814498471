import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import CreateContactScreen from './CreateContact/CreateContact.Screen';
import CreateLocationScreen from './CreateLocation/CreateLocation.Screen';
import CreateRoleScreen from './CreateRole/CreateRole.Screen';
import ShiftInfoPresenter from './ShiftInfo.Presenter';
import { useTranslation } from 'react-i18next';
import { IStepShifInfo } from 'pages/postShift/core/Modules/Form/Domain/Dtos/ShiftInfo.dto';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';
import { AllDays, Days, WeekDays } from 'pages/postShift/shared/constants/days';
import Icon from 'assets/icons';
import { TIME_ZONE } from 'utils/constants/days';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShiftInfoScreen = ({ stepName }: IStepShifInfo) => {
  const presenter = ShiftInfoPresenter();
  const [t] = useTranslation(['Shifts']);

  return (
    <div className="py-3 min-h-screen">
      <CreateRoleScreen />

      <CreateLocationScreen />

      <p className="text-lg font-semibold mt-3">{t('create.shiftInfo.date')}</p>

      {presenter.mode !== 'edit' && (
        <>
          <p className="text-lg text-neutralBlue mt-2">{t('create.shiftInfo.recurrent')}</p>

          <div className="flex my-2">
            <Controller
              control={presenter.form.control}
              name="recurrent"
              render={({ field: { onChange, value } }) => (
                <>
                  <div>
                    <input
                      onChange={() => onChange(true)}
                      type="radio"
                      checked={value === true}
                      className="w-4 h-4"
                    />
                    <label className="ml-1 mr-4">{t('create.shiftInfo.yes')}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      checked={value === false}
                      onChange={() => onChange(false)}
                      className="w-4 h-4"
                    />
                    <label className="ml-1 mr-4">No</label>
                  </div>
                </>
              )}
            />
          </div>

          {presenter.form.formState.errors.recurrent && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}
        </>
      )}

      {presenter.isRecurrent ? (
        <>
          <p className="text-lg text-neutralBlue">{t('create.shiftInfo.betweenDates')}</p>

          <div>
            <Controller
              control={presenter.form.control}
              name="recurrentStart"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  selected={value}
                  onChange={(date: Date) =>
                    onChange(DateTime.fromJSDate(date).startOf('day').toJSDate())
                  }
                  bgWhite
                  timeCaption="date"
                  dateFormat="MM/dd/yyyy"
                  textInput={t('create.shiftInfo.startDate')}
                  minDate={new Date()}
                  hideArrow
                  wrapperClassName="w-full"
                  customStyle="!mt-2 border border-neutral-400 text-base"
                />
              )}
            />
            {presenter.form.formState.errors.recurrentStart && (
              <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
            )}
            <Controller
              control={presenter.form.control}
              name="recurrentEnd"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  selected={value}
                  onChange={(date: Date) =>
                    onChange(DateTime.fromJSDate(date).startOf('day').toJSDate())
                  }
                  bgWhite
                  timeCaption="date"
                  dateFormat="MM/dd/yyyy"
                  textInput={t('create.shiftInfo.endDate')}
                  minDate={new Date()}
                  hideArrow
                  wrapperClassName="w-full"
                  customStyle="!mt-2 border border-neutral-400 text-base"
                />
              )}
            />
            {presenter.form.formState.errors.recurrentEnd && (
              <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
            )}
          </div>

          <p className="text-lg text-neutralBlue mt-2">{t('create.shiftInfo.days')}</p>

          <Controller
            control={presenter.form.control}
            name="recurrentDays"
            render={({ field: { value, onChange } }) => (
              <>
                <div className="flex my-2">
                  <div
                    onClick={() => onChange(presenter.selectDay(Days.Monday, value))}
                    className={`border mr-2 w-14 h-14 rounded-full flex items-center justify-center cursor-pointer ${
                      value?.includes(Days.Monday) ? 'border-secondary-500' : 'border-softGray'
                    }`}
                  >
                    <p className="text-center">{t('create.shiftInfo.mon')}</p>
                  </div>
                  <div
                    onClick={() => onChange(presenter.selectDay(Days.Tuesday, value))}
                    className={`border mr-2 w-14 h-14 rounded-full flex items-center justify-center cursor-pointer ${
                      value?.includes(Days.Tuesday) ? 'border-secondary-500' : 'border-softGray'
                    }`}
                  >
                    <p className="text-center">{t('create.shiftInfo.tue')}</p>
                  </div>
                  <div
                    onClick={() => onChange(presenter.selectDay(Days.Wednesday, value))}
                    className={`border mr-2 w-14 h-14 rounded-full flex items-center justify-center cursor-pointer ${
                      value?.includes(Days.Wednesday) ? 'border-secondary-500' : 'border-softGray'
                    }`}
                  >
                    <p className="text-center">{t('create.shiftInfo.wen')}</p>
                  </div>
                  <div
                    onClick={() => onChange(presenter.selectDay(Days.Thursday, value))}
                    className={`border mr-2 w-14 h-14 rounded-full flex items-center justify-center cursor-pointer ${
                      value?.includes(Days.Thursday) ? 'border-secondary-500' : 'border-softGray'
                    }`}
                  >
                    <p className="text-center">{t('create.shiftInfo.thu')}</p>
                  </div>
                  <div
                    onClick={() => onChange(presenter.selectDay(Days.Friday, value))}
                    className={`border mr-2 w-14 h-14 rounded-full flex items-center justify-center cursor-pointer ${
                      value?.includes(Days.Friday) ? 'border-secondary-500' : 'border-softGray'
                    }`}
                  >
                    <p className="text-center">{t('create.shiftInfo.fri')}</p>
                  </div>
                  <div
                    onClick={() => onChange(presenter.selectDay(Days.Saturday, value))}
                    className={`border mr-2 w-14 h-14 rounded-full flex items-center justify-center cursor-pointer ${
                      value?.includes(Days.Saturday) ? 'border-secondary-500' : 'border-softGray'
                    }`}
                  >
                    <p className="text-center">{t('create.shiftInfo.sat')}</p>
                  </div>
                  <div
                    onClick={() => onChange(presenter.selectDay(Days.Sunday, value))}
                    className={`border  w-14 h-14 rounded-full flex items-center justify-center cursor-pointer ${
                      value?.includes(Days.Sunday) ? 'border-secondary-500' : 'border-softGray'
                    }`}
                  >
                    <p className="text-center">{t('create.shiftInfo.sun')}</p>
                  </div>
                </div>
                {presenter.form.formState.errors.recurrentDays && (
                  <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
                )}
                <div className="flex">
                  <p
                    onClick={() => onChange(WeekDays())}
                    className=" underline text-secondaryBlue cursor-pointer"
                  >
                    {t('create.shiftInfo.weekdays')}
                  </p>
                  <p
                    onClick={() => onChange(AllDays())}
                    className=" underline text-secondaryBlue cursor-pointer ml-4"
                  >
                    {t('create.shiftInfo.selectAll')}
                  </p>
                  <p
                    onClick={() => onChange([])}
                    className=" underline text-secondaryBlue cursor-pointer ml-4"
                  >
                    {t('create.shiftInfo.clear')}
                  </p>
                </div>
              </>
            )}
          />
        </>
      ) : (
        <>
          <Controller
            control={presenter.form.control}
            name="startDate"
            render={({ field: { onChange, value } }) => {
              if (Array.isArray(value)) {
                return (
                  <div className="flex flex-wrap">
                    {value.map((v, key) => (
                      <div className="mr-2 relative" key={key}>
                        <CustomDatePicker
                          key={key}
                          selected={v}
                          onChange={(date: Date) => {
                            value[key] = date;
                            onChange(value);
                          }}
                          bgWhite
                          timeCaption="date"
                          dateFormat="MM/dd/yyyy"
                          textInput={t('create.shiftInfo.startDate')}
                          minDate={new Date()}
                          hideArrow
                          customStyle="!mt-2 border border-neutral-400 text-base mr-4"
                        />
                        <div onClick={() => onChange(value.filter((_, k) => k !== key))}>
                          <Icon icon="close" size={10} className="absolute right-2 top-6" />
                        </div>
                      </div>
                    ))}
                  </div>
                );
              } else {
                return (
                  <>
                    <CustomDatePicker
                      selected={value}
                      onChange={(date: Date) =>
                        onChange(DateTime.fromJSDate(date).startOf('day').toJSDate())
                      }
                      bgWhite
                      timeCaption="date"
                      dateFormat="MM/dd/yyyy"
                      textInput={t('create.shiftInfo.startDate')}
                      minDate={new Date()}
                      hideArrow
                      wrapperClassName="w-full"
                      customStyle="!mt-2 border border-neutral-400 text-base"
                    />
                  </>
                );
              }
            }}
          />

          {presenter.mode === 'initial' && !presenter.isRecurrent && (
            <Button
              size="xs"
              color="outlineSecondary"
              name={t('create.shiftInfo.addDate')}
              className="rounded-sm mt-4"
              onClick={presenter.onAddNewDate}
            >
              {t('create.shiftInfo.addDate')}
            </Button>
          )}
        </>
      )}
      <form id="shiftInfoForm" onSubmit={presenter.form.handleSubmit(presenter.onSubmit)}>
        <p className="text-lg  font-semibold mt-3"> {t('create.shiftInfo.time')}</p>
        <div className="flex w-3/4">
          <div className="w-1/2 mr-3">
            <Controller
              control={presenter.form.control}
              name="startTime"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  selected={value}
                  onChange={(date: Date) => onChange(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  bgWhite
                  timeCaption={t('create.shiftInfo.time')}
                  dateFormat="h:mm aa"
                  textInput={t('create.shiftInfo.startTime')}
                  hideArrow
                  wrapperClassName="w-full"
                  customStyle="!mt-2 border border-neutral-400"
                />
              )}
            />

            {presenter.form.formState.errors.startTime && (
              <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
            )}
          </div>
          <div className="w-1/2">
            <Controller
              control={presenter.form.control}
              name="endTime"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  selected={value}
                  onChange={(date: Date) => onChange(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  bgWhite
                  timeCaption={t('create.shiftInfo.time')}
                  dateFormat="h:mm aa"
                  textInput={t('create.shiftInfo.endTime')}
                  hideArrow
                  wrapperClassName="w-full"
                  customStyle="!mt-2 border border-neutral-400"
                />
              )}
            />
            {presenter.hasTimeError && (
              <p className="text-xs text-warnings-500 mt-2">
                {t('create.shiftInfo.shiftRestriction')}
              </p>
            )}
            {presenter.form.formState.errors.endTime && (
              <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
            )}
          </div>
        </div>
        <p className="text-lg  font-semibold mt-3 mb-2"> {t('create.shiftInfo.timeZone')}</p>
        <Controller
          control={presenter.form.control}
          name="timeZone"
          render={({ field: { onChange, value } }) => (
            <>
              <SelectV2
                items={TIME_ZONE(t) ?? []}
                className="w-full rounded-lg text-base border-neutral-400 px-3 py-1 border mb-3 mt-2"
                itemSelected={value}
                name={t('create.shiftInfo.selectTZ')}
                showDropDownArrow
                setSelectedItem={onChange}
              />
            </>
          )}
        />
        {presenter.form.formState.errors.timeZone && (
          <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
        )}
      </form>

      <CreateContactScreen />

      <div className="pt-3 flex justify-end">
        <div>
          <Button
            name={t('next')}
            type="submit"
            form="shiftInfoForm"
            size="xs"
            className=" rounded-md py-2 px-4"
            disabled={presenter.hasTimeError}
          />
        </div>
      </div>
    </div>
  );
};

export default ShiftInfoScreen;
