import { Controller, useForm } from 'react-hook-form';
import Select from 'components/SelectPost';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { KeyboardEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getBusiness, getCards } from 'store/business/selectors';
import { useSelector } from 'react-redux';
import { IAddCardRequest, IResponseCard } from 'actions/payments.api';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'store';
import { AddCardThunk, GetCardsByBusinessThunk } from 'store/business/api.thunks';
import { StepPaymentContext } from '..';
import Tooltip from 'components/TooltipDeprecated';
import { PaymentOptionEnum } from 'actions/business/model';
import CreatableSelect from 'react-select/creatable';
import { Option } from 'actions/shift/model';
import useUser from 'utils/hooks/useUser';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { emailOptions, getStepPaymentName } from 'utils';
import CreditCard from 'components/Creditcard';
import Icon from 'assets/icons';

interface ICard {
  card: IItems;
}

const Step2 = () => {
  const {
    email: value,
    setEmail: setValue,
    selectedPayment,
    setPaymentId,
    setSelectedPayment,
  } = useContext(StepPaymentContext);
  const [t] = useTranslation(['Shifts', 'Profile']);

  const [options, setOptions] = useState<IItems[] | null>(null);
  const [inputValue, setInputValue] = useState('');
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const business = useSelector(getBusiness);
  const { trackEvent } = useMatomo();

  const {
    business: { paymentOption, email },
  } = useUser();

  const createOption = (label: string, prev: Option[]): Option[] => [
    ...prev,
    {
      label,
      value: label,
    },
  ];

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue(createOption(inputValue, value));
        setInputValue('');
        event.preventDefault();
    }
  };

  const [readyCard, setReadyCard] = useState({
    number: false,
    expirity: false,
    ccv: false,
  });

  const updateCards = useCallback(() => {
    if (business && business.id) {
      dispatch(GetCardsByBusinessThunk(business.id));
    }
  }, [business]);

  useEffect(() => {
    updateCards();
  }, [updateCards, business]);

  const data = useSelector(getCards);

  const {
    register,
    control,
    formState: { errors },
    setValue: setFormValue,
    watch,
  } = useForm<ICard>({
    defaultValues: {
      card: { id: '0', text: `${t('SelectCard')}` },
    },
  });

  const selectedCard = watch('card');

  useEffect(() => {
    setPaymentId(selectedCard.id);
  }, [selectedCard]);

  const selectedCardFullInfo = useMemo((): IResponseCard | undefined => {
    if (data !== null) {
      return data.find((cd) => cd.id === selectedCard.id);
    }
    return undefined;
  }, [data, selectedCard]);

  useEffect(() => {
    const temp = data?.map((e) => {
      return { id: e.id, text: e.brand.toUpperCase() + ' *' + e.last4 };
    });
    temp && setOptions(temp);
  }, [data]);

  const onSaveCard = async () => {
    if (!elements || !stripe) {
      toast.error(`${t('again')}`);
    } else {
      const elementNumber = elements.getElement('cardNumber');
      if (stripe && elementNumber) {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elementNumber,
        });

        if (result.paymentMethod && business?.id) {
          const requestData: IAddCardRequest = {
            businessId: business.id,
            paymentMethodId: result.paymentMethod?.id,
          };
          setFormValue('card', {
            id: result.paymentMethod.id,
            text:
              result.paymentMethod.card?.brand?.toUpperCase() +
                ' *' +
                result.paymentMethod.card?.last4 ?? '',
          });

          await dispatch(AddCardThunk(requestData)).unwrap();

          toast.success('Card stored');
        } else {
          toast.error(`${t('again')}`);
        }

        if (result.paymentMethod?.id) {
          setSelectedPayment(PaymentOptionEnum.AutoCharge);
          setPaymentId(result.paymentMethod.id);
          updateCards();
        }
      }
    }
  };

  useEffect(() => {
    trackEvent({
      action: 'open-modal-payment',
      category: 'Shift',
      value: 1,
      name: 'step',
    });
  }, []);

  return (
    <>
      <div className="sm:pl-9 pl-3 w-[95%]">
        <div className="bg-white w-full">
          <div className="flex mb-7">
            <h1 className="font-bold text-4xl text-primary m-0">{t('paymentModal.title')}</h1>
            <div className="flex ml-4 items-center content-center">
              <span className="border p-2  border-neutral-200 rounded-xl flex content-center items-center">
                {getStepPaymentName(selectedPayment)}
                <Tooltip>
                  <p className="max-w-[1400px] text-sm text-white">
                    {t('paymentShift.changePayment', { ns: 'Profile' })}
                  </p>
                </Tooltip>
              </span>
            </div>
          </div>
          {(paymentOption === PaymentOptionEnum.NotSelected ||
            !paymentOption ||
            paymentOption === PaymentOptionEnum.AutoCharge) && (
            <>
              <p className="mb-4 font-semibold text-primary">
                {t('paymentModal.sendEmail', { ns: 'Shifts' })}
              </p>
              <CreatableSelect
                isMulti={true}
                classNames={{
                  placeholder: () => '!text-primary',
                  control: () => '!border-transparent',
                  container: () =>
                    'border w-full rounded-md border-neutral-200 placeholder-primary !text-primary',
                }}
                isClearable={true}
                inputValue={inputValue}
                onChange={(newValue) => setValue(newValue as Option[])}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                value={value}
                options={emailOptions(email)}
                placeholder="Select or add email"
                components={{
                  DropdownIndicator: () => (
                    <Icon icon="ChevronDown" size={12} className="fill-neutral-200 mx-2.5" />
                  ),
                }}
              />
              <br />
              <p className="mb-4 font-semibold text-primary">{t('paymentModal.card')}:</p>
            </>
          )}
          <div className="pb-4 relative">
            <Controller
              control={control}
              name="card"
              rules={{
                required: true,
                validate: (value: IItems) => value.id !== '0' || `${t('choose')}`,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  customStyle="border w-full rounded-md border-neutral-200 pl-2 py-2 placeholder-primary text-primary"
                  items={options ? [{ id: '-1', text: t('add') }, ...options] : []}
                  itemSelected={value}
                  setSelectedItem={(type) => {
                    onChange(type);
                  }}
                  errors={errors.card}
                  register={register}
                  validation={{
                    required: true,
                    validate: (value: IItems) => value.id !== '0' || `${t('choose')}`,
                  }}
                ></Select>
              )}
            />
          </div>

          <>
            {selectedCardFullInfo !== undefined && (
              <CreditCard
                number={selectedCardFullInfo.last4}
                name={business ? business.name : 'DEFAULT USER'}
                bank={selectedCardFullInfo.brand}
                date={selectedCardFullInfo.cvc}
              />
            )}
          </>
          {selectedCard.id === '-1' && (
            <div>
              <div>
                <CardNumberElement
                  onReady={() => setReadyCard({ ...readyCard, number: true })}
                  options={{
                    placeholder: t('paymentModal.card'),
                    showIcon: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        padding: '10px 20px',
                      },
                    },
                  }}
                  className="mt-4 px-3 shadow-md text-base appearance-none rounded-lg relative block w-full py-2 border border-grayBack placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                />
              </div>

              <div className="flex">
                <CardExpiryElement
                  onReady={() => setReadyCard({ ...readyCard, expirity: true })}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        padding: '10px 20px',
                      },
                    },
                  }}
                  className="w-1/2 p-5 shadow-md mt-4 mr-1 font-light appearance-none rounded-lg relative block px-3 py-2 border border-grayBack placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                />
                <CardCvcElement
                  onReady={() => setReadyCard({ ...readyCard, ccv: true })}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        padding: '10px 20px',
                      },
                    },
                  }}
                  className="w-1/2 p-5 shadow-md mt-4 text-base appearance-none rounded-lg relative block px-3 py-2 border border-grayBack placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                />
              </div>
            </div>
          )}
          {selectedCard.id === '-1' && (
            <>
              <br />
              <button
                onClick={onSaveCard}
                className="bg-primary text-white w-full rounded-full py-3"
              >
                Save card
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Step2;
