import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { TemplateHubContext } from 'pages/postShift/core/Infrastructure/Providers/TemplateHub.provider';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import RecurrentAction from './Recurrent.Action';
import { CatalogContext } from 'pages/postShift/core/Infrastructure/Providers/Catalog.provider';
import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';
import useUser from 'utils/hooks/useUser';

const RecurrentPresenter = () => {
  const action = RecurrentAction();
  const hubContext = useContext(TemplateHubContext);

  const contextForm = useContext(DataFormContext);
  const catalog = useContext(CatalogContext);
  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const {
    business: { serviceFee, isMarkdown },
  } = useUser();

  const dataForm = contextForm.dataForm;

  const onChangeOpenings = (id: string, increment: boolean) => {
    setLoading(true);
    const shift = hubContext?.draft?.resultDraftData.shifts.find((value) => value.id === id);

    if (shift && hubContext && hubContext.draft && (shift.openings > 1 || increment)) {
      setLoading(false);
      hubContext?.setDraft({
        resultDraftData: {
          ...hubContext.draft?.resultDraftData,
          shifts: hubContext.draft.resultDraftData.shifts.map((v) => {
            return {
              ...v,
              ...(v.id === id && { openings: v.openings + (increment ? 1 : -1) }),
            };
          }),
        },
      });
    }
  };

  const onRemoveShift = (id: string) => {
    if (hubContext?.draft) {
      hubContext?.setDraft({
        ...hubContext.draft,
        resultDraftData: {
          ...hubContext.draft.resultDraftData,
          shifts: hubContext.draft.resultDraftData.shifts.filter((v) => v.id !== id),
        },
      });
    }
  };

  const saveDraft = async () => {
    setLoading(true);
    const formdata = { ...dataForm };

    if (formdata.shiftInfo?.role.amount && isMarkdown) {
      formdata.shiftInfo.role.amount = formdata.shiftInfo.role.amount / (1 + serviceFee);

      setLoading(false);
    }

    const result = await action.invokeSaveDraft({ ...formdata });
    if (result) {
      setLoading(false);
      hubContext?.setDraft(result);
      contextForm.setMode('draft');
    }
  };

  const titleShifts = useMemo(() => {
    if (hubContext?.draft) {
      if (hubContext.draft.resultDraftData.shifts[0].positionTitle) {
        return getTextTranslated(hubContext.draft.resultDraftData.shifts[0].positionTitle);
      }

      return (
        catalog?.eventData.shiftPositions.find(
          (v) => v.id === hubContext?.draft?.resultDraftData.shifts[0].positionId
        )?.text ?? catalog?.eventData.shiftPositions[0].text
      );
    }

    return '';
  }, [hubContext?.draft]);

  useEffect(() => {
    if (contextForm.mode !== 'draft') {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      saveDraft();
    }
  }, []);

  return {
    draft: hubContext?.draft,
    onRemoveShift,
    onChangeOpenings,
    titleShifts,
    loading,
  };
};

export default RecurrentPresenter;
