export default {
  en: {
    name: 'Name',
    professionals: 'Professionals',
    browse: 'Browse',
    favorites: 'Favorites',
    blocked: 'Blocked',
    hired: 'Hired',
    pending: 'Pending',
    rejected: 'Rejected',
    cityName: 'City name',
    shifts: '# Shifts',
    rating: 'Rating',
    clickOn: 'Click on',
    selectStatus: 'Select status',
    addPreferred:
      'and add your preferred Pros to favorite list. The favorite Pros will have priority to all your future shifts. It can build up your own team and have them repeat for your shifts.',
    profile: {
      shiftsDone: 'Shifts done:',
      rating: 'Rating:',
      viewFullProfile: 'View full profile',
      hired: 'Hired',
      declined: 'Offer declined',
      hiredSince: 'Hired since',
      offerSent: 'Offer sent on',
    },
    recentlyWorked: {
      recentlyWorkedWith: 'Recently worked with:',
    },
    applicant: {
      back: 'Back',
      close: 'Close',
      totalShift: 'Total shifts',
      pending: 'Pending',
      total: 'Total cost',
      experience: 'Work experience',
      education: 'Education',
      spanish: 'Spanish',
      english: 'English',
      language: 'Language',
      skills: 'Skills',
    },
    emptyPros: 'No professionals found',
    emptyFavoriteList: 'You have no favorites Pro yet',
    emptyBlockedList: 'You have no blocked Pro yet',
    emptyGeneralList: 'Enter a city to see all Pros in that area',
    actions: {
      addFavorite: 'Add to favorites',
      block: 'Block',
      unblock: 'Unblock',
      removeFavorite: 'Remove from favorites',
      hire: 'Hire',
      successPreferred: 'Preferred professional added successfully',
      successBlockUser: 'Professional blocked successfully',
      successUnblockUser: 'Professional unblocked successfully',
      successRemoveFavorite: 'Professional removed from favorites successfully',
    },
  },
  es: {
    name: 'Nombre',
    professionals: 'Profesionales',
    browse: 'Explorar',
    favorites: 'Favoritos',
    blocked: 'Bloqueados',
    hired: 'Contratados',
    pending: 'Pendientes',
    rejected: 'Rechazados',
    selectStatus: 'Seleccionar estado',
    cityName: 'Ciudad',
    shifts: '# Turnos',
    rating: 'Calificación',
    clickOn: 'Click en',
    addPreferred:
      'y añade tus Pros preferidos a la lista de favoritos. Los Profesionales favoritos tendrán prioridad para todos tus turnos futuros. Puede crear su propio equipo y hacer que repitan para sus turnos.',
    profile: {
      shiftsDone: 'Turnos realizados:',
      rating: 'Calificación:',
      viewFullProfile: 'Ver perfil completo',
      hired: 'Contratado',
      declined: 'Oferta declinada',
      hiredSince: 'Contratado desde',
      offerSent: 'Oferta enviada en',
    },
    recentlyWorked: {
      recentlyWorkedWith: 'Trabajó recientemente con:',
    },
    applicant: {
      back: 'Atrás',
      close: 'Cerrar',
      totalShift: 'Turnos totales',
      pending: 'Pendiente',
      total: 'Costo total',
      experience: 'Experiencia laboral',
      education: 'Educación',
      spanish: 'Español',
      english: 'Inglés',
      language: 'Idioma',
      skills: 'Habilidades',
    },
    emptyPros: 'No se encontraron profesionales',
    emptyFavoriteList: 'Aún no tienes profesionales favoritos',
    emptyBlockedList: 'Aún no tienes profesionales bloqueados',
    emptyGeneralList: 'Ingresa una ciudad para ver todos los profesionales en esa área',
    actions: {
      addFavorite: 'Agregar a favoritos',
      block: 'Bloquear',
      unblock: 'Desbloquear',
      removeFavorite: 'Eliminar de favoritos',
      hire: 'Contratar',
      successPreferred: 'Profesional preferido agregado correctamente',
      successBlockUser: 'Profesional bloqueado correctamente',
      successUnblockUser: 'Profesional desbloqueado correctamente',
      successRemoveFavorite: 'Profesional eliminado de favoritos correctamente',
    },
  },
};
