import { ShowToastError } from 'utils';
import { SHIFT_APPLICANT_STATUS } from 'utils/constants';
import { API_ENDPOINTS, getEndpoint } from './api.endpoints';
import api from './http';

export interface IShiftItems {
  shiftApplicants: IShiftApplicants[];
  totalItems: number;
}

export interface IShiftApplicants {
  applicationId: string;
  userId: string;
  name: string;
  status: SHIFT_APPLICANT_STATUS;
  phoneNumber: string;
  applicationDate: string;
  userImage: string;
  clockIn: string | null;
  clockOut: string | null;
  totalCost: string | null;
  shiftFinished: boolean;
  isBlocked: boolean;
  isPreferred: boolean;
  cancelledDate: string | null;
}

export interface ILocationData {
  location: {
    topic: string;
    address: string;
    notificationsTopic: string;
  };
  locationCoords: {
    latitude: number;
    longitude: number;
  };
}

export interface IOption {
  label: string;
  value: string;
}

export interface IApplicantResume {
  userId: string;
  location: string;
  image: string;
  name: string;
  ratesNumber: number;
  rating: number;
  resume: {
    workExperiences: IWorkExperienceItem[];
    educationLevel: string;
    englishLevel: string;
    spanishLevel: string;
    primaryTransport: string;
    hasVehicle: boolean;
    hasLicense: boolean;
    skills: Array<string>;
  };
}

export interface IWorkExperienceItem {
  employer: string;
  position: string;
  workedTime: string;
}

export interface IEducationLevel {
  title: IResumeTranslation;
}

export interface ILanguagesLevel {
  english: IResumeTranslation;
  spanish: IResumeTranslation;
}

export interface IResumeTranslation {
  id: string;
  text: string;
}

export interface IPreferredUser {
  businessId: string;
  userId: string;
}

export interface IPreferredUserResponse {
  id: string;
  businessId: string;
  userId: string;
  creationDate: Date;
}

export interface IBlockedUser {
  businessId: string;
  userId: string;
}

export interface IBlockedUserResponse {
  id: string;
  businessId: string;
  userId: string;
  creationDate: Date;
}

export interface IGetDayInvoice {
  page: number;
  businessId: string;
  dayStart: Date;
  dayEnd: Date;
}

export interface IGetShiftInvoice {
  page: number;
  businessId: string;
}

export interface IGetShiftInvoiceDescription {
  shiftId: string;
}

export interface IGetDayInvoiceDescription {
  businessId: string;
  dayStart: Date | undefined;
  dayEnd: Date | undefined;
}
export interface IInvoiceDayCardsResponse {
  dayInvoiceCards: IInvoiceCard[];
  totalItems: number;
}
export interface IInvoiceCard {
  dayStart: Date | undefined;
  dayEnd: Date | undefined;
  openings: number;
  baseAmount: number;
  serviceFee: number;
  totalAmount: number;
  cardId?: string;
  isMarkdown: boolean;
}
export interface IShiftInvoiceCard {
  id: string;
  positionTitle: string;
  startDate: string | undefined;
  endDate: string | undefined;
  openings: number;
  isTotalCompensation: boolean;
  salaryTotal: number;
  salaryHour: number;
  totalAmount: number;
}
export interface IInvoiceShiftCardsResponse {
  shiftInvoiceCards: IShiftInvoiceCard[];
  totalItems: number;
}

export interface IGetShiftInvoiceDescriptionResponse {
  id: string;
  positionTitle: string;
  isTotalCompensation: true;
  salaryTotal: number;
  salaryHour: number;
  applicants: Array<{
    name: string;
    positionTitle: string;
    isTotalCompensation: true;
    salaryTotal: number;
    salaryHour: number;
    clockIn: string;
    clockOut: string;
    breakTime: number;
    laborCost: number;
  }>;
  serviceFee: number;
  totalAmount: number;
  isMarkdown: boolean;
}

export interface IGetDayInvoiceDescriptionResponse {
  dayStart?: string;
  dayEnd?: string;
  applicants: Array<{
    name: string;
    positionTitle: string;
    isTotalCompensation: boolean;
    salaryTotal: number;
    salaryHour: number;
    clockIn: string | null;
    clockOut: string | null;
    breakTime: number;
    laborCost: number;
  }>;
  serviceFee: number;
  totalAmount: number;
  isMarkdown: boolean;
}

export const GetApplicantResume = async (id: string): Promise<IApplicantResume> => {
  try {
    const result = await api.post<IApplicantResume>(
      getEndpoint(`${API_ENDPOINTS.SEARCH.GET_RESUME}`),
      { userId: id }
    );
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const CreatePreferredUser = async (dto: IPreferredUser): Promise<IPreferredUserResponse> => {
  try {
    const result = await api.post<IPreferredUserResponse>(
      getEndpoint(API_ENDPOINTS.USERS.CREATE_PREFERRED_USER),
      dto
    );
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const CreateBlockedUser = async (dto: IBlockedUser): Promise<IBlockedUserResponse> => {
  try {
    const result = await api.post<IBlockedUserResponse>(
      getEndpoint(API_ENDPOINTS.USERS.CREATE_BLOCKED_USER),
      dto
    );
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const DeletePreferredUser = async (id: string): Promise<boolean> => {
  try {
    const result = await api.delete(getEndpoint(API_ENDPOINTS.USERS.DELETE_PREFERRED_USER, { id }));
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const DeleteBlockedUser = async (id: string): Promise<boolean> => {
  try {
    const result = await api.delete(getEndpoint(API_ENDPOINTS.USERS.DELETE_BLOCKED_USER, { id }));
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const GetDayInvoiceCards = async (dto: IGetDayInvoice) => {
  const result = await api.post<IInvoiceDayCardsResponse>(
    getEndpoint(API_ENDPOINTS.SEARCH.GET_DAY_INVOICE_CARDS),
    dto
  );
  return result.data;
};

export const GetWeekInvoiceCards = async (dto: IGetDayInvoice, abortSignal: AbortSignal) => {
  const result = await api.post<IInvoiceDayCardsResponse>(
    getEndpoint(API_ENDPOINTS.SEARCH.GER_WEEK_INVOICE_CARDS),
    dto,
    { signal: abortSignal }
  );
  return result.data;
};

export const GetShiftInvoiceCards = async (dto: IGetShiftInvoice) => {
  try {
    const result = await api.post<IInvoiceShiftCardsResponse>(
      getEndpoint(API_ENDPOINTS.SEARCH.GET_SHIFT_INVOICE_CARDS),
      dto
    );
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const GetShiftInvoiceDescription = async (dto: IGetShiftInvoiceDescription) => {
  const result = await api.post<IGetShiftInvoiceDescriptionResponse>(
    getEndpoint(API_ENDPOINTS.SEARCH.GET_SHIFT_INVOICE_DESCRIPTION),
    dto
  );
  return result.data;
};

export const GetDayInvoiceDescription = async (
  dto: IGetDayInvoiceDescription,
  signal: AbortSignal
) => {
  const result = await api.post<IGetDayInvoiceDescriptionResponse>(
    getEndpoint(API_ENDPOINTS.SEARCH.GET_DAY_INVOICE_DESCRIPTION),
    dto,
    { signal }
  );
  return result.data;
};
