import CatalogProvider from '../core/Infrastructure/Providers/Catalog.provider';
import DataFormProvider from '../core/Infrastructure/Providers/DataForm.provider';
import { TemplateHubProvider } from '../core/Infrastructure/Providers/TemplateHub.provider';
import IndexScreen from './Index/Index.Screen';

const PostShiftPage = () => {
  return (
    <CatalogProvider>
      <DataFormProvider>
        <TemplateHubProvider>
          <IndexScreen />
        </TemplateHubProvider>
      </DataFormProvider>
    </CatalogProvider>
  );
};

export default PostShiftPage;
