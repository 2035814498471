export default {
  en: {
    paid: 'Paid',
    pending: 'Pending',
    due: 'Past due',
    export: 'Export',
    noElement: 'There are no elements',
    title: 'Weekly invoice (net ',
    days: ' days)',
    invoiceNumber: 'Invoice Number',
    time: 'Time period',
    issue: 'Issue date',
    dueDate: 'Due date',
    bill: 'Total bill',
    status: 'Status',
    account: 'Bank account',
    bankInfo: 'See bank info',
    routingNumber: 'Routing number',
    address: 'Address',
    companyName: 'Company name',
    phone: 'Phone',
    rate: 'Rate',
    clockIn: 'Clock in',
    clockOut: 'Clock out',
    unPaidHours: 'Unpaid hours',
    laborCost: 'Labor cost',
    chambaFee: 'Chamba fee',
    total: 'Total',
    noTransactions: 'You have not made any transaction yet',
    serviceFee: 'Service fee',
    startDate: 'Start date',
    endDate: 'End Date',
    name: 'Name',
    position: 'Position',
    chambaServiceFee: 'Chamba service fee',
    totalCost: 'Total cost',
  },
  es: {
    paid: 'Pagado',
    pending: 'Pendiente',
    due: 'Retrasado',
    export: 'Exportar',
    noElement: 'No hay elementos',
    title: 'Factura semanal (neto ',
    days: ' días)',
    invoiceNumber: 'Número factura',
    time: 'Periodo',
    issue: 'Fecha de expedición',
    dueDate: 'Fecha de vencimiento',
    bill: 'Cuenta total',
    bankInfo: 'Información del banco',
    status: 'Estado',
    account: 'Cuenta del banco',
    routingNumber: 'Número de enrutamiento',
    address: 'Dirección',
    phone: 'Teléfono',
    rate: 'Tarifa',
    clockIn: 'Entrada',
    clockOut: 'Salida',
    unPaidHours: 'Horas no pagadas',
    laborCost: 'Costo de mano de obra',
    chambaFee: 'Tarifa de Chamba',
    total: 'Total',
    noTransactions: 'Aún no has realizado ninguna transacción',
    serviceFee: 'Tarifa de servicio',
    name: 'Nombre',
    position: 'Posición',
    chambaServiceFee: 'Tarifa de servicio de Chamba',
    totalCost: 'Costo total',
  },
};
