export default {
  en: {
    message: 'We have sent an email to ',
    verify: ' please verify your email with the code',
    type: 'Type the code here to verify your email',
    confirm: 'Confirm',
    resend: 'Resend code',
    success: 'Success process, logging in',
    successResend: 'Success process, please check your email',
    alreadyHaveAccount: 'Already have an account',
    login: 'Login in here',
  },
  es: {
    message: 'Hemos enviado un email a ',
    verify: ' por favor verifica tu email con el código',
    type: 'Escribe el código aquí para verificar tu email',
    confirm: 'Confirmar',
    resend: 'Re enviar código',
    success: 'Proceso exitoso, iniciando sesión',
    successResend: 'Proceso exitoso, por favor revise su correo',
    alreadyHaveAccount: 'Ya tienes una cuenta',
    login: 'Inicia sesión aquí',
  },
};
