import toast from 'react-hot-toast';
import { InferType, array, bool, number, object, string } from 'yup';
import { ShiftCalendarGet } from '../Entities/ShiftCalendar.entity';

export const CalendarShiftsSchema = object({
  postedShifts: array(
    object({
      id: string().required(),
      eventId: string().required(),
      positionTitle: string().required(),
      startDate: string().required(),
      endDate: string().required(),
      isActive: bool().required(),
      openings: number().required(),
      totalApplicants: number().required(),
      started: bool().required(),
    })
  ).required(),
});

export type CalendarShiftDto = InferType<typeof CalendarShiftsSchema>;

export const dtoToCalendarShiftGet = async (dto: object) => {
  try {
    const validatedDto = await CalendarShiftsSchema.validate(dto);
    return new ShiftCalendarGet(validatedDto);
  } catch (e) {
    console.error(e);
    toast.error('Please contact support');
  }
};
