import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import CreateTemplateSet from '../../../../../../shift/core/Modules/Shifts/Domain/Dtos/CreateTemplateSet.dto';
import { dtoToTemplateDetailGet } from '../Dtos/TemplateDetail.dto';

export class TemplateRepository {
  public async createTemplate(data: CreateTemplateSet) {
    const request = await instance.post(
      getEndpoint(API_ENDPOINTS.EVENTS.CREATE_SHIFT_TEMPLATE),
      data
    );

    return request.data;
  }

  public async getTemplateDetail(id: string) {
    const request = await instance.get(
      getEndpoint(API_ENDPOINTS.EVENTS.GET_TEMPLATE_DETAIL.replace('{id}', id))
    );

    return dtoToTemplateDetailGet(request.data);
  }
}
