import Page404 from './Texts/404';
import ApplicantsProfile from './Texts/ApplicantProfile';
import ConfirmEmail from './Texts/ConfirmEmail';
import Forgot from './Texts/Forgot';
import FreeTrial from './Texts/FreeTrial';
import Home from './Texts/Home';
import Login from './Texts/Login';
import OnBoarding from './Texts/OnBoarding';
import Payment from './Texts/Payment';
import Profile from './Texts/Profile';
import Register from './Texts/Register';
import Settings from './Texts/Settings';
import Others from './Texts/Others';
import auth from './Texts/Login/auth';
import Shifts from './Texts/Shifts';
import ShiftApplicants from './Texts/ShiftApplicants';
import Support from './Texts/Support';
import Timesheet from './Texts/Timesheet';
import massive from './Texts/Shifts/massive';
import Invoice from './Texts/Invoice';
import Hire from './Texts/Hire';

export default {
  AuthActionText: auth.en,
  Page404: Page404.en,
  ApplicantsProfile: ApplicantsProfile.en,
  ConfirmEmail: ConfirmEmail.en,
  Forgot: Forgot.en,
  FreeTrial: FreeTrial.en,
  Home: Home.en,
  Login: Login.en,
  OnBoarding: OnBoarding.en,
  Others: Others.en,
  Payment: Payment.en,
  Profile: Profile.en,
  Register: Register.en,
  Settings: Settings.en,
  Shifts: Shifts.en,
  ShiftApplicants: ShiftApplicants.en,
  Invoice: Invoice.en,
  Support: Support.en,
  Timesheet: Timesheet.en,
  Massive: massive.en,
  Hire: Hire.en,
};
