import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { useContext, useEffect, useState } from 'react';
import IndexAction from './Index.Action';
import { useQuery } from '@tanstack/react-query';
import { CatalogContext } from 'pages/postShift/core/Infrastructure/Providers/Catalog.provider';
import { TemplateHubContext } from 'pages/postShift/core/Infrastructure/Providers/TemplateHub.provider';
import { useSearchParams } from 'react-router-dom';
import { set } from 'lodash';

const IndexPresenter = () => {
  const action = IndexAction();
  const contextForm = useContext(DataFormContext);
  const contextHub = useContext(TemplateHubContext);
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const templateId = searchParams.get('templateId');

  const catalog = useContext(CatalogContext);
  const [alreadySettedPrevious, setAlreadySettedPrevious] = useState(false);

  const draft = useQuery(['get-draft-item', catalog], () => action.executeGetDraft(catalog), {
    enabled: catalog !== undefined && !id,
    cacheTime: 0,
  });

  const editShift = useQuery(
    ['get-shift-edit', catalog],
    () => action.executeGetShiftEdit(id ?? '', catalog),
    {
      enabled: !!id && catalog !== undefined,
      cacheTime: 0,
    }
  );

  const templateShift = useQuery(
    ['get-shift-template', catalog, templateId],
    () => action.executeGetTemplate(templateId ?? '', catalog),
    {
      enabled: !!templateId && catalog !== undefined,
      cacheTime: 0,
    }
  );

  // TEMPLATE EFFECTs
  useEffect(() => {
    if (!templateShift.isLoading) {
      new Promise((resolve) => {
        if (templateShift.data?.form) {
          contextForm.setDataForm(set(templateShift.data.form, 'shiftInfo.recurrent', false));
        }

        resolve(templateShift.data);
      }).then(() => {
        setAlreadySettedPrevious(true);
        contextForm.setMode('template');
      });
    }
  }, [templateShift.isLoading, templateShift.data]);

  // DRAFT EFFECTs
  useEffect(() => {
    if (!draft.isLoading) {
      new Promise((resolve) => {
        if (draft.data?.form) {
          contextForm.setDataForm(draft.data.form);
        }

        if (draft.data?.data) {
          contextHub?.setDraft(draft.data.data);
        }
        resolve(draft.data);
      }).then(() => {
        setAlreadySettedPrevious(true);

        if (draft.data?.data) {
          contextForm.setMode('draft');
        }
      });
    }
  }, [draft.isLoading, draft.data]);

  // EDIT SHIFT EFFECTs

  useEffect(() => {
    if (!editShift.isLoading) {
      new Promise((resolve) => {
        if (editShift.data?.form) {
          contextForm.setDataForm(set(editShift.data.form, 'shiftInfo.recurrent', false));
        }

        resolve(draft.data);
      }).then(() => {
        setAlreadySettedPrevious(true);

        contextForm.setMode('edit');
      });
    }
  }, [editShift.data]);

  return {
    draft,
    contextForm,
    alreadySettedPrevious,
  };
};

export default IndexPresenter;
