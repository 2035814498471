import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { emailRegex, RoutesConstant, USER_NOT_FOUND } from 'utils/constants';
import { AxiosError } from 'axios';
import {
  CheckCognitoInstance,
  CloseSession,
  ILoginUser,
  IRegisterRequest,
  LoginUser,
  RegisterOldUser,
} from 'actions/auth.api';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'store';
import { GetBusinessThunk, GetBusinessUserIdThunk } from 'store/business/api.thunks';
import { useEffect, useState } from 'react';
import Federated from 'components/Federated';
import { useTranslation } from 'react-i18next';
import { REACT_APP_CDN } from 'utils/enviroment';
import PasswordInput from 'components/PasswordInput';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Input from 'components/InputPost';

const LoginScreen = (): JSX.Element => {
  const [t, i18n] = useTranslation(['Login']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { trackPageView } = useMatomo();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const lng = searchParams.get('lang');
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [searchParams]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginUser>();

  useEffect(() => {
    CheckCognitoInstance().then((e) => {
      if (e !== null) {
        navigate(RoutesConstant.secure.shift);
      }
    });
  }, []);

  const onSubmit: SubmitHandler<ILoginUser> = async (data) => {
    setLoading(true);
    LoginUser(data)
      .then((result) => {
        if (result) {
          dispatch(GetBusinessUserIdThunk(result.getIdToken().payload.sub))
            .unwrap()
            .then((result) => {
              if (!result.isActive) {
                setLoading(false);
                toast.error(t('notFound'));
                CloseSession();
              } else if (result.businessId) {
                dispatch(GetBusinessThunk(result.businessId));
                navigate(RoutesConstant.secure.shift);
              } else {
                navigate(RoutesConstant.secure.onBoarding);
              }
            })
            .catch((e) => {
              const error = e as AxiosError<IErrorResponse>;
              if (error.response && error.response.data.code == USER_NOT_FOUND) {
                const params: IRegisterRequest = {
                  email: data.email,
                  name: data.email,
                  cognitoId: result.getIdToken().payload.sub,
                };
                RegisterOldUser(params)
                  .then(() => {
                    dispatch(GetBusinessUserIdThunk(result.getIdToken().payload.sub)).then(() => {
                      navigate(RoutesConstant.secure.onBoarding);
                    });
                  })
                  .catch((e) => toast.error(e.message));
              }
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
      });
  };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Login',
      href: window.location.href,
    });
  }, []);

  return (
    <div className="flex min-h-[100vh] bg-[#E5E5E5] w-full">
      <div className="w-full lg:w-1/2 p-10 sm:p-20 xl:p-40 flex self-center">
        <div className="w-full">
          <h2 className="sm:text-4xl text-3xl font-bold mb-3 text-primary">{t('title')}</h2>
          <p className="text-lg mb-5 text-primary">{t('help')}</p>
          <Federated />
          <div className="flex items-center justify-between">
            <p className="text-lg text-primary">{t('or')}</p>
          </div>
          <div className="w-full flex justify-center lg:hidden ">
            <img src={`${REACT_APP_CDN}/RestaurantLogin.svg`} alt="lock" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              customStyle="mt-4 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-5 py-2 border border-grayBack bg-white placeholder-primary text-gray-900 focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              label={'email'}
              name="email"
              placeholder={t('email')}
              register={register}
              type="email"
              errors={errors.email}
              validation={{
                required: true,
                pattern: emailRegex,
              }}
            />
            <div className="my-2"></div>
            <PasswordInput
              label={'password'}
              name="password"
              placeholder={t('password')}
              register={register}
              type="password"
              errors={errors.password}
              validation={{
                required: true,
              }}
            />
            <p className="pt-3 text-right text-sm ">
              <p>
                {t('forgot')}
                <Link
                  type={'button'}
                  className="text-[#117AF4] font-semibold underline underline-offset-2"
                  to={RoutesConstant.unsecure.forgot}
                >
                  {t('click')}
                </Link>
              </p>
            </p>
            <div>
              <div className="flex justify-center my-4">
                <button
                  disabled={loading}
                  type="submit"
                  className="w-[100%] rounded-full py-2 text-base font-semibold bg-primary text-white cursor-pointer"
                >
                  {t('login')}
                </button>
              </div>

              <div className="flex items-center justify-center">
                <p className="text-sm mr-1">{t('textRegister')} </p>
                <Link
                  type={'button'}
                  to={RoutesConstant.unsecure.register}
                  className="text-[#117AF4] py-1 rounded-md text-center text-sm font-semibold underline underline-offset-2"
                >
                  {t('register')}
                </Link>
              </div>
            </div>
          </form>

          <br />
        </div>
      </div>
      <div className="w-1/2 bg-lightBlue/30 hidden lg:flex justify-center">
        <div className="self-center ">
          <img
            src={`${REACT_APP_CDN}/auth-icon.svg`}
            alt={`${REACT_APP_CDN}/auth-icon.svg`}
            className="pl-10"
          />
          <div className="w-full text-center mt-5">
            <h3 className="font-bold text-primary text-xl">{t('chambaFills')}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
