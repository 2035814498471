import { useQuery } from '@tanstack/react-query';
import BusinessService from 'actions/business';
import Button from 'components/Button';
import FloatingDialog from 'components/FloatingDialog';
import useUser from 'utils/hooks/useUser';

type ModalPolicyProps = {
  open: boolean;
  setOpen: (v: boolean) => void;
  userId: string;
  userName: string;
  setHire: (s?: { name: string; id: string }) => void;
  onClickAccept: () => void;
};

const ModalPolicy = ({
  open,
  setOpen,
  userId,
  userName,
  onClickAccept,
  setHire,
}: ModalPolicyProps) => {
  const {
    business: { id },
  } = useUser();

  const query = useQuery({
    queryFn: () =>
      BusinessService.getUserWorkedHours({
        businessId: id,
        userId,
      }),
    queryKey: ['worked-hours', userId],
  });

  const onChangeOpen = (s: boolean) => {
    if (!s) {
      setHire(undefined);
    }
    setOpen(s);
  };

  return (
    <FloatingDialog setIsOpen={onChangeOpen} isOpen={open}>
      <div className="rounded-lg shadow-md bg-white m-auto p-6 w-full max-w-3xl">
        <h2 className="text-primary text-[22px] font-bold"> Chamba hire policy</h2>

        <p className="text-[17px] font-semibold text-primary mt-2">
          According to Chamba agreement, hiring a Chamba Pro directly will have the following cost
        </p>

        <div className="bg-secondary-200 rounded-xl p-4 mt-4">
          If this Pro has worked <span className="font-bold">0 - 100</span> hours with your account,
          the direct hire fee is <span className="font-bold">$3000</span>
        </div>

        <div className="bg-secondary-200 rounded-xl p-4 mt-4">
          If this Pro has worked <span className="font-bold">100 - 200</span> hours with your
          account, the direct hire fee is <span className="font-bold">$2000</span>{' '}
        </div>

        <div className="bg-secondary-200 rounded-xl p-4 mt-4">
          If this Pro has worked <span className="font-bold">200+</span> hours with your account,
          the direct hire fee is <span className="font-bold">$1000</span>{' '}
        </div>

        <p className="text-[17px] font-normal mt-4">
          The Pro <span className="font-bold">{userName}</span> you want to hire has worked with
          your account <span className="font-bold">{query.data?.hours?.toFixed(2)}</span> hours
        </p>

        <p className="text-[17px] font-normal mt-4">
          The cost for hiring <span className="font-bold">{userName}</span> is $
          {query.data?.amount?.toFixed(2)}
        </p>

        <p className="text-xs font-normal mt-2">
          Note: Once the Pro accepts your offer, Chamba will send the invoice on this Pros first day
          of work. If the Pro doesnt accept your offer, there will be no charge.
        </p>

        <Button className="w-full justify-center mt-4" name="Continue" onClick={onClickAccept} />
      </div>
    </FloatingDialog>
  );
};

export default ModalPolicy;
