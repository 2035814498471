import { Dialog, Transition } from '@headlessui/react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Icon from 'assets/icons';
import ImageAvatar from 'components/ImageAvatar';
import StarRating from 'components/StarsRating';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { GetApplicantResumeThunk } from 'store/shifts/api.thunks';
import { getApplicantResume } from 'store/shifts/selectors';

export interface ProfileInformationProps {
  isOpen: boolean;
  closeModal: () => void;
  userId: string | null;
}

const ProfileInformation = ({
  isOpen,
  closeModal,
  userId,
}: ProfileInformationProps): JSX.Element => {
  const [t] = useTranslation(['ApplicantsProfile']);

  const completeButtonRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const applicantResume = useSelector(getApplicantResume);
  const { trackEvent } = useMatomo();

  const getResumeApplicant = React.useCallback(async () => {
    if (userId !== null) {
      const result = await dispatch(GetApplicantResumeThunk(userId));
      if (GetApplicantResumeThunk.rejected.match(result)) {
        closeModal();
      }
    }
  }, [dispatch, userId]);

  React.useEffect(() => {
    getResumeApplicant();
  }, [getResumeApplicant]);

  React.useEffect(() => {
    trackEvent({ action: 'open-profile-modal', category: 'Professionals', name: userId ?? '' });
  }, [userId]);

  return (
    <React.Fragment>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog
          initialFocus={completeButtonRef}
          className="relative z-10"
          onClose={() => {
            //
          }}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed max-w-xl m-auto inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[438px] text-primary transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  {applicantResume !== null && (
                    <div className="w-full px-7 pb-4 pt-4">
                      <div>
                        <div className="flex justify-between">
                          <button
                            onClick={closeModal}
                            className="text-primary font-thin text-sm pb-2 outline-none flex items-center mb-3"
                          >
                            <Icon className="mr-3 rotate-90" icon={'ChevronDown'} size={15} />
                            {t('applicant.back')}
                          </button>

                          <button
                            onClick={closeModal}
                            className="text-primary font-thin text-sm pb-2 outline-none flex items-center mb-3"
                          >
                            <Icon className="" icon={'notIncluded'} size={15} />
                          </button>
                        </div>
                        <div className="overflow-y-auto max-h-[533px]">
                          <div className="flex w-full items-center pb-2">
                            <div className="mr-4">
                              <ImageAvatar size={56} url={applicantResume.image} />
                            </div>

                            <div>
                              <p className="text-lg font-normal m-0 leading-5">
                                {applicantResume.name}
                              </p>
                              {applicantResume.ratesNumber > 0 && (
                                <div className="my-1.5 text-secondary-500">
                                  <StarRating
                                    size={10}
                                    disabled
                                    rates={applicantResume.ratesNumber}
                                    defaultRating={applicantResume.rating}
                                  />
                                </div>
                              )}
                              <p className="text-[#C0C2C3] text-xs">{applicantResume.location}</p>
                            </div>
                          </div>
                          <hr className="w-6/7 pb-2 flex justify-center text-[#D7D7D7]" />
                          <React.Fragment>
                            <div>
                              <p className="text-[#A6ACB5] text-sm mb-2">
                                {t('applicant.experience')}:
                              </p>
                              {applicantResume.resume.workExperiences &&
                                applicantResume.resume.workExperiences.map((experience, i) => (
                                  <div key={i}>
                                    <h4 className=" text-base font-semibold pb-1">
                                      {experience.position}
                                    </h4>
                                    <p className="text-sm pb-3 font-base">
                                      {experience.workedTime} - {experience.employer}
                                    </p>
                                  </div>
                                ))}
                              <hr className="w-6/7 pb-2 flex justify-center text-[#D7D7D7]" />
                              <p className="text-[#A6ACB5] text-sm mb-3">
                                {t('applicant.education')}:
                              </p>
                              <div className="flex items-center mb-2">
                                <Icon className="mr-3" icon={'case'} size={15} />
                                <p className="text-base font-semibold">
                                  {applicantResume.resume.educationLevel}
                                </p>
                              </div>
                            </div>
                            <hr className="w-6/7 pb-2 flex justify-center text-[#D7D7D7]" />
                            <div className="flex flex-col">
                              <h4 className="text-[#A6ACB5] text-sm mb-2">
                                {t('applicant.language')}:
                              </h4>

                              <p className="text-base font-semibold mb-3">
                                {t('applicant.english')} -{applicantResume.resume.englishLevel}
                              </p>

                              <p className="text-base font-semibold mb-3">
                                {t('applicant.spanish')} -{applicantResume.resume.spanishLevel}
                              </p>
                            </div>
                            <hr className="w-6/7 pb-2 flex justify-center text-[#D7D7D7]" />
                            <div className="flex flex-col mb-8">
                              <h4 className="text-[#A6ACB5] text-sm mb-2">
                                {t('applicant.skills')}:
                              </h4>
                              <div className="w-full flex flex-wrap">
                                {applicantResume.resume.skills.map((skill, i) => (
                                  <div
                                    key={i}
                                    className="bg-secondary-500 px-2 py-1 rounded-md mr-2 mt-2"
                                  >
                                    <p className="text-white whitespace-nowrap">{skill}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

export default ProfileInformation;
