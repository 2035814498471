import request from 'actions/api.wrapper';
import { API_ENDPOINTS, getEndpoint } from '../api.endpoints';
import api from '../http';
import { IDeleteReasonResponse } from './models';

export interface ICategories {
  id: string;
  text: string;
  image: string;
}

export interface IOnboardData {
  businessTypes: IItems[];
  discoveryOptions: IItems[];
}

export const GetOnboardData = async (): Promise<IOnboardData> => {
  const result = await api.get<IOnboardData>(getEndpoint(API_ENDPOINTS.CATALOG.GET_ONBOARD_DATA));
  return result.data;
};

export const GetCategories = async (): Promise<ICategories[]> => {
  const result = await api.get<ICategories[]>(getEndpoint(API_ENDPOINTS.CATALOG.GET_CATEGORIES));
  return result.data;
};

export default class CatalogService {
  public static async getDeleteReasons() {
    return request<IDeleteReasonResponse[]>({
      method: 'GET',
      url: API_ENDPOINTS.CATALOG.GET_DELETE_REASONS,
    });
  }

  public static async getHireData() {
    return request<Array<{ id: string; text: string }>>({
      method: 'GET',
      url: API_ENDPOINTS.CATALOG.GET_HIRE_DATA,
    });
  }
}
