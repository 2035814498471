import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import axios from 'axios';
import { dtoToUploadedResultGet } from '../Dtos/UploadedResult.dto';

export class FileRepository {
  public async uploadExcel(data: FormData) {
    const result = await axios.post(getEndpoint(API_ENDPOINTS.INTEGRATION.UPLOAD_EXCEL), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      validateStatus: null,
    });

    return dtoToUploadedResultGet(result.data);
  }
}
