import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TemplateDetailGet } from '../Entities/TemplateDetail.entity';

const schema = Yup.object().shape({
  id: Yup.string().required(),
  businessId: Yup.string().required(),
  templateName: Yup.string().required(),
  location: Yup.object()
    .shape({
      topic: Yup.string().required(),
      address: Yup.string().required(),
      notificationsTopic: Yup.string().required(),
    })
    .required(),
  locationCoords: Yup.object()
    .shape({
      latitude: Yup.number().required(),
      longitude: Yup.number().required(),
    })
    .required(),
  parkingInfo: Yup.string().required(),
  locationInfo: Yup.object()
    .shape({
      english: Yup.string().required(),
      spanish: Yup.string().required(),
    })
    .required()
    .nullable(),
  clockInInfo: Yup.object()
    .shape({
      english: Yup.string().required(),
      spanish: Yup.string().required(),
    })
    .required(),
  positionTitle: Yup.object()
    .shape({
      english: Yup.string().required(),
      spanish: Yup.string().required(),
    })
    .required(),
  positionId: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
  breakTime: Yup.number().required(),
  openings: Yup.number().required(),
  salaryType: Yup.string().required(),
  salaryAmount: Yup.number().required(),
  fullTimeOportunity: Yup.boolean().required(),
  uniform: Yup.string().required(),
  dressCode: Yup.string().required(),
  groomingRestrictions: Yup.array().of(Yup.string().required()).required(),
  jobInformation: Yup.object()
    .shape({
      english: Yup.string().required(),
      spanish: Yup.string().required(),
    })
    .required(),
  abilities: Yup.array().of(Yup.string().required()).required(),
  experience: Yup.string().required(),
  contacts: Yup.array()
    .of(
      Yup.object()
        .shape({
          name: Yup.string().required(),
          role: Yup.string().required(),
          phoneNumber: Yup.string().required(),
          email: Yup.string().email().required(),
        })
        .required()
    )
    .required(),
  onlyFavorites: Yup.boolean().required(),
  creationDate: Yup.string().required(),
  timeZone: Yup.number().required(),
});

export type TemplateDetailDto = Yup.InferType<typeof schema>;

export const dtoToTemplateDetailGet = async (dto: object) => {
  try {
    const validatedDto = await schema.validate(dto);
    return new TemplateDetailGet(validatedDto);
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      const errors: Record<string, string[]> = {};

      console.log(e.errors);

      e.inner.forEach((element) => {
        const path = element.path || 'root';
        errors[path] ? errors[path].push(element.message) : (errors[path] = [element.message]);
      });

      console.error(errors);
    }
    toast.error('Please contact support');
  }
};
