import { createContext, useEffect, useState } from 'react';
import { EventDataGet } from '../../Modules/Catalog/Domain/Entities/Catalog.entity';
import { CatalogUseCase } from '../../Modules/Catalog/Application/CatalogUseCase';

type CatalogProviderProps = {
  children: JSX.Element;
};

export const CatalogContext = createContext({} as EventDataGet | undefined);

const CatalogProvider = ({ children }: CatalogProviderProps) => {
  const CatalogUseCases = new CatalogUseCase();
  const [data, setData] = useState<EventDataGet | undefined>();

  const executeGetEventData = () => CatalogUseCases.invokeGetEventData();

  useEffect(() => {
    executeGetEventData().then((e) => setData(e));
  }, []);

  return <CatalogContext.Provider value={data}>{children}</CatalogContext.Provider>;
};

export default CatalogProvider;
