import { InferType, array, boolean, number, object, string } from 'yup';
import toast from 'react-hot-toast';
import { ShiftDescriptionGet } from '../Entities/ShiftDedscription.entity';

export const ShiftDescriptionSchema = object({
  id: string().required(),
  eventId: string().required(),
  positionId: string().required(),
  positionTitle: string().required(),
  businessId: string().required(),
  businessName: string().required(),
  businessLogo: string().required(),
  location: string().required(),
  postedDate: string().required(),
  startDate: string().required(),
  endDate: string().required(),
  breakTime: number().required(),
  locationCoords: object({
    latitude: number(),
    longitude: number(),
  }).required(),
  openings: number().required(),
  isTotalCompensation: boolean(),
  salaryTotal: number().required(),
  salaryHour: number().required(),
  shiftHours: number().required(),
  parkingInfo: string().required(),
  locationInfo: string().required().nullable(),
  clockInInfo: string().required().nullable(),
  abilities: array().of(string().required()).required(),
  experience: string().required(),
  uniform: string().required(),
  dressCode: string().required(),
  groomingRestrictions: array().of(string().required()).required(),
  jobInformation: string().required().nullable(),
  contacts: array(
    object({
      name: string().required(),
      role: string().required(),
      phoneNumber: string().required(),
      email: string().required(),
    }).required()
  ).required(),
  appliedShift: boolean().required(),
  clockIn: string().required().nullable(),
  clockOut: string().required().nullable(),
  breakStart: string().required().nullable(),
  timeZone: string().required(),
  breakEnd: string().required().nullable(),
  isActive: boolean().required(),
  started: boolean().required(),
  isW2: boolean().required(),
  onlyFavorites: boolean().required(),
  isUserFavorite: boolean().required(),
  cancelledWithin24Hours: boolean(),
});

export type PaginatedShiftDto = InferType<typeof ShiftDescriptionSchema>;

export const dtoToDescriptionShiftGet = async (dto: PaginatedShiftDto) => {
  try {
    const validatedDto = await ShiftDescriptionSchema.validate(dto);

    return new ShiftDescriptionGet(validatedDto);
  } catch (e) {
    console.error(e);
    toast.error('Please contact support');
  }
};
