import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { UserUseCase } from 'pages/shift/core/Modules/Users/Applications/UserUseCase';
import {
  ApplicantShiftSet,
  ApplicantStatus,
} from 'pages/shift/core/Modules/Users/Domain/Entities/ApplicantShift.entity';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/hooks/useUser';

const UseUserCases = new UserUseCase();

const ShiftProsAction = () => {
  const {
    business: { id },
  } = useUser();

  const [t] = useTranslation(['Shifts']);

  const executeGetShiftApplicants = async (d: ApplicantShiftSet) => {
    try {
      const result = await UseUserCases.invokeGetApplicantShift(d);
      return result;
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeAddToFavorite = async (userId: string) => {
    try {
      await UseUserCases.invokeAddToFavorite({
        businessId: id,
        userId,
      });

      toast.success(t('proList.updated'));
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeAddToBlocked = async (userId: string) => {
    try {
      await UseUserCases.invokeAddToBlocked({
        businessId: id,
        userId,
      });

      toast.success(t('proList.updated'));
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeRejectApplicant = async (id: string) => {
    try {
      await UseUserCases.invokeRejectApplicant({
        id,
        status: ApplicantStatus.CANCELED,
      });

      toast.success(t('proList.updated'));
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.message ?? 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const executeIs24HRefuse = (t: TFunction, timezone?: string, startDate?: string) => {
    return UseUserCases.is24hRefuse(t, timezone, startDate);
  };

  return {
    executeGetShiftApplicants,
    executeAddToFavorite,
    executeAddToBlocked,
    executeRejectApplicant,
    executeIs24HRefuse,
  };
};

export default ShiftProsAction;
