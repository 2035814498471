import Button from 'components/Button';
import { UploadDocumentPresenter } from './UploadDocument.Presenter';
import Icon from 'assets/icons';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

const UploadDocumentScreen = () => {
  const presenter = UploadDocumentPresenter();
  const [t] = useTranslation(['Massive']);

  return (
    <div>
      <div className="w-full h-full px-9 py-3">
        {!presenter.loading ? (
          <div
            onDrop={presenter.handleDrop}
            onDragOver={(event) => event.preventDefault()}
            className="w-full h-full min-h-96 border-neutral-200 border rounded p-4 flex justify-center items-center"
          >
            <div className="flex justify-center flex-col">
              <div className="text-center">
                <Icon icon="downloadicon" size={60} className="text-neutral-1000" />
              </div>

              <p className="py-3 text-xl font-bold text-neutral-1000">{t('drag')}</p>

              <Button
                onClick={presenter.onClickSubmit}
                name={t('browse')}
                className="justify-center"
              />
            </div>

            <input
              type="file"
              hidden
              ref={presenter.refInput}
              id="browse"
              onChange={(e) => presenter.handleFileChange(e.target.files)}
              accept=".xlsx"
            />
          </div>
        ) : (
          <div className="w-full h-full min-h-96 border-neutral-200 border rounded p-4 flex justify-center items-center">
            <div>
              <div className="text-center">
                <Icon icon="downloadicon" size={60} className="text-neutral-1000" />
              </div>
              <p className="py-2 text-xl font-bold text-neutral-1000">{presenter.file?.name}</p>

              <LoadingSpinner />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDocumentScreen;
