export default {
  en: {
    menu: {
      dash: 'Dashboard',
      post: 'Post a job',
      mypost: 'My post',
      applicant: 'Applicants',
      payment: 'Payment',
      settings: 'Settings',

      jobs: 'Jobs',
      menu: 'Menu',
      shifts: 'Shifts',
      more: 'More',
      language: 'Language',
      professionals: 'Professionals',
      account: 'Account',
      invoice: 'Invoice',
      profiles: 'Profiles',
      support: 'Support',
      timesheet: 'Timesheet',
      hire: 'Hire',
    },
    welcome: 'The #1 platform to hire Latino talent for the Food & Beverage industry.',
    help: 'We help you find the best staff for your company, what are you waiting to start?',
    start: "Let's start",
    free: 'Post For Free',
    jobSearch: 'Job search',
    easyStart: 'Join Chamba ',
    easy: 'along with hundreds of leading restaurants to hire faster and increase diversity.',
    create: 'Create an account',
    account: 'Create your free account, click the button below to start',
    post: 'Post your first job',
    createJob: 'Use the create job option on the dashboard to post your first job on Chamba',
    dashboard: 'Use the dashboard',
    da: 'Dashboard',
    dash: 'Our new dashboard will help you manage statistics, applicants, and other features',
    hire: 'HIRE',
    smallHire: 'WITHIN',
    timeHire: '3 DAYS',
    instant: 'Instant connection',
    allow:
      'Chamba allows you to connect immediately with interested candidates. You can view their profiles and manage their status using an innovative administration system',
    responsive: 'No More Ghosting',
    page: 'Ghosting has always been a headache in recruiting, especially for the Food & Beverage industry. In Chamba, we take no-shows seriously.',
    pageSecond:
      'Our AI and Chamba Navigator maximize interview show-rates to 70% more than the industry average.',
    candidates: 'Unique Candidates',
    candidatesContentFirst:
      'The Chamba community is 100% composed of candidates who are looking for careers in the Food & Beverage industry.',
    candidatesContentSecond:
      'Unlike other platforms, in Chamba you will find candidates with relevant experience in BOH and FOH positions.',
    partnerships: 'Chamba Navigator',
    personalAccountOne: 'Hiring is hard, we know it.',
    personalAccount:
      'Our Chamba Navigator will help you craft job descriptions to attract the best talent. We also provide API tech support, sourcing, coordinating interviews and help with translation and onboarding.',
    contact: 'Contact us for demo',
    contactText:
      'please provide us the following info so the right person will get back to you soon.',
    send: 'Send info',
    ///Footer
    about: 'About',
    product: 'Product',
    data: 'Does Chamba sell my info?',
    questions: 'FAQ',
    terms: 'Terms',
    policies: 'Policies',
    privacy: 'Privacy',
    ///Header
    login: 'Employer Login',
    home: 'Home',
    jobSeeker: 'Job seeker',
    language: 'Language',
    profile: 'Profile',
    settings: 'Account Settings',
    logout: 'Log out',
    english: 'English (EN)',
    spanish: 'Spanish (ES)',
    //DashHeader
    postJ: 'Post a job',
    plans: 'Our plans',
    statistics: 'Statistics',
    manage: 'Manage jobs',
    manageA: 'Manage applicants',
    invite: 'Invite colaborator',
    // Metrics
    jobseekers: 'Jobseekers',
    companies: 'Companies',
    jobViews: 'Impressions',
    hires: 'Hires',
    news: 'As seen on',
    // Contact us Form
    businessName: 'Business name',
    businessEmail: 'Business email',
    businessPhone: 'Business phone',
    message: 'Write us a message:',
    successContact: 'Thanks for contact us, we will get in touch with your soon.',
  },
  es: {
    menu: {
      dash: 'Dashboard',
      post: 'Publicar',
      mypost: 'Mis posts',
      applicant: 'Aplicantes',
      payment: 'Pagos',
      settings: 'Ajustes',
      jobs: 'Trabajos',
      menu: 'Menú',
      shifts: 'Turnos',
      more: 'Más',
      language: 'Idioma',
      professionals: 'Profesionales',
      account: 'Cuenta',
      invoice: 'Facturas',
      profiles: 'Perfiles',
      support: 'Soporte',
      hire: 'Contratación',
      timesheet: 'Hoja de horas',
    },
    welcome: 'La plataforma #1 para encontrar talento Latino para la industria restaurantera.',
    help: 'Te ayudamos a encontrar el mejor personal para tu compañía, ¿qué esperas para iniciar?',
    start: 'Iniciemos',
    free: 'Publicación Gratis',
    jobSearch: 'Buscar trabajo',
    easyStart: 'Únete a Chamba ',
    easy: 'con cientos de restaurantes líderes para contratar más rápido y aumentar la diversidad de tu restaurante.',
    create: 'Crea una cuenta',
    account: 'Crea tu cuenta gratis, haz click en el siguiente botón para iniciar',
    post: 'Publica tu primer trabajo',
    createJob:
      'Usa la opción de crear un trabajo en el dashboard para publicar tu primer trabajo en chamba',
    dashboard: 'Usa el dashboard',
    da: 'Dashboard',
    dash: 'Nuestro nuevo dashboard te ayudará a gestionar estadísticas, aplicantes, y otras características',
    hire: 'CONTRATA',
    smallHire: 'EN',
    timeHire: '3 DÍAS',
    instant: 'Conexión inmediata',
    allow:
      'Chamba te permite conectarte inmediatamente con candidatos interesados. Puedes ver sus perfiles y gestionar su estado usando un innovador sistema de administración',
    responsive: '¡Que no te dejen plantado!',
    page: 'Esto siempre ha sido un dolor de cabeza en el reclutamiento, especialmente para la industria restaurantera. En Chamba, nos tomamos esto muy en serio.',
    pageSecond:
      'Nuestra tecnología avanzada y nuestro Chamba Navigator maximizan las tasas de presentación de entrevistas hasta un 70% más que el promedio de la industria.',
    candidates: 'Candidatos únicos',
    candidatesContentFirst:
      'La comunidad de Chamba está compuesta al 100% por candidatos que buscan trabajo en la industria restaurantera.',
    candidatesContentSecond:
      'A diferencia de otras plataformas, en Chamba encontrarás candidatos con experiencia relevante en puestos de cocina y servicio.',
    partnerships: 'Chamba Navigator',
    personalAccountOne: 'Contratar es difícil, lo sabemos.',
    personalAccount:
      'Nuestro Chamba Navigator lo ayudará a elaborar descripciones de puestos para atraer al mejor talento. También brindamos soporte técnico de API, abastecimiento, coordinación de entrevistas y ayuda con la traducción y la incorporación.',
    contact: 'Contáctanos',
    contactText:
      'por favor proporcione la siguiente información para que la persona adecuada se ponga en contacto con usted pronto.',
    send: 'Enviar',
    ///Footer
    about: 'Nosotros',
    product: 'Producto',
    data: 'Manejor de datos',
    questions: 'Preguntas',
    terms: 'Términos',
    policies: 'Políticas',
    privacy: 'Privacidad',
    ///Header
    login: 'Ingreso de Empleador',
    home: 'Inicio',
    jobSeeker: 'Buscador de trabajo',
    language: 'Lenguaje',
    profile: 'Perfil',
    settings: 'Ajustes',
    logout: 'Cerrar sesión',
    english: 'Inglés (EN)',
    spanish: 'Español (ES)',
    //DashHeader
    postJ: 'Publicar un trabajo',
    plans: 'Nuestros planes',
    statistics: 'Estadísticas',
    manage: 'Gestionar ofertas de empleo',
    manageA: 'Gestionar candidatos',
    invite: 'Invitar a un colaborador',
    // Metrics
    jobseekers: 'Trabajadores',
    companies: 'Empresas',
    jobViews: 'Impresiones',
    hires: 'Contratados',
    news: 'Menciones',
    // Contact us Form
    businessName: 'Nombre de la empresa',
    businessEmail: 'Email empresarial',
    businessPhone: 'Teléfono',
    message: 'Escribenos un mensaje:',
    successContact:
      'Gracias por contactar con nosotros, nos pondremos en contacto con usted en breve.',
  },
};
