import { BlockUserSet, CreatePreferredUserSet } from '../../Domain/Entities/User.entity';
import { UserRepository } from '../../Domain/Repository/User.repository';

export class UserUseCase {
  private userRepository = new UserRepository();

  public async invokeCreatePreferredUser(d: CreatePreferredUserSet) {
    return this.userRepository.createPreferredUser(d);
  }

  public async invokeBlockUser(d: BlockUserSet) {
    return this.userRepository.blockUser(d);
  }
}
