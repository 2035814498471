import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import { HireListSet } from '../Entities/HireListSet';
import { dtoToHireListDto } from '../Dtos/HireList.dto';
import { dtoToHireDescription } from '../Dtos/HireDescription.dto';

export default class HireRepository {
  public async getHireList(d: HireListSet) {
    const result = await instance.post(getEndpoint(API_ENDPOINTS.SEARCH.GET_HIRE_LIST), d);
    return dtoToHireListDto(result.data);
  }

  public async getHireDescription(hireId: string) {
    const result = await instance.post(getEndpoint(API_ENDPOINTS.SEARCH.GET_HIRE_DESCRIPTION), {
      hireId,
    });

    return dtoToHireDescription(result.data);
  }
}
