import { IShiftPaginatedData } from './ShiftsCalendarSet';

export class ShiftCalendarPaginatedGet {
  constructor(
    public list: {
      metadata: {
        total: number;
        page: number;
      } | null;
      nextPage: boolean;
      data: IShiftPaginatedData[];
    }
  ) {}
}
