import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { ShiftDataGet } from '../Domain/Entities/ShiftData.entity';
import ShiftRepository from '../Domain/Repository/Shift.repository';
import { EventDataGet } from '../../Catalog/Domain/Entities/Catalog.entity';
import { dtoDraftToEntityForm } from '../Domain/Mappers/dtoToEntityForm.mapper';
import { EditShiftMapper } from '../Domain/Mappers/EditShift.mapper';
import { TFunction } from 'i18next';

export class ShiftUseCase {
  private shiftRepository = new ShiftRepository();

  public async invokeGetShift(id: string, t: TFunction, catalog?: EventDataGet) {
    const result = await this.shiftRepository.getShift(id);

    const finalObject: { form: DataFormType; data: ShiftDataGet } = {} as {
      form: DataFormType;
      data: ShiftDataGet;
    };

    if (result && catalog) {
      const shift = result.resultShiftData.shifts[0];

      const entityForm = dtoDraftToEntityForm(shift, catalog, t);

      finalObject.form = entityForm;
    }

    if (result) {
      finalObject.data = result;
    }

    return finalObject ?? undefined;
  }

  public async invokeUpdateShift(d: DataFormType, id: string) {
    const finalDto = EditShiftMapper(d, id);
    await this.shiftRepository.editShift(finalDto);
  }
}
