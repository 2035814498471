import ModalV2 from 'components/ModalV2';
import RolePresenter from './CreateRole.Presenter';
import InputV2 from 'components/InputV2';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import SelectV2 from 'components/SelectV2';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import Icon from 'assets/icons';
import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';

const CreateRoleScreen = () => {
  const presenter = RolePresenter();
  const [t] = useTranslation(['Shifts']);
  const roleName = presenter?.contextForm?.dataForm?.shiftInfo?.role?.name;
  const roleCategory = presenter?.contextForm?.dataForm?.shiftInfo?.role?.category?.text;
  const role = presenter?.contextForm?.dataForm?.shiftInfo?.role;

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold mb-2">{t('create.shiftInfo.role')}</p>
        {presenter.shouldNotShowSelect && (
          <p className="text-xs font-medium cursor-pointer" onClick={presenter.onClickClear}>
            Clear
          </p>
        )}
      </div>

      {!presenter.shouldNotShowSelect && (
        <SelectV2
          items={
            presenter.roles.data?.map((v) => ({
              id: v.id,
              text: getTextTranslated(v.positionTitle),
            })) ?? []
          }
          className="w-full rounded-lg text-base border-neutral-400 px-3 py-1 border mb-3 mt-2"
          itemSelected={presenter.selectedRole}
          name={t('create.shiftInfo.selectRole')}
          showDropDownArrow
          setSelectedItem={presenter.onClickRole}
        />
      )}

      {!presenter.shouldNotShowSelect && (
        <p
          onClick={presenter.onClickCreateRole}
          className="text-secondary-500 text-sm font-normal underline my-3 cursor-pointer"
        >
          {t('create.shiftInfo.createRole')}
        </p>
      )}

      {presenter?.contextForm?.dataForm?.shiftInfo?.role && (
        <div className="bg-neutral-200 border border-neutral-400 rounded p-4">
          <div className="flex justify-between">
            <p className="text-neutral-1600 text-base font-semibold">
              {t('create.shiftInfo.role')}
            </p>
            <div className="mr-2 cursor-pointer" onClick={() => presenter.onClickEditRole()}>
              <Icon icon="edit1" size={16} />
            </div>
          </div>

          <p className="text-neutral-1600 text-base ">
            {roleName && roleName !== ''
              ? roleName
              : roleCategory && roleCategory !== '' && roleCategory}
          </p>

          <p className="text-neutral-1600 text-base font-semibold mt-2">
            {t('create.role.description')}
          </p>
          <p className="text-neutral-1600 text-base ">{role?.information}</p>

          <p className="text-neutral-1600 text-base font-semibold mt-2">
            {t('create.role.payment')}
          </p>
          <p className="text-neutral-1600 text-base ">
            {role?.amount} / {role?.payment.text}
          </p>

          <p className="text-neutral-1600 text-base font-semibold mt-2">
            {t('create.role.experience')}
          </p>
          <p className="text-neutral-1600 text-base ">{role?.experience.text}</p>

          <p className="text-neutral-1600 text-base font-semibold mt-2">
            {t('create.role.skills')}
          </p>
          {role?.abilities.map((abilitie) => (
            <p key={abilitie.id} className="text-neutral-1600 text-base ">
              {abilitie.text}
            </p>
          ))}
        </div>
      )}

      <ModalV2
        isOpen={presenter.openedModal}
        setIsOpen={presenter.setOpenedModal}
        classNameContainer="w-full max-w-3xl overflow-y-auto"
      >
        <p className="font-bold text-primary text-2xl mb-3">{t('create.role.create')}</p>
        <form onSubmit={presenter.form.handleSubmit(presenter.onSubmit)}>
          <Controller
            control={presenter.form.control}
            name="category"
            render={({ field: { onChange, value } }) => (
              <>
                <SelectV2
                  items={presenter.catalog?.eventData.shiftPositions ?? []}
                  className="mt-3 font-regular text-sm sm:text-base text-left appearance-none text-neutral-1600 placeholder:text-neutral-1600 rounded border border-neutral-400 bg-white relative flex w-full px-3 py-0.5 placeholder-primary  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                  itemSelected={value}
                  name={t('create.role.category')}
                  showDropDownArrow
                  setSelectedItem={onChange}
                />
              </>
            )}
          />

          {presenter.form.formState.errors.category && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <InputV2
            {...presenter.form.register('name')}
            className="mt-3 text-base "
            placeholder={t('create.role.name')}
          />
          {presenter.form.formState.errors.name && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <TextArea
            {...presenter.form.register('information')}
            className="mt-3 text-base"
            rows={5}
            placeholder={t('create.role.description')}
          />

          {presenter.form.formState.errors.information && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <p className="font-bold text-primary text-xl my-4">{t('create.role.skills')}</p>
          <Controller
            control={presenter.form.control}
            name="abilities"
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                classNames={{
                  control: () => '!border-neutral-400 rounded border text-neutral-1600',
                  input: () => '!text-neutral-1600',
                  placeholder: () => '!text-neutral-1600',
                }}
                options={
                  presenter.catalog?.eventData.abilities.map((v) => ({
                    value: v.id,
                    label: v.text,
                    default: v.default,
                    text: v.text,
                    id: v.id,
                  })) ?? []
                }
                onChange={(e, meta) => {
                  if (meta.option?.default === true && meta.action === 'select-option') {
                    onChange([meta.option]);
                  } else {
                    onChange([...e.filter((value) => !value.default)]);
                  }
                }}
                value={value?.map((v) => ({
                  value: v.id,
                  label: v.text,
                  default: v.default,
                  text: v.text,
                  id: v.id,
                }))}
              />

              // <SelectV2
              //   className="mt-3 font-regular text-sm sm:text-base appearance-none text-neutral-1600 placeholder:text-neutral-1600 rounded border border-gray-300 bg-white relative block w-full px-3 py-1 placeholder-primary  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              //   items={presenter.catalog?.eventData.abilities ?? []}
              //   itemSelected={value}
              //   name={t('create.role.selectSkills')}
              //   setSelectedItem={(type) => {
              //     onChange(type);
              //   }}
              // />
            )}
          />

          {presenter.form.formState.errors.abilities && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <p className="font-bold text-primary text-xl my-4">{t('create.role.payment')}</p>
          <Controller
            control={presenter.form.control}
            name="payment"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <SelectV2
                showDropDownArrow
                className="mt-3 font-regular text-sm sm:text-base text-left appearance-none text-neutral-1600 placeholder:text-neutral-1600 rounded border border-neutral-400 bg-white relative flex w-full px-3 py-0.5 placeholder-primary  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                items={[
                  {
                    id: '56620a39-5ccb-4f47-874e-efb4851f4cbd',
                    text: t<string>('create.role.hourly'),
                  },
                  {
                    id: '3219c114-2aca-47d8-907e-a6cdecfdcc3f',
                    text: t<string>('create.role.compensation'),
                  },
                ]}
                itemSelected={value}
                name={t('create.role.paymentType')}
                setSelectedItem={onChange}
              />
            )}
          />

          {presenter.form.formState.errors.payment && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <InputV2
            {...presenter.form.register('amount')}
            className="mt-3 text-base"
            type="number"
            step={'any'}
            placeholder={t('create.role.amount')}
          />

          {presenter.form.formState.errors.amount && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          {/* <p className="font-bold text-primary text-xl my-4">{t('create.role.tipped')}</p>
          <div className="flex my-2">
            <div className="">
              <input
                type="radio"
                value={'true'}
                {...presenter.form.register('tipped')}
                className="w-4 h-4"
              />
              <label className="ml-1 mr-4">{t('create.shiftInfo.yes')}</label>
            </div>
            <div>
              <input
                type="radio"
                value={'false'}
                {...presenter.form.register('tipped')}
                className="w-4 h-4"
              />
              <label className="ml-1 mr-4">No</label>
            </div>
          </div> */}

          <p className="font-bold text-primary text-xl my-4">{t('create.role.experience')}</p>
          <Controller
            control={presenter.form.control}
            name="experience"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <SelectV2
                showDropDownArrow
                items={presenter.catalog?.eventData.shiftExperiences ?? []}
                itemSelected={value}
                name={t('create.role.selectexperience')}
                className="mt-3 font-regular text-sm sm:text-base appearance-none text-neutral-1600 placeholder:text-neutral-1600 rounded border border-gray-300 bg-white relative flex w-full px-3 py-0.5 placeholder-primary  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                setSelectedItem={onChange}
              />
            )}
          />

          {presenter.form.formState.errors.experience && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <div className="flex justify-between flex-shrink-0 mt-7">
            <Button
              onClick={presenter.onCancel}
              size="xs"
              type="button"
              name={t('create.contacts.cancel')}
              color="outline"
            />
            <Button type="submit" size="xs" name={t('create.contacts.save')} color="primary" />
          </div>
        </form>
      </ModalV2>
    </>
  );
};

export default CreateRoleScreen;
