import { InferType, array, object, string } from 'yup';

export const ContactFormSchema = object({
  name: string().required(),
  role: string().required(),
  email: string().required(),
  phoneNumber: string().required(),
  id: string(),
});

export interface SaveContactSet {
  name: string;
  role: string;
  email: string;
  phoneNumber: string;
  businessId: string;
}
export interface EditContactnSet extends SaveContactSet {
  id: string;
}

export const ContactFetchSchema = array(ContactFormSchema);

export type ContactFormSchemaType = InferType<typeof ContactFormSchema>;
export type ContactFetchSchemaType = InferType<typeof ContactFetchSchema>;

export const ValidateContact = async (d: object): Promise<boolean> => {
  try {
    await ContactFetchSchema.validate(d);
    return true;
  } catch {
    return false;
  }
};

export const ValidateFetchContact = async (d: object) => {
  try {
    const result = await ContactFetchSchema.validate(d);
    return result;
  } catch {
    return undefined;
  }
};
