export class ShiftPaginationGet {
  constructor(
    public resultShiftPagination: {
      shiftListItems: {
        id: string;
        positionTitle: string;
        location: string;
        startDate: string;
        timeZone: string;
        endDate: string;
        openings: number;
        totalApplicants: number;
        isTotalCompensation: boolean;
        salaryAmount: number;
        totalCost: number;
        isActive: boolean;
      }[];
      totalItems: number;
    }
  ) {}
}
