import { ShowToastError } from 'utils';
import { API_ENDPOINTS, getEndpoint } from '../api.endpoints';
import api from '../http';
import { GetProsType } from './models';

export interface IGetGeneralJobseekers {
  filters: {
    location?: {
      latitude: number;
      longitude: number;
    };
  };
  businessId: string;
  page: number;
}

export type IGetJobseekers = {
  filters: {
    location?: {
      latitude: number;
      longitude: number;
    };
  };
} & Omit<IGetGeneralJobseekers, 'filters'>;

export interface JobseekersCards {
  userId: string;
  blockedUserId: string;
  preferredUserId: string;
  name: string;
  userImage: string;
  totalShifts: number;
  rating?: number;
  ratesNumber: number;
}

export interface IJobseekersResponse {
  jobseekerCards: Array<JobseekersCards>;
  totalItems: number;
}

export const GeJobseekers = async (
  params: IGetGeneralJobseekers,
  type: GetProsType
): Promise<IJobseekersResponse> => {
  try {
    let url = '';
    switch (type) {
      case 'blocked':
        url = API_ENDPOINTS.SEARCH.GET_BLOCKED_JOBSEEKERS;
        break;
      case 'general':
        url = API_ENDPOINTS.SEARCH.GET_GENERAL_JOBSEEKERS;
        break;
      case 'preferred':
        url = API_ENDPOINTS.SEARCH.GET_PREFERRED_JOBSEEKERS;
        break;
      default:
        url = API_ENDPOINTS.SEARCH.GET_RECENT_JOBSEEKERS;
        break;
    }
    const response = await api.post<IJobseekersResponse>(getEndpoint(url), params);
    return response.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const GetRecentJobseekers = async (params: IGetJobseekers): Promise<IJobseekersResponse> => {
  try {
    const response = await api.post<IJobseekersResponse>(
      getEndpoint(API_ENDPOINTS.SEARCH.GET_RECENT_JOBSEEKERS),
      params
    );
    return response.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};
