export default {
  en: {
    titleW2: 'What type of Pros do you need?',
    ///Step1
    title: 'Chamba business free trial',
    description:
      'With chamba business free trial you can create an account, post a job and manage it with our dashboard',
    create: 'Create an account',
    onboard: 'Complete the onboard process',
    use: 'Use the dashboard to post your first job',
    start: "Let's start",
    ///Step2
    remember:
      'Remember that you can upgrade your account at any time by selecting one of our plans on the dashboard',
    createWith: 'Create an account with',
    or: 'Or',
    name: 'Name',
    email: 'Email',
    confirm: 'Confirm email',
    password: 'Password',
    confirmPass: 'Confirm password',
    noMatch: 'Passwords do not match',
    accept: 'I accept the terms and conditions, click here to read them',
    register: 'Sign up',
    ///Step3
    sent: 'We have sent an email to',
    verify: 'please verify your email with the code',
    type: 'Type the code here',
    confirmMail: 'Confirm',
    resend: 'Resend code',
  },
  es: {
    titleW2: '¿Qué tipo de Pros necesita?',
    ///Step1
    title: 'Prueba gratuita de Chamba business',
    description:
      'Con la prueba gratuita de chamba business podrás crear una cuenta, publicar un trabajo y gestionarlo con nuestro dashboard',
    create: 'Crea una cuenta',
    onboard: 'Completa la información de tu empresa',
    use: 'Usa el dashboard para publicar tu primer trabajo',
    start: 'Empecemos',
    ///Step2
    remember:
      'Recuerda que puedes actualizar tu cuenta en cualquier momento seleccionando uno de nuestros planes en el dashboard',
    createWith: 'Crea una cuenta con',
    or: 'O',
    name: 'Nombre',
    email: 'Correo electrónico',
    confirm: 'Confirmar correo electrónico',
    password: 'Contraseña',
    confirmPass: 'Confirmar contraseña',
    noMatch: 'Las contraseñas no coinciden',
    accept: 'Acepto los términos y condiciones, click aquí para leerlos',
    register: 'Registrarse',
    ///Step3
    sent: 'Hemos enviado un correo a',
    verify: 'por favor verifica tu email con el código',
    type: 'Digita el código aquí',
    confirmMail: 'Confirm',
    resend: 'Re enviar código',
  },
};
