import { LocationSchemaType, SaveLocationSet } from '../Dtos/Location';
export const locationFormToDto = (
  data: LocationSchemaType,
  businessId: string
): SaveLocationSet => {
  return {
    locationInfo: data.info || '',
    parkingInfo: data.parking,
    location: {
      topic: data.location.location.topic || '',
      address: data.location.location.address,
      notificationsTopic: data.location.location.notificationsTopic || '',
    },
    locationCoords: {
      latitude: data.location.locationCoords.latitude,
      longitude: data.location.locationCoords.longitude,
    },
    clockInInfo: data.clockInInfo,
    businessId,
  };
};
