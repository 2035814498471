export default {
  en: {
    selectCountry: 'Select country',
    invalid: 'This field is required',
    previous: 'Previous',
    next: 'Next',
    showing: 'Showing',
    to: 'to',
    of: 'of',
    results: 'results',
    use: 'Use setting',
    print: 'Print QR code',
    logout: 'Log out',
    block: 'Your account has been temporarily suspended. To resume, please contact us',
    notAvailable: 'Chamba currently is not operating in the selected area, Please contact us at ',
    forHelp: ' for help',
    noItems: 'No items available',
    loading: 'Loading...',
    scanQr: 'Scan QR code',
    scanDescription1: 'Pros should scan this QR code using',
    scanDescription2: 'Chamba app clock in & out button',
    loadingDesc: 'This may take a few seconds, please dont close this page.',
  },
  es: {
    selectCountry: 'Seleccione un país',
    invalid: 'Campo requerido',
    previous: 'Anterior',
    next: 'Siguiente',
    showing: 'Mostrando',
    to: 'a',
    of: 'de',
    results: 'resultados',
    use: 'Usar configuración',
    print: 'Imprimir código QR',
    logout: 'Cerrar sesión',
    scanQr: 'Escanea código QR',
    scanDescription1: 'Los Pros deben escanear este código QR usando ',
    scanDescription2:
      'la app de Chamba con el botón de Clock in & out para marcar sus tiempos de salida, entrada y descansos.',
    block:
      'Su cuenta ha sido suspendida temporalmente. Para reanudarla, póngase en contacto con nosotros:',
    notAvailable:
      'Chamba actualmente no está operando en el área seleccionada. Por favor contáctanos en ',
    forHelp: ' para obtener ayuda.',
    noItems: 'No hay elementos disponibles',
    loading: 'Cargando...',
    loadingDesc: 'Esto puede tardar varios segundos, no cierres esta página',
  },
};
