import { HireUseCase } from 'pages/hire/core/Modules/Hire/Application/HireUseCases';
import toast from 'react-hot-toast';

const hireApp = new HireUseCase();

const IndexActionDescription = () => {
  const executeGetDescription = (hireId: string) => {
    try {
      return hireApp.invokeGetDescription(hireId);
    } catch {
      toast.error('Something went wrong');
    }
  };

  return { executeGetDescription };
};

export default IndexActionDescription;
