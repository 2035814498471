import { useEffect, useMemo, useRef, useState } from 'react';
import ShiftsTableAction from './ShiftsTable.Action';
import { ShiftPaginationSet } from 'pages/shift/core/Modules/Shifts/Domain/Entities/ShiftPaginationSet';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import useUser from 'utils/hooks/useUser';
import debounce from 'lodash.debounce';
import useConfirm from 'utils/confirmation';
import { useTranslation } from 'react-i18next';

const ShiftsTablePresenter = () => {
  const [startDate, setStartDateFilter] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState({ id: '1', text: 'Upcoming' });
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const [searchBarText, setSearchBarText] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeoutRef = useRef<any>(null);
  const [t] = useTranslation(['Shifts']);

  const [page, setPage] = useState(0);
  const {
    business: { id },
  } = useUser();
  const router = useNavigate();

  const actions = ShiftsTableAction();
  const { getConfirmation, Confirmation } = useConfirm();

  const filter = useMemo<ShiftPaginationSet>(() => {
    return {
      filters: {
        ...(startDate ? { startDate: startDate.toISOString() } : undefined),
        upcoming: selectedTime.id === '1',
        ...(searchBarText ? { name: searchBarText } : undefined),
      },
      page: page + 1,
    };
  }, [startDate, page, selectedTime, searchBarText]);

  const getQueryPostedShifts = useQuery(['get-posted-shifts', filter], () =>
    actions.executeGetShiftsPaginated(filter)
  );

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected);
  };

  const onClickCode = () => {
    router(RoutesConstant.unsecure.event + '/' + id);
  };

  const setStartDate = (d?: Date) => {
    setPage(0);
    setStartDateFilter(d);
  };

  const onClickResetFilter = () => {
    setStartDate(undefined);
    setSelectedTime({
      id: '1',
      text: 'Upcoming',
    });
    setQuery('');
  };

  const onChangeBar = (text: string) => setSearchBarText(text);

  const onClickDetails = (shiftId: string) => navigate(shiftId);

  const onClickCreateShift = () => {
    navigate(RoutesConstant.secure.postShift);
  };

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;

      const barFunction = debounce(onChangeBar, 300);
      barFunction(query);
    }, 500);
  }, [query]);

  const onClickDeleteShift = async (id: string) => {
    const data = getQueryPostedShifts.data?.resultShiftPagination.shiftListItems;

    const itemShift = data?.find((v) => v.id === id) ?? data?.[0];

    const is24h = actions.executeIs24(t, itemShift?.timeZone, itemShift?.startDate);

    let text = 'Do you confirm to delete this shift?';
    if (is24h) {
      text =
        'You are deleting a shift within 24 hours of the start time, you may get charged for 4 hours for those who already accepted this shift. Do you confirm? ';
    }

    const result = await getConfirmation(text);

    if (result) {
      actions.executeDeleteShift(id).then(() => getQueryPostedShifts.refetch());
    }
  };

  const onClickCreateManualShift = () => {
    router(RoutesConstant.secure.createShiftExcel);
  };

  return {
    getQueryPostedShifts,
    handlePageClick,
    onClickCode,
    setStartDate,
    setSelectedTime,
    selectedTime,
    startDate,
    onClickResetFilter,
    setQuery,
    onClickDetails,
    onClickCreateShift,
    onClickDeleteShift,
    Confirmation,
    onClickCreateManualShift,
  };
};

export default ShiftsTablePresenter;
