import { KeyboardEventHandler, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepPaymentContext } from '..';
import useUser from 'utils/hooks/useUser';
import Tooltip from 'components/TooltipDeprecated';
import CreatableSelect from 'components/CreatableSelect';
import { Option } from 'actions/shift/model';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { emailOptions, getStepPaymentName } from 'utils';

const ModalPaymentStep3 = (): JSX.Element => {
  const { email: value, setEmail: setValue, selectedPayment } = useContext(StepPaymentContext);
  const {
    business: { email },
  } = useUser();

  const [t] = useTranslation(['Shifts']);
  const { trackEvent } = useMatomo();

  const [inputValue, setInputValue] = useState('');

  const createOption = (label: string, prev: Option[]): Option[] => [
    ...prev,
    {
      label,
      value: label,
    },
  ];

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue(createOption(inputValue, value));
        setInputValue('');
        event.preventDefault();
    }
  };

  useEffect(() => {
    trackEvent({
      action: 'open-modal-payment',
      category: 'Shift',
      value: 1,
      name: 'step',
    });
  }, []);

  return (
    <>
      <div className="flex mb-7">
        <h1 className="font-bold text-4xl text-primary m-0">{t('paymentModal.title')}</h1>
        <div className="flex ml-4 items-center content-center">
          <span className="border p-2  border-neutral-200 rounded-xl flex content-center items-center">
            {getStepPaymentName(selectedPayment)}
            <Tooltip>
              <p className="max-w-[1400px] text-sm text-white">{t('paymentShift.changePayment')}</p>
            </Tooltip>
          </span>
        </div>
      </div>
      <p className="mb-4 font-semibold text-primary">{t('emails')}</p>
      <CreatableSelect
        isMulti={true}
        isClearable={true}
        inputValue={inputValue}
        onChange={(newValue) => setValue(newValue as Option[])}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        value={value}
        defaultValue={{ label: email, value: email }}
        options={emailOptions(email)}
      />
    </>
  );
};

export default ModalPaymentStep3;
