import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @deprecated Use new InputV2
 */
const Input = ({
  label,
  register,
  name,
  validation,
  defaultValue,
  placeholder,
  type,
  errors,
  rows,
  customStyle,
}: IInput): JSX.Element => {
  const [t] = useTranslation(['Others']);
  const errorMessage = React.useMemo(() => {
    if (errors) {
      if (errors.message !== '') {
        return errors.message;
      }
      return t('invalid');
    }
    return null;
  }, [errors]);
  return (
    <div>
      <label className="sr-only">{label}</label>
      {type === 'textarea' ? (
        <textarea
          id={name}
          type={type}
          autoComplete={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
          rows={rows}
          className={
            customStyle
              ? customStyle
              : `mt-4 font-medium text-sm sm:text-lg appearance-none rounded-lg relative block w-full px-3 py-2 border border-none bg-grayBack placeholder-primary text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10`
          }
          placeholder={placeholder}
        />
      ) : (
        <input
          id={name}
          type={type}
          onWheel={(e) => e.currentTarget.blur()}
          autoComplete={name}
          defaultValue={defaultValue}
          {...register(name, validation)}
          className={
            customStyle
              ? customStyle
              : `mt-4 font-medium text-sm sm:text-lg appearance-none rounded-lg relative block w-full px-3 py-2 border border-none bg-grayBack placeholder-primary text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10`
          }
          placeholder={placeholder}
        />
      )}

      <small className=" text-warnings-500 text-left mt-1">{errorMessage}</small>
    </div>
  );
};

export default Input;
