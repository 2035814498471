import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import { dtoToEntityShift } from '../Dtos/GetShift.dto';
import { ShiftDataGet } from '../Entities/ShiftData.entity';
import { EditShiftSet } from '../Dtos/EditShiftSet.dto';

class ShiftRepository {
  public async getShift(id: string) {
    const result = await instance.get(
      getEndpoint(API_ENDPOINTS.EVENTS.GET_EVENT.replace('{id}', id))
    );

    const dto = await dtoToEntityShift(result.data);

    if (dto) {
      return new ShiftDataGet(dto);
    }
  }

  public async editShift(d: EditShiftSet) {
    await instance.post(getEndpoint(API_ENDPOINTS.EVENTS.POST_EDIT_EVENT), d);
  }
}

export default ShiftRepository;
