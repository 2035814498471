import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from 'store';
import { IGeneralReducer } from '.';

const getState = (state: GlobalState): IGeneralReducer => state.general;

export const getCategories = createSelector(getState, (state: IGeneralReducer) => state.categories);

export const openMenuData = createSelector(getState, (state: IGeneralReducer) => state.openMenu);
export const showMenuButton = createSelector(getState, (state) => state.showMenuButton);
