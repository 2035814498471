import { HTMLAttributes, PropsWithChildren } from 'react';

type ICard = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const Card = ({ children, ...props }: ICard) => {
  return (
    <div className="bg-white px-10 py-9 rounded-xl" {...props}>
      {children}
    </div>
  );
};

export default Card;
