export class ShiftDescriptionGet {
  constructor(
    public description: {
      id: string;
      eventId: string;
      positionId: string;
      positionTitle: string;
      businessId: string;
      businessName: string;
      businessLogo: string;
      location: string;
      postedDate: string;
      startDate: string;
      endDate: string;
      breakTime: number;
      locationCoords: {
        latitude?: number;
        longitude?: number;
      };
      openings: number;
      isTotalCompensation?: boolean;
      salaryTotal: number;
      salaryHour: number;
      shiftHours: number;
      parkingInfo: string;
      locationInfo: string | null;
      clockInInfo: string | null;
      timeZone: string;
      abilities: string[];
      experience: string;
      uniform: string;
      dressCode: string;
      groomingRestrictions: string[];
      jobInformation: null | string;
      contacts: {
        name: string;
        role: string;
        phoneNumber: string;
        email: string;
      }[];
      appliedShift: boolean;
      clockIn: string | null;
      clockOut: string | null;
      breakStart: string | null;
      breakEnd: string | null;
      isActive: boolean;
      started: boolean;
      isW2: boolean;
      onlyFavorites: boolean;
      isUserFavorite: boolean;
      cancelledWithin24Hours?: boolean;
    }
  ) {}
}
