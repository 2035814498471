import i18next from 'i18next';

export const TranslateLoginExceptions = (code: string): string => {
  if (code === 'UserNotConfirmedException') {
    return i18next.t('AuthActionText:login.verified');
  } else if (code === 'PasswordResetRequiredException') {
    return i18next.t('AuthActionText:login.required');
  } else if (code === 'NotAuthorizedException') {
    return i18next.t('AuthActionText:login.fail');
  } else if (code === 'UserNotFoundException') {
    return i18next.t('AuthActionText:login.exist');
  } else if (code === 'InvalidParameterException') {
    return i18next.t('AuthActionText:login.invalid');
  } else {
    return i18next.t('AuthActionText:login.default');
  }
};

export const TranslateEmailConfirmation = (code: string): string => {
  if (code === 'CodeMismatchException') {
    return i18next.t('AuthActionText:code.mismatch');
  } else if (code === 'ExpiredCodeException') {
    return i18next.t('AuthActionText:code.expired');
  } else if (code === 'NotAuthorizedException') {
    return i18next.t('AuthActionText:login.fail');
  } else if (code === 'UserNotFoundException') {
    return i18next.t('AuthActionText:login.exist');
  } else if (code === 'LimitExceededException') {
    return i18next.t('AuthActionText:code.limit');
  } else if (code === 'TooManyFailedAttemptsException') {
    return i18next.t('AuthActionText:code.many');
  } else {
    return i18next.t('AuthActionText:login.default');
  }
};

export const TranslateSignUpExceptions = (code: string): string => {
  if (code === 'UserNotConfirmedException') {
    return 'Cuenta no verificada.';
  } else if (code === 'PasswordResetRequiredException') {
    return 'Se requiere cambio de contraseña.';
  } else if (code === 'NotAuthorizedException') {
    return 'La contraseaña debería ser más fuerte.';
  } else if (code === 'UserNotFoundException') {
    return 'El usuario no existe.';
  } else if (code === 'InvalidParameterException') {
    return 'Usuario o contraseña inválidos.';
  } else if (code === 'UsernameExistsException') {
    return 'El correo ingresado ya se encuentra en uso.';
  } else {
    return 'No se ha podido crear el usuario, por favor inténtelo nuevamente.';
  }
};
