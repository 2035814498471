import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import { dtoToTemplateListGet } from '../Dtos/TemplatesList.dto';
import { ITEMS_PER_PAGE } from 'utils/constants';
import CreateTemplateSet from '../Dtos/CreateTemplateSet.dto';

export class TemplateRepository {
  public async getTemplates(businessId: string, page: number) {
    const request = await instance.get(
      getEndpoint(API_ENDPOINTS.EVENTS.GET_SHIFT_TEMPLATE.replace('{id}', businessId)),
      {
        headers: {
          'x-page': page.toString(),
          'x-limit': ITEMS_PER_PAGE,
        },
      }
    );

    return dtoToTemplateListGet(request.data);
  }

  public async createTemplate(data: CreateTemplateSet) {
    const request = await instance.post(
      getEndpoint(API_ENDPOINTS.EVENTS.CREATE_SHIFT_TEMPLATE),
      data
    );

    return request.data;
  }
}
