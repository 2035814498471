import Button from 'components/Button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from 'components/Sheet';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputFilter from './filter-input';

type EditTipProps = {
  children: JSX.Element;
  tipFromBackend?: number;
  setTipValue: Dispatch<SetStateAction<string | undefined>>;
  tipValue?: string;
  shiftId: string;
  handleEditTips: () => void;
};

const EditTips = ({
  children,
  tipValue,
  setTipValue,
  tipFromBackend,
  handleEditTips,
}: EditTipProps) => {
  const [t] = useTranslation(['Timesheet']);

  useEffect(() => {
    if (tipFromBackend) {
      setTipValue(tipFromBackend.toString());
    }
  }, [tipFromBackend]);

  return (
    <Sheet>
      <SheetTrigger>{children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{t('sheetTip.title')}</SheetTitle>
          <div className="!mt-4">
            <InputFilter
              value={tipValue}
              onChange={(e) => setTipValue(e.target.value)}
              placeholder={t('sheetTip.update')}
            />
            <div className="mt-5">
              <Button disabled={!tipValue} name="Update" size="sm" onClick={handleEditTips}>
                {t('sheetTip.update')}
              </Button>
            </div>
          </div>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default EditTips;
