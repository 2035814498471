import toast from 'react-hot-toast';
import { InferType, array, number, object, string } from 'yup';
import { GetInvoices } from '../Entities/GetInvoicesEntity';

export enum StatusInvoice {
  PENDING = 0,
  PAID = 1,
  PAST_DUE = 2,
}

export const InvoiceListSchema = object({
  businessInvoices: array(
    object({
      id: string().required(),
      paymentOption: number().required(),
      consecutive: number().required(),
      startDate: string().required(),
      endDate: string().required(),
      invoiceDate: string().required(),
      dueDate: string().required(),
      totalAmount: number().required(),
      status: number().required(),
    })
  ).required(),
  totalItems: number().required(),
}).required();

export type InvoiceListDto = InferType<typeof InvoiceListSchema>;

export const dtoToInvoiceList = async (dto: object) => {
  try {
    const validatedDto = await InvoiceListSchema.validate(dto);

    return new GetInvoices(validatedDto);
  } catch (e) {
    console.error(e);
    toast.error('Please contact support');
  }
};
