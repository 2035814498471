import { getCategoryText } from 'pages/postShift/shared/utils/get-text-translated';
import { InferType, array, boolean, number, object, string } from 'yup';
import { EventDataGet } from '../../../Catalog/Domain/Entities/Catalog.entity';

export interface SaveRoleSet {
  businessId: string;
  positionTitle: string;
  positionId: string;
  salaryType: string;
  salaryAmount: number;
  jobInformation?: string;
  abilities: string[];
  experience: string;
}

export interface EditRoleSet extends SaveRoleSet {
  id: string;
}

export const RoleSchema = object({
  idRole: string(),
  category: object({
    id: string().required(),
    text: string().required(),
  }).required(),

  name: string().required(),
  information: string(),
  payment: object({
    id: string().required(),
    text: string().required(),
  }).required(),
  amount: number().min(1).required(),
  // tipped: bool().required(),
  experience: object({
    id: string().required(),
    text: string().required(),
  }).required(),
  abilities: array(
    object({
      id: string().required(),
      text: string().required(),
      default: boolean().required(),
    })
  ).required(),
});

export type RoleSchemaType = InferType<typeof RoleSchema>;

export const ValidateRole = async (d: object): Promise<boolean> => {
  try {
    await RoleSchema.validate(d);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const RoleFetchSingle = object({
  id: string().required(),
  businessId: string().required(),
  positionTitle: object({
    english: string().required(),
    spanish: string().required(),
  })
    .required()
    .nullable(),
  positionId: string().required(),
  salaryType: string().required(),
  salaryAmount: number().required(),
  jobInformation: object({
    english: string().required(),
    spanish: string().required(),
  }).nullable(),
  abilities: array(string().required()).required(),
  experience: string().required(),
});

export const RoleFetchSchema = array(RoleFetchSingle);

export type RoleFetchSchemaType = InferType<typeof RoleFetchSchema>;
export type RoleFetchSingleSchemaType = InferType<typeof RoleFetchSingle>;

export const ValidateFetchRole = async (d: object[], catalog?: EventDataGet) => {
  try {
    const result = await RoleFetchSchema.validate(d);

    return result?.map((v) => ({
      ...v,
      positionTitle: v.positionTitle ?? {
        english: getCategoryText(v.positionId, catalog),
        spanish: getCategoryText(v.positionId, catalog),
      },
    }));
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
