import { DateTime } from 'luxon';
import { HTMLAttributes, useMemo } from 'react';

export type ApprovedOrPending = 'approved' | 'pending' | undefined;

type MiniCalendarProps = {
  startDate: string;
  endDate: string;
  timeSheetStatus: null | number;
  selected: { startDate: string; endDate: string };
} & HTMLAttributes<HTMLDivElement>;

const MiniCalendar = ({
  startDate,
  timeSheetStatus,
  endDate,
  selected,
  ...props
}: MiniCalendarProps) => {
  const statusMemo = useMemo(() => {
    if (timeSheetStatus === 1) {
      return 'bg-neutral-1000';
    } else if (timeSheetStatus === 0) {
      return 'bg-secondary-500';
    }

    return 'bg-transparent';
  }, [timeSheetStatus]);

  const isSelected = useMemo(() => {
    return selected.endDate === endDate && selected.startDate === startDate;
  }, [selected]);

  return (
    <div
      {...props}
      className={`cursor-pointer ${
        isSelected === true ? 'border-neutral-1000 bg-secondary-200' : 'border-transparent'
      } border-t-[1px] border-b-[1px]`}
    >
      <div className="border-l-[1px] border-neutral-1000 border-r-[1px] border-xs px-5 py-2 ml-[-1px] text-center flex flex-col justify-center">
        <span className="font-normal text-[10px]">
          {DateTime.fromISO(startDate).toFormat('ccc')}
        </span>
        <span className=" font-normal text-xl">{DateTime.fromISO(startDate).toFormat('dd')}</span>
        <div className={`${statusMemo} w-[6px] h-[6px] rounded-full text-center m-auto`} />
      </div>
    </div>
  );
};

export default MiniCalendar;
