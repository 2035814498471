/* eslint-disable @typescript-eslint/no-explicit-any */
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { IGetInvoiceDetails, PaymentOptionEnum } from 'actions/business/model';
import { DateTime } from 'luxon';

const styles = StyleSheet.create({
  table: {
    width: '100%',
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 10,
  },
  body: {
    padding: 0,
  },
  header: {
    borderTop: 'none',
    paddingLeft: 5,
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 20,
    paddingLeft: 20,
  },
  // So Declarative and unDRY 👌
  row1: {
    width: '20%',
  },
  row2: {
    width: '20%',
  },
  row3: {
    width: '10%',
  },
  row4: {
    width: '12%',
  },
  row5: {
    width: '12%',
  },
  row6: {
    width: '13%',
  },
  row7: {
    width: '11%',
  },
  totalCost: {
    textAlign: 'center',
    color: '#00214c',
    marginRight: 20,
  },
  itemHeader: {
    marginBottom: 10,
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 15,
    paddingLeft: 15,
  },
});

type IReportTable = {
  type: 'shift' | 'day';
  details: Array<{
    name: string;
    isTotalCompensation: boolean;
    positionTitle: string;
    salaryTotal: number;
    salaryHour: number;
    clockIn: string;
    clockOut: string;
    breakTime: number;
    laborCost: number;
  }>;
};

export type IGetInvoiceDetailsDocument = {
  date: string;
  name: string;
  title: string;
  pros: number;
  data: IReportTable;
  fee: number;
  total: number;
};

const ReportTable = ({
  data,
  paymentOption,
  positionTitle,
  startDate,
  endDate,
}: {
  data: IGetInvoiceDetails['details'];
  paymentOption: PaymentOptionEnum;
  positionTitle: string | null;
  startDate: string;
  endDate: string;
}): JSX.Element => {
  return (
    <View>
      <View style={{ marginTop: 12 }} fixed />
      <View fixed={false} style={{ backgroundColor: '#00214c', height: '45' }}>
        <Text style={{ textAlign: 'center', color: 'white', marginTop: 12 }}>
          {paymentOption === PaymentOptionEnum.InvoicePerShift
            ? positionTitle !== null
              ? positionTitle
              : ''
            : paymentOption === PaymentOptionEnum.InvoicePerDay
              ? `${DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_MED)}`
              : ` Week ${DateTime.fromISO(startDate).toLocaleString(
                  DateTime.DATE_MED
                )} - ${DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}`}
        </Text>
      </View>
      {data.map((e, key) => (
        <View key={key}>
          {e.day && (
            <Text
              style={{
                paddingHorizontal: 20,
                paddingTop: 10,
                fontSize: 11,
                fontFamily: 'Helvetica-Bold',
              }}
            >
              {DateTime.fromISO(e.day).toLocaleString(DateTime.DATE_MED)}
            </Text>
          )}
          <View style={styles.table}>
            <View style={[styles.row, styles.header]}>
              <Text
                style={[
                  styles.row1,
                  styles.bold,
                  {
                    width:
                      paymentOption === PaymentOptionEnum.InvoicePerShift ||
                      paymentOption == PaymentOptionEnum.InvoicePerWeek
                        ? '40%'
                        : '20%',
                  },
                ]}
              >
                Name
              </Text>
              {(paymentOption === PaymentOptionEnum.InvoicePerDay ||
                paymentOption == PaymentOptionEnum.InvoicePerWeek) && (
                <>
                  <Text style={[styles.row2, styles.bold]}>Position</Text>
                  <Text style={[styles.row3, styles.bold]}>Rate</Text>
                </>
              )}
              <Text style={[styles.row4, styles.bold]}>Clock In</Text>
              <Text style={[styles.row5, styles.bold]}>Clock out</Text>
              <Text style={[styles.row6, styles.bold]}>Unpaid hrs</Text>
              <Text style={[styles.row7, styles.bold]}>Labor cost</Text>
            </View>

            {e.applicants.map((v, key) => (
              <>
                <View
                  key={key}
                  style={[
                    styles.row,
                    {
                      backgroundColor: key % 2 == 0 ? '#ededed' : '#d7d7d7',
                      borderRadius: 10,
                      paddingLeft: 5,
                      marginBottom: 10,
                    },
                  ]}
                  wrap={false}
                >
                  <Text
                    style={[
                      styles.row1,
                      {
                        width:
                          paymentOption === PaymentOptionEnum.InvoicePerShift ||
                          paymentOption == PaymentOptionEnum.InvoicePerWeek
                            ? '40%'
                            : '20%',
                      },
                    ]}
                  >
                    {v.name}
                  </Text>
                  {(paymentOption === PaymentOptionEnum.InvoicePerDay ||
                    paymentOption == PaymentOptionEnum.InvoicePerWeek) && (
                    <>
                      <Text style={styles.row2}>{v.positionTitle}</Text>
                      <Text style={styles.row3}>${v.salaryHour || 0}</Text>
                    </>
                  )}
                  <Text style={styles.row4}>
                    {v.clockIn
                      ? DateTime.fromISO(v.clockIn).toLocaleString(DateTime.TIME_SIMPLE)
                      : 'No time'}
                  </Text>
                  <Text style={styles.row5}>
                    {v.clockOut
                      ? DateTime.fromISO(v.clockOut).toLocaleString(DateTime.TIME_SIMPLE)
                      : 'No time'}
                  </Text>
                  <Text style={styles.row6}>{`${v.breakTime} mins` || 0}</Text>
                  <Text style={styles.row7}>${v.laborCost || 0}</Text>
                </View>
              </>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
};

const NewShiftDocument = ({
  invoiceDate,
  paymentOption,
  businessName,
  totalAmount,
  totalPros,
  serviceFee,
  details,
  startDate,
  endDate,
  consecutive,
  positionTitle,
  totalHours,
  isMarkdown,
}: IGetInvoiceDetails): any => {
  return (
    <Document>
      <Page style={styles.body}>
        <View fixed={false} style={{ backgroundColor: '#00214c', height: '90' }}>
          <Image
            style={{ width: '150', paddingLeft: '30' }}
            src="https://chamba-bucket.s3.amazonaws.com/Defaults/landing-page/SEO-logo.jpg"
          />
          <View
            style={{
              position: 'absolute',
              color: 'white',
              right: 40,
              padding: 10,
              fontSize: 14,
              top: 17,
            }}
          >
            <Text>Issue Date: </Text>
            <Text>{DateTime.fromISO(invoiceDate).toLocaleString(DateTime.DATE_MED)}</Text>
          </View>
        </View>
        <View
          style={{
            padding: '20',
            fontSize: 15,
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>To:</Text>{' '}
            <Text style={{ marginLeft: 10 }}>{businessName}</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>From:</Text>
            <Text style={{ marginLeft: 10 }}>Chamba Inc.</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Invoice:</Text>
            <Text style={{ marginLeft: 10 }}>#{consecutive}</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Email:</Text>
            <Text style={{ marginLeft: 10 }}>team@lachamba.app</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Total Pros:</Text>
            <Text style={{ marginLeft: 10 }}>{totalPros}</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Bank account:</Text>
            <Text style={{ marginLeft: 10 }}>3915858367</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Total paid hours:</Text>
            <Text style={{ marginLeft: 10 }}>{totalHours}</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Routing number:</Text>
            <Text style={{ marginLeft: 10 }}>083009060</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Invoice amount:</Text>
            <Text style={{ marginLeft: 10 }}>${totalAmount.toFixed(2)}</Text>
          </View>
          <View style={styles.itemHeader}>
            <Text style={styles.bold}>Address:</Text>
          </View>
          <View style={styles.itemHeader}></View>
          <View style={styles.itemHeader}>
            <Text>{`2785 w 104th pl Westminster CO 80234`}</Text>
          </View>
        </View>
        <View style={{ fontSize: 12, margin: 10 }}>
          <Text>
            In accordance with our terms and conditions, a 1.5% late fee will accrue on the 1st
            business day of each calendar month during which your payment for this invoice remains
            past due. To avoid this financing fee, please review the details below and coordinate
            payment at your earliest convenienc
          </Text>
        </View>

        <View style={{}}>
          <ReportTable
            data={details}
            paymentOption={paymentOption}
            endDate={endDate}
            positionTitle={positionTitle}
            startDate={startDate}
          />
          {/* <ReportTable details={data.details} type={data.type} /> */}

          <View
            style={{
              width: '92%',
              height: 1,
              backgroundColor: 'black',
              marginTop: 50,
              marginHorizontal: 20,
            }}
          />
          {!isMarkdown && (
            <View
              style={[
                styles.row,
                styles.flex,
                {
                  backgroundColor: '#d7d7d7',
                  borderRadius: 10,
                  paddingLeft: 5,
                  marginTop: 10,
                  marginLeft: 20,
                  marginRight: 20,
                },
              ]}
              wrap={false}
            >
              <Text>Chamba service fee</Text>
              <Text>${serviceFee}</Text>
            </View>
          )}
          <Text style={styles.totalCost}>Total cost ${totalAmount.toFixed(2)}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default NewShiftDocument;
