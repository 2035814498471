export default {
  en: {
    title: 'How can we give you support?',
    pageTitle: 'Support',
    call: 'Call us or send us an email with your problem',
    giveCall: 'Give us a call',
    callText: "Call us for quick support! We're here to assist you promptly.",
    hours: 'Hours today: ',
    time: 'From 9:00 AM to 5:00 PM',
    sendEmail: 'Send us an email',
    emailText: "Email us for support with attachments! We're here to assist you promptly.",
    phone: 'Phone',
  },
  es: {
    title: '¿Cómo podemos ayudarte?',
    pageTitle: 'Soporte',
    call: 'Llámanos o envíanos un correo con tus problemas',
    giveCall: 'Llámanos',
    callText: '¡Llámanos para recibir asistencia rápida! Estamos aquí para ayudarte con prontitud.',
    hours: 'Horario: ',
    time: 'De 9:00 AM a 5:00 PM',
    sendEmail: 'Envíanos un correo',
    emailText:
      '¡Envíanos un correo electrónico si necesita ayuda con archivos adjuntos! Estamos aquí para ayudarte con prontitud.',
    phone: 'Teléfono',
  },
};
