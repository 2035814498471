export enum Days {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const AllDays = () => {
  return [
    Days.Monday,
    Days.Tuesday,
    Days.Wednesday,
    Days.Thursday,
    Days.Friday,
    Days.Saturday,
    Days.Sunday,
  ];
};

export const WeekDays = () => {
  return [Days.Monday, Days.Tuesday, Days.Wednesday, Days.Thursday, Days.Friday];
};
