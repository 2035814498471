import { EventDataGet } from '../../Catalog/Domain/Entities/Catalog.entity';
import { RoleSchemaType } from '../Domain/Dtos/Role.dto';
import { roleFormToDto } from '../Domain/Mappers/roleFormToDto';
import { RoleRepository } from '../Domain/Repository/Role.repository';

export class RoleUseCases {
  private roleRepository: RoleRepository;

  constructor() {
    this.roleRepository = new RoleRepository();
  }

  public async invokeGet(id: string, catalog?: EventDataGet) {
    return this.roleRepository.get(id, catalog);
  }

  public async invokeSave(d: RoleSchemaType, businessId: string) {
    const dto = roleFormToDto(d, businessId);
    return this.roleRepository.save(dto);
  }

  public async invokeEdit(d: RoleSchemaType, idRole: string, businessId: string) {
    const dto = roleFormToDto(d, businessId);
    return this.roleRepository.edit({ ...dto, businessId, id: idRole });
  }
}
