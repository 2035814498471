import Attire from '../createShift/Attire/Attire.Screen';
import Confirm from '../createShift/Confirm/Confirm.Screen';
import ShiftInfo from '../createShift/ShiftInfo/ShiftInfo.Screen';
import Worker from '../createShift/Worker/Worker.Screen';
import StepperProvider from 'pages/postShift/core/Infrastructure/Providers/Stepper.provider';
import IndexPresenter from './Index.Presenter';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

const IndexScreen = () => {
  const presenter = IndexPresenter();
  const [t] = useTranslation(['Shifts']);

  return (
    <div className="w-full relative max-h-screen overflow-y-auto">
      <div className=" bg-white w-full max-w-[800px] m-auto py-8 min-h-screen">
        <h2 className="text-primary font-bold text-xl px-16 pb-4">{t('create.post')}</h2>
        <hr className="text-neutral-600 w-full" />
        {presenter.alreadySettedPrevious ? (
          <div className="overflow-hidden">
            <StepperProvider>
              <ShiftInfo stepName={'shiftInfo'} />
              <Worker stepName={'worker'} />
              <Attire stepName={'attire'} />
              <Confirm stepName={'confirm'} />
            </StepperProvider>
          </div>
        ) : (
          <div className="p-3">
            <Skeleton width={'100%'} height={'80vh'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default IndexScreen;
