import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type ContainerProps = {
  children: JSX.Element;
} & HTMLAttributes<HTMLInputElement>;

const Container = ({ children, className, ...props }: ContainerProps) => {
  return (
    <div className={twMerge('pt-7  pl-12 pr-12 min-h-screen w-full', className)} {...props}>
      {children}
    </div>
  );
};

export default Container;
