export default {
  en: {
    signUp: 'Sign Up',
    continue: 'Continue',
    onboarding: {
      messageTax:
        'You can request either independent contractors (1099) or Chamba employees (W2) from the platform base on your need.',
      mainTitleW2: 'What type of Pros do you need?',
      mark: 'Mark up fee',
      title: '1099 Pros (Chamba Independent Contractors)',
      titleW2: 'W2 Pros (Chamba Employees)',
      descriptionW2: 'Great for longer contracts with defined set schedules and conditions.',
      description:
        'More flexible for seasonal work and events. Ideal for companies whose staffing needs fluctuate frequently.',
    },
    form: {
      fullName: 'Full Name',
      email: 'Email',
      phoneNumber: 'Phone Number',
      companyName: 'Company Name',
      companyCategory: 'Company Category',
      hearAboutUs: 'Where did you hear about us?',
    },
    message: 'Chamba provides vetted and insured pros for your shift',
    required: 'This field is required',
    terms: 'Terms & Conditions',
    bySing: 'By sign up you accept our ',
    alreadyHaveAccount: 'Already have an account',
    login: 'Login in here',
  },
  es: {
    continue: 'Continue',
    alreadyHaveAccount: 'Ya tienes una cuenta',
    login: 'Inicia sesión aquí',
    signUp: 'Regístrate',
    form: {
      fullName: 'Nombre completo',
      email: 'Email',
      phoneNumber: 'Número de teléfono',
      companyName: 'Nombre de la empresa',
      companyCategory: 'Categoría de la empresa',
      hearAboutUs: '¿Dónde has oído hablar de nosotros?',
    },
    message: 'Chamba proporciona profesionales examinados y asegurados para su turno',
    required: 'Este campo es obligatorio',
    termos: 'Términos y condiciones',
    bySing: 'Al registrarte aceptas nuestros ',
    onboarding: {
      messageTax:
        'Puede solicitar contratistas independientes (1099) o empleados de Chamba (W2) desde la plataforma en función de sus necesidades. ',
      mainTitleW2: '¿Qué tipo de Pro necesita?',
      mark: 'Tasa de recargo',
      title: 'Trabajadores “Prestación de servicios” o “Contrato ocasional”',
      titleW2: 'Trabajadores con contrato “Indefinido, Obra labor o Fijo”',
      descriptionW2: 'Ideal para contratos más largos con horarios y condiciones definidos',
      description:
        'Más flexible para trabajos de temporada y eventos. Ideal para empresas cuyas necesidades de personal fluctúan con frecuencia.',
    },
  },
};
