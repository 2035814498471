import {
  CreateDisputeRequestSet,
  DisputeOption,
  DisputeType,
  FormType,
} from '../../Domain/Entities/CreateDisputeFormSet';
import { DisputeRepository } from '../../Domain/Repository/Disputes.repository';

export class DisputeUseCase {
  private disputeRepository = new DisputeRepository();

  public async invokeCreateDispute(
    data: FormType,
    selectedDisputes: DisputeOption[],
    businessId: string,
    businessUserId: string,
    executionId: string
  ) {
    const hasNoShow = selectedDisputes.find((e) => e.id === DisputeType.NoShow);

    const createDisputeBody: CreateDisputeRequestSet = {
      executionId,
      businessId,
      businessUserId,
      disputeDetails: null,
    };

    if (!hasNoShow) {
      createDisputeBody.disputeDetails = selectedDisputes.map((e) => {
        switch (e.id) {
          case DisputeType.ClockIn:
            return {
              additionalInfo: data.additionalClockIn,
              disputeType: e.id,
              proposedDate: this.transformDisputeDate(data.clockInDate, data.clockInTime),
            };

          case DisputeType.ClockOut:
            return {
              additionalInfo: data.additionalClockOut,
              disputeType: e.id,
              proposedDate: this.transformDisputeDate(data.clockOutDate, data.clockOutTime),
            };

          case DisputeType.BreakEnd:
            return {
              additionalInfo: data.additionalBreakTimeEnd,
              disputeType: e.id,
              proposedDate: data.breakTimeEnd,
            };

          case DisputeType.BreakStart:
            return {
              additionalInfo: data.additionalBreakTimeStart,
              disputeType: e.id,
              proposedDate: data.breakTimeStart,
            };

          case DisputeType.NoShow:
            return {
              additionalInfo: '',
              disputeType: e.id,
              proposedDate: '',
            };
        }
      });
    } else {
      createDisputeBody.noShow = true;
      createDisputeBody.noShowInfo = data.additionalInformationNoShow;
      createDisputeBody.disputeDetails = null;
    }

    await this.disputeRepository.createDispute(createDisputeBody);
  }

  private transformDisputeDate(date?: Date, time?: string) {
    if (date !== undefined && time !== undefined) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();

      const timeComponents = time.split('T')[1].split('.')[0].split(':');
      const hours = parseInt(timeComponents[0]);
      const minutes = parseInt(timeComponents[1]);
      const seconds = parseInt(timeComponents[2]);

      const combinedDate = new Date(year, month, day, hours, minutes, seconds);

      return combinedDate.toISOString();
    }
  }
}
