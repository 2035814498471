import { ShowToastError } from 'utils';
import { API_ENDPOINTS, getEndpoint } from '../api.endpoints';
import api from '../http';
import request from '../api.wrapper';
import {
  IChangePaymentMethod,
  ICompany,
  IDeleteRequestData,
  IEditProfileApiRequest,
  IFullBusinessUser,
  IGetInvoiceDetails,
  IGetWorkHours,
  IGetWorkHoursResult,
  IOnBoardingApiRequest,
  IPostHire,
  IPostMassive,
  UpdateLang,
} from './model';
import axios from 'axios';
import { PostDraftDto } from 'pages/postShift/core/Modules/Checkout/Domain/Dtos/PostDraft.dto';

export const OnBoardBusiness = async (data: IOnBoardingApiRequest): Promise<ICompany> => {
  try {
    const result = await api.post<ICompany>(
      getEndpoint(API_ENDPOINTS.BUSINESS.ONBOARD_BUSINESS),
      data
    );

    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const UpdateProfilePhoto = async (data: FormData): Promise<ICompany> => {
  const result = await axios.post(getEndpoint(API_ENDPOINTS.BUSINESS.UPDATE_PROFILE_PHOTO), data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return result.data;
};

export const GetUsersManagement = async (id: string): Promise<IFullBusinessUser[]> => {
  const result = await api.get<IFullBusinessUser[]>(
    getEndpoint(API_ENDPOINTS.BUSINESS.GET_ALL_BUSINESS, { id })
  );
  return result.data;
};

export const DeleteBusinessUser = async (id: string): Promise<void> => {
  try {
    await api.delete(getEndpoint(API_ENDPOINTS.BUSINESS.DELETE_BUSINESS_USER, { id }));
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const UpdateBusinessRole = async (id: string, role: number): Promise<void> => {
  await api.post(getEndpoint(API_ENDPOINTS.BUSINESS.UPDATE_BUSINESS_ROLE), { id, role });
};

export const ChangeNotification = async (id: string, email: boolean): Promise<void> => {
  await api.post(getEndpoint(API_ENDPOINTS.BUSINESS.MANAGE_NOTIFICATIONS), { id, email });
};

export const EditBusinessLanguage = async (opt: UpdateLang): Promise<boolean> => {
  return await api.post(getEndpoint(API_ENDPOINTS.BUSINESS.UPDATE_LANGUAGE), opt);
};

export default class BusinessService {
  public static async updatePaymentOption(data: IChangePaymentMethod) {
    return request<IChangePaymentMethod>({
      method: 'POST',
      url: API_ENDPOINTS.BUSINESS.UPDATE_PAYMENT_OPTION,
      data: data,
    });
  }

  public static async postEvent(data: PostDraftDto) {
    return request({
      method: 'POST',
      data: data,
      url: API_ENDPOINTS.EVENTS.POST_DRAFT,
    });
  }

  public static async postMassive(data: IPostMassive) {
    return request({
      method: 'POST',
      url: API_ENDPOINTS.EVENTS.POST_MASSIVE,
      data,
    });
  }

  public static async getInvoice(id?: string) {
    return request<IGetInvoiceDetails>({
      method: 'POST',
      data: {
        invoiceId: id,
      },
      url: API_ENDPOINTS.BUSINESS.GET_INVOICE,
    });
  }

  public static async deleteBusiness(data: IDeleteRequestData) {
    return request({
      method: 'POST',
      data,
      url: API_ENDPOINTS.BUSINESS.DELETE_BUSINESS,
    });
  }

  public static async editBusinessProfile(data: IEditProfileApiRequest) {
    return request({
      method: 'POST',
      data,
      url: API_ENDPOINTS.BUSINESS.EDIT_PROFILE,
    });
  }

  public static async getUserWorkedHours(data: IGetWorkHours) {
    return request<IGetWorkHoursResult>({
      method: 'POST',
      url: API_ENDPOINTS.EVENTS.GET_USER_WORKED_HOURS,
      data,
    });
  }

  public static async createHire(data: IPostHire) {
    return request({
      method: 'POST',
      url: API_ENDPOINTS.EVENTS.CREATE_HIRE,
      data,
    });
  }
}
