import * as yup from 'yup';
import { HireStatusEnum, hireStatusValues } from './HireList.dto';
import toast from 'react-hot-toast';
import { HireDescriptionGet } from '../Entities/HireDescription.entity';

const locationCoordsSchema = yup.object().shape({
  latitude: yup.number().required(),
  longitude: yup.number().required(),
});

const HireDescriptionSchema = yup.object().shape({
  hireId: yup.string().required(),
  positionTitle: yup.string().required(),
  startDate: yup.string().required(),
  firstDayInstructions: yup.string().required(),
  paymentType: yup.string().required(),
  paymentAmount: yup.number().required(),
  benefits: yup.string().required(),
  location: yup.string().required(),
  locationCoords: locationCoordsSchema.required(),
  status: yup.mixed<HireStatusEnum>().oneOf(hireStatusValues).required(),
});

export type HireDescriptionType = yup.InferType<typeof HireDescriptionSchema>;

export const dtoToHireDescription = async (dto: object) => {
  try {
    const validateDto = await HireDescriptionSchema.validate(dto);
    return new HireDescriptionGet(validateDto);
  } catch (e) {
    if (e instanceof yup.ValidationError) {
      console.log(e.errors);
    }
    toast.error('Please contact support');
  }
};
