import Icon from 'assets/icons';
import FloatingDialog from 'components/FloatingDialog';
import { useState } from 'react';

type ImageAvatarType = {
  size: number;
  url: string;
  roundedAvatar?: boolean;
};

const ImageAvatar = ({ size, url }: ImageAvatarType) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen ? (
        <FloatingDialog isOpen={isOpen} setIsOpen={setIsOpen}>
          <div>
            <div
              onClick={() => setIsOpen(false)}
              style={{ transform: 'translateY(26px)' }}
              className="relative top-0 right-0 text-right pr-3 cursor-pointer"
            >
              <Icon icon="close" size={20} />
            </div>
            <img key={url} src={url} style={{ maxWidth: 400 }} />
          </div>
        </FloatingDialog>
      ) : (
        <img
          onClick={() => setIsOpen(true)}
          src={url}
          style={{ width: size, height: size }}
          className="rounded-full"
        />
      )}
    </>
  );
};

export default ImageAvatar;
