import { ICategories } from 'actions/catalog/catalog.api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGeneralReducer {
  categories: ICategories[] | null;
  openMenu: boolean;
  showMenuButton: boolean;
}

const initialState: IGeneralReducer = {
  categories: null,
  openMenu: true,
  showMenuButton: false,
};

const generalSlice = createSlice({
  name: 'general',
  initialState: initialState,
  reducers: {
    setMenu: (state: IGeneralReducer, action: PayloadAction<boolean>) => {
      state.openMenu = action.payload;
    },
    setMenuButton: (state: IGeneralReducer, action: PayloadAction<boolean>) => {
      state.showMenuButton = action.payload;
    },
  },
});

export const { setMenu, setMenuButton } = generalSlice.actions;
export default generalSlice.reducer;
