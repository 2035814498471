export default {
  en: {
    title: 'Profile',
    edit: 'Edit',
    contact: 'Contact phone number',
    plan: 'Your plan',
    emailAddress: 'Email address:',
    change: 'Change plan',
    teamMember: 'Team Member',
    payments: 'Payments',
    phoneNumber: 'Phone Number:',
    WeeklyInvoice: 'Weekly Invoice',
    category: 'Category',
    newMember: 'Add a new member',
    ///Edit
    modified: 'Your profile has been modified',
    name: 'Company name',
    logo: 'Company logo',
    drop: 'Drag here the company logo in jpg or png',
    select: 'Select a logo in png or jpg from your device',
    desc: 'Company description',
    description: 'Please describe your company in 100 words max',
    email: 'Company email',
    phone: 'Company phone number',
    type: 'Type of restaurant',
    choose: 'You must select something',
    location: 'Locations of your restaurant',
    save: 'Save',
    success: 'Profile phto had been changed',
    paymentShift: {
      createEmail: 'Add email',
      breadcumb: 'Once a payment method is selected, you need to contact us at',
      change: 'for changing that',
      method1: 'Auto charge',
      method1description:
        'Chamba will charge your card automatically after each shift is completed',
      method2: 'Per shift invoice (Only for partners with formal contract)',
      method2description:
        'Chamba will send invoice to your email address after each shift is completed',
      method3: 'Daily invoice (Only for partners with formal contract)',
      method3description: 'Chamba will send invoice to your email address on a daily basis',
      method4: 'Weekly invoice (Only for partners with formal contract)',
      method4description: 'Chamba will send invoice to your email address on a weekly basis',
      changePayment: 'To change the payment type, you need to contact us at team@lachamba.app',
    },
    company: {
      title: 'Company',
      email: 'Email',
      category: 'Category',
      name: 'Full name',
      phone: 'Phone number',
      save: 'Save changes',
      info: 'Company info',
      edit: 'Edit',
    },
  },
  es: {
    title: 'Perfil',
    edit: 'Editar',
    teamMember: 'Equipo',
    payments: 'Info de Pagos',
    WeeklyInvoice: 'Factura Semanal',
    emailAddress: 'Correo:',
    category: 'Categoría',
    phoneNumber: 'Número de teléfono:',
    newMember: 'Agregar colaborador',

    contact: 'Teléfono de contacto',
    plan: 'Tu plan',
    change: 'Cambiar plan',
    ///Edit
    modified: 'Tu perfil ha sido modificado',
    name: 'Nombre de la compañía',
    logo: 'Logo de la compañía',
    drop: 'Arrastra aquí el logo de la compañía en jpg o png',
    select: 'Selecciona un logo en png o jpg de tu dispositivo',
    desc: 'Descripción de la compañía',
    description: 'Por favor describe tu compañía en máximo 100 palabras',
    email: 'Email de la compañía',
    phone: 'Teléfono de la compañía',
    type: 'Tipo de restaurante',
    choose: 'Debes seleccionar algo',
    location: 'Ubicaciones de tu restaurante',
    save: 'Guardar',
    success: 'Se ha guardado la foto con éxito',

    paymentShift: {
      breadcumb: 'Una vez seleccionado un método de pago, debe ponerse en contacto con nosotros en',
      change: 'para cambiarlo',
      method1: 'Cargo automático',
      method1description:
        'Chamba cargará el importe automáticamente en su tarjeta una vez finalizado cada turno',
      method2: 'Factura por turno (Sólo para socios con contrato formal)',
      method2description:
        'Chamba enviará factura a su dirección de correo electrónico después de completar cada turno',
      method3: 'Factura diaria (Sólo para socios con contrato formal)',
      method3description:
        'Chamba enviará la factura a su dirección de correo electrónico diariamente',
      method4: 'Factura semanal (Sólo para socios con contrato formal)',
      method4description:
        'Chamba enviará la factura a su dirección de correo electrónico semanalmente',
      changePayment:
        'Para cambiar el tipo de pago, debe ponerse en contacto con nosotros en team@lachamba.app',
      createEmail: 'Añadir nuevo email',
    },
    company: {
      title: 'Compañía',
      email: 'Email',
      category: 'Categoría',
      name: 'Nombre completo',
      phone: 'Número de teléfono',
      save: 'Guardar cambios',
      info: 'Información de la empresa',
      edit: 'Editar',
    },
  },
};
