import { DateTime } from 'luxon';
import { ListSheetUseCase } from 'pages/timesheet/core/Modules/Timesheet/Applications/UseCases/ListSheetUseCase';
import { UpdateShiftUseCase } from 'pages/timesheet/core/Modules/Timesheet/Applications/UseCases/UpdateShiftUseCase';
import { UserUseCase } from 'pages/timesheet/core/Modules/Timesheet/Applications/UseCases/UserUseCase';
import { CreateIndividualRateSet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/CreateIndividualRateSet';
import { ListShiftSet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/ListShiftSet';
import { UpdateTipSet } from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/UpdateTipSet';
import {
  BlockUserSet,
  CreatePreferredUserSet,
} from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/User.entity';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/hooks/useUser';

const UseCases = new ListSheetUseCase();
const ShiftUseCases = new UpdateShiftUseCase();
const UserUseCases = new UserUseCase();

const TimeSheetAction = () => {
  const {
    business: { id },
  } = useUser();

  const [t] = useTranslation(['Timesheet']);

  const executeGetListSheet = (weekDate: DateTime) => {
    try {
      const result = UseCases.invokeGetListSheet({ businessId: id, weekDate });
      return result;
    } catch {
      toast.error('Something went wrong');
    }
  };

  const executeGetShiftList = (data: Omit<ListShiftSet, 'businessId'>) => {
    try {
      const result = UseCases.invokeGetShiftList({ ...data, businessId: id });
      return result;
    } catch {
      toast.error('Something went wrong');
    }
  };

  const executeCreateIndividualRate = async (data: Omit<CreateIndividualRateSet, 'businessId'>) => {
    try {
      await ShiftUseCases.invokeCreateRate({ ...data, businessId: id });
      toast.success('Rate added');
    } catch {
      toast.error('Something went wrong');
    }
  };

  const executeUpdateTip = async (data: UpdateTipSet) => {
    try {
      await ShiftUseCases.invokeUpdateTip(data);
      toast.success(t('sheetTip.successUpdate'));
    } catch {
      toast.error('Something went wrong');
    }
  };

  const executeApprovedExecutions = async (ids: string[]) => {
    try {
      await ShiftUseCases.invokeCreateApproved({ executionIds: ids });
      toast.success(t('sheetTip.successExecutions'));
    } catch {
      toast.error('Something went wrong');
    }
  };

  const executeCreatePreferredUser = async (d: CreatePreferredUserSet) => {
    try {
      await UserUseCases.invokeCreatePreferredUser(d);
      toast.success(t('sheetTip.successPreferred'));
    } catch {
      toast.error('Something went wrong');
    }
  };

  const executeBlockUser = async (d: BlockUserSet) => {
    try {
      await UserUseCases.invokeBlockUser(d);
      toast.success(t('sheetTip.successBlock'));
    } catch {
      toast.error('Something went wrong');
    }
  };

  return {
    executeGetListSheet,
    executeGetShiftList,
    executeCreateIndividualRate,
    executeUpdateTip,
    executeApprovedExecutions,
    executeCreatePreferredUser,
    executeBlockUser,
  };
};

export default TimeSheetAction;
