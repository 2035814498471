import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  arrow,
  FloatingArrow,
} from '@floating-ui/react';
import { useRef, useState } from 'react';

interface ITooltip {
  children: React.ReactNode;
  content: string;
  background?: string;
}

const Tooltip = ({ content, children, background }: ITooltip) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <>
      <button ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </button>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className="bg-white py-2 px-3 rounded-xl text-xs"
          style={{ ...floatingStyles, backgroundColor: background || 'white' }}
          {...getFloatingProps()}
        >
          {content}
          <FloatingArrow style={{ fill: background || 'white' }} ref={arrowRef} context={context} />
        </div>
      )}
    </>
  );
};

export default Tooltip;
