import { Calendar } from 'react-big-calendar';
import ShiftCalendarPresenter from './ShiftsCalendar.Presenter';
import { ColoredDateCellWrapper, Toolbar } from 'pages/shift/shared/components/calendar-items';
import ModalMoreEvents from './ModalMoreEvents';

const ShiftCalendarScreen = () => {
  const presenter = ShiftCalendarPresenter();

  return (
    <div className="mt-2">
      <Calendar
        components={{
          toolbar: Toolbar,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          eventWrapper: ColoredDateCellWrapper as any,
        }}
        localizer={presenter.calendarConfig.localizer}
        events={presenter.myEvents}
        startAccessor="start"
        endAccessor="end"
        views={{ month: true }}
        selectable={true}
        onSelectEvent={presenter.onClickEvent}
        style={{ minHeight: '90vh' }}
        onNavigate={(date) => presenter.onNavigateCalendar(date)}
        popup={false}
        dayLayoutAlgorithm={'no-overlap'}
        doShowMoreDrillDown={false}
        onShowMore={presenter.onClickShowMore}
      />
      <ModalMoreEvents
        dateSelected={presenter.openShowMore}
        setDateSelected={presenter.setOpenShowMore}
        onSelectEvent={presenter.onClickEventById}
      />
    </div>
  );
};

export default ShiftCalendarScreen;
