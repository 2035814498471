export class ListSheetGet {
  constructor(
    public timesheetWeekItems: {
      dayStart: string;
      dayEnd: string;
      timesheetStatus: number | null;
      allowChanges: boolean;
    }[]
  ) {}
}
