import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getBusiness } from 'store/business/selectors';
import { RoutesConstant } from 'utils/constants';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Form from './components/Form';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import { REACT_APP_CDN } from 'utils/enviroment';
import { useTranslation } from 'react-i18next';
import { IOnBoardingApiRequest } from 'actions/business/model';
import W2Select from './components/W2';
import { setLoading } from 'store/business';
import { OnBoardBusinessThunk } from 'store/business/api.thunks';
import { useAppDispatch } from 'store';

const onBoarding = (): JSX.Element => {
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const business = useSelector(getBusiness);
  const dispatch = useAppDispatch();

  const [instance, setInstance] = useState<Partial<StepWizardChildProps>>({});
  const [data, setData] = useState<IOnBoardingApiRequest>();
  const [t] = useTranslation(['OnBoarding']);

  useEffect(() => {
    if (business && business.id) {
      navigate(RoutesConstant.secure.shift);
    }
  }, [business]);

  useEffect(() => {
    trackPageView({
      documentTitle: 'On boarding',
      href: window.location.href,
    });
  }, []);

  const onSubmit = async () => {
    if (data) {
      dispatch(setLoading(true));
      await dispatch(OnBoardBusinessThunk(data)).unwrap();
      navigate(RoutesConstant.secure.shift);
    }
  };

  const nextStep = () => {
    if (instance && instance.nextStep) {
      instance.nextStep();
    }
  };

  return (
    <div className="flex w-full  top-0 left-0 bg-gray-200 h-screen">
      <div className="flex w-full">
        <div className="w-full lg:w-1/2 p-10 sm:p-20 xl:p-40 self-center">
          <StepWizard instance={setInstance}>
            <Form setData={setData} nextStep={nextStep} />
            <W2Select setData={setData} data={data} onSubmit={onSubmit} />
          </StepWizard>
        </div>
        <div className="w-1/2 bg-lightBlue/30 hidden lg:flex justify-center">
          <div className="self-center">
            <img
              src={
                instance?.currentStep === 1
                  ? `${REACT_APP_CDN}/auth-icon.svg`
                  : `${REACT_APP_CDN}/register/w2.svg`
              }
              alt={`${REACT_APP_CDN}/auth-icon.svg`}
              className="pl-10 w-full"
            />
            <div className="w-full text-center mt-5">
              <h3 className=" px-24 font-bold text-primary text-2xl">
                {instance?.currentStep === 1 ? t('message') : t('onboarding.messageTax')}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default onBoarding;
