import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import { REACT_APP_CDN } from 'utils/enviroment';

export interface PrintQrProps {
  image: string;
}

const PrintQr = ({ image }: PrintQrProps): JSX.Element => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Print QR',
      href: window.location.href,
    });
  }, []);

  return (
    <div className="w-full">
      <div className="mt-10 mb-14 mx-6">
        <h1 className="text-center font-bold text-4xl">Clock-in & Clock-out</h1>
        <div className="w-full flex justify-center mt-10">
          {image && (
            <div
              style={{
                background: image,
                width: 300,
                height: 300,
              }}
            />
          )}
        </div>
        <div className="w-full flex justify-center ">
          <div className="w-full">
            <h3 className="text-primary font-bold text-3xl text-center my-10">How to clock-in</h3>
            <div className="flex justify-center">
              <img
                src={`${REACT_APP_CDN}/qr-instructions.png`}
                alt={`${REACT_APP_CDN}/qr-instructions.png`}
                className="w-[81%]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary text-white flex w-full p-10 flex-shrink-0">
        <div className="w-1/2 font-bold text-lg flex justify-center self-center">
          <div>
            <p className="pb-3">Need support? Call us 1 (720) 217-5864</p>
            <p>To join Chamba? Text us 1 (720) 217-5864</p>
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <img
            src={`${REACT_APP_CDN}/img/chamba-logo-white.svg`}
            alt={`${REACT_APP_CDN}/img/chamba-logo-white.svg`}
            className="w-3/5"
          />
        </div>
      </div>
    </div>
  );
};

export default PrintQr;
