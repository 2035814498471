import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Loading = (): JSX.Element => {
  const { trackPageView } = useMatomo();
  const [t] = useTranslation(['Others']);

  useEffect(() => {
    trackPageView({
      documentTitle: 'Loading',
      href: window.location.href,
    });
  }, []);

  return (
    <div className="bg-primary fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 className="text-center text-white text-xl font-semibold">{t('loading')}</h2>
      <p className="w-1/3 text-center text-white">{t('loadingDesc')}</p>
    </div>
  );
};

export default Loading;
