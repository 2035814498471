import { Dialog } from '@headlessui/react';
import { CloseSession } from 'actions/auth.api';
import Icon from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBusiness, getCognitoUser } from 'store/business/selectors';
import { REACT_APP_CDN } from 'utils/enviroment';

const DisabledAccount = () => {
  const business = useSelector(getBusiness);
  const user = useSelector(getCognitoUser);

  const [t] = useTranslation(['Others']);

  return (
    <Dialog
      as="div"
      className="relative z-10"
      open={!business?.isActive && user?.businessId !== null}
      onClose={() => {
        //
      }}
    >
      <div className="fixed inset-0 bg-black/60" aria-hidden="true" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-[26.29px] text-left align-middle shadow-xl transition-all">
            <div className="mt-2">
              <img
                src={`${REACT_APP_CDN}/Blocked.svg`}
                alt={`${REACT_APP_CDN}/Blocked.svg`}
                className="w-[100%]"
              />
              <p className="text-sm text-black font-semibold text-center mt-5">{t('block')}</p>

              <p className="text-center mt-5 text-blue-500">
                <Icon icon="phone" size={19} />
                <span className="ml-4 text-xl text-blue-500">720 217 5864</span>
              </p>
              <div className="w-full">
                <button
                  onClick={CloseSession}
                  className="bg-primary mt-5 text-white px-4 py-2 rounded-full w-full"
                >
                  {t('logout')}
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default DisabledAccount;
