import { DateTime } from 'luxon';
import {
  ApplicantRejectSet,
  ApplicantShiftSet,
  ApplicantStatusSet,
} from '../Domain/Entities/ApplicantShift.entity';
import { UserRepository } from '../Domain/Repository/User.repository';
import { TIME_ZONE } from 'utils/constants/days';
import { TFunction } from 'i18next';

export class UserUseCase {
  private userRepository = new UserRepository();

  public async invokeGetApplicantShift(d: ApplicantShiftSet) {
    return this.userRepository.getShiftApplicants(d);
  }

  public async invokeAddToFavorite(d: ApplicantStatusSet) {
    return this.userRepository.addApplicantToFavorites(d);
  }

  public async invokeAddToBlocked(d: ApplicantStatusSet) {
    return this.userRepository.addApplicantToBlock(d);
  }

  public async invokeRejectApplicant(d: ApplicantRejectSet) {
    return this.userRepository.rejectApplicant(d);
  }

  public is24hRefuse = (t: TFunction, timeZone?: string, startDate?: string) => {
    const timezoneData = TIME_ZONE(t).find((v) => v.id == timeZone) ?? TIME_ZONE(t)[0];
    const result = DateTime.fromISO(startDate ?? '').setZone(timezoneData.key);
    const now = DateTime.now().setZone(timezoneData.key);

    return Math.abs(result.diff(now, 'hour').hours) <= 24;
  };
}
