import Page404 from './Texts/404';
import ApplicantsProfile from './Texts/ApplicantProfile';
import ConfirmEmail from './Texts/ConfirmEmail';
import Forgot from './Texts/Forgot';
import FreeTrial from './Texts/FreeTrial';
import Home from './Texts/Home';
import Login from './Texts/Login';
import auth from './Texts/Login/auth';
import OnBoarding from './Texts/OnBoarding';
import Others from './Texts/Others';
import Payment from './Texts/Payment';
import Profile from './Texts/Profile';
import Register from './Texts/Register';
import Settings from './Texts/Settings';
import Shifts from './Texts/Shifts';
import ShiftApplicants from './Texts/ShiftApplicants';
import Support from './Texts/Support';
import Timesheet from './Texts/Timesheet';
import massive from './Texts/Shifts/massive';
import Invoice from './Texts/Invoice';
import Hire from './Texts/Hire';

export default {
  AuthActionText: auth.es,
  Page404: Page404.es,
  ApplicantsProfile: ApplicantsProfile.es,
  ConfirmEmail: ConfirmEmail.es,
  Forgot: Forgot.es,
  FreeTrial: FreeTrial.es,
  Home: Home.es,
  Login: Login.es,
  OnBoarding: OnBoarding.es,
  Others: Others.es,
  Payment: Payment.es,
  Profile: Profile.es,
  Register: Register.es,
  Settings: Settings.es,
  Shifts: Shifts.es,
  ShiftApplicants: ShiftApplicants.es,
  Invoice: Invoice.es,
  Support: Support.es,
  Timesheet: Timesheet.es,
  Massive: massive.es,
  Hire: Hire.es,
};
