/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useTypeahead,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useClick,
  useRole,
  Placement,
} from '@floating-ui/react';
import Icon from 'assets/icons';
import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface ISelectV2 {
  items: IItems[];
  itemSelected: IItems | undefined;
  setSelectedItem: (v: IItems) => void;
  disabled?: boolean;
  name: string;
  className?: string;
  customContent?: React.ReactNode;
  showDropDownArrow?: boolean;
  customDropDownArrow?: JSX.Element;
  placement?: Placement;
  maxHeight?: number;
  showUsePortal?: boolean;
  error?: FieldError;
  icon?: JSX.Element;
}

const SelectV2 = ({
  items: options,
  itemSelected,
  setSelectedItem: setSelectedIndex,
  disabled,
  name,
  className,
  showDropDownArrow,
  customContent,
  customDropDownArrow,
  error,
  placement = 'bottom-start',
  icon,
}: ISelectV2) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [t] = useTranslation(['Others']);

  const selectedIndex = useMemo(() => {
    return options.findIndex((e) => e.id === itemSelected?.id);
  }, [itemSelected]);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  const listContentRef = React.useRef(options.map((e) => e.text));
  const isTypingRef = React.useRef(false);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: setActiveIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    typeahead,
    click,
  ]);

  const handleSelect = (index: number) => {
    setSelectedIndex(options[index]);
    setIsOpen(false);
  };

  const selectedItemLabel =
    selectedIndex !== null && selectedIndex !== -1 ? options[selectedIndex].text : undefined;

  return (
    <>
      <button
        tabIndex={0}
        ref={refs.setReference}
        type="button"
        aria-labelledby="select-label"
        aria-autocomplete="none"
        style={{ width: '100%', lineHeight: 2, margin: 'auto', transition: '1s linear' }}
        className={twMerge('flex items-center justify-between outline-none', className)}
        disabled={disabled}
        {...getReferenceProps()}
      >
        {icon && icon}
        <span className=" text-neutral-1600">
          {customContent ? customContent : selectedItemLabel || name}
        </span>
        <span className="flex inset-y-0 items-center pr-2 pointer-events-none">
          {showDropDownArrow &&
            (customDropDownArrow ? (
              customDropDownArrow
            ) : (
              <Icon
                icon="Polygon"
                size={12}
                color="black"
                style={{ marginLeft: 8, marginTop: 2, transform: isOpen ? 'rotate(-180deg)' : '' }}
              />
            ))}
        </span>
      </button>
      {error && (
        <small className="text-warnings-400 text-left mt-1">
          {error && (error.message ?? t('invalid'))}
        </small>
      )}
      {isOpen && (
        <>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className="overflow-y-auto outline-0 bg-white shadow-lg rounded-lg z-10 border-neutral-400"
              style={{
                ...floatingStyles,
                minWidth: 100,
              }}
              {...getFloatingProps()}
            >
              <>
                {options.map((value, i) => (
                  <div
                    key={value.id}
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    aria-selected={i === selectedIndex && i === activeIndex}
                    style={{
                      padding: 10,
                      cursor: 'pointer',
                    }}
                    className={
                      i === activeIndex ? 'border-2 rounded-md' : 'border-2 border-transparent'
                    }
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect(i);
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(i);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          handleSelect(i);
                        }
                      },
                    })}
                  >
                    {value.text}
                    <span
                      aria-hidden
                      style={{
                        right: 10,
                        paddingLeft: 10,
                      }}
                    >
                      {i === selectedIndex ? ' ✓' : ''}
                    </span>
                  </div>
                ))}
                {!options.length && <p className="p-3 text-neutral-1400">{t('noItems')}</p>}
              </>
            </div>
          </FloatingFocusManager>
        </>
      )}
    </>
  );
};

export default SelectV2;
