import { Dialog, Transition } from '@headlessui/react';
import Icon from 'assets/icons';
import { Fragment, useEffect, useState } from 'react';

export interface IModal {
  title?: string;
  text?: string;
  icon?: string;
  closeButtonText?: string;
  secondButtonText?: string;
  secondButtonColor?: string;
  secondButtonAction?: () => void;
  modalOpen: boolean;
  actionClose: () => void;
  customComponent?: JSX.Element;
  bgColor?: string;
  noShadow?: boolean;
  secondButtonTextColor?: string;
}
/**
 * @deprecated Use new ModalV2 instead
 */
const Modal = ({
  title,
  text,
  icon,
  closeButtonText,
  secondButtonText,
  secondButtonColor,
  modalOpen,
  actionClose,
  customComponent,
  bgColor,
  noShadow,
  secondButtonTextColor,
  secondButtonAction,
}: IModal): JSX.Element => {
  const [isOpen, setIsOpen] = useState(modalOpen);

  const closeModal = () => {
    setIsOpen(modalOpen);
  };

  secondButtonColor = 'bg-' + secondButtonColor;
  secondButtonTextColor = 'text-' + secondButtonTextColor;

  useEffect(() => {
    setIsOpen(modalOpen);
  }, [modalOpen]);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center bg-black/80 inset-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ minHeight: '30vh' }}
                className={` inline-block p-6 my-8 text-left align-middle transition-all transform ${
                  bgColor ? 'bg-[' + { bgColor } + ']' : 'bg-white'
                } ${!noShadow && 'shadow-xl'}  rounded-sm`}
              >
                <div className="relative right-0 flex justify-end pt-1">
                  <button
                    type="button"
                    onClick={() => actionClose()}
                    className="flex justify-center items-center outline-none text-[black] z-50"
                  >
                    <Icon
                      icon="notIncluded"
                      size={20.11}
                      color="text-primary"
                      style={{ margin: 0 }}
                    />
                  </button>
                </div>
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                )}

                {text && (
                  <div className="mt-1">
                    <p className="text-sm text-gray-500">{text}</p>
                  </div>
                )}
                {icon && (
                  <div className="flex justify-center">
                    <Icon
                      icon={icon}
                      size={120}
                      color="text-primary"
                      style={{ marginLeft: 8, marginTop: 6 }}
                    />
                  </div>
                )}
                {customComponent && customComponent}
                <div className="mt-4 justify-center flex">
                  {secondButtonText && (
                    <>
                      <button
                        className={`inline-flex items-center justify-center px-4 py-1 w-5/12 ${
                          secondButtonTextColor ? secondButtonTextColor : 'text-white'
                        }   text-lg font-medium rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 ${
                          secondButtonColor ? secondButtonColor : 'bg-primary'
                        } `}
                        onClick={secondButtonAction && (() => secondButtonAction())}
                      >
                        {secondButtonText}
                      </button>
                      <div className="w-3"></div>
                    </>
                  )}
                  {closeButtonText && (
                    <button
                      type="button"
                      className="inline-flex justify-center items-center px-4 py-1 w-5/12 text-lg font-medium text-blue-900 bg-blue-100 border border-black rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={() => actionClose()}
                    >
                      {closeButtonText}
                    </button>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
