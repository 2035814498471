import { tv } from 'tailwind-variants';

const Card = tv({
  base: 'bg-white p-[31.84px] border-2 rounded-xl border-neutral-200 cursor-pointer',
  slots: {
    Title: 'text-base text-primary font-bold',
    SubTitle: 'text-blue-500 text-base',
    Description: 'text-xs font-normal text-primary',
  },

  variants: {
    small: {
      true: {
        base: 'p-4',
      },
    },
    selected: {
      true: {
        base: 'border-secondary-500',
      },
      false: {},
    },
  },
});

export default Card;
