import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreatePreferredUser,
  IPreferredUserResponse,
  IPreferredUser,
  CreateBlockedUser,
  IBlockedUserResponse,
  IBlockedUser,
  GetApplicantResume,
  IApplicantResume,
  DeletePreferredUser,
  DeleteBlockedUser,
} from 'actions/shifts.api';
import { ShowToastError } from 'utils';

export const CreatePreferredUserThunk = createAsyncThunk<
  IPreferredUserResponse,
  IPreferredUser,
  IReject
>('events/create-preferred-user', async (dto, { rejectWithValue }) => {
  try {
    const result = await CreatePreferredUser(dto);
    return result;
  } catch (e) {
    ShowToastError(e);
    return rejectWithValue(e);
  }
});

export const CreateBlockedUserThunk = createAsyncThunk<IBlockedUserResponse, IBlockedUser, IReject>(
  'events/create-blocked-user',
  async (dto, { rejectWithValue }) => {
    try {
      const result = await CreateBlockedUser(dto);
      return result;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const GetApplicantResumeThunk = createAsyncThunk<IApplicantResume, string, IReject>(
  'events/get-applicant-resume',
  async (dto, { rejectWithValue }) => {
    try {
      const result = await GetApplicantResume(dto);
      return result;
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const DeletePreferredUserThunk = createAsyncThunk<boolean, string, IReject>(
  'events/delete-preferred-user',
  async (dto, { rejectWithValue }) => {
    try {
      return await DeletePreferredUser(dto);
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);

export const DeleteBlockedUserThunk = createAsyncThunk<boolean, string, IReject>(
  'events/delete-blocked-user',
  async (dto, { rejectWithValue }) => {
    try {
      return await DeleteBlockedUser(dto);
    } catch (e) {
      ShowToastError(e);
      return rejectWithValue(e);
    }
  }
);
