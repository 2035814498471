import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Icon from 'assets/icons';

/**
 * @deprecated The method should not be used
 */
const Select = ({
  items,
  itemSelected,
  errors,
  customStyle,
  setSelectedItem,
  disabled,
  name,
  checked,
}: ISelectSimple): JSX.Element => {
  return (
    <div className="w-full">
      {name && itemSelected.text != name && (
        <span className="block mt-3 truncate">{`${name}:`}</span>
      )}
      <Listbox disabled={disabled} value={itemSelected} onChange={setSelectedItem}>
        <div className="relative">
          <Listbox.Button
            className={`${
              customStyle
                ? customStyle
                : 'mt-4 flex  text-left bg-grayBack font-medium  placeholder-primary text-gray-900 text-sm sm:text-lg items-center appearance-none rounded-lg relative w-full px-3 py-2  border-0   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10'
            }`}
          >
            <div className="w-full flex justify-between">
              <span className="block truncate">{itemSelected.text}</span>
              {!checked && (
                <>
                  <span className="flex  inset-y-0 items-center pr-3.5 pointer-events-none">
                    <Icon
                      icon="ChevronDown"
                      size={12}
                      className="fill-neutral-200"
                      style={{ marginLeft: 8, marginTop: 2 }}
                    />
                  </span>
                </>
              )}
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 min-w-[200px] w-full py-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) =>
                    `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                   flex items-center cursor-default select-none relative py-2 pl-2 pr-4`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      {selected ? (
                        <span
                          className={`${active ? 'text-amber-600' : 'text-amber-600'}
                           inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <Icon
                            icon="check"
                            size={10}
                            color="text-primary"
                            style={{ marginLeft: 1, marginRight: 2 }}
                          />
                        </span>
                      ) : null}
                      <span
                        className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                      >
                        {item.text}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {errors && <small style={{ color: 'red' }}>{errors.message}</small>}
    </div>
  );
};

export default Select;
