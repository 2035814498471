import { ListSheetGet } from '../../Domain/Entities/ListSheet.entity';
import { ListSheetSet } from '../../Domain/Entities/ListSheetSet';
import { ListShiftSet } from '../../Domain/Entities/ListShiftSet';
import { TimeSheetRepository } from '../../Domain/Repository/Timesheet.repository';

export class ListSheetUseCase {
  public timesheetRepository = new TimeSheetRepository();

  public async invokeGetListSheet(data: ListSheetSet): Promise<ListSheetGet> {
    return this.timesheetRepository.getTimesheetList(data);
  }

  public async invokeGetShiftList(data: ListShiftSet) {
    return this.timesheetRepository.getListSheetList(data);
  }
}
