import { useContext, useState } from 'react';
import ShiftsTemplateAction from './ShiftsTemplate.Action';
import { useQuery } from '@tanstack/react-query';
import { CatalogContext } from 'pages/shift/core/Infrastructure/Providers/Catalog.provider';
import getSalaryType from 'pages/shift/shared/utils/get-salary-type';
import { RoutesConstant } from 'utils/constants';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useUser from 'utils/hooks/useUser';

const ShiftTemplatePresenter = () => {
  const [page, setPage] = useState(0);
  const catalog = useContext(CatalogContext);
  const actions = ShiftsTemplateAction();
  const router = useNavigate();
  const {
    business: { id },
  } = useUser();

  const { data, isLoading } = useQuery(['get-templates', page], () =>
    actions.executeGetTemplates(page)
  );

  const getSalary = (id: string) => {
    return getSalaryType(id, catalog?.eventData.shiftSalaryTypes);
  };

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const onClickCode = () => {
    router(RoutesConstant.unsecure.event + '/' + id);
  };

  const onClickCreateShift = () => {
    router(RoutesConstant.secure.postShift);
  };

  const onClickCreateManualShift = () => {
    router(RoutesConstant.secure.createShiftExcel);
  };

  const onClickUseTemplate = (id: string) => {
    router({
      pathname: RoutesConstant.secure.postShift,
      search: createSearchParams({ templateId: id }).toString(),
    });
  };

  return {
    data,
    isLoading,
    getSalary,
    handlePageClick,
    onClickCode,
    onClickCreateShift,
    onClickCreateManualShift,
    onClickUseTemplate,
  };
};

export default ShiftTemplatePresenter;
