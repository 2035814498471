import { Combobox, Transition } from '@headlessui/react';
import { GetAutoCompleteData, GetPlaceById, ILocationItem } from 'actions/integration.api';
import { ILocationData } from 'actions/shifts.api';
import Icon from 'assets/icons';
import debounce from 'lodash.debounce';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ISearchLocation {
  className?: string;
  defaultValue?: string;
  classNameInput?: string;
  setValue: (value: ILocationData) => void;
  placeholder?: string;
  messageLocationAvailableShow: boolean;
}

const SearchLocation = ({
  className,
  defaultValue,
  setValue,
  classNameInput,
  placeholder,
  messageLocationAvailableShow,
}: ISearchLocation): JSX.Element => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<ILocationItem>();
  const [autoSuggestItems, setAutoSuggestItems] = useState<ILocationItem[]>();

  const [t] = useTranslation(['Others']);
  const onChangeBar = (text: string) => {
    GetAutoCompleteData(text).then((value) => {
      setAutoSuggestItems(value);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeoutRef = useRef<any>();

  const itemsToSelect = useMemo<ILocationItem[] | null>(() => {
    if (autoSuggestItems) {
      return autoSuggestItems;
    }

    return null;
  }, [autoSuggestItems]);

  useEffect(() => {
    if (selected && selected.id !== '1') {
      setLoading(true);
      GetPlaceById(selected.id)
        .then((result) => {
          const location: ILocationData = {
            locationCoords: {
              latitude: result.coordinates.latitude,
              longitude: result.coordinates.longitude,
            },
            location: {
              address: result.address,
              topic: result.county,
              notificationsTopic: result.topic,
            },
          };
          setValue(location);
        })
        .finally(() => setLoading(false));
    }
  }, [selected]);

  useEffect(() => {
    if (query !== '') {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;

        const barFunction = debounce(onChangeBar, 100);
        barFunction(query);
      }, 500);
    }
  }, [query]);

  const selectedLocationMessageRestriction = useMemo(() => {
    if (selected) {
      const matchCO = selected.title.match('CO');
      const matchNY = selected.title.match('NY');
      const matchTX = selected.title.match('TX');
      const matchCA = selected.title.match('CA');
      const matchFL = selected.title.match('FL');

      if (matchCO || matchNY || matchTX || matchCA || matchFL) {
        return false;
      }
      return true;
    }
    return false;
  }, [selected, t]);

  useEffect(() => {
    if (defaultValue) {
      setSelected({
        id: '1',
        title: defaultValue,
      });
    }
  }, [defaultValue]);

  return (
    <div className="w-full flex mt-2">
      <div className="w-full">
        <Combobox value={selected} onChange={setSelected} disabled={loading}>
          <div className="relative mt-1 w-full">
            <div
              className={`${
                className ?? ''
              } relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm`}
            >
              <Combobox.Input
                className={
                  classNameInput
                    ? classNameInput
                    : `min-w-300 border px bg-grayBack font-medium text-lg appearance-none rounded-lg relative block w-full px-3 placeholder-gray-500 text-gray-900  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10`
                }
                displayValue={(data: ILocationItem) => (data ? data.title : defaultValue ?? '')}
                onChange={(event) => setQuery(event.target.value)}
                placeholder={placeholder ? placeholder : t('location')}
              />
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                style={{ zIndex: 2 }}
                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {itemsToSelect && itemsToSelect.length === 0 && query !== '' && (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    {t('notAvailable')}
                  </div>
                )}
                {itemsToSelect &&
                  itemsToSelect.map((person) => (
                    <Combobox.Option
                      key={person.id}
                      className={`relative py-2 pl-10 pr-4 ${'text-gray-900'} cursor-pointer`}
                      value={person}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            {person.title}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        {selectedLocationMessageRestriction && messageLocationAvailableShow && (
          <div className="bg-[#7A9ECC] bg-opacity-30 flex py-3 px-3 rounded-xl">
            <div className="pr-2">
              <Icon icon={'about'} size={20} />
            </div>
            <p className="text-primary text-base">
              {t<string>('notAvailable')}{' '}
              <a className="text-secondary-500 underline" href={`mailto:team@lachamba.app`}>
                {t<string>('emailChamba')}
              </a>
              {t<string>('forHelp')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchLocation;
