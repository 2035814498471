import Icon from 'assets/icons';
import { DateTime } from 'luxon';
import { MouseEventHandler, forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';

type DatePickerProps = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  value?: Date;
};

type CalendarProps = {
  onChange?: (d: Date) => void;
  value?: Date;
};

const Calendar = ({ onChange, value }: CalendarProps) => {
  const [uncontrolledDate, setUncontrolledDate] = useState(new Date());

  const dateValue = value ?? uncontrolledDate;
  const setDateValue = onChange ?? setUncontrolledDate;

  const CustomComponent = forwardRef<HTMLButtonElement, DatePickerProps>(
    ({ value, onClick }, ref) => {
      return (
        <button
          className="border-[1px] rounded-full py-2 px-5 font-medium text-sm"
          onClick={onClick}
          ref={ref}
        >
          <Icon icon="editcalendar" size={16} className="-mt-0.5 mr-1 fill-primary" />
          {DateTime.fromJSDate(new Date(value ?? '')).toFormat('LLL, dd, y')}
        </button>
      );
    }
  );

  CustomComponent.displayName = 'DatePicker';

  return (
    <DatePicker
      selected={dateValue}
      onChange={(date: Date) => setDateValue(date)}
      customInput={<CustomComponent />}
    />
  );
};

export default Calendar;
