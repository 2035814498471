import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';
import { EventDataGet } from '../../../Catalog/Domain/Entities/Catalog.entity';
import { RoleFetchSingleSchemaType, RoleSchemaType } from '../Dtos/Role.dto';

export const fetchRoleToForm = (
  catalog: EventDataGet,
  data?: RoleFetchSingleSchemaType
): RoleSchemaType | undefined => {
  if (data)
    return {
      abilities: data.abilities.map(
        (ability) =>
          catalog.eventData.abilities.find((v) => v.id === ability) ??
          catalog.eventData.abilities[0]
      ),
      amount: data.salaryAmount,
      category:
        catalog.eventData.shiftPositions.find((v) => v.id === data.positionId) ??
        catalog.eventData.shiftPositions[0],
      experience:
        catalog.eventData.shiftExperiences.find((v) => v.id === data.experience) ??
        catalog.eventData.shiftExperiences[0],
      payment:
        catalog.eventData.shiftSalaryTypes.find((v) => v.id === data.salaryType) ??
        catalog.eventData.shiftSalaryTypes[0],
      information: data.jobInformation ? getTextTranslated(data.jobInformation) : undefined,
      name: data.positionTitle
        ? getTextTranslated(data.positionTitle)
        : catalog.eventData.shiftPositions.find((v) => v.id === data.positionId)?.text ??
          catalog.eventData.shiftPositions[0].text,
      idRole: data.id,
    };
};
