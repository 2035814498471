import CreateTemplateSet from '../Domain/Dtos/CreateTemplateSet.dto';
import { TemplateRepository } from '../Domain/Repository/Template.repository';

export class TemplateUseCase {
  private templateRepository = new TemplateRepository();

  public async invokeGetTemplates(businessId: string, page: number) {
    return this.templateRepository.getTemplates(businessId, page);
  }

  public async invokeCreateTemplate(data: CreateTemplateSet) {
    return this.templateRepository.createTemplate(data);
  }
}
