import { useNavigate } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { RoutesConstant } from 'utils/constants';
import { useCallback, useEffect } from 'react';
import { REACT_APP_CDN } from 'utils/enviroment';
import { useTranslation } from 'react-i18next';

const Federated = (): JSX.Element => {
  const [t] = useTranslation(['Login']);

  const navigate = useNavigate();
  const onGooglePress = (): void => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  const onApplePress = (): void => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
  };

  const Listener = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      const { payload } = data;
      switch (payload.event) {
        case 'signIn':
          navigate(RoutesConstant.unsecure.home);
          break;
        case 'signOut':
          navigate(RoutesConstant.unsecure.login);
          break;
      }
    },
    [history]
  );

  useEffect(() => {
    Hub.listen('auth', Listener);
    return () => {
      Hub.remove('auth', Listener);
    };
  }, [Listener]);

  return (
    <>
      <div className="">
        <div
          onClick={onGooglePress}
          className="flex cursor-pointer bg-[#7AB2F4]/40 py-2 mb-6 rounded-2xl"
        >
          <img className="pl-5" src={`${REACT_APP_CDN}/img/GoogleColor.svg`} />
          <p className="pl-3">{t('continueWithG')}</p>
        </div>

        <div
          onClick={onApplePress}
          className="flex cursor-pointer bg-[#7AB2F4]/40 py-2 mb-4 rounded-2xl"
        >
          <img className="pl-5" src={`${REACT_APP_CDN}/img/Apple.svg`} />
          <p className="pl-3">{t('continueWithA')}</p>
        </div>
      </div>
    </>
  );
};

export default Federated;
