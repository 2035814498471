import { InferType, boolean, number, object } from 'yup';

export const WorkerFormSchema = object({
  openings: number().required(),
  breakTime: number().required(),
  selectedAssign: boolean().required(),
  fullTimeOportunity: boolean().required(),
});

export type WorkerFormSchemaType = InferType<typeof WorkerFormSchema>;

export const ValidateWorker = async (d: object): Promise<boolean> => {
  try {
    await WorkerFormSchema.validate(d);
    return true;
  } catch (e) {
    return false;
  }
};
