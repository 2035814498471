import SelectV2 from 'components/SelectV2';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AttirePresenter from './Attire.Presenter';
import Button from 'components/Button';

interface IStepAttire {
  stepName: string;
}

// ESLINT DISABLED FOR STEPPER NAME FROM REACT-STEP-WIZARD
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AttireScreen = ({ stepName }: IStepAttire) => {
  const presenter = AttirePresenter();
  const animatedComponents = makeAnimated();
  const [t] = useTranslation(['Shifts']);

  return (
    <div className="sm:h-screen">
      <form onSubmit={presenter.form.handleSubmit(presenter.onSubmit)}>
        <p className="text-lg font-semibold mt-2"> {t('create.attire.provided')}</p>

        <Controller
          control={presenter.form.control}
          name="uniform"
          render={({ field: { onChange, value } }) => (
            <div className="mt-3 font-regular text-sm sm:text-base appearance-none text-neutral-1600 placeholder:text-neutral-1600 rounded border border-gray-300 bg-white relative block w-full px-3 py-1 placeholder-primary  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10">
              <SelectV2
                items={presenter.catalog?.eventData.uniforms || []}
                itemSelected={value}
                name={t('create.attire.select')}
                setSelectedItem={(type) => {
                  onChange(type);
                }}
              />
            </div>
          )}
        />

        {presenter.form.formState.errors.uniform && (
          <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
        )}

        <p className="text-lg font-semibold my-2"> {t('create.attire.dress')}</p>

        <Controller
          control={presenter.form.control}
          name="dressCode"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-wrap  w-full">
              {presenter.catalog?.eventData.dressCodes &&
                presenter.catalog?.eventData.dressCodes.map((dress, index) => (
                  <div key={index} className="w-1/2 xs:w-1/3 sm:w-1/4 p-1">
                    <div
                      onClick={() => {
                        onChange(dress);
                      }}
                      style={{ backgroundImage: `url(${dress.image})` }}
                      className={`bg-no-repeat rounded-lg bg-cover bg-top mr-5 mb-3 h-[169px] ${
                        value?.id == dress.id && 'border-2 border-secondary-500'
                      } `}
                    >
                      <div
                        className={`w-full h-full  bg-opacity-40 rounded-md px-2 flex items-end py-3 ${
                          value?.id == dress.id
                            ? 'bg-secondary-500 bg-opacity-20'
                            : 'bg-black bg-opacity-40'
                        }`}
                      >
                        <p className=" z-20 text-white w-full text-xs bottom-3">{dress.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        />
        <div>
          {presenter.form.formState.errors.dressCode && (
            <small style={{ color: 'red' }}>{t('create.required')}</small>
          )}
        </div>
        <p className="text-lg font-semibold my-2"> {t('create.attire.grooming')}</p>
        <Controller
          control={presenter.form.control}
          name={`groomingRestrictions`}
          render={({ field: { onChange, value } }) => (
            <div className="text-primary">
              <Select
                options={presenter.catalog?.eventData.groomingRestrictions.map((e) => ({
                  value: e.id,
                  label: e.text,
                  id: e.id,
                  text: e.text,
                  default: e.default,
                }))}
                isMulti
                className="mt-4 z-20"
                value={value?.map((v) => ({
                  value: v.id,
                  label: v.text,
                  id: v.id,
                  text: v.text,
                  default: v.default,
                }))}
                onChange={(e, meta) => {
                  if (meta.option?.default === true && meta.action === 'select-option') {
                    onChange([meta.option]);
                  } else {
                    onChange([...e.filter((value) => !value.default)]);
                  }
                }}
                components={animatedComponents}
                placeholder={t<string>('create.attire.selectMore')}
              />
              <div>
                {presenter.form.formState.errors.groomingRestrictions && (
                  <small style={{ color: 'red' }}>{t('create.required')}</small>
                )}
              </div>
            </div>
          )}
        />
        <div className="flex justify-between mt-6">
          <p className="cursor-pointer" onClick={presenter.onClickBack}>
            {t('create.back')}
          </p>
          <Button
            className=" rounded-md py-2 px-4"
            type="submit"
            color="primary"
            name={t('create.save')}
            size="xs"
          />
        </div>
      </form>
    </div>
  );
};

export default AttireScreen;
