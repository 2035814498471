import FloatingDialog from 'components/FloatingDialog';
import IndexPresenterDescription from './Index.Presenter';
import { DateTime } from 'luxon';
import MapComponent from 'components/Map';
import { useTranslation } from 'react-i18next';

type DescriptionProps = {
  open: boolean;
  setSelected: (s?: string) => void;
  selected?: string;
};

const IndexScreenDescription = ({ open, setSelected, selected }: DescriptionProps) => {
  const presenter = IndexPresenterDescription(selected);
  const [hireTranslation] = useTranslation(['Hire']);

  const description = presenter.data?.description;

  return (
    <FloatingDialog
      isOpen={open}
      className="bg-white w-full max-w-2xl"
      setIsOpen={(v) => {
        if (!v) setSelected(undefined);
      }}
    >
      <div>
        <h3 className="text-primary text-[22px] font-bold">{hireTranslation('username')}</h3>

        <div className="overflow-y-auto w-full">
          <p className="mt-3">{hireTranslation('position')}</p>

          <p className=" text-base font-semibold capitalize">{description?.positionTitle}</p>

          <p className="mt-3">{hireTranslation('starts')}</p>
          <p className=" text-base font-semibold">
            {DateTime.fromISO(description?.startDate ?? '').toLocaleString(DateTime.DATE_MED)}
          </p>

          <p className="mt-3">{hireTranslation('first')}</p>
          <p className=" text-base font-semibold">{description?.firstDayInstructions}</p>

          <p className="mt-3">{hireTranslation('payment')}</p>
          <p className=" text-base font-semibold">
            ${description?.paymentAmount} {description?.paymentType}
          </p>

          <p className="mt-3">{hireTranslation('benefits')}</p>
          <p className=" text-base font-semibold">{description?.benefits}</p>

          <p className="mt-3">{hireTranslation('location')}</p>
          <p className=" text-base font-semibold mb-4">{description?.location}</p>

          {description && (
            <MapComponent
              height={200}
              whenReady={true}
              latitude={description?.locationCoords.latitude}
              longitude={description?.locationCoords.longitude}
            />
          )}
        </div>
      </div>
    </FloatingDialog>
  );
};
export default IndexScreenDescription;
