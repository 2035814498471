import { useQuery } from '@tanstack/react-query';
import InvoiceDetailAction from './InvoiceDetail.Action';
import { InvoiceDescriptionDto } from 'pages/invoice/core/Modules/Invoices/Domain/Dtos/InvoiceDescription.dto';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import { useState } from 'react';

const InvoiceDetailPresenter = (idSelected: string) => {
  const [openDialog, setOpenDialog] = useState(false);

  const actions = InvoiceDetailAction();
  const navigate = useNavigate();

  const description = useQuery({
    queryKey: ['get-invoice-description', idSelected],
    queryFn: () => actions.executeGetDetail(idSelected),
    enabled: openDialog,
    cacheTime: 0,
  });

  const laborCostValues = (applicant: InvoiceDescriptionDto['details'][0]['applicants'][0]) => {
    if (applicant.clockIn && applicant.clockOut && applicant.laborCost) {
      return `$${applicant.laborCost.toFixed(2)}`;
    } else if (!applicant.clockIn && !applicant.clockOut && !applicant.laborCost) {
      return 'No show';
    } else if (!applicant.clockIn && !applicant.clockOut && applicant.laborCost) {
      return 'Cancelled';
    }
  };

  const breakTimeMinutesToHours = (breakTime: number) => {
    if (breakTime === 0) return '----';
    else if (breakTime === 1) return `${breakTime} minute`;
    else if (breakTime > 1) return `${breakTime} minutes`;
  };

  const onClickExport = (id: string) => {
    navigate(`${RoutesConstant.unsecure.invoice}/${id}`);
  };

  return {
    description,
    laborCostValues,
    breakTimeMinutesToHours,
    onClickExport,
    setOpenDialog,
    openDialog,
  };
};

export default InvoiceDetailPresenter;
