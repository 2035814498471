import { fullCompensation } from 'pages/postShift/shared/constants/payments';
import { DraftDataGet } from '../Domain/Entities/DraftDataGet.entity';
import { DateTime } from 'luxon';

export class SummaryUseCases {
  public invokeCalculateTotal(
    data: DraftDataGet | undefined,
    isMarkdown: boolean | undefined,
    fee: number
  ) {
    const total = data?.resultDraftData.shifts.reduce(
      (acc, item) => {
        if (item.salaryType === fullCompensation) {
          acc.total += item.salaryAmount * item.openings;
        } else {
          let hours = Math.abs(
            DateTime.fromJSDate(item.startDate).diff(DateTime.fromJSDate(item.endDate), ['hours'])
              .hours
          );

          if (item.breakTime !== 0) {
            hours -= item.breakTime;
          }

          acc.total += item.salaryAmount * (isMarkdown ? fee : 1) * hours * item.openings;
          acc.hourly = true;
        }

        acc.openings += item.openings;

        return acc;
      },
      { total: 0, hourly: false, openings: 0, fee: 0 }
    );

    const totalAmount = total?.total ?? 0;

    return {
      total: total?.total,
      fee: isMarkdown ? 0 : totalAmount * fee,
      openings: total?.openings,
      hourly: total?.hourly,
    };
  }
}
