import { InferType, array, boolean, object, string } from 'yup';
import toast from 'react-hot-toast';
import { EventDataGet } from '../Entities/Catalog.entity';

export const EventDataSchema = object({
  parkingInfos: array(
    object({
      id: string().required(),
      text: string().required(),
    }).required()
  ).required(),
  shiftPositions: array(
    object({
      id: string().required(),
      text: string().required(),
      image: string().required(),
      default: boolean().required(),
    }).required()
  ).required(),
  shiftSalaryTypes: array(
    object({
      id: string().required(),
      text: string().required(),
    }).required()
  ).required(),
  uniforms: array(
    object({
      id: string().required(),
      text: string().required(),
    }).required()
  ).required(),
  dressCodes: array(
    object({
      id: string().required(),
      text: string().required(),
      image: string().required(),
    }).required()
  ).required(),
  groomingRestrictions: array(
    object({
      id: string().required(),
      text: string().required(),
      default: boolean().required(),
    }).required()
  ).required(),
  abilities: array(
    object({
      id: string().required(),
      text: string().required(),
      default: boolean().required(),
    }).required()
  ).required(),
  shiftExperiences: array(
    object({
      id: string().required(),
      text: string().required(),
    }).required()
  ).required(),
});

export type EventDataDto = InferType<typeof EventDataSchema>;

export const dtoToEventDataGet = async (dto: EventDataDto) => {
  try {
    const validatedDto = await EventDataSchema.validate(dto);

    return new EventDataGet(validatedDto);
  } catch (e) {
    console.error(e);
    toast.error('Please contact support');
  }
};
