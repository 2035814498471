import Icon from 'assets/icons';
import { PopoverTrigger, PopoverContent, Popover } from 'components/Popover';
import { useState } from 'react';

export type DotMenuProps = {
  data: {
    displayName: string;
    func: () => void;
  }[];
};

const DotMenu = ({ data }: DotMenuProps) => {
  const [openPopover, setOpenPopover] = useState(false);

  const onClickButton = (func: () => void) => {
    func();
    setOpenPopover(false);
  };

  return (
    <Popover onOpenChange={setOpenPopover} open={openPopover}>
      <PopoverTrigger onClick={() => setOpenPopover(true)}>
        <Icon icon="dots" className="fill-black" size={10} />
      </PopoverTrigger>

      <PopoverContent className="p-2 bg-white shadow rounded-md">
        {data.map((e, k) => (
          <div key={k} className="p-2 cursor-pointer" onClick={() => onClickButton(e.func)}>
            {e.displayName}
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default DotMenu;
