import { InferType, array, number, object, string } from 'yup';

export const LocationSchema = object({
  id: string(),
  info: string(),
  parking: string().required(),
  location: object({
    location: object({
      topic: string(),
      address: string().required(),
      notificationsTopic: string(),
    }).required(),
    locationCoords: object({
      latitude: number().required(),
      longitude: number().required(),
    }).required(),
  }).required(),
  clockInInfo: string().required(),
});

export interface SaveLocationSet {
  locationInfo: string;
  parkingInfo: string;
  location: {
    topic: string;
    address: string;
    notificationsTopic: string;
  };
  locationCoords: {
    latitude: number;
    longitude: number;
  };

  clockInInfo: string;
  businessId: string;
}
export interface EditLocationSet extends SaveLocationSet {
  id: string;
}

export const LocationFetchSchemaSingle = object({
  id: string(),
  locationInfo: object({
    english: string().required(),
    spanish: string().required(),
  }).nullable(),
  parkingInfo: string().required(),
  location: object({
    topic: string(),
    address: string().required(),
    notificationsTopic: string(),
  }).required(),
  locationCoords: object({
    latitude: number().required(),
    longitude: number().required(),
  }).required(),
  clockInInfo: object({
    english: string().required(),
    spanish: string().required(),
  }),
});

export type LocationSchemaType = InferType<typeof LocationSchema>;

export const LocationFetchSchema = array(LocationFetchSchemaSingle);
export type LocationFetchSchemaType = InferType<typeof LocationFetchSchema>;

export type LocationFetchSchemaSingleType = InferType<typeof LocationFetchSchemaSingle>;

export const ValidateLocation = async (d: object): Promise<boolean> => {
  try {
    await LocationSchema.validate(d);
    return true;
  } catch (e) {
    return false;
  }
};

export const ValidateFetchLocation = async (d: object) => {
  try {
    const result = await LocationFetchSchema.validate(d);
    return result;
  } catch (error) {
    return undefined;
  }
};
