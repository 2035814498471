import { AxiosError } from 'axios';
import {
  DataFormContext,
  DataFormType,
} from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { DraftUseCase } from 'pages/postShift/core/Modules/Checkout/Application/DraftUseCaes';
import { ShiftUseCase } from 'pages/postShift/core/Modules/Checkout/Application/ShiftUseCase';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { ShowToastError } from 'utils';
import useUser from 'utils/hooks/useUser';

const draftUseCase = new DraftUseCase();
const shiftUseCase = new ShiftUseCase();

const ConfirmAction = () => {
  const {
    business: { id },
    cognitoUser: { id: businessUserId },
  } = useUser();

  const { setDataForm } = useContext(DataFormContext);

  const [params] = useSearchParams();

  const invokeSaveDraft = (data: Partial<DataFormType>) => {
    try {
      return draftUseCase.invokeSave(data as DataFormType, id, businessUserId);
    } catch (e) {
      ShowToastError(e);
    }
  };

  const invokeDeleteDraft = async (id: string) => {
    try {
      await draftUseCase.invokeDelete(id);
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong');
    }
  };

  const executeEditShift = async (data: DataFormType) => {
    try {
      await shiftUseCase.invokeUpdateShift(data, params.get('id') ?? '');
      setDataForm({});
      return true;
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        toast.error(e.response?.data.message);
      } else {
        toast.error('Something went wrong');
      }
      return false;
    }
  };

  return { invokeSaveDraft, invokeDeleteDraft, executeEditShift };
};

export default ConfirmAction;
