import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Support = (): JSX.Element => {
  const [t] = useTranslation(['Support']);
  return (
    <div className="w-full">
      <Helmet>
        <title>{t('pageTitle')} | Chamba Business</title>
      </Helmet>
      <div className="lg:ml-[5%] lg:w-[85%]  min-h-screen  text-primary px-5">
        <div className="md:px-16 py-12 w-full">
          <h3 className="text-2xl lg:text-4xl font-bold w-full">{t('title')}</h3>
          <p className="text-xl py-6">{t('call')}:</p>
          <div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-start">
            <div className="bg-white rounded-xl  sm:mr-9 p-12 max-w-sm mb-7 lg:mb-0">
              <div className="w-full flex justify-center min-h-[157px]">
                <div className=" w-1/2 sm:w-5/12">
                  <img src={`https://chamba-cdn.s3.amazonaws.com/business/callChamba.png`} />
                </div>
              </div>
              <p className="text-2xl font-semibold pb-4 pt-6">{t('giveCall')}</p>
              <p className="text-xl pb-4">{t('callText')}</p>
              <div className="flex">
                <p className="text-secondary-500">{t('hours')}</p>
                <p className=" text-grayText ml-1"> {t('time')}</p>
              </div>

              <p className="mt-14 py-1.5 text-secondary-500"> {t('phone')}: +1 (303) 943-4469</p>
            </div>
            <div className="bg-white rounded-xl  p-12 max-w-sm">
              <div className="w-full flex justify-center min-h-[157px]">
                <div className=" sm:w-7/12 w-9/12">
                  <img src={`https://chamba-cdn.s3.amazonaws.com/business/emailChamba.png`} />
                </div>
              </div>
              <p className="text-2xl font-semibold pb-4 pt-6">{t('sendEmail')}</p>
              <p className="text-xl pb-4">{t('emailText')}</p>
              <a
                href="mailto:team@lachamba.app"
                className="block w-full rounded-2xl mt-14 bg-primary text-white py-1.5 text-center"
              >
                Email
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
