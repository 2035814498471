import { API_ENDPOINTS, getEndpoint } from './api.endpoints';
import api from './http';

export interface IResponseCard {
  id: string;
  last4: string;
  funding: string;
  brand: string;
  default: boolean;
  cvc: string;
}

export interface IChangeDefaultCard {
  paymentMethodId: string;
  businessId: string;
}

export interface IAddCardRequest {
  businessId: string;
  paymentMethodId: string;
}

export interface ICreateSubscription {
  paymentMethod: string;
  businessId: string;
}

export interface IResultCreateSubscription {
  success: boolean;
  id: string;
  startDate: string;
  endDate: string;
}

export const GetCardsByBusiness = async (id: string): Promise<IResponseCard[]> => {
  const result = await api.get<IResponseCard[]>(
    getEndpoint(API_ENDPOINTS.PAYMENTS.LIST_CARDS, { id })
  );
  return result.data;
};

export const UpdateDefaultCard = async (data: IChangeDefaultCard): Promise<void> => {
  await api.patch(getEndpoint(API_ENDPOINTS.PAYMENTS.UPDATE_DEFAULT), data);
};

export const DeleteCard = async (data: string) => {
  await api.delete(getEndpoint(API_ENDPOINTS.PAYMENTS.DELETE_CARD, { id: data }));
};

export const AddCard = async (data: IAddCardRequest) => {
  await api.post(getEndpoint(API_ENDPOINTS.PAYMENTS.ADD_CARD), data);
};

export const CreateSubscription = async (
  data: ICreateSubscription
): Promise<IResultCreateSubscription> => {
  const result = await api.post<IResultCreateSubscription>(
    getEndpoint(API_ENDPOINTS.PAYMENTS.CREATE_SUBSCRIPTION),
    data
  );
  return result.data;
};

export const DeleteSubscription = async (businessId: string): Promise<void> => {
  await api.post(getEndpoint(API_ENDPOINTS.PAYMENTS.DELETE_SUBSCRIPTION), { businessId });
};
