import { Table, TableBody, TableHeader, TablePaginate, TableTd, TableTr } from 'components/Table';
import ShiftTemplatePresenter from './ShiftsTemplate.Presenter';
import { DateTime } from 'luxon';
import { ITEMS_PER_PAGE } from 'utils/constants';
import Button from 'components/Button';
import Icon from 'assets/icons';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

const ShiftsTemplateScreen = () => {
  const presenter = ShiftTemplatePresenter();
  const [t] = useTranslation(['Shifts']);

  return (
    <div>
      <div className=" flex flex-wrap justify-end mb-3">
        <Button
          name={t('download')}
          color="outlineSecondary"
          size="sm"
          className="mr-2 py-2  mb-3 xl:mb-0 max-h-[57px]"
          onClick={presenter.onClickCode}
          prefixIcon={<Icon icon="qr" size={15} className="-mt-0.5" />}
        />

        <Disclosure>
          <div className="flex flex-col">
            <Disclosure.Button className="bg-secondary-500 text-white rounded-3xl py-2 px-6">
              <Icon icon="add" size={15} className="-mt-0.5 mr-2" />
              {t('createShift').toString()}{' '}
              <Icon icon="arrowRight" size={10} className="text-white -mt-0.5 rotate-90" />
            </Disclosure.Button>
            <Disclosure.Panel className="absolute mt-12 -ml-4 bg-neutral-200 rounded-md  font-semibold text-neutral-1200 shadow-lg">
              <p className="my-2 mx-3 cursor-pointer" onClick={presenter.onClickCreateShift}>
                {t('createManual')}
              </p>
              <hr className="bg-neutral-1200 h-0.5" />
              <p onClick={presenter.onClickCreateManualShift} className="my-2 mx-3 cursor-pointer">
                {t('createExcel')}
              </p>
            </Disclosure.Panel>
          </div>
        </Disclosure>
      </div>
      <Table>
        <TableHeader
          items={[
            t('template.name'),
            t('template.start'),
            t('template.end'),
            t('template.pay'),
            t('template.openings'),
            t('template.location'),
            '',
          ]}
        />
        <TableBody>
          <>
            {presenter.data?.list.data.map((template, key) => {
              return (
                <TableTr key={key}>
                  <TableTd>{template.templateName}</TableTd>
                  <TableTd>
                    {DateTime.fromISO(template.startDate).toLocaleString(DateTime.TIME_SIMPLE)}
                  </TableTd>
                  <TableTd>
                    {DateTime.fromISO(template.endDate).toLocaleString(DateTime.TIME_SIMPLE)}
                  </TableTd>
                  <TableTd>
                    ${template.salaryAmount}{' '}
                    <span className="ml-1">{presenter.getSalary(template.salaryType)}</span>
                  </TableTd>
                  <TableTd>{template.openings}</TableTd>
                  <TableTd>{template.location.address}</TableTd>
                  <TableTd>
                    <Button
                      onClick={() => presenter.onClickUseTemplate(template.id)}
                      name="Use"
                      size="xs"
                    />
                  </TableTd>
                </TableTr>
              );
            })}
          </>
        </TableBody>
      </Table>

      <TablePaginate
        pageCount={Math.ceil((presenter?.data?.list.metadata.total ?? 0) / ITEMS_PER_PAGE)}
        pageRangeDisplayed={ITEMS_PER_PAGE}
        onPageChange={presenter.handlePageClick}
      />
    </div>
  );
};

export default ShiftsTemplateScreen;
