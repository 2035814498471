export default {
  en: {
    manager: 'Applicants manager',
    name: 'Name',
    status: 'Status',
    phoneNumber: 'Phone number',
    appliedOn: 'Applied on',
    actions: 'Actions',
    success: 'Success',
    accept: 'Accept',
    deny: 'Deny',
    try: 'Try again',
    1: 'Accepted',
    2: 'Rejected',
  },
  es: {
    manager: 'Gestor de aplicantes',
    name: 'Nombre',
    status: 'Estado',
    phoneNumber: 'Teléfono',
    appliedOn: 'Aplicó en',
    actions: 'Acciones',
    success: 'Éxito',
    accept: 'Aceptar',
    deny: 'Rechazar',
    try: 'Intenta de nuevo',
    1: 'Aceptado',
    2: 'Rechazado',
  },
};
