import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const Refresh = () => {
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
  }, []);

  return <></>;
};

export default Refresh;
