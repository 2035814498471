import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TemplateListGet } from '../Entities/TemplateList.entity';

const TemplatesListSchema = Yup.object().shape({
  metadata: Yup.object().shape({
    total: Yup.number().required(),
    page: Yup.number().required(),
  }),
  nextPage: Yup.boolean().required(),
  data: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        businessId: Yup.string().required(),
        templateName: Yup.string().required(),
        location: Yup.object().shape({
          topic: Yup.string().required(),
          address: Yup.string().required(),
          notificationsTopic: Yup.string().required(),
        }),
        locationCoords: Yup.object().shape({
          latitude: Yup.number().required(),
          longitude: Yup.number().required(),
        }),
        parkingInfo: Yup.string().required(),
        locationInfo: Yup.object()
          .shape({
            english: Yup.string().required(),
            spanish: Yup.string().required(),
          })
          .nullable(),
        clockInInfo: Yup.object().shape({
          english: Yup.string().required(),
          spanish: Yup.string().required(),
        }),
        positionTitle: Yup.object().shape({
          english: Yup.string().required(),
          spanish: Yup.string().required(),
        }),
        positionId: Yup.string().required(),
        startDate: Yup.string().required(),
        endDate: Yup.string().required(),
        breakTime: Yup.number().required(),
        openings: Yup.number().required(),
        salaryType: Yup.string().required(),
        salaryAmount: Yup.number().required(),
        fullTimeOportunity: Yup.boolean().required(),
        uniform: Yup.string().required(),
        dressCode: Yup.string().required(),
        groomingRestrictions: Yup.array().of(Yup.string().required()).required(),
        jobInformation: Yup.object().shape({
          english: Yup.string().required(),
          spanish: Yup.string().required(),
        }),
        abilities: Yup.array().of(Yup.string().required()).required(),
        experience: Yup.string().required(),
        contacts: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string().required(),
              role: Yup.string().required(),
              phoneNumber: Yup.string().required(),
              email: Yup.string().email().required(),
            })
          )
          .required(),
        onlyFavorites: Yup.boolean().required(),
        creationDate: Yup.string().required(),
      })
    )
    .required(),
});

export type TemplateListDto = Yup.InferType<typeof TemplatesListSchema>;

export const dtoToTemplateListGet = async (dto: object) => {
  try {
    const validateDto = await TemplatesListSchema.validate(dto);
    return new TemplateListGet(validateDto);
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      console.log(e.message); // Property 'message' does not exist on type '{}'.

      toast.error('Please contact support');
    }
  }
};
