export class ShiftCalendarGet {
  constructor(
    public data: {
      postedShifts: {
        id: string;
        eventId: string;
        positionTitle: string;
        startDate: string;
        endDate: string;
        isActive: boolean;
        openings: number;
        totalApplicants: number;
        started: boolean;
      }[];
    }
  ) {}
}
