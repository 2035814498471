export type FormType = {
  clockInTime?: string;
  clockOutTime?: string;
  clockInDate?: Date;
  clockOutDate?: Date;
  additionalClockIn?: string;
  additionalClockOut?: string;
  breakTimeStart?: string;
  breakTimeEnd?: string;
  additionalBreakTimeStart?: string;
  additionalBreakTimeEnd?: string;
  additionalInformationNoShow?: string;
};

export type DisputeOption = { id: DisputeType; text: string };

export enum DisputeType {
  ClockIn = 1,
  ClockOut = 2,
  BreakStart = 3,
  BreakEnd = 4,
  NoShow = 5,
}

export interface CreateDisputeRequestSet {
  executionId: string;
  businessId: string;
  businessUserId: string;
  noShow?: boolean;
  noShowInfo?: string;
  disputeDetails:
    | {
        disputeType: DisputeType;
        proposedDate?: string;
        additionalInfo?: string;
      }[]
    | null;
}
