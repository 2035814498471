import Icon from 'assets/icons';
import SelectV2 from 'components/SelectV2';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

interface DateTimePickerType {
  onChangeField: (e: string) => void;
}

const DateTimePicker = ({ onChangeField }: DateTimePickerType) => {
  const [hours, setHours] = useState<number>();
  const [minutes, setMinutes] = useState<number>();
  const [anteMeridiem, setAnteMeridiem] = useState<string>('am');

  const optionsMeridiem = [
    {
      id: 'am',
      text: 'AM',
    },
    {
      id: 'pm',
      text: 'PM',
    },
  ];

  useEffect(() => {
    if (hours && minutes && anteMeridiem) {
      onChangeField(
        DateTime.now()
          .set({
            minute: minutes + (anteMeridiem === 'am' ? 0 : 12),
            hour: hours,
          })
          .toISO()
      );
    }
  }, [hours, minutes, anteMeridiem]);

  return (
    <div className="flex items-center">
      <div className="w-full">
        <SelectV2
          className="border-neutral-200 border-[1px] rounded-lg px-2"
          setSelectedItem={(e) => setHours(parseInt(e.id))}
          itemSelected={{
            id: hours?.toString() ?? '',
            text: hours?.toString().padStart(2, '0') ?? '',
          }}
          items={[...Array(12).keys()].map((e) => ({
            id: (e + 1).toString(),
            text: (e + 1).toString().padStart(2, '0'),
          }))}
          name="00"
          customDropDownArrow={<Icon icon="arrow-down" color="black" size={12} />}
          showDropDownArrow
          placement="top"
        />
      </div>

      <span className="pl-1">:</span>

      <div className="pl-2 pr-3 w-full">
        <SelectV2
          className="border-neutral-200 border-[1px] rounded-lg px-2"
          setSelectedItem={(e) => setMinutes(parseInt(e.id))}
          itemSelected={{
            id: minutes?.toString() ?? '',
            text: minutes?.toString().padStart(2, '0') ?? '',
          }}
          items={[...Array(59).keys()].map((e) => ({
            id: e.toString(),
            text: e.toString().padStart(2, '0'),
          }))}
          name="00"
          showDropDownArrow
          customDropDownArrow={<Icon icon="arrow-down" size={12} />}
        />
      </div>

      <div className="w-full">
        <SelectV2
          setSelectedItem={(e) => setAnteMeridiem(e.id)}
          className="border-neutral-200 border-[1px] rounded-lg px-2"
          itemSelected={{
            id: anteMeridiem,
            text: anteMeridiem.toUpperCase(),
          }}
          items={optionsMeridiem}
          name="AM"
          showDropDownArrow
          customDropDownArrow={<Icon icon="arrow-down" size={12} />}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
