import NotFound from 'pages/404';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RoutesConstant, RoutesConstantComponent } from 'utils/constants';
import RequireAuth from './secure';
import { useSelector } from 'react-redux';
import {
  getBusiness,
  getBusinessOnboarding,
  getBusinessloading,
  getCognitoUser,
  getLogged,
} from 'store/business/selectors';
import Loading from 'pages/loading/indext';
import { useEffect, Fragment, useMemo } from 'react';
import { useAppDispatch } from 'store';
import { setLoading } from 'store/business';
import NotRequireAuth from './unsecure';
import EventQr from 'pages/AdditionalContent';
import Invoice from 'pages/AdditionalContent/Invoice';

const RoutesIndex = (): JSX.Element => {
  const loading = useSelector(getBusinessloading);
  const location = useLocation();
  const business = useSelector(getCognitoUser);
  const isOnboarding = useSelector(getBusinessOnboarding);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const businessdata = useSelector(getBusiness);
  const logged = useSelector(getLogged);

  useEffect(() => {
    if (params.get('code')) {
      dispatch(setLoading(true));
    }
  }, [params]);

  const isLoading = useMemo(() => {
    if (loading) {
      return true;
    }

    if (logged === true) {
      if (isOnboarding) {
        return false;
      }
      if (business && businessdata) {
        return false;
      }
    } else if (logged === false) {
      return false;
    }

    return true;
  }, [logged, loading, business, businessdata]);

  useEffect(() => {
    if (!isLoading) {
      if (
        business &&
        location.pathname !== RoutesConstant.secure.onBoarding &&
        !business.businessId
      ) {
        navigate(RoutesConstant.secure.onBoarding);
      }
    }
  }, [location.pathname, business, isLoading]);

  return (
    <Routes>
      <Route path={`${RoutesConstant.unsecure.event}/:id`} element={<EventQr />} />
      <Route path={`${RoutesConstant.unsecure.invoice}/:id`} element={<Invoice />} />

      <Fragment>
        {isLoading ? (
          <Route path="*" element={<Loading />} />
        ) : (
          <>
            <Route path="*" element={<NotFound />}></Route>
            {RoutesConstantComponent.secure.map((val, key) => (
              <Route
                key={key}
                path={val.route}
                element={
                  <RequireAuth>
                    <val.component />
                  </RequireAuth>
                }
              />
            ))}
            {RoutesConstantComponent.unsecure.map((val, key) => (
              <Route
                key={key}
                path={val.route}
                element={
                  <NotRequireAuth>
                    <val.component />
                  </NotRequireAuth>
                }
              />
            ))}
          </>
        )}
      </Fragment>
    </Routes>
  );
};

export default RoutesIndex;
