import { SubmitHandler, useForm } from 'react-hook-form';
import { passwordRegex, RoutesConstant } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { ActionResetPassword } from 'actions/auth.api';
import { REACT_APP_CDN } from 'utils/enviroment';
import PasswordInput from 'components/PasswordInput';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface IForgot {
  password: string;
  confirmPassword: string;
}

const Step = ({ nextStep, code, email }: IStep & { code: string; email: string }): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IForgot>({ criteriaMode: 'all', mode: 'onChange', reValidateMode: 'onChange' });
  const [t] = useTranslation(['Forgot']);
  const { trackPageView } = useMatomo();

  const onSubmit: SubmitHandler<IForgot> = async (data) => {
    try {
      await ActionResetPassword(
        {
          code,
          confirmPass: data.confirmPassword,
          password: data.password,
        },
        email
      );

      toast.success('Proceso exitoso');
      nextStep();
    } catch (e) {
      const error = e as IError;
      toast.error(error.message);
    }
  };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Forgot Password Step 3',
      href: window.location.href,
    });
  }, []);

  const password = watch('password');
  return (
    <div className="flex h-[100vh] bg-[#E5E5E5] w-full">
      <div className="w-full lg:w-1/2 p-10 sm:p-20 xl:p-40 flex self-center">
        <div className="w-full">
          <h3 className="w-full text-xl font-bold mb-5 text-primary"> {t('change')}</h3>
          <div className="w-10/12 flex justify-center md:hidden mb-3">
            <img className="w-80 mt-4" src={`${REACT_APP_CDN}/Coder.svg`} alt="" />
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full">
              <PasswordInput
                label={'password'}
                name="password"
                placeholder={t('new')}
                register={register}
                type="password"
                errors={errors.password}
                validation={{
                  required: true,
                  pattern: { value: passwordRegex, message: t<string>('passwordRequirements') },
                }}
              />
              <PasswordInput
                label={'confirmPassword'}
                name="confirmPassword"
                placeholder={t('confirmPass')}
                register={register}
                type="password"
                errors={errors.confirmPassword}
                validation={{
                  required: true,
                  pattern: { value: passwordRegex, message: t<string>('passwordRequirements') },
                  validate: (value: string) => value === password || `${t('noMatch')}`,
                }}
              />
            </div>

            <div className="flex justify-center mb-10 mt-4 w-full">
              <button
                type="submit"
                className="w-full rounded-full py-2 font-semibold bg-primary text-white"
              >
                {t('confirm2')}
              </button>
            </div>
            <div className="flex items-center justify-center ">
              <p className="text-sm font-semibold mr-1">{t('havaAnAc')} </p>
              <Link
                to={RoutesConstant.unsecure.login}
                className="text-[#117AF4] py-1 rounded-md text-center text-sm font-semibold underline underline-offset-2"
              >
                {t('logHere')}
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 bg-lightBlue/30 hidden lg:flex justify-center">
        <div className="self-center ">
          <img src={`${REACT_APP_CDN}/Coder.svg`} alt={`${REACT_APP_CDN}/Coder.svg`} />
        </div>
      </div>
    </div>
  );
};

export default Step;
