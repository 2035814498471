import { ContactFormSchemaType } from '../Domain/Dtos/ContactForm.dto';
import { contactFormToDto } from '../Domain/Mappers/contactFormToDto';
import { ContactRepository } from '../Domain/Repository/Contact.Repository';

export class ContactUseCases {
  private contactRepository: ContactRepository;

  constructor() {
    this.contactRepository = new ContactRepository();
  }

  public async invokeGet(id: string) {
    return this.contactRepository.get(id);
  }

  public async invokeSave(d: ContactFormSchemaType, businessId: string) {
    const dto = contactFormToDto(d, businessId);
    return this.contactRepository.save(dto);
  }

  public async invokeEdit(d: ContactFormSchemaType, id: string, businessId: string) {
    const dto = contactFormToDto(d, businessId);
    return this.contactRepository.edit({ ...dto, businessId, id });
  }
}
