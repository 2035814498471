import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';

const Payment = (): JSX.Element => {
  const [t] = useTranslation(['Payment']);
  return (
    <div className="text-primary w-full">
      <div className="w-full text-center h-screen items-center flex flex-wrap">
        <div className="w-full text-center">
          <h3 className="md:text-4xl text-2xl font-bold w-full">{t('payment')}</h3>
          <div className="w-full flex justify-center">
            <p className="md:text-3xl text-xl font-light mt-5 mb-10 w-1/2">{t('noCard')}</p>
          </div>
          <Link
            to={RoutesConstant.secure.addCard}
            className="lg:text-3xl text-xl mb-12 font-semibold py-1 px-6 border mt-1 lg:mt-5 border-primary rounded-lg"
          >
            {t('add')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Payment;
