export class ShiftDataGet {
  constructor(
    public resultShiftData: {
      id: string;
      postedDate: Date;
      isActive: boolean;
      businessId: string;
      businessUserId: string;
      paymentTotal: number;
      shifts: {
        id: string;
        eventId: string;
        location: {
          topic: string;
          address: string;
          notificationsTopic: string;
        };
        locationCoords: {
          latitude: number;
          longitude: number;
        };
        parkingInfo: string;
        locationInfo?: {
          english: string;
          spanish: string;
        } | null;
        clockInInfo: {
          english: string;
          spanish: string;
        };
        positionTitle?: {
          english: string;
          spanish: string;
        };
        positionId: string;
        startDate: Date;
        endDate: Date;
        timeZone?: string;
        breakTime: number;
        openings: number;
        salaryType: string;
        salaryAmount: number;
        fullTimeOportunity: boolean;
        uniform: string;
        dressCode: string;
        groomingRestrictions: string[];
        jobInformation: {
          english: string;
          spanish: string;
        } | null;
        abilities: string[];
        experience: string;
        contacts: {
          name: string;
          role: string;
          phoneNumber: string;
          email: string;
        }[];

        isFilled: boolean;
        isFinished: boolean;
        isAccounted: boolean;
        isActive: boolean;
        views: number;
        stripeId?: string | null;
        postedDate: Date;
        cancelledDate: Date | null;
        cancelledByBusiness: boolean;
        cancelledByAdmin: boolean;
        isW2: boolean;
        onlyFavorites: boolean;
        external: number;
      }[];

      hold?: boolean;
      draft?: boolean;
    }
  ) {}
}
