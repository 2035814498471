import Cards from 'components/Payment';
import NoCards from 'components/Payment/noCards';

import { useCallback, useEffect, useState } from 'react';
import LeftNavigation from 'components/LeftNavigation';
import { useAppDispatch } from 'store';
import {
  DeleteCardThunk,
  GetCardsByBusinessThunk,
  UpdateDefaultCardThunk,
} from 'store/business/api.thunks';
import Skeleton from 'react-loading-skeleton';
import toast from 'react-hot-toast';
import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { getBusiness, getCards } from 'store/business/selectors';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { SIDEBAR_NAV } from 'utils/constants';
import { IChangeDefaultCard } from 'actions/payments.api';

const payment = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const business = useSelector(getBusiness);
  const [t] = useTranslation(['Payment']);
  const { trackPageView } = useMatomo();

  const data = useSelector(getCards);

  const updateCards = useCallback(() => {
    if (business && business.id) {
      dispatch(GetCardsByBusinessThunk(business.id));
    }
  }, [business]);

  useEffect(() => {
    updateCards();
  }, [updateCards, business]);

  const setFavoriteCard = (paymentmethod: string) => {
    if (business?.id) {
      const data: IChangeDefaultCard = {
        businessId: business.id,
        paymentMethodId: paymentmethod,
      };

      setLoading(true);

      dispatch(UpdateDefaultCardThunk(data))
        .then(() => {
          toast.success(`${t('success')}`);
          updateCards();
        })
        .finally(() => setLoading(false));
    }
  };

  const onDeleteCard = (id: string) => {
    setLoading(true);
    dispatch(DeleteCardThunk(id))
      .then(() => {
        toast.success(`${t('success')}`);
        updateCards();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Payment',
      href: window.location.href,
    });
  }, []);

  return (
    <>
      <LeftNavigation selected={SIDEBAR_NAV.PAYMENT} />
      <Helmet>
        <title>{t('titleCards')} | Chamba Business</title>
      </Helmet>
      <div className="lg:ml-[15%] lg:w-[85%]">
        {!data && (
          <div className="mt-10 text-center">
            <h3 className="md:text-4xl text-2xl font-bold w-full">{t('payment')}</h3>
            <Skeleton count={3} className="w-full h-36 max-w-sm mt-2" />
          </div>
        )}
        <CSSTransition timeout={500} in={data !== undefined} classNames={'fade'}>
          {() => (
            <>
              {data && data.length > 0 && (
                <Cards
                  loading={loading}
                  onDeleteCard={onDeleteCard}
                  onSetFavoriteCard={setFavoriteCard}
                  data={data}
                />
              )}
            </>
          )}
        </CSSTransition>
        {data?.length === 0 && <NoCards />}
        {/* {data && (
          <>
            {data.length > 0 ? (
              <Cards
                loading={loading}
                onDeleteCard={onDeleteCard}
                onSetFavoriteCard={setFavoriteCard}
                data={data}
              />
            ) : (
              <NoCards />
            )}
          </>
        )} */}
      </div>
    </>
  );
};
export default payment;
