import { GlobalState } from 'store';
import { IUserReducer } from '.';
import { createSelector } from '@reduxjs/toolkit';

export const getState = (state: GlobalState): IUserReducer => state.business;

export const getBusiness = createSelector(getState, (state: IUserReducer) => state.data);

export const getCards = createSelector(getState, (state: IUserReducer) => state.cards);

export const getCognitoUser = createSelector(getState, (state: IUserReducer) => state.dataUser);

export const getBusinessloading = createSelector(getState, (state: IUserReducer) => state.loading);
export const getBusinessOnboarding = createSelector(
  getState,
  (state: IUserReducer) => state.isOnboarding
);

export const getManagedUsers = createSelector(getState, (state: IUserReducer) => state.manageUsers);

export const getEmailRecovery = createSelector(
  getState,
  (state: IUserReducer) => state.emailRecovery
);

export const getLogged = createSelector(getState, (state: IUserReducer) => state.loggedIn);

export const getOnboardingData = createSelector(
  getState,
  (state: IUserReducer) => state.onboardingData
);
