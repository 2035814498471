import { RoleSchema, RoleSchemaType } from 'pages/postShift/core/Modules/Form/Domain/Dtos/Role.dto';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CatalogContext } from 'pages/postShift/core/Infrastructure/Providers/Catalog.provider';
import { set } from 'lodash';
import { DataFormContext } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { entityToFormRole } from 'pages/postShift/core/Modules/Checkout/Domain/Mappers/entityToForm.mapper';
import { useQuery } from '@tanstack/react-query';
import CreateRoleAction from './CreateRole.Action';
import { fetchRoleToForm } from 'pages/postShift/core/Modules/Form/Domain/Mappers/fetchRoleToForm';
import { getTextTranslated } from 'pages/postShift/shared/utils/get-text-translated';

const CreateRolePresenter = () => {
  const action = CreateRoleAction();
  const catalog = useContext(CatalogContext);
  const contextForm = useContext(DataFormContext);
  const [openedModal, setOpenedModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<IItems>();
  const [editingId, setEditingId] = useState<string>();
  const [shouldNotShowSelect, setShouldNotShowSelect] = useState(
    contextForm.mode === 'edit' ? true : false
  );

  const form = useForm({
    resolver: yupResolver(RoleSchema),
    mode: 'all',
    defaultValues: {
      ...(entityToFormRole(contextForm) as object),
    },
  });

  const roles = useQuery(['get-roles'], () => action.invokeGetRoles(catalog));

  const onClickCreateRole = () => {
    setOpenedModal(true);
    setEditingId(undefined);
    form.reset();
  };

  const onSubmit: SubmitHandler<RoleSchemaType> = (data) => {
    const finalValue = set(contextForm.dataForm, 'shiftInfo.role', data);
    contextForm.setDataForm({ ...finalValue });

    if (!editingId) {
      action.invokeSaveRole(data).then(async (role) => {
        await roles.refetch();
        setSelectedRole(role);
      });
    } else {
      action.invokeEditRole(data, selectedRole?.id).then(() => roles.refetch());
    }
    setOpenedModal(false);
  };

  const onCancel = () => setOpenedModal(false);

  const onClickRole = (selected: IItems) => {
    if (catalog) {
      setSelectedRole(selected);
      const getSelectedRole = roles.data?.find((v) => v.id === selected.id);

      contextForm.setDataForm(
        set(contextForm.dataForm, 'shiftInfo.role', fetchRoleToForm(catalog, getSelectedRole))
      );
    }
  };

  const onClickEditRole = () => {
    setEditingId(selectedRole?.id);
    setOpenedModal(true);
    form.reset(entityToFormRole(contextForm) as object);
  };

  const onClickClear = () => {
    form.reset();
    setShouldNotShowSelect(false);
    contextForm.setDataForm(set(contextForm.dataForm, 'shiftInfo.role', undefined));
  };

  // HANDLE SELECTED ROLE

  useEffect(() => {
    if (contextForm?.dataForm?.shiftInfo?.role?.idRole && roles.data) {
      const roleSelected = roles.data?.find(
        (v) => v.id === contextForm.dataForm.shiftInfo?.role.idRole
      );

      if (roleSelected) {
        setSelectedRole({
          id: roleSelected.id,
          text: getTextTranslated(roleSelected.positionTitle),
        });
      }
    }
  }, [roles.data]);

  return {
    openedModal,
    setOpenedModal,
    form,
    onClickCreateRole,
    catalog,
    onSubmit,
    contextForm,
    onCancel,
    roles,
    selectedRole,
    onClickRole,
    onClickEditRole,
    shouldNotShowSelect,
    onClickClear,
  };
};

export default CreateRolePresenter;
