import {
  DataFormContext,
  DataFormType,
} from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { StepperContext } from 'pages/postShift/core/Infrastructure/Providers/Stepper.provider';
import { useContext, useEffect, useState } from 'react';
import ConfirmAction from './Confirm.Action';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const ConfirmPresenter = () => {
  const action = ConfirmAction();
  const [dateConfirmationOpen, setDateConfirmationOpen] = useState(false);
  const stepper = useContext(StepperContext);
  const [openPayment, setOpenPayment] = useState(false);
  const contextForm = useContext(DataFormContext);
  const navigate = useNavigate();

  const onClickEditInfo = () => {
    stepper.changeStep('shiftInfo');
  };

  const onClickEditWorker = () => {
    stepper.changeStep('worker');
  };

  const onClickEditAttire = () => {
    stepper.changeStep('attire');
  };

  const dataForm = contextForm.dataForm;

  const onClickPayment = () => setOpenPayment(true);

  const onClickNext = () => {
    if (contextForm.mode === 'edit') {
      action.executeEditShift(dataForm as DataFormType).then((v) => {
        if (v) {
          toast.success('Success');
          navigate(-1);
        }
      });
    } else {
      setDateConfirmationOpen(true);
    }
  };

  const onClickBack = () => setDateConfirmationOpen(false);

  const onStepperBack = () => stepper.changeStep('attire');

  useEffect(() => {
    if (contextForm.mode === 'draft') {
      setDateConfirmationOpen(true);
    }
  }, [contextForm.mode]);

  return {
    onClickEditInfo,
    onClickEditAttire,
    onClickEditWorker,
    dataForm,
    setOpenPayment,
    onClickPayment,
    openPayment,
    onClickBack,
    onClickNext,
    dateConfirmationOpen,
    setDateConfirmationOpen,
    onStepperBack,
    mode: contextForm.mode,
  };
};

export default ConfirmPresenter;
