import Icon from 'assets/icons';

interface ITooltip {
  children: JSX.Element;
}

/**
 * @deprecated Use `Tooltip` instead.
 */
const Tooltip = ({ children }: ITooltip) => {
  return (
    <div className="">
      <div className="group relative w-max flex">
        <Icon icon={'support'} className="ml-2" size={28} />
        <span className="pointer-events-none absolute -top-12 z-50 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100 bg-primary p-3 rounded-md">
          {children}
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
