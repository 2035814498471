import DisabledAccount from 'components/DisabledAccount';
import LeftNavigation from 'components/LeftNavigation';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getBusiness, getCognitoUser, getLogged } from 'store/business/selectors';
import { RoutesConstant } from 'utils/constants';

interface IRequireAuth {
  children: JSX.Element;
}

const RequireAuth = ({ children }: IRequireAuth): JSX.Element => {
  const location = useLocation();
  const logged = useSelector(getLogged);
  const user = useSelector(getCognitoUser);
  const business = useSelector(getBusiness);

  if (logged === false && !user) {
    return <Navigate to={RoutesConstant.unsecure.login} state={{ from: location }} replace />;
  }

  if (
    logged === undefined ||
    (!business && location.pathname !== RoutesConstant.secure.onBoarding)
  ) {
    return (
      <div
        id="loading-screen"
        className="animate-pulse w-full h-full fixed block top-0 left-0 bg-primary opacity-75 z-50"
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
    );
  }

  return (
    <>
      <DisabledAccount />
      <div className="sm:flex w-full bg-gray-400 ">
        {location.pathname !== RoutesConstant.secure.onBoarding && <LeftNavigation />}
        {children}
      </div>
    </>
  );
};

export default RequireAuth;
