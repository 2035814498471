interface IAvatar {
  name: string;
  width?: string;
  height?: string;
}

const Avatar = ({ name, width, height }: IAvatar) => {
  return (
    <div
      className={`rounded-full bg-secondary-500 ${width ? width : 'w-7'} ${
        height ? height : 'h-7'
      } flex justify-center items-center mr-1 text-white`}
    >
      {name.charAt(0).toUpperCase()}
    </div>
  );
};

export default Avatar;
