import Card from 'components/Card';
import useWindowDimensions from 'utils/hooks/window';
import TabsProfileMemberPayment from '../Tabs';

const PaymentAndMemberCard = () => {
  const { width } = useWindowDimensions();
  return (
    <Card {...(width < 1180 ? { className: 'bg-transparent px-2' } : '')}>
      <TabsProfileMemberPayment />
    </Card>
  );
};

export default PaymentAndMemberCard;
