export default {
  en: {
    success: 'The account has been successfully created',
    create: 'Sign up',
    or: 'Or continue with:',
    name: 'Name',
    email: 'Email',
    confirmEmail: 'Confirm email',
    emailNoMatch: 'Emails do not match',
    password: 'Password',
    confirmPass: 'Repeat password',
    noMatch: 'Passwords do not match',
    acc: 'I accept the ',
    terms: 'terms and conditions',
    accept: 'You must accept the terms and conditions',
    sign: 'Sign up',
    haveAcc: 'Already have an account ',
    login: 'Log in here',
    position: 'What is your position in the company',
    welcome: 'Welcome to Chamba',
    invited: 'You have been invited to join in the ',
    invited2: ' account as a collaborator, click continue to set up',
    continue: 'Continue',
    passwordRequirements: 'Password need to have minimum eight characters and a capital letter',
    chambaProv: 'Chamba provides vetted and insured Pros for your open shifts',
    descriptionImage: 'Chamba fills the requested shifts within 2 hours',
    bySign: 'By sign up you accept our ',
  },
  es: {
    success: 'Se ha creado la cuenta correctamente',
    create: 'Registrarse',
    or: 'O',
    name: 'Nombre',
    email: 'Correo electrónico',
    confirmEmail: 'Confirmar correo electrónico',
    emailNoMatch: 'Los correos no coinciden',
    password: 'Contraseña',
    confirmPass: 'Confirmar contraseña',
    noMatch: 'Las contraseñas no coinciden',
    acc: 'Acepto los ',
    terms: 'términos y condiciones',
    accept: 'Debes aceptar los términos y condiciones',
    sign: 'Crear cuenta',
    haveAcc: 'Tienes una cuenta, ',
    login: 'Ingresar',
    position: 'Cuál es tu posición en la compañía',
    welcome: 'Bienvenido a Chamba business',
    invited: 'Has sido invitado a participar en ',
    invited2: ' como colaborador, haga clic en Continuar para configurar',
    continue: 'Continuar',
    passwordRequirements: 'La contraseña debe tener como mínimo ocho caracteres y una mayúscula',
    chambaProv: 'Chamba ofrece profesionales cualificados y asegurados para su turno',
    descriptionImage: 'Chamba llena los turnos solicitados en 2 horas',
    bySign: 'Al registrarte aceptas nuestros ',
  },
};
