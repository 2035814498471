/* eslint-disable @typescript-eslint/no-unused-vars */

import { ILocationData } from 'actions/shifts.api';
import { createContext } from 'react';

type ProfesionalsContextType = {
  refresh?: boolean;
  setRefresh: (v?: boolean) => void;
  location?: ILocationData;
  name: string;
  setName: (v: string) => void;
};

export const ProfesionalsContext = createContext({} as ProfesionalsContextType);
