export default {
  en: {
    timesheet: 'Timesheet',
    success: 'Success process',
    week: 'Week',
    pending: 'Pending approval',
    approve: 'Approved',
    help: 'How it works',
    helpDescription:
      "In this timesheet page you can view all Chamba Pro's clock in and clock out time. After reviewing the timesheet, you can approve or dispute.",
    helpOperation: 'Chamba operates on a',
    operation: 'weekly basis',
    anyShift: 'For any shifts happening in a week between Monday to Sunday, you have until ',
    time: 'next Monday 10:30am Mountain time',
    dispute:
      'to approve or dispute. Once the week is over, any timesheet that is still in "pending approval" will change to "approved" automatically. ',
    submitDispute:
      'Once you submit the dispute, your account manager will confirm with the Pro and update the change for you if correct. You will receive an email notification once its done.',
    table: {
      name: 'Pros name',
      shiftName: 'Shift name',
      clockIn: 'Clock-In',
      clockOut: 'Clock-Out',
      startBreak: 'Start break',
      endBreak: 'End break',
      total: 'Total paid hours',
      tips: 'Tips',
      rating: 'Rating',
      status: 'Status',
    },
    sheetTip: {
      title: 'Update tip',
      update: 'Update',
      successUpdate: 'Tip updated',
      successExecutions: 'Approved',
      approveSelected: 'Approve selected',
      pending: 'Pending',
      approved: 'Approved',
      approve: 'Approve',
      disputed: 'Disputed',
      createDispute: 'Create dispute',
      favorites: 'Add to favorites',
      block: 'Block user',
      successPreferred: 'User added to favorites',
      successBlock: 'User blocked',
    },
    disputes: {
      title: 'Dispute',
      description: 'What issue you want to dispute',
      clockIn: 'Clock in time',
      clockOut: 'Clock out time',
      breakStart: 'Start break time',
      breakEnd: 'End break time',
      noShow: 'Pro no show',
      error: 'Something went wrong',
      success: 'Dispute created',
      correct: "What's the correct",
      addInformation: 'Additional information',
    },
  },
  es: {
    timesheet: 'Hoja de horas',
    success: 'Proceso exitoso',
    week: 'Semana',
    pending: 'Aprobación pendiente',
    approve: 'Aprobados',
    help: 'Cómo funciona',
    helpDescription:
      'En esta página puede ver todas las horas de entrada y salida de Chamba Pro. Después de revisar la hoja de horas, usted puede aprobar o disputar',
    helpOperation: 'Chamba funciona con un',
    operation: 'semanalmente',
    anyShift: 'Para los turnos que se produzcan en una semana de lunes a domingo, tiene hasta el',
    time: 'proximo lunes 10:30am hora de la montaña',
    dispute:
      'aprobar o impugnar. Una vez finalizada la semana, cualquier hoja de horas que aún esté en "pendiente de aprobación" cambiará a "aprobada" automáticamente. ',
    submitDispute:
      'Una vez que envíe la disputa, su gestor de cuenta confirmará con el Pro y actualizará el cambio para usted si es correcto. Recibirás una notificación por correo electrónico una vez que se haya hecho',
    table: {
      name: 'Nombre del pro',
      shiftName: 'Nombre del turno',
      clockIn: 'Entrada',
      clockOut: 'Salida',
      startBreak: 'Entrada descanso',
      endBreak: 'Salida descanso',
      total: 'Horas totales pagadas',
      tips: 'Propinas',
      rating: 'Calificación',
      status: 'Estado',
    },
    sheetTip: {
      title: 'Update tip',
      update: 'Actualizar',
      successUpdate: 'Tip actualizado',
      successExecutions: 'Aprobados',
      approveSelected: 'Aprobar seleccionados',
      pending: 'Pendiente',
      approved: 'Aprobado',
      disputed: 'Cuestionado',
      approve: 'Aprobar',
      createDispute: 'Crear disputa',
      favorites: 'Añadir a favoritos',
      block: 'Bloquear usuario',
      successPreferred: 'Usuario añadido a favoritos',
      successBlock: 'Usuario bloqueado',
    },
    disputes: {
      title: 'Disputa',
      description: 'Asunto que desea impugnar',
      clockIn: 'Hora de entrada',
      clockOut: 'Hora de salida',
      breakStart: 'Inicio de descanso',
      breakEnd: 'Fin de descanso',
      noShow: 'No se presentó',
      error: 'Ocurrió un error',
      success: 'Se ha presentado la disputa',
      correct: 'Cuál es el correcto',
      addInformation: 'Información adicional',
    },
  },
};
