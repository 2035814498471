import toast from 'react-hot-toast';
import { ApplicantShiftDto, ApplicantShiftSchema } from '../Dtos/ApplicantShift.dto';
import { ApplicantShiftGet } from '../Entities/ApplicantShift.entity';

const dtoToApplicantShift = async (dto: ApplicantShiftDto) => {
  try {
    const validatedDto = await ApplicantShiftSchema.validate(dto);
    return new ApplicantShiftGet(validatedDto);
  } catch {
    toast.error('Something went wrong validating applicant');
  }
};

export default dtoToApplicantShift;
