import { InvoiceUseCase } from 'pages/invoice/core/Modules/Invoices/Application/InvoiceUseCase';
import useUser from 'utils/hooks/useUser';

const invoiceUseCases = new InvoiceUseCase();

const IndexInvoiceAction = () => {
  const {
    business: { id },
  } = useUser();

  const executeGetInvoice = (page: number) => {
    return invoiceUseCases.invokeGetInvoices({
      businessId: id,
      page,
    });
  };

  return { executeGetInvoice };
};

export default IndexInvoiceAction;
