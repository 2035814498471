import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputFilter extends InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: JSX.Element;
}

const InputFilter = ({ className, prefixIcon, ...props }: InputFilter) => {
  return (
    <div className="flex relative">
      {prefixIcon && <div className="absolute pl-3 pt-1.5">{prefixIcon}</div>}
      <input
        className={twMerge(
          'border-[1px] border-neutral-400 placeholder:text-neutral-400 rounded-xl py-1.5 w-full',
          className,
          prefixIcon ? 'pl-7' : 'px-3 '
        )}
        {...props}
      />
    </div>
  );
};

export default InputFilter;
