import { UserRepositoryType } from 'pages/timesheet/core/Infrastructure/Contracts/User.contract';
import { BlockUserSet, CreatePreferredUserSet } from '../Entities/User.entity';
import instance from 'actions/http';
import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';

export class UserRepository implements UserRepositoryType {
  public async createPreferredUser(d: CreatePreferredUserSet) {
    await instance.post(getEndpoint(API_ENDPOINTS.USERS.CREATE_PREFERRED_USER), d);
  }

  public async blockUser(d: BlockUserSet) {
    await instance.post(getEndpoint(API_ENDPOINTS.USERS.CREATE_BLOCKED_USER), d);
  }
}
