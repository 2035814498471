import { useState } from 'react';
import { useTranslation } from 'react-i18next';

enum Step {
  UPLOAD = 0,
  REPORT = 1,
}

export const IndexPresenterMassive = () => {
  const [t] = useTranslation(['Massive']);
  const [step, setStep] = useState<Step>(Step.UPLOAD);

  return { step, t, Step, setStep };
};
