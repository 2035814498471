import Card from 'components/Card';
import useUser from 'utils/hooks/useUser';
import ItemCard from './ItemCard';
import { useContext, useEffect, useState } from 'react';
import { ProfileContext } from 'pages/profile/provider';
import Input from 'components/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import SelectPost from 'components/SelectPost';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import ProfileImage from '../ProfileImage';
import { useMutation } from '@tanstack/react-query';
import BusinessService from 'actions/business';
import { useAppDispatch } from 'store';
import { GetBusinessThunk } from 'store/business/api.thunks';

interface IFormCompany {
  email: string;
  companyCategory: IItems;
  name: string;
  phone: string;
}

const CompanyCard = () => {
  const {
    business: { name, email, type, phoneNumber, id },
  } = useUser();
  const [t] = useTranslation(['Profile']);
  const [editMode, setEditMode] = useState(false);
  const { data } = useContext(ProfileContext);
  const { mutateAsync } = useMutation(BusinessService.editBusinessProfile);
  const dispatch = useAppDispatch();

  const selectedType = data?.businessTypes?.find((e) => e.id === type);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormCompany>({
    defaultValues: {
      companyCategory: selectedType ?? { id: '0', text: t<string>('company.category') },
      phone: phoneNumber,
      email,
      name,
    },
  });

  useEffect(() => {
    if (selectedType) {
      setValue('companyCategory', selectedType);
    }
  }, [selectedType]);

  const onSubmit: SubmitHandler<IFormCompany> = (data) => {
    mutateAsync({
      id,
      email: data.email,
      name: data.name,
      phoneNumber: data.phone,
      type: data.companyCategory.id,
    }).then(() => {
      dispatch(GetBusinessThunk(id));
      setEditMode(false);
    });
  };

  return (
    <>
      <h2 className="text-primary text-3xl pb-0 font-bold">{t('title')}</h2>
      <p className="py-3 font-semibold text-grayText mb-4">{t('company.title')}:</p>
      <Card>
        {editMode && <p className="text-sm pt-1 pb-4 text-primary">Company photo:</p>}
        <div className="flex w-full justify-between">
          <ProfileImage editMode={editMode} />
          {!editMode && (
            <Button name={t('company.edit')} size="sm" onClick={() => setEditMode(true)} />
          )}
        </div>
        {editMode ? (
          <>
            <p className=" text-sm pt-2 pb-1 text-primary">{t('company.info')}:</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input name="name" placeholder={t('company.name')} register={register} type="text" />

              <Input
                name="email"
                disabled
                placeholder="Full name"
                register={register}
                type="text"
              />

              <Input
                name="phone"
                placeholder={t('company.phone')}
                register={register}
                type="text"
              />

              <Controller
                control={control}
                name="companyCategory"
                rules={{
                  required: true,
                  validate: (value: IItems) => value.id !== '0' || t<string>('required'),
                }}
                render={({ field: { onChange, value } }) => (
                  <SelectPost
                    customStyle="mt-5 flex  text-left bg-white font-medium border-2 border-[#EDEDED] hover:border-2 hover:border-secondary-500 placeholder-primary text-gray-900 text-sm sm:text-base items-center appearance-none rounded-2xl relative w-full px-4 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                    items={data?.businessTypes ?? []}
                    itemSelected={value}
                    setSelectedItem={(type) => {
                      onChange(type);
                    }}
                    errors={errors.companyCategory}
                    register={register}
                    validation={{
                      required: true,
                      validate: (value: IItems) => value.id !== '0' || t<string>('required'),
                    }}
                  />
                )}
              />
              <br />
              <Button type="submit" size="md" name={t('company.save')} style={{ width: '100%' }} />
            </form>
          </>
        ) : (
          <>
            <hr className="my-2.5 w-full" />

            <ItemCard attribute={t('emailAddress')} icon="Envelope" value={email} />
            <hr className="my-2.5 w-full" />

            <ItemCard
              attribute={t('category')}
              icon="case"
              value={selectedType?.text ?? 'Not selected'}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default CompanyCard;
