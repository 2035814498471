import Icon from 'assets/icons';
import SelectV2 from 'components/SelectV2';
import {
  BlockUserSet,
  CreatePreferredUserSet,
} from 'pages/timesheet/core/Modules/Timesheet/Domain/Entities/User.entity';
import DisputeScreen from 'pages/timesheet/screens/Dispute/Dispute.Screen';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useUser from 'utils/hooks/useUser';

enum Options {
  CREATE_DISPUTE = 0,
  FAVORITE = 1,
  BLOCK = 2,
}

interface DotMenuType {
  id: string;
  name: string;
  isActive: boolean;
  onPreferredUser: (d: CreatePreferredUserSet) => Promise<void>;
  onBlockUser: (d: BlockUserSet) => Promise<void>;
  executionId: string;
  refetch: () => void;
}

const DotMenu = ({
  id,
  name,
  isActive,
  onPreferredUser,
  onBlockUser,
  executionId,
  refetch,
}: DotMenuType) => {
  const [selectedOption, setSelectedOption] = useState<IItems>();
  const [disputeModal, setDisputeModal] = useState(false);

  const navigate = useNavigate();
  const [t] = useTranslation(['Timesheet']);
  const {
    business: { id: businessId },
  } = useUser();

  useEffect(() => {
    switch (selectedOption?.id) {
      case Options.BLOCK.toString():
        onBlockUser({
          userId: id,
          businessId: businessId,
        });

        break;

      case Options.FAVORITE.toString():
        onPreferredUser({
          userId: id,
          businessId: businessId,
        });

        break;

      case Options.CREATE_DISPUTE.toString():
        setDisputeModal(true);
        break;
      default:
        break;
    }
  }, [selectedOption, id, name, navigate]);

  const options: IItems[] = [
    {
      id: Options.CREATE_DISPUTE.toString(),
      text: t('sheetTip.createDispute'),
    },
    {
      id: Options.FAVORITE.toString(),
      text: t('sheetTip.favorites'),
    },
    {
      id: Options.BLOCK.toString(),
      text: t('sheetTip.block'),
    },
  ];

  return (
    <div className="ml-5">
      <SelectV2
        itemSelected={undefined}
        items={options}
        name="Options"
        customContent={<Icon icon="dots" size={12} />}
        setSelectedItem={setSelectedOption}
        showDropDownArrow={false}
        disabled={!isActive}
      />

      <DisputeScreen
        refetch={refetch}
        open={disputeModal}
        setOpen={setDisputeModal}
        executionId={executionId}
      />
    </div>
  );
};

export default DotMenu;
