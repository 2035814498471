import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

interface ISwitch {
  enabled: boolean;
  setEnabled: (value: boolean) => void;
}

const SwitchComponent = ({ enabled, setEnabled }: ISwitch) => {
  const [t] = useTranslation(['Others']);

  return (
    <div className="">
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${enabled ? 'bg-[#117AF4]' : 'bg-gray-700'}
           relative inline-flex flex-shrink-0 h-[17px] w-[33px] lg:h-[20px] lg:w-[35px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">{t('use')}</span>
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[13px] w-[13px] lg:h-[16px] lg:w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
        />
      </Switch>
    </div>
  );
};

export default SwitchComponent;
