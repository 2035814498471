export default {
  es: {
    login: {
      fail: 'Usuario o Contraseña incorrecta',
      verified: 'Cuenta no verificada',
      required: 'Se requiere cambio de contraseña',
      exist: 'El usuario no existe',
      invalid: 'Usuario o contraseña inválidos',
      default: 'No se ha podido leer la información del usuario, por favor inténtelo nuevamente.',
    },
    success: 'Registro exitoso, tu cuenta ha sido verificada, puedes iniciar sesión.',
    errorCreate: 'No se ha podido consultar la información del perfil en la base de datos.',
    executed: 'Se ejecutó el cambio.',
    errorExecute: 'La información del perfil no pudo ser consultada en la base de datos.',
    consultInfo: 'No se ha podido consultar la información del perfil en la base de datos.',
    tasker: {
      info: 'No se ha podido consultar la información del tasker.',
    },
    consultError: 'No se ha podido consultar la información del usuario.',
    appError: 'No se ha podido consultar la información de la aplicación',
    taskerAction: 'No se ha podido consultar la lista de trabajadores.',
    expertError: 'No se ha podido consultar la lista de trabajadores.',
    code: {
      mismatch: 'El código no es correcto, por favor revisar',
      expired: 'Código expirado, por favor envíe otro',
      limit: 'Límite excedido por favor espere',
      many: 'Demasiados intentos',
    },
  },
  en: {
    success: 'Registration successful, your account has been verified, you can login.',
    errorCreate: 'Profile information could not be consulted in the database.',
    executed: 'The change was executed.',
    errorExecute: 'The profile information could not be consulted in the database.',
    consultInfo: 'The profile information could not be consulted in the database.',
    login: {
      fail: 'Incorrect user or password',
      verified: 'Unverified account',
      required: 'Password change required',
      exist: "User doesn't exist",
      invalid: 'Invalid username or password',
      default: 'The user information could not be read, please try again.',
      tasker: {
        info: 'The information of the tasker could not be consulted.',
      },
      consultError: 'The user information could not be consulted.',
      appError: 'The application information could not be consulted',
      serviceConsult: 'No se ha podido consultar la lista de servicios.',
      taskerAction: 'The list of workers could not be consulted.',
      expertError: 'The list of workers could not be consulted.',
    },
    code: {
      mismatch: 'Incorrect code, please try again',
      expired: 'Expired code, please send again',
      limit: 'Limit had been reached, please wait',
      many: 'Too many tries',
    },
  },
};
