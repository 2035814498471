import { GetApplicantResume, IApplicantResume } from 'actions/shifts.api';
import { Sheet, SheetContent } from 'components/Sheet';
import StarRating from 'components/StarsRating';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

type SheetProfileProps = {
  idProfile: string;
  selected?: boolean;
  setSelected: (s?: string) => void;
  actionButtons: JSX.Element;
};

const SheetProfile = ({ selected, idProfile, setSelected, actionButtons }: SheetProfileProps) => {
  const [details, setDetails] = useState<IApplicantResume>();
  const [t] = useTranslation(['Shifts']);

  useEffect(() => {
    if (idProfile !== undefined && selected) {
      GetApplicantResume(idProfile).then((e) => {
        setDetails(e);
      });
    }
  }, [selected, idProfile]);

  return (
    <Sheet open={selected} onOpenChange={(e) => !e && setSelected(undefined)}>
      <SheetContent>
        <div className="overflow-y-auto mt-9">
          <div
            className="w-24 h-24 rounded-full max-w-3xl m-auto"
            style={{
              backgroundImage: `url(${details?.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
          <p className="text-center mt-4 font-semibold text-primary text-base">{details?.name}</p>
          {details && (
            <div className="text-secondary-500 flex justify-center mt-2">
              <StarRating
                defaultRating={details?.rating ?? 0}
                rates={details?.ratesNumber}
                size={12}
                disabled
              />
            </div>
          )}

          <p className="text-neutral-800 font-normal text-xs text-center mt-1">
            {details?.location ?? <Skeleton />}
          </p>
          <div>{actionButtons && actionButtons}</div>

          <hr className="mt-4" />

          <p className="mt-3 text-neutral-800 font-medium text-xs">
            {t('proList.workExperience')}:
          </p>

          {details?.resume.workExperiences.map((e, key) => (
            <div className="" key={key}>
              <p className="text-primary text-[13px] font-semibold mt-2">{e.position}</p>
              <p className="text-[13px] text-primary font-normal">
                {e.workedTime} - {e.employer}
              </p>
            </div>
          ))}

          <p className="mt-3 text-neutral-800 font-medium text-xs">{t('proList.education')}:</p>

          <p className="text-[13px] text-primary">{details?.resume.educationLevel}</p>

          <p className="mt-3 text-neutral-800 font-medium text-xs">{t('proList.language')}:</p>
          <p className="text-primary text-[13px]">
            {t('proList.spanish')} - {details?.resume.spanishLevel}
          </p>
          <p className="text-primary text-[13px]">
            {t('proList.english')} - {details?.resume.englishLevel}
          </p>

          <p className="mt-3 text-neutral-800 font-medium text-xs">{t('proList.skills')}:</p>

          <div className="flex flex-wrap mt-2">
            {details?.resume.skills.map((e, key) => (
              <div
                className="bg-secondary-500 mr-4 rounded-md text-white py-2 px-[10px] text-[10px] mt-1 font-medium"
                key={key}
              >
                {e}
              </div>
            ))}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SheetProfile;
