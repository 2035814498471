import Icon from 'assets/icons';
import Button from 'components/Button';
import FloatingDialog from 'components/FloatingDialog';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const BANK_INFO = {
  number: '3915858367',
  routing: '043000096',
  address: '2785 w 104th pl Westminster CO 80234',
  companyName: 'Chamba Inc.',
  phone: '(303) 943-4469',
};

const ModalBankInfo = () => {
  const [openModal, setOpenModal] = useState(false);
  const [t] = useTranslation(['Invoice']);
  return (
    <div>
      <Button name={t('bankInfo')} size="xs" onClick={() => setOpenModal(true)} />

      <FloatingDialog
        isOpen={openModal}
        setIsOpen={setOpenModal}
        className="bg-white py-6 px-5 rounded-xl top-10 absolute w-full max-w-lg"
      >
        <div>
          <h3 className=" text-xl text-primary font-bold">Chamba Info</h3>
          <div>
            <p className="text-xl text-black font-semibold mt-2.5">
              {t('account')}: <span className="font-normal">{BANK_INFO.number}</span>
              <CopyToClipboard text={BANK_INFO.number}>
                <button>
                  <Icon icon="copy" size={17} className="text-secondary-200 -mt-1 ml-2" />
                </button>
              </CopyToClipboard>
            </p>
          </div>

          <div>
            <p className="text-xl text-black font-semibold mt-2.5">
              {t('routingNumber')}: <span className="font-normal">{BANK_INFO.routing}</span>
              <CopyToClipboard text={BANK_INFO.routing}>
                <button>
                  <Icon icon="copy" size={17} className="text-secondary-200 -mt-1 ml-2" />
                </button>
              </CopyToClipboard>
            </p>
          </div>

          <div>
            <p className="text-xl text-black font-semibold mt-2.5">
              {t('address')}: <span className="font-normal">{BANK_INFO.address}</span>
              <CopyToClipboard text={BANK_INFO.address}>
                <button>
                  <Icon icon="copy" size={17} className="text-secondary-200 -mt-1 ml-2" />
                </button>
              </CopyToClipboard>
            </p>
          </div>

          <div>
            <p className="text-xl text-black font-semibold mt-2.5">
              {t('companyName')}: <span className="font-normal">{BANK_INFO.companyName}</span>
              <CopyToClipboard text={BANK_INFO.companyName}>
                <button>
                  <Icon icon="copy" size={17} className="text-secondary-200 -mt-1 ml-2" />
                </button>
              </CopyToClipboard>
            </p>
          </div>

          <div>
            <p className="text-xl text-black font-semibold mt-2.5">
              {t('phone')}: <span className="font-normal">{BANK_INFO.phone}</span>
              <CopyToClipboard text={BANK_INFO.phone}>
                <button>
                  <Icon icon="copy" size={17} className="text-secondary-200 -mt-1 ml-2" />
                </button>
              </CopyToClipboard>
            </p>
          </div>
        </div>
      </FloatingDialog>
    </div>
  );
};

export default ModalBankInfo;
